import SockJS from "sockjs-client";
import { over } from 'stompjs';
import config from 'react-global-configuration';
import log from 'loglevel';

export default class WebsocketUtil {
 
  constructor() {
  }

  tries=0;

  disconnect(disconnectCallback) {
    this.valid=false;
    if(this.connected()) {
      this.stompClient.disconnect(disconnectCallback);
    }
    this.close();
    if(this.topic) {
      log.debug("SockJS disconnected  "+this.topic+ " clear interval: "+this.timerCurrent);
    } else {
      log.debug("SockJS disconnect called, but there was no connection.");
    }
    clearInterval(this.timerCurrent);    
  }

  connect(frame,topic,connectCallback, processMessageCallback) {
    log.debug("SockJS Connected  "+topic);
    this.stompClient.subscribe(topic,(msg) => processMessageCallback(JSON.parse(msg.body)));
    log.debug("Now issue connectCallback ... ");
    clearInterval(this.timerCurrent);
    connectCallback();
  }

  error(frame,topic, connectCallback, processMessageCallback, errorCallback) {
    log.debug("SockJS Connect Error  "+topic);
    log.debug("SockJS Connect Error frame  "+frame);
    if(errorCallback) {
      errorCallback();
    }
    
    if(this.tries<3 && this.valid) {
        log.debug(this.tries+". attempt - retry connect SockJS");
        clearInterval(this.timerCurrent);
        this.websocket(topic, connectCallback, processMessageCallback, errorCallback);
    } else {
      log.debug(this.tries+". attempt not issuing - Max tries reached OR not valid anymore (error returned after view was changed). SockJS");      
    }
  }

  //https://umes4ever.medium.com/real-time-application-using-websocket-spring-boot-java-react-js-flutter-eb87fe95f94f
  websocket(topic, connectCallback, processMessageCallback, errorCallback) {
    log.debug("websocket to topic: "+topic)
    if(!topic || !connectCallback) {
      log.debug("SockJS insufficent websocket config data - will not connect!! "+topic+" - "+connectCallback);
      return;
    }
    this.topic=topic;
    this.valid=true;
    this.tries++;
    /* this.timerCurrent = window.setInterval(() => {
        log.debug("SockJS Issue timed callback since websocket is not established yet  "+topic);
        connectCallback();
    }, 5000);
    */
    this.topic=topic;
    this.sockjs = new SockJS(config.get('broadcaster')+'/ws/ws-tickets');
    this.stompClient = over(this.sockjs);
    this.stompClient.debug = null;
    this.stompClient.connect({},(frame) => this.connect(frame, topic, connectCallback, processMessageCallback), (frame) => this.error(frame, topic, connectCallback, processMessageCallback, errorCallback));
  }

  initialized() {
    return this.stompClient!=null;
  }

  connected() {
    return this.stompClient && this.stompClient.connected;
  }

  close() {
    if(this.sockjs && this.stompClient.connected) {
      this.sockjs.close();
    }
  }

};
