import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { MyLocation, Save } from '@material-ui/icons';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));





function EditPlace(props) {
  const { t } = props;
  const classes = useStyles();
  const { vPlace } = props.location;
  const { vDomain } = props.location;

  const [domain] = useState(vDomain?vDomain:SessionContext.getDomain());
  const [place, getPlace] = useState(vPlace?vPlace:SessionContext.getPlace());
  const [address, setAddress] = useState(place?place.address:{});


  const [name, setName] = useState(place?place.name:"");
  const [placeUid] = useState(place?place.placeUid:"");

  const [decription, setDecription] = useState(place?place.decription:"");
 
  const { addMessage } = useContext(MessageContext)

  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("place: " + JSON.stringify(place));
    SessionContext.setPlace(place);
  }, [] )

  function validateForm() {
    console.log("place: " + place);
    return (place.id || domain) && name && name.length > 0;
  }


  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/places/'+place.id;
    axios.delete(url,  place )
        .then(res => {
          history.push("/my/places");
          addMessage(t("place_deleted"));
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    if(event) {
      event.preventDefault();
    }
    console.log("Save Place.")
    let tPlace = place;
    if(!tPlace) {
      tPlace = {};
    }

    tPlace.name = name;
    tPlace.decription = decription;
    tPlace.address = address;
    tPlace.domain = domain;

    const url = config.get('vqueueUrl')+'/api/places';
    let pushData = {pathname: "/my/places",  vDomain: domain};
    if(!tPlace.id) {
        axios.post(url,  tPlace )
        .then(res => {
          history.push(pushData);
          //addMessage(t("place_created"));
        }).catch(function (error) {
          console.log(error);
        })
     } else {
        axios.put(url,  tPlace )
        .then(res => {
          history.push(pushData);
          //addMessage(t("place_saved"));
        }).catch(function (error) {
          console.log(error);
        })
      }
  }

  

  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link onClick={() => handleSubmit()} style={!validateForm()?{pointerEvents: "none", color: "#ffb1b1"}:{cursor: "pointer"}}>
              <Save />            
        </Link>
    );
    return quickActionItems;
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
        title={<Fragment><Crumbs domain={domain}/> : <Fragment>{vPlace?t("change_place"):t("create_place")}</Fragment></Fragment>}
        backTo="/my/places" 
        quickActions={getQuickActionItems()}/>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MyLocation />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("place")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="name"
                variant="outlined"
                value={name}
                required
                fullWidth
                id="name"
                label={t("place_name")}
                autoFocus
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{width:"100%"}}
                label={t("description")}
                variant="outlined"
                fullWidth
                value={decription}
                id="decription"
                multiline
                rows={4}
                name="decription"
                autoComplete="fcolor"
                onChange={(e) => setDecription(e.target.value)}
              />
            </Grid>
            
            <EditLocation vAddress={address} setAddress={(newAdress) => { setAddress(newAdress) }} key={setAddress}/>
            
          </Grid>


          <Grid container spacing={2}>
              <Grid item  xs={6} >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!validateForm()}
                >
                  {t("save")}
                </Button> 
              </Grid>
              <Grid item  xs={6} >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => deleteEntity()}
                  disabled={!place || !place.id}
                >
                  {t("delete")}
                </Button>  
              </Grid>
          </Grid>
         
                    
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditPlace);