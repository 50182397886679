import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

class TermsOfUse extends Component {

  constructor() {
    super();
    
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <DeskHeader title={t("use_conditions")}  />
        <Container maxWidth="md"  style={{position: 'relative', marginTop:"80px", marginBottom:"100px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                {t("use_conditions")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                {t("your_data_belongs_to_us")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary" >
                {t("yes_understood")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <AppFooter />
          
    </div>
      
    );
  }
}

export default withTranslation()(TermsOfUse);