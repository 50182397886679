import React, { useState,useEffect, Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import TicketsListCalendar from './TicketsListCalendar';
import log from 'loglevel';
import { MessageContext } from '../pages/assets/MessageProvider';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




export default function TicketsListLoader(props) {
  const classes = useStyles();
  const { queue } = props;
  const { manage } = props;
  const { offsetFrom } = props;
  const { offsetTo } = props;
  const { partitionResourceDTO } = props;
  const { createTicketLink } = props;
  const { from } = props;
  const { filters } = props;
  
  const { to } = props;
  const { lastUpdate } = props;
  const { backTo } = props;
  
  const [tickets, setTickets] = useState(null);
  const { wsTickets, removeWsTickets } = useContext(MessageContext); // Zugriff auf wsTickets im MessageContext

  const [timer, setTimer] = useState(null);

  const [ready , setReady] = useState(false);

  const abortController = new AbortController();

  useEffect(() => {
    log.debug("TicketsListLoader useEffect");
    loadTickets();
  }, [] ) // Trigger the effect only once, when component mounts

  useEffect(() => {
    if (wsTickets) {
      clearTimeout(timer);
      log.debug("TicketsListLoader useEffect wsTickets");
      if (wsTickets) {
        setTimer(setTimeout(() => {
          removeWsTickets();
          setReady(false);
          loadTickets();
        }, 500)); // Timer von 5 Sekunden, angepasst an Ihre Anforderungen
      }
      
    }
  }, [wsTickets] ) // Trigger the effect when wsTickets changes

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer); // Timer zurücksetzen, wenn die Komponente entladen wird
      }
    };
  }, [timer]);

  const loadTickets = () => {
    let filterString = "".concat("PREPARING").concat(",").concat("WAITING").concat(",").concat("FINALIZING").concat(",").concat("PROCESSING").concat(",").concat("BLOCKER");
    let url = config.get('vqueueUrl')+'/api/ticketByQID/'+queue.id+"/"+from+"/"+to+"/"+filterString;
    axios.get(url, {signal: abortController.signal})
          .then(response => { 
                let tickets = response.data;
                log.debug("goTickets ! "+tickets.length);
                setTickets(tickets);         
          }
      ); 
  }

  
  
  return (
    <Fragment>
      { tickets &&
        <TicketsListCalendar 
          offsetFrom={offsetFrom} 
          offsetTo={offsetTo} 
          partitionResourceDTO={partitionResourceDTO}
          createTicketLink={createTicketLink} 
          queue={queue} 
          from={from} 
          filters={filters} 
          to={to}  
          tickets={tickets}
          hideStatus={true} 
          hideFrame={false} 
          backTo={backTo}
          small={true}
          ready={ready}
          readyStatusCallback={setReady}
          timeline={true}
          popupable={true} 
          manage={manage} 
          lastUpdate={lastUpdate}>
          
        </TicketsListCalendar>
     }
    </Fragment>
  );
}
