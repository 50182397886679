import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, Input, InputLabel, ListItemText, OutlinedInput, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import Crumbs from '../queue/Crumbs';
import { Save } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { CirclePicker, SketchPicker } from 'react-color';
import { Badge } from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function EditCategory(props) {
  const {t} = props;
  const classes = useStyles();
  const { category } = props.location;
  const { domain } = props.location;
  const { vBackTo } = props.location;
  const [backTo] = useState(vBackTo?vBackTo:SessionContext.getBackTo());
  
  const [name, setName] = useState(category?category.name:"");
  const [color, setColor] = useState(category?category.color:"#fff");
  const [priority, setPriority] = useState(category?category.priority:"");
  const [categoryConfig, setCategoryConfig] = useState(category?category.config:"{}");
  const [standardTicketRedeemDuration, setStandardTicketRedeemDuration] = useState(category?category.standardTicketRedeemDuration:"");
  const [standardTicketProcessingDuration, setStandardTicketProcessingDuration] = useState(category?category.standardTicketProcessingDuration:"");
  const [standardTicketExtendDuration, setStandardTicketExtendDuration] = useState(category?category.standardTicketExtendDuration:"");
  const [standardTicketExpiryDuration, setStandardTicketExpiryDuration] = useState(category?category.standardTicketExpiryDuration:"");
  const [standardCallingTimeDuration, setStandardCallingTimeDuration] = useState(category?category.standardCallingTimeDuration:"");
  
  const [standardStateChangeCallback, setStandardStateChangeCallback] = useState(category?category.standardStateChangeCallback:"");
  const [standardStateChangeCallbackMethod, setStandardStateChangeCallbackMethod] = useState(category?category.standardStateChangeCallbackMethod:"");
  
  const [categoryResources, setCategoryResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [resources, setResources] = useState([]);
  
  const { addMessage } = useContext(MessageContext)

  const history = useHistory();
  const styles = theme => ({
    margin: {
      margin: theme.spacing.unit * 2
    },
    customBadge: {
      backgroundColor: color,
      color: "white"
    }
  });

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("category: " + JSON.stringify(category));
    SessionContext.setBackTo(backTo);
    SessionContext.setCategory(category);    
    let uri = '/api/resources'; //später noch auf den Ort einschränken
    let url = config.get('vqueueUrl')+uri;
    axios.get(url )
        .then(res => {
          let allResources = res.data;
          setResources(allResources);
          uri = '/api/category-resources'; //später noch auf diw Kategorie einschränken
          url = config.get('vqueueUrl')+uri;
          axios.get(url ).then(res => {
            setCategoryResources(res.data);
            let selectedResources = [];
            res.data.forEach (function (aCategoryResources, index) {
              let defaultResource = allResources.filter((aResource) => {
                return aResource.id==aCategoryResources.resource.id; 
              }) 
              let aSelectedResource = defaultResource[0];
              selectedResources.push(aSelectedResource);
            });
            setSelectedResources(selectedResources);
          }).catch(function (error) {
            console.log(error);
            addMessage(error.detail);
          })
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })
    
  }, [] )

  function validateForm() {
    console.log("category: " + JSON.stringify(category));
    return name && name.length > 0 &&  standardTicketProcessingDuration && standardTicketProcessingDuration>0;
  }

  function handleColorChangeComplete(color, event) {
    setColor(color.hex );
  };

  function millisToMinuten(millis) {
      return millis / 1000 / 60;
  }

  function minutenToMillis(minuten) {
      return minuten * 1000 * 60;
  }

  function changeSelectedResources(e) {
    console.log("changeSelectedResources - "+e);
    let selectedOnes = e.target.value;
    /*let newSelection = [];
    selectedOnes.forEach(function(aResource,index) {
      let exists = selectedResources.filter(r => r.id === aResource.id).length > 0;
      if(!exists) {
        newSelection.push(aResource);
      }
    })*/
    
    setSelectedResources(e.target.value);
  }

  function getSelectedResources() {
    let values = [];
    selectedResources.forEach (function (aCategoryResources, index) {
      let aValue = aCategoryResources.name;
      aValue=aValue+" ("+aCategoryResources.resourceVariant.name+")"
      if(index+1<selectedResources.length) {
        aValue=aValue+", ";
      }
      values.push(aValue);
    });
    return values;
  }

  function deleteCategory() {
    const url = config.get('vqueueUrl')+'/api/categories/'+category.id;
    axios.delete(url,  category )
        .then(res => {
          history.push(backTo);
          addMessage(t("category_deleted"));
          
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log(t("save_category"))
    let category = props.location.category;
    if(!category) {
      category = {};      
    }
    category.name = name;
    category.color = color;
    category.priority = priority;
    category.config = categoryConfig;
    
    category.standardTicketRedeemDuration = standardTicketRedeemDuration;
    category.standardTicketProcessingDuration = standardTicketProcessingDuration;
    category.standardTicketExtendDuration = standardTicketExtendDuration;
    category.standardTicketExpiryDuration = standardTicketExpiryDuration;
    category.standardCallingTimeDuration = standardCallingTimeDuration;
    category.standardStateChangeCallback = standardStateChangeCallback;
    category.standardStateChangeCallbackMethod = standardStateChangeCallbackMethod;
    
    category.selectedResources = selectedResources;

    category.queue=SessionContext.getActiveQueue();

    
    let url = config.get('vqueueUrl');
    if(!category.id) {
      let categoryMapping = {};
      /*if(queue) {
        url = url+'/api/category-queues';
        categoryMapping.queue=queue;
      } else */
      if(domain) {
        url = url+'/api/category-domains';
        categoryMapping.domain=domain;
      }
      categoryMapping.category=category;
      axios.post(url,  categoryMapping )
        .then(res => {
          history.push(backTo);
          addMessage(t("category_saved"));
          //submitCategoryResources(res.data);
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          
        })
     } else {
      url = url+'/api/categories';
        axios.put(url,  category )
        .then(res => {
          history.push(backTo);
          addMessage(t("category_saved"));
          //submitCategoryResources(res.data,0);
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          
        })
      }
  }

  function submitCategoryResources(category, index) {
    console.log("submitCategoryResources.")
    const url = config.get('vqueueUrl')+'/api/category-resources';
    let aCategoryResource = categoryResources[index]
    if(aCategoryResource) {
      axios.delete(url+"/"+aCategoryResource.id )
        .then(res => {
          submitCategoryResources(category, index+1);
        }).catch(function (error) {
          // handle error
          addMessage(t("mapping_failure_DEL"));          
          console.log(error);
        })
    } else {
      selectedResources.forEach(function(aSelectedResource, index) {
        let categoryResources = {
          category: category,
          resource: aSelectedResource
        }
        axios.post(url,  categoryResources )
          .then(res => {
          //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
          }).catch(function (error) {
            // handle error
            addMessage(t("mapping_failure_ADD"));          
            console.log(error);          
          })
      })
    }
  }

  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link onClick={(e) => handleSubmit(e)} style={!validateForm()?{pointerEvents: "none", color: "#ffb1b1"}:{cursor: "pointer"}}>
          <Save />            
      </Link>
    );
    return quickActionItems;
  }

  function update (name, millis, e, cfg, aValue, float)  {
    
    console.log("update."+name+" - "+millis+" - "+e.target.value)
    let value = e.target.value;
      
      
      if(aValue != undefined && aValue) {
        value = aValue;
      } else if(!value) {
        value = e.target.textContent; //mui slider value is here
      }
      if(millis && !isNaN(value)) {
        value = value*1000*60;
      }
      if(float) {
        value = value.replace(",", ".");
      }

      let lConfig = JSON.parse(categoryConfig);
      if(!lConfig) {
        lConfig = {};      
      }
      lConfig[name] = value;
      lConfig = JSON.stringify(lConfig);
      setCategoryConfig(lConfig );
  }

  return (
     backTo && <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader backTo={backTo}
        title={<Fragment><Crumbs domain={domain} queue={SessionContext.getActiveQueue()}/> : <Fragment>{category.name?t("edit_cateory"):t("create_cateory")}</Fragment></Fragment>}
        quickActions={getQuickActionItems()}  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("category")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <TextField
                style={{backgroundColor:color}}
                inputProps={{style:{backgroundColor:"rgba(255, 255, 255, 0.65)", padding:"6px", margin:"10px", fontSize: "1.5rem"}}}
                InputLabelProps={{style:{backgroundColor:"rgba(255, 255, 255, 0.65)"}}}
                autoComplete="fname"
                name="name"
                variant="outlined"
                value={name}
                required
                fullWidth
                id="name"
                label={t("category_name")}
                autoFocus
                onChange={(e) => setName(e.target.value)}
                
              />
            </Grid>
            <Grid item xs={12}>
              { <CirclePicker 
                onChangeComplete={ handleColorChangeComplete } /> }
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h5">
                {t("timings")}
              </Typography>
            </Grid>
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                 <TextField type="number" 
                    autoComplete="fcycles"
                    name="cycles_units_pieces"
                    variant="outlined"
                    value={standardTicketProcessingDuration} 
                    fullWidth
                    id="cycles_units_pieces"
                    label={t("necessary_work_cycles")}
                    onChange={(e) => setStandardTicketProcessingDuration(e.target.value)}
                  />
              
              </FormControl>
            </Grid>
              <Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <TextField style={{width:"100%"}}
                    label={t("externalContextScope")}
                    variant="outlined" 
                    value={categoryConfig?JSON.parse(categoryConfig).externalContextScope:""}
                    onChange={(e) => update("externalContextScope", false, e, true, false, false)}/>
                </FormControl>
              </Grid>
           
            {/*
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                fullWidth
                value={color}
                id="color"
                label="Farbe"
                name="color"
                autoComplete="fcolor"
                onChange={(e) => setColor(e.target.value)}
              />
            </Grid>
            
            <Grid item  xs={12}>
              Priorität
            </Grid>
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">Priorität</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={priority} 
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-multiple-checkbox-label">Ressourcen</InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedResources}
                  onChange={changeSelectedResources}
                  input={<OutlinedInput label="Ressourcen" />}
                  renderValue={getSelectedResources}
                  MenuProps={MenuProps}
                >
                  {resources.map((aResource, index) => (
                    <MenuItem key={aResource.id} value={aResource}>
                      <Checkbox checked={selectedResources.filter(r => r.id === aResource.id).length > 0} />
                      <ListItemText primary={aResource.name+" ("+aResource.resourceVariant.name+") "} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
                  

            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("duration_last_call")}</InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  label={t("duration_last_call")}
                    value={millisToMinuten(standardTicketRedeemDuration)} 
                  onChange={(e) => setStandardTicketRedeemDuration(minutenToMillis(e.target.value))}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("call_advance_time")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("call_advance_time")}
                  value={millisToMinuten(standardCallingTimeDuration)} 
                  onChange={(e) => setStandardCallingTimeDuration(minutenToMillis(e.target.value))}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item  xs={12}>
              {t("extension_time")}
            </Grid>
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("extension_time")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("extension_time")}
                  value={millisToMinuten(standardTicketExtendDuration)} 
                  onChange={(e) => setStandardTicketExtendDuration(minutenToMillis(e.target.value))}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item  xs={12}>
              {t("count_down")}
            </Grid>
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("count_down")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  label={t("count_down")}
                  id="demo-simple-select-outlined"
                  value={millisToMinuten(standardTicketExpiryDuration)} 
                  onChange={(e) => setStandardTicketExpiryDuration(minutenToMillis(e.target.value))}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
          */}

            <Grid item  xs={6} >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t("save")}
              </Button> 
            </Grid>
            <Grid item  xs={6} >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => deleteCategory()}
                disabled={!category || !category.id}
              >
                {t("delete")}
              </Button>  
            </Grid>
          </Grid>
        </form>
      </div>
      <AppFooter />
      
    </Container>
        
  );
}

export default withTranslation()(EditCategory);