import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CardGrid from '../pages/assets/CardGrid';
import LocationData from '../pages/assets/LocationData';
import { Grade, MyLocation, Settings } from '@material-ui/icons';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';
import AuthorizationUtil from '../authorization/AuthorizationUtil';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




export default withTranslation()(function Places(props) {
  const { t } = props;
  const classes = useStyles();
  const { vDomain } = props.location;

  const [domain] = useState(vDomain?vDomain:SessionContext.getDomain());

  const [places, setPlaces] = useState([]);
  const [place] = useState({});
 
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  
  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setDomain(domain); 
    SessionContext.setPlace(null);
    SessionContext.setResorucesOrigin("/my/places");     
    let uri = domain?'/api/domainplaces/'+domain.id:'/api/places';
    const url = config.get('vqueueUrl')+uri;
    axios.get(url )
        .then(res => {
          setPlaces(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })   
  }, [] )

  function getGridContent() {
    let gridContent = [];
    places.forEach(function (aPlace, index) {
      let entry =  {
        customTitle: <Grid container>  
                        <Grid item xs={12}  style={{ width:"100%", paddingTop:"4px"}}>
                        { aPlace.domain && <Typography  variant="body1" component="p"  style={{ width:"100%", marginTop:"-4px", fontSize:"0.8rem",whiteSpace: "nowrap", fontWeight:"bold", textDecoration:"underline"}}>
                          {aPlace.domain.domainName}
                        </Typography> }
                        <Typography  variant="body1"  component="p"  style={{ width:"100%", marginTop:"-6px", fontSize:"1.5rem",whiteSpace: "nowrap", fontWeight:"bold"}}>
                          {aPlace.name}
                        </Typography>
                      </Grid>
                    </Grid>,
        text: <LocationData location={aPlace}/>,
        action: <Fragment>
                  
                  <Grid item xs={(!AuthorizationUtil.isUser(aPlace) || (AuthorizationUtil.isUser(aPlace) && !AuthorizationUtil.isManager(aPlace)))?12:6}  >
                    <Link to={{pathname: '/my/groups', vPlace:aPlace}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
                        {t("groups")}
                      </Button>
                    </Link>                      
                  </Grid>
                { AuthorizationUtil.isManager(aPlace) && 
                <Grid item xs={6}  >
                  <Link to={{pathname: '/my/places/edit', vPlace:aPlace, vDomain:domain}}   style={{color: "inherit", textDecoration: "none"}}>
                    <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                      {t("Change")}
                    </Button>
                  </Link>
                </Grid>  
                }
           </Fragment>,
        menuItems: getMenuItems(aPlace),
        cardStyle:{height: "18.5rem"},
        object: aPlace
      };
      if(AuthorizationUtil.isUser(aPlace)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>U</span>;
      }
      if(AuthorizationUtil.isManager(aPlace)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>M</span>;
      }
      if(AuthorizationUtil.isAdmin(aPlace)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>A</span>;
      }
      if(AuthorizationUtil.isOwner(aPlace)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>O</span>;
      }


      gridContent.push(entry);
    });
    return gridContent;
  }


  function getMenuItems(vPlace) {

    let menuContent = [
      <MenuItem >
        <Link to={{pathname: '/my/groups', vPlace:vPlace}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
          <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
          {t("groups")}
          </Button>
        </Link>
      </MenuItem>
        ]
        if (AuthorizationUtil.isManager(vPlace)) {
          let manageContent = [
        
        <MenuItem >
          <Link to={{pathname: '/my/places/edit', vPlace:vPlace, vDomain:domain}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
              <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                  {t("Change")} 
              </Button>
            </Link>  
        </MenuItem>,
        <MenuItem >
          <Link to={{pathname: '/authorizations', title:<Crumbs place={vPlace}/>, vBackTo: "/my/places", vKind: "PLACE", vKindId: vPlace.id}}  style={{color: "inherit", textDecoration: "none"}}>
            <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
              {t("rights")}
            </Button>
          </Link> 
        </MenuItem>
            ]
          menuContent = menuContent.concat(manageContent);
        }
        return menuContent;      
  }

  function getFabButton() {
    return  domain && <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={{pathname: '/my/places/edit', vPlace:place, vDomain:domain}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }

  function getQuickActionItems() {
    let quickActionItems = [];
    
    if(domain && AuthorizationUtil.isManager(domain)) {
      quickActionItems.push(
        <Link to={{pathname: '/my/places/edit', vPlace:place, vDomain:domain}} 
              style={{color: "inherit", textDecoration: "none"}}>
                <AddIcon />            
          </Link>
      );
    }
    return quickActionItems;
  }

  function getMoreMenuItems() {

    let gridContent = [
      {
        to: {pathname: '/callBoard', vPlace:place, vDomain:domain },
        title: t("callboard"),               
        text: "Aufruftafel",
        icon: <Settings />
      }
      
    ];
    return gridContent;
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
        title={domain?<Crumbs domain={domain}/>:t("all_places")}  
        backTo={"/my/domains"} 
        quickActions={getQuickActionItems()}
        moreMenuItems={getMoreMenuItems()}/>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MyLocation />
        </Avatar>
        <Typography component="h1" variant="h5">
            {domain?t("all_domain_places")+" "+domain.domainName: t("all_places")}
        </Typography>
        <Grid container>  
          <Grid xs={12}>
            <CardGrid gridContent={getGridContent()}/>
          </Grid> 
        </Grid>
        
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
})
