import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import MoreIcon from '@material-ui/icons/MoreVert';
import log from 'loglevel';


export default function MoreMenu(props) {
   
  const [anchorEl, setAnchorEl] = useState(false)
  const {menuItems}= props;
  const {plainItems}= props;  
  const {iconStyle} = props;
    



  const handleClick = (event) => {
    log.debug("handleClick "+event);
    setAnchorEl(!anchorEl?event.currentTarget:undefined);

  }

    
 
  const getMenuItems = () => {
    let menuItems = [];
    plainItems.forEach(function (aItem, index) {
      let entry = 
      <MenuItem key={index} >
        <Link to={{pathname: aItem.link}} 
            style={{color: "inherit", textDecoration: "none"}}>  
          {aItem.icon}&nbsp;{aItem.title} 
        </Link>
      </MenuItem>;

      if(aItem.externalLink) {
          entry =  
          <MenuItem key={index}><a href={aItem.externalLink} style={{textDecoration:"none", color:"inherit"}}>
              {aItem.icon}&nbsp;{aItem.title}
            </a>
          </MenuItem>
        }
      if(aItem.action) { 
        let entity = aItem.entity;
        entry =  <MenuItem entity={entity.id} onClick={(e) => aItem.action(e)}>{aItem.icon}&nbsp;{aItem.title}</MenuItem>
      } 
        
      if(aItem.to) {
        entry = <MenuItem key={index}><Link to={aItem.to}
                  style={{color: "inherit", textDecoration: "none"}}>  
                  {aItem.icon}&nbsp;{aItem.title}
              </Link></MenuItem>;
      }
      let mItem = <MenuItem key={index} >{entry}</MenuItem>;
      menuItems.push(mItem);
    });
    return menuItems;
  }


    return (
      <Fragment>
        <IconButton edge="end" color="inherit" style={{padding: 0, marginLeft:"-0.8rem"}}  onClick={handleClick} >
          <MoreIcon style={iconStyle} />
        </IconButton>
        <Menu
          id="desk-sub-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClick }
        >
            { menuItems && menuItems } 
            { plainItems && getMenuItems()}     
        </Menu>
      </Fragment>
    );
  }