import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Fab, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import Chat from '../../queue/Chat';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined } from '@material-ui/icons';
import { t } from 'i18next';



export default function ServiceCard(props) {
  const { ticket } = props
  const { call } = props
  const [ showMore, setShowMore ] = useState(false)
  

  const handleClick = (event) => {
    setShowMore(!showMore?event.currentTarget:undefined);
  }

  const isWaiting = () => {
    return ticket.primaryStatus==='WAITING' && ticket.secondaryStatus==='WAIT'
  }


  const isCalled = () => {
    return ticket 
    && ticket.primaryStatus==='WAITING' && ticket.secondaryStatus==='CALL'
    
  }
  
  const isFinished = () => {
    return ticket 
    && ticket.primaryStatus==='FINALIZING'
    
  }
  
  const isSuggestionPending = () => {
    return ticket 
    && ticket.secondaryStatus==='APPOINTMENT_REQUEST_CLIENT_OPERATOR'
    
  }
  

  const isReadyToRedeem = () => {
    return ticket 
    && ticket.primaryStatus==='WAITING' && ticket.secondaryStatus==='LAST_CALL'
  }

  
  const isWatching = () => {
    return (!ticket || !ticket.id ||  ticket.primaryStatus=='FINALIZING')  ;
  }
  
  
  const isApprovalPending = () => {
    return ticket 
    && ticket.primaryStatus==='PREPARING' && ticket.secondaryStatus==='PULL_REQUESTED'
  }

  const isInProcess = () => {
    return ticket 
    && ticket.primaryStatus==='PROCESSING' && ticket.secondaryStatus==='PROCESS'
  }
  const isAnythingElse = () => {
    return !isInProcess() && !isReadyToRedeem() && !isFinished() && !isCalled() && !isWaiting();
  }


  const getDetails = () => {
    return <Fragment>
             
             { isAnythingElse() &&
              <WaitingCardInfo
                description="" 
                ticket={ticket}/>
              
            } 
            { isInProcess() &&
              <WaitingCardInfo
                title={t("remaining_process_duration")}
                info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.endPlanned}/> }
                description="" 
                ticket={ticket}/>
              
            } 
            { isReadyToRedeem() &&
              <WaitingCardInfo
                title={t("it_begins")}
                info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.redeemEndTime}/> }
                description={t("your_timeslot_begins")}
                ticket={ticket} />
              
            } 
            {
            isWaiting() && 
              <WaitingCardInfo
                title={t("current_waiting_time")}
                info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.startPlanned}/> }
                description={t("you_will_be_informed")}
                ticket={ticket} />
            }
            {
            isCalled() &&
              <WaitingCardInfo
                    title={t("attention")}
                    info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.startPlanned}/> }
                    description={t("your_turn_soon")}
                    ticket={ticket} />
            }  
            { isFinished() && 
              <Fragment>
                { ticket.secondaryStatus==='COMPLETED' &&
                  <WaitingCardInfo
                    title={t("Done")}
                    description={t("appointment_finished")}
                    ticket={ticket} />
                  
                }
                { ticket.secondaryStatus==='CALL_MISSEED' &&
                  <WaitingCardInfo
                    title={t("call_missed")}
                    description={t("appointment_over")}
                    ticket={ticket} />
                  
                }
                { ticket.secondaryStatus==='EXPIRED' &&
                  <WaitingCardInfo
                    title={t("Run_out")}
                    description={t("appointment_run_out")}
                    ticket={ticket} />
                  
                }
                { ticket.secondaryStatus==='RETURNED' &&
                  <WaitingCardInfo
                    title={t("given_back")}
                    info=""
                    description={t("appointment_given_back")}
                    ticket={ticket} />
                  
                }
              </Fragment>    
            }
        </Fragment>    
                        
  }


  return (
    
     
    <Fragment>
      
      <Grid container spacing={2} justify="center" >
        <Grid item xs={12} >
          <Grid container >
            <Grid item xs={12} style={{marginTop:"0.5rem", borderTop:"1px solid", borderBottom:"1px solid", paddingBottom:"0.5rem", paddingTop:"0.5rem"}}>
              <Grid container >
                  <Grid item xs={12} >
                    <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"1rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                      {t("service_queue")}
                    </Typography>                      
                  </Grid>
                  <Grid xs={4} justify="center" >
                    <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                      {t("suid")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}  >
                    <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.8rem"}}>
                      <span style={{whiteSpace: "nowrap"}}>{ticket.uid}</span>                                      
                    </Typography>   
                  </Grid>
                    <Grid item xs={12} >
                      <WaitingCardInfo
                        description="" 
                        ticket={ticket}/>
                    </Grid> 
                  { ticket.extraData &&
                    <Grid item xs={12}  >
                      <Typography  variant="body1" style={{width:"100%", textAlign:"left"}}>
                        <span >{ ticket.extraData }</span>                    
                      </Typography>
                    </Grid>
                  }  
                  { SessionContext.getActiveQueue().contact && 
                    <Fragment>
                        <Grid xs={4} justify="center" >
                          <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                            {t("contacts")}
                          </Typography>
                        </Grid>
                        <Grid xs={8} justify="center" >
                          <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.6rem"}}>
                            {SessionContext.getActiveQueue().contact} 
                          </Typography>
                        </Grid>
                    </Fragment>
                  }
                  { ticket.extraData && <Fragment>
                      <Grid xs={4} justify="center" >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                          {t("further_information")}
                        </Typography>
                      </Grid>
                      <Grid xs={8} justify="center" >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.6rem"}}>
                          {ticket.extraData} 
                        </Typography>
                      </Grid>
                    </Fragment>
                  }  
                  <Grid item xs={12}  >
                    <Chat ticket={ticket} />
                  </Grid>         
              </Grid>
            </Grid>
           
          
            
          </Grid>
        </Grid>
      </Grid>  
        
    </Fragment>     
  );
}