import React, { useState,useContext, createContext, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

import axios from 'axios';
import config from 'react-global-configuration';
import { t } from 'i18next';
import { AppBar, Dialog, Divider, IconButton, List, ListItem, ListItemText, Slide, Toolbar } from '@material-ui/core';
import SessionContext from '../frame/SessionContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignUp() {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState("");
  const { addMessage } = useContext(MessageContext)
  const { addModalMessage } = useContext(MessageContext);
    const history = useHistory();
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateForm() {
    return firstName.length > 0 && lastName.length > 0 && email.length > 0 && password.length > 5 && terms;
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Submit User for registration.")
    var userLang = navigator.language || navigator.userLanguage;
    let entity = {
      firstName: firstName,
      lastName: lastName, 
      email: email,
      password: password,
      login: email,
      langKey: userLang,
      clientId: SessionContext.getClientId()
    }
    const url = config.get('vqueueUrl')+'/api/register';
    axios.post(url,  entity )
        .then(res => {
          addModalMessage(t("user_created"));
          history.push("/login");
     
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          
        })
  }

  return (
    <Fragment>
      <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
        <DeskHeader title={t("make_account")}  />
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("registrate")}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label={t("first_name")}
                  autoFocus
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label={t("last_name")}
                  name="lastName"
                  autoComplete="lname"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label={t("email_adress")}
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label={t("pw")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Link onClick={handleClickOpen} variant="body2">
                  {t("for_juristic_safety")}
                </Link>
                <FormControlLabel
                  control={<Checkbox value="aggreeUsageRule" color="primary" />}
                  label={t("confirm_use_condition")}
                  onChange={(e) => setTerms(e.target.checked)}
                />
                
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!validateForm()}
            >
              {t("request_registration")}
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  {t("allready_have_an_account")}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <AppFooter />
        
      </Container>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
           <Typography component="h1" variant="h5" edge="start" className={classes.title}>
              {t("use_conditions")}
            </Typography>
            <IconButton   color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                {t("your_data_belongs_to_us")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button autoFocus
                fullWidth  onClick={handleClose}
                variant="contained"
                color="primary" >
                {t("yes_understood")}
              </Button>
            </Grid>
          </Grid>
      </Dialog>
    </Fragment>
  );
}
