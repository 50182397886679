import React, { Component, Fragment } from 'react';
import axios from 'axios';
import ls from 'local-storage'


import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DateTimeDisplay from '../pages/assets/DateTimeDisplay';
import { Box, Container, MenuItem, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ButtonUpdateState from './assets/ButtonUpdateState';
import TicketGrid from '../pages/assets/CardGrid';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CropFreeIcon from '@material-ui/icons/CropFree';
import StatusTicket from './assets/StatusTicket';
import { Add, ForumOutlined } from '@material-ui/icons';
import config from 'react-global-configuration';
import TicketData from '../pages/assets/TicketData';
import ServiceCard from '../pages/assets/ServiceCard';
import ActionMenu from './assets/ActionMenu';
import BlockerData from '../pages/assets/BlockerData';
import TimelineMatrix from '../pages/assets/TimelineMatrix';
import TicketCard from '../pages/assets/TicketDataUtil';
import TicketDataUtil from '../pages/assets/TicketDataUtil';
import { t } from 'i18next';
import { Badge } from 'reactstrap';
import BookSlotData from '../pages/assets/BookSlotData';
import log from 'loglevel';
import Crumbs from '../queue/Crumbs';
import SessionContext from '../frame/SessionContext';

class TicketList extends Component {

  constructor() {
    super();

    this.state = {
     
    };
    this.slotsBookableCallback = this.slotsBookableCallback.bind(this);
    
  }

  filterTickets(tickets, primaryStatus, secondaryStatus, filters) {
    var filteredTickets = tickets;
    if (!filters || filters.length == 0) {
      if (secondaryStatus) {
        var filteredTickets = this.props.tickets.filter((ticket) => {
          return ticket.secondaryStatus == secondaryStatus
        })
      } else if (primaryStatus) {
        var filteredTickets = this.props.tickets.filter((ticket) => {
          return ticket.primaryStatus == primaryStatus
        })
      }
    } else {
      filteredTickets = [];
      //always show blockers!
      
      for (var filterIndex in filters) {
        let aFilter = filters[filterIndex];
        var filtered4Filter = this.props.tickets.filter((ticket) => {
          return (aFilter.secondaryStatus ===undefined && ticket.secondaryStatus !== "RESERVED" && ticket.primaryStatus === aFilter.primaryStatus) || (ticket.primaryStatus === aFilter.primaryStatus && ticket.secondaryStatus === aFilter.secondaryStatus);
        });
        filteredTickets = filteredTickets.concat(filtered4Filter);
      }     
    }
    /*var blockers = this.props.tickets.filter((ticket) => {
      return ticket.primaryStatus == "BLOCKER";
    });
    filteredTickets = filteredTickets.concat(blockers);*/
    return filteredTickets;
  }

  getTickets() {
    let tTickets = this.props.tickets;
    if(tTickets) {
      if (this.props.filters && this.props.filters.length > 0) {
        tTickets = this.filterTickets(tTickets, this.props.primaryStatus, this.props.secondaryStatus, this.props.filters);
      }
      if(tTickets && this.props.slotsBookable!==undefined) {
        tTickets=tTickets.concat(this.props.slotsBookable);
        //log.trace("getTickets concated tTickets: "+JSON.stringify(tTickets));      
      }
      tTickets.sort(function (a, b) { 
        if(b === undefined || b === null) {
          log.trace("b undefined");
        }
        return a.startPlanned - b.startPlanned;
      })
    }
    return tTickets;
  }


  /*componentWillReceiveProps(nextProps){
    log.trace("componentWillReceiveProps nextProps.currentDateFrom "+nextProps.currentDateFrom);
    log.trace("componentWillReceiveProps nextProps.currentDateTo "+nextProps.currentDateTo);
    log.trace("componentWillReceiveProps this.props.currentDateFrom "+this.props.currentDateFrom);
    log.trace("componentWillReceiveProps this.props.currentDateTo "+this.props.currentDateTo);
  }
  componentDidMount(){
    log.trace("componentDidMount this.props.currentDateFrom "+this.props.currentDateFrom);
    log.trace("componentDidMount this.props.currentDateTo "+this.props.currentDateTo);
  }
  */


  shareLink(ticket) {
    let loc = window.location.protocol + "//" + window.location.hostname
    if (window.location.port) {
      loc = loc + ":" + window.location.port;
    }

    let linkTicket = loc + "/wallet?tuid=" + ticket.uid;
    /* window.open(linkTicket, '_blank'); */
    navigator.clipboard.writeText(linkTicket);
    this.context.addModalMessage(t("temporary_save"));  
  }

  onDrillDown(currentDate) {
    let date = new Date(currentDate);
    log.trace("this.state.currentDate" + this.state.currentDate);
    log.trace("currentDate" + date);
    var state = {};
    state.currentDate = date;
    this.props.toggleView(state);
  }

  showSelectedTicket(currentTicket) {
    var state = {};
    state.currentTicket = currentTicket;
    this.setState(state);
  }

  getGridContent() {
    log.trace("TicketList getGridContent");
    let date = Date.now();
    let gridContent = [];
    let tickets = this.getTickets();
    let minute = 60*1000;
    let adder = {
      custom: <Grid item xs={12} style={{textAlign:"center", marginTop:this.props.small?"0":"-2rem", marginBottom:this.props.small?"0":"-2rem"}} >
              <Link to={{pathname: '/manageTicket', manage: true, queue: this.props.queue}} 
                    style={{color: "inherit", textDecoration: "none"}}>
                    <IconButton style={{background:"none", border:"solid 1px", zIndex:"-100"}} >
                      <Add />
                  </IconButton>
                </Link>  
            </Grid>
   };  
   let lastTicket;
   
    tickets.forEach(function (ticket, index) {
      var filteredTickets = SessionContext.getMyTickets().filter((myTicket) => {
        return myTicket.uid == ticket.uid
      })
      let manageTicket = filteredTickets.length>0 || this.props.manage;
      let cardActions = this.getCardActions(ticket);
      {
        if(!this.props.timeline && !this.props.call && lastTicket && lastTicket.endPlanned+10000<ticket.startPlanned) {
          gridContent.push(adder);     
        }
        let cardContent = TicketDataUtil.convertToTicketCardContent(ticket, this.props.queue, cardActions, this.props.small, this.props.call, this.props.manage, this.props.manage );
        let entry = {};
        if(!ticket.bookableSlot) {
          entry = Object.assign({}, cardContent, {});   //always standard  
        }
        entry.ticket=ticket;    
        if(this.props.small) {
          if(this.props.timeline) {
            // seconds with factor min 10
            entry.distance=((ticket.startPlanned - this.props.currentDateFrom-this.props.offsetFrom))/(1000*60/6*3); 
            entry.height=(((ticket.primaryStatus==="BLOCKER" && ticket.secondaryStatus!=="RESERVED") && ticket.endPlanned<date?date - ticket.startPlanned:ticket.endPlanned - ticket.startPlanned))/(1000*60/6*3); 
          }
          entry.bgColor=""; //, backgroundColor:value.bgColor?value.bgColor:"#ffde85"
          let bgColor= (ticket.primaryStatus==="BLOCKER" && ticket.secondaryStatus!=="RESERVED")?(ticket.wishSource==="SLOT"?"#A0A0A0":"#A0A0A0"):(ticket.category && ticket.category.color)?ticket.category.color:"#abfff2";
          bgColor= ticket.primaryStatus==="BOOKABLE"?"#85a18f":bgColor;
          let hoverBgColor=ticket.primaryStatus==="BOOKABLE"?"#85a18f":bgColor;  

          /// Hier den Custom Teil auch in das Util verlegen!
          entry.popup=!ticket.bookableSlot?TicketDataUtil.convertToTicketCardContent(ticket, this.props.queue, cardActions, this.props.small, this.props.call, this.props.manage, this.props.manage ):null;
          entry.custom=<Fragment >
            <Grid id={"ticketEntryCustomCard"+ticket.id} 
                container 
                direction="column" 
                justifyContent="space-between" 
                onMouseOver={e => { 
                  if(!ticket.bookableSlot) {e.target.style.maxHeight = "unset";}
                  document.getElementById("ticketEntryCustomCard"+ticket.id).style.backgrounColor=hoverBgColor;
                }}
                onMouseLeave={e => { 
                  if(!ticket.bookableSlot) {e.target.style.maxHeight = entry.height+"px";}
                  document.getElementById("ticketEntryCustomCard"+ticket.id).style.backgrounColor=bgColor;
                }}
                alignItems="top" 
                className={ticket.bookableSlot?"bookableSlot":""}
                
                style={
                    {
                      backgroundColor:bgColor, 
                      minHeight:entry.height+"px", 
                      maxHeight:entry.height+"px",
                      overflow: "hidden",
                      padding:"5px",
                      borderRadius:"2px",
                      border: "1px solid",
                      borderRight: ticket.originTicket?"2px solid red":"1px solid"
                    }
                  } >
                  {ticket !== null && (ticket.primaryStatus==="BLOCKER" && ticket.secondaryStatus!=="RESERVED") &&
                    <BlockerData ticket={ticket} type={ticket.wishSource}/>
                  }
                  {ticket !== null && (ticket.primaryStatus !== "BLOCKER" || ticket.secondaryStatus==="RESERVED") && !ticket.bookableSlot &&
                    <TicketData from={this.props.currentDateFrom} manage={manageTicket} timeline={this.props.timeline} small={this.props.small} ticket={ticket} call={this.props.call}  queue={this.props.queue} />
                  }
                  {
                    ticket !== null && ticket.bookableSlot && ticket.bookableSlot
                  }
              </Grid>
          </Fragment>
          }
            
        lastTicket = ticket;
        gridContent.push(entry);       
      }
    }.bind(this));
    if(!this.props.timeline && !this.props.call && (tickets.length==0 || tickets.length==1 || lastTicket.primaryStatus!="BLOCKER")) { 
      gridContent.push(adder);  
     }
    return gridContent;
  }

  

  getCardActions(ticket) {
    if(!this.props.manage) {
      return null;
    }
    if (this.props.cardActions) {
      return this.props.cardActions;
    }
    return <Fragment>
      {ticket.secondaryStatus === "WAIT" &&
        <Fragment>
          <Grid item xs={12}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("call")}
              message={`${ticket.uid}`+" "+t("got_called")}
              primaryStatus="WAITING"
              secondaryStatus="CALL"
              variant="contained" />
          </Grid>


        </Fragment>
      }

      {(ticket.secondaryStatus === "LAST_CALL" || ticket.secondaryStatus === "CALL") &&
        <Fragment>
          <Grid item xs={this.props.small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("cash")}
              message={`${ticket.uid}`+" "+t("begins_process")}
              primaryStatus="PROCESSING"
              secondaryStatus="START_PROCESS"
              variant="contained" />
          </Grid>
          <Grid item xs={this.props.small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="secondary"
              title={t("Wait")}
              message={`${ticket.uid}`+" "+t("waits_again")}
              primaryStatus="WAITING"
              secondaryStatus="WAIT"
              variant="contained" />
          </Grid>
        </Fragment>
      }
      {(ticket.secondaryStatus === "PROCESS") &&
        <Fragment>
          <Grid item xs={this.props.small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("Done")}
              message={`${ticket.uid}`+" "+t("ends_process")}
              primaryStatus="PROCESSING"
              secondaryStatus="STOP_PROCESS"
              variant="contained" />
          </Grid>
          <Grid item xs={this.props.small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("Wait")}
              message={`${ticket.uid}`+" "+t("waits_again")}
              primaryStatus="WAITING"
              secondaryStatus="WAIT"
              variant="contained" />
          </Grid>
        </Fragment>
      }

      {(ticket.primaryStatus === "FINALIZING" || ticket.secondaryStatus === "REPLAN") &&
        <Fragment>
          <ButtonUpdateState
            ticket={ticket}
            color="primary"
            title={t("New_planing")}
            message={`${ticket.uid}`+" "+t("is_planning_new")}
            primaryStatus="PREPARING"
            secondaryStatus="PULLED"
            variant="contained" />

        </Fragment>
      }
      {(ticket.secondaryStatus === "APPOINTMENT_REQUEST_CLIENT_OPERATOR") &&
        <Fragment>
          <Link to={{ pathname: '/reviewRequest', vTicket: ticket, queue: this.props.queue, manage: true }}
            style={{ color: "inherit", textDecoration: "none", width: '100%' }}>
            <Button variant="contained" color="primary" style={{ width: '100%' }} >{t("check")}</Button>
          </Link>

        </Fragment>
      }
    </Fragment>
  }

  getMenuItemsTicket(ticket) {
    return [
      <ActionMenu ticketEditor='/manageTicket' ticket={ticket} queue={this.props.queue} />
    ]
  }

  slotsBookableCallback(slotsBookable) {
   // log.trace("slotsBookableCallback: "+JSON.stringify(slotsBookable));
    this.props.slotsBookableCallback(slotsBookable);
  }

  render() {
    return (
      <Fragment>
        { /*this.getTickets().length == 0 && <Grid container alignItems="center" justify="center">
          <span class="label" style={{ marginTop: '20%' }}>keiner</span>
        </Grid>
      */}
          
          { this.props.timeline && this.props.partitionStatusDTO  && this.props.tickets &&  
          <TimelineMatrix tickets={this.getTickets()} 
          slotsBookableCallback={this.slotsBookableCallback} 
          partitionResourceDTO={this.props.partitionStatusDTO}
          linkTo={{pathname: this.props.createTicketLink?this.props.createTicketLink.link:"/", vOptions: this.props.options?this.props.options:this.props.createTicketLink.options, queue: this.props.queue, manage: this.props.manage, backTo:this.props.createTicketLink?this.props.createTicketLink.backTo:"/my/groups/slotmanager" }}
          options={this.props.createTicketLink && this.props.createTicketLink.options} 
          queue={this.props.queue} 
          from={new Date(this.props.currentDateFrom.getTime() + this.props.offsetFrom)}
          to={new Date(this.props.currentDateTo.getTime() + this.props.offsetTo)} /> }
        <TicketGrid queue={this.props.queue} 
          key="ticketGrid4TicketList"
          gridContent={this.getGridContent()} 
          small={this.props.small} 
          call={this.props.call} 
          timeline={this.props.timeline} 
          popupable={this.props.popupable} 
          manage={this.props.manage}/>

      </Fragment>
    );
  }
}



export default TicketList;