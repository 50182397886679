import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Category } from '@material-ui/icons';
import LocationData from '../pages/assets/LocationData';
import CardGrid from '../pages/assets/CardGrid';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';
import AuthorizationUtil from '../authorization/AuthorizationUtil';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function Categories(props) {
  const {t}= props;
  const classes = useStyles();
  const { vDomain } = props.location;
  const { vQueue } = props.location;
  const { vBackTo } = props.location;
  const { title } = props.location;
  const { map } = props.location;
  
  const [backTo, setBackTo] = useState(vBackTo);
  const [domain, setDomain] = useState(vDomain);
  const [queue, setQueue] = useState(vQueue);

  const [categories, setCategories] = useState([]);
  const [category] = useState({});
 

  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setBackTo(backTo);
    SessionContext.setDomain(domain); 
    SessionContext.setCategory(null);   
    loadCategories();
  }, [] )

  function loadCategories() {
    let uri = (queue && !map)?'/api/category-queues/queue/'+queue.id:'/api/category-domains/domain/'+domain.id;
    const url = config.get('vqueueUrl')+uri;
    
    axios.get(url )
        .then(res => {
          setCategories(res.data)
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        })   
  }

  function getMenuItems(vCategory) {
    let menuItems = [];
    if(AuthorizationUtil.isManager(domain) && !map) {
    menuItems.push(
      <MenuItem >
        <Link to={{pathname: '/my/domains/categories/edit', vBackTo: '/my/domains/categories', category:vCategory.category, domain:domain}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
          <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
            {t("Change")}
          </Button>
        </Link> 
      </MenuItem>)
    }
    return menuItems; 
  }
  function getGridContent() {
    let gridContent = [];
    categories.forEach(function (aCategory, index) {
      let entry =  {
        title: <span className="label" style={{whiteSpace: "nowrap"}}>{aCategory.category.name}</span>  ,
        text: <Grid item xs={12}  >
                <Grid container spacing={0} alignItems="left" justify="left" >
                  <Typography  variant="body1" >
                    <span >{aCategory.category.decription}</span>
                  </Typography>
                </Grid>
              </Grid>,
        action: getActionItems(aCategory),
        menuItems: getMenuItems(aCategory),
        cardNumberStyle: {backgroundColor: aCategory.category.color},
        cardStyle:{height: "18.5rem"},
        object: aCategory.category
      };
      gridContent.push(entry);
    });
    return gridContent;
  }

  function getActionItems(aCategory) {
    let menuItems = [];
      if(map) {
        menuItems.push(
          <Fragment>
              <Grid item xs={6}  >
                  <Button onClick={() => mapCategory(aCategory)}  style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                  {t("map")}
                  </Button>
              </Grid>    
          </Fragment>
        ) ;
      } else {
        if(queue) {
          menuItems.push(
            <Fragment>
                <Grid item xs={6}  >
                    <Button onClick={() => deleteMapping(aCategory)}  style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                    {t("unmap")}
                    </Button>
                </Grid>    
            </Fragment>
          ) ;
        } else if(AuthorizationUtil.isManager(domain)) {
          menuItems.push(
            <Fragment>
                <Grid item xs={6}  >
                  <Link to={{pathname: '/my/domains/categories/edit', vBackTo: '/my/domains/categories', category:aCategory.category, domain:domain}}  style={{color: "inherit", textDecoration: "none"}}>
                    <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                    {t("Change")}
                    </Button>
                  </Link>
                </Grid>    
            </Fragment>
          ) ;
        }
      }
    return menuItems;
  }

  function deleteMapping(categoryQMapping) {
    let url = config.get('vqueueUrl');
    url = url+'/api/category-queues/'+categoryQMapping.id;;

    axios.delete(url )
        .then(res => {
          loadCategories();
//          history.push(queue?"/my/groups/slotmanager/settings/categories":"/my/domains");
         // addMessage(t("category_deleted"));
          
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function mapCategory(domainCategory) {
    let categoryQMapping = {};
    categoryQMapping.category=domainCategory.category;
    categoryQMapping.queue=queue;

    let url = config.get('vqueueUrl');
    url = url+'/api/category-queues';

    axios.post(url,  categoryQMapping )
      .then(res => {
        history.push(queue?"/my/groups/slotmanager/settings/categories":"/my/domains");
        //addMessage(t("category_q_saved"));
        //submitCategoryResources(res.data);
      //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
      }).catch(function (error) {
        // handle error
        console.log(error);
        
      })
  }

  function getCategoryCreateLink() {
    let linkParams = {pathname: '/my/domains/categories/edit', vBackTo:'/my/domains/categories', category:category, domain:domain};
    if(queue) {
      linkParams = {pathname: '/my/domains/categories', vBackTo:'/my/groups/slotmanager/settings/categories', vQueue: queue, vDomain:domain, map:true};
    }
    return linkParams;
  }

  function getFabButton() {
    return  <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={getCategoryCreateLink()} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }
  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link to={getCategoryCreateLink()} 
          style={{color: "inherit", textDecoration: "none"}}>
            <AddIcon />            
      </Link> 
    );
    return quickActionItems;
  }
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
        title={<Fragment><Crumbs domain={domain} queue={vQueue}/> : {(map && vQueue)?<Fragment>Mappen</Fragment>:<Fragment>{t("all_categories")}</Fragment>}</Fragment>}
        backTo={queue?"/my/groups/slotmanager/settings":"/my/domains"} quickActions={getQuickActionItems()} />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Category />
        </Avatar>
        <Typography component="h1" variant="h5">
              {t("all_categories")}
        </Typography>
       
        <form className={classes.form}>
        
          <Grid container >  
            <Grid xs={12}>
              <CardGrid gridContent={getGridContent()}/>
            </Grid>
          </Grid>
        </form>
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
}

export default withTranslation()(Categories);