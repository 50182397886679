import React, { useState,useContext, createContext, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import EditTicket from '../ticket/EditTicket';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




export default function BookingData(props) {
  const classes = useStyles();
  const { vDomain } = props.location;
  const { vOptions } = props.location;
  const { queue } = props.location;
  const { manage } = props.location;
  const { backTo } = props.location;
  
  const [options, setOptions] = useState(vOptions);
  const [domain, getDomain] = useState(vDomain?vDomain:SessionContext.getDomain());
  const [aQueue, setAQueue] = useState(queue?queue:SessionContext.getActiveQueue());
  const [categories, setCategories] = useState([]);
  const [cylces, setCylces] = useState(33);
  
  const { addMessage } = useContext(MessageContext)

  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    
    let url = config.get('vqueueUrl')
    if(aQueue) {
      url = url +'/api/qcategories/'+aQueue.id;
    } else if(domain) {
      url = url +'/api/domain/categories/'+domain.id;
    }    
    if(aQueue || domain) {
      axios.get(url )
          .then(res => {
            setCategories(res.data)
          //  history.goBack();//history.push("/queuemanagement")
          }).catch(function (error) {
            // handle error
            console.log(error);
            addMessage(error.detail);
            
          }) 
      }
  }, [] )

  function next() {
    history.push("/booking/variants")

  }

  
  
  
  return (
    <EditTicket  
      queue={(options && options.ticket)?options.ticket.queue:props.location.queue} 
      startPreset={props.location.startPreset} 
      endPreset={props.location.endPreset} 
      cyclesPreset={props.location.cyclesPreset} 
      vOptions={options} 
      vTicket={options && options.ticket} 
      confirmationTarget={options && options.confirmationTarget}
      manage={manage}
      backTo={backTo}  />
  );
}
