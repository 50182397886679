import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import Typography from '@material-ui/core/Typography';

import SessionContext from '../frame/SessionContext';
import {t} from 'i18next';


class GlobalSettings extends Component {
  
  constructor() {
    super();
   
  }


  cleanBrowserStorage() {
    SessionContext.clear();    
  }

  render() {
    return (
      <div>
        <DeskHeader title={t("global_settings")}  />
          
          <Container maxWidth="md"  style={{position: 'relative', marginTop:"80px", marginBottom:"100px"}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  {t("further_settings")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {t("any_settings")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary" onClick={() => this.cleanBrowserStorage()}>
                    {t("remove_all")}
                </Button>
              </Grid>
            </Grid>
            
                
        </Container>
        
      <AppFooter />
          
    </div>
      
    );
  }
}

export default GlobalSettings;