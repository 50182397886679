import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Card from './Card';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "200px"
  },
});

export default function LinkCard(props) {
  const classes = useStyles();
  const { area} = props;
  
  const getCard  = () => {
    if(area.externalLink) {
      return <a href={area.externalLink} style={{textDecoration:"none"}}>
        {getCardContent()}
      </a>
    } else if(area.link) { 
      return <Link to={area.link} style={{textDecoration:"none"}}>  
        {getCardContent()}
      </Link>
    } else if(area.action) { 
      return <Link  onClick={area.action} style={{textDecoration:"none"}}>  
        {getCardContent()}
      </Link>
    } 
   
    {
      return getCardContent();
    }
  }

  

  const getCardContent  = () => {
    return  <Card area={area} />
  }

  return (
     getCard()  
  );
}