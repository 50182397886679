import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import config from 'react-global-configuration';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../frame/SessionContext';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import TimeScopeBrowser from '../pages/assets/TimeScopeBrowser';
import RoomTicketWrapper from '../ticket/calendar/components/RoomTicketWrapper';
import Crumbs from './Crumbs';
import { t } from 'i18next';


var QRCode = require('qrcode.react');

class Room extends Component {
  static contextType = MessageContext

  constructor(props) {
    super(props);
    this.state = {
      pullingTicket: false
    }
    this.pullTicket = this.pullTicket.bind(this);
    this.returnTicket = this.returnTicket.bind(this);
    
  }
  
  

  componentDidMount() {
    console.log("Room componentDidMount ");
    if(this.props.location && this.props.location.startPreset) {
      let gap = {
        start: this.props.location.startPreset
      }
      this.pullTicket(gap,this.props.location.queue,this.props.location); //proceed to request ticket formular
     } else {
        
        let uid = null;
                
        let tuid = null;

        if(this.props.location) {
          let query = new URLSearchParams(this.props.location.search);
          tuid = query.get('tuid');   
          uid = query.get('uid');     
        }

        let ticket = this.props.location?this.props.location.ticket:this.props.ticket;
        if(ticket) {
          tuid = ticket.uid;
        }
        
        let state = { tuid: tuid }
        if(tuid!=null) { 
          this.loadQueueWithTicket(tuid);
          return;
        } else if(uid!=null) {
          /* let queue = this.state.queue;  
          if(queue && queue.uid==uid) {
            state.queue = queue;  
            state.currentTicket = queue.currentTicket;
          } else { */
            this.loadQueue(uid);
            return;
          /* } */
        } else {
          let queue = this.props.location?this.props.location.queue:this.props.queue;
          if(!queue || queue==null) {
            queue = SessionContext.getActiveQueue();
          } 
          if(!queue || queue==null) {
            this.showAlert(t("no_active_slotmanager"))
            this.props.history.push({pathname: "/" });
          } 
          if(queue && queue!=null) {
            state.currentTicket = queue.currentTicket;
            state.queue = queue;          
            this.loadQueue(queue.uid);
          }   
        }
        this.timer = setInterval(async () => {
          this.loadWaitingData();
        }, 10000)  
        
        

        this.setState(state);  
      }
  }
  
  callbackQueueChange(queue) {
    this.setState({
      queue: queue
    });   
  }

  componentDidUpdate() {
    const msgCtx = this.context;
    console.log("Room componentDidUpdate: ");
      if(msgCtx.ticket ) {
        clearInterval(this.timer);
        console.log("msgCtx.ticket: "+JSON.stringify(msgCtx.ticket));
        let queue = SessionContext.refreshTicket(msgCtx.ticket);
        msgCtx.removeTicket();
        this.setState({
          queue: queue,
          pullingTicket:false
        });                 
      } 
      
      
  }
  
  c

  componentWillUnmount(){
    clearInterval(this.timer);
  }
  

  resetCurrentTicket() {
    console.log("Room resetCurrentTicket ");
    let queue = SessionContext.removeTicket(this.state.queue);
    this.setState({ queue: queue }); 
  }

  pullTicket(gap, queue, props) {
    console.log(gap);
    queue = queue?queue:this.state.queue;
    var ticket = this.props.ticket;
    if(!ticket) {
      ticket = { 
        queueId: queue.id,
        //clientId: SessionContext.getClientId(),
        primaryStatus : "PREPARING",
        //cycles: 40, //hier nicht so vorbelegen
        startWish : gap.start,
        endWish : gap.end
      }
    } else {
      ticket.secondaryStatus= "REPLAN";
      ticket.endWish=ticket.endWish?(gap.start+(ticket.endWish-ticket.startWish)):gap.end;
      ticket.startWish=gap.start;    
    }
    if(gap.timeslot) {
      ticket.primaryStatus="TIMESLOT";
    }
    if(queue.status=="SERVICE") {
      ticket.primaryStatus="SERVICE";
    }

    console.log("pullTicket ticket: "+JSON.stringify(ticket));
    if(this.props.history) { //history is implicit there only if Room.js was called via link - not as embedded component
      this.props.history.push({pathname: "/requestTicket", cyclesMax:props?props.cyclesMax:null, cyclesPreset:props?props.cyclesPreset:null,  startPreset:props?props.startPreset:null, endPreset:props?props.endPreset:null, vTicket: ticket  , queue: queue, backTo:this.props.location.backTo, confirmationTarget:this.props.location.manage?"/my/groups/slotmanager":"/wallet", manage: this.props.location.manage});
    } else { 
      this.setState({ pullingTicket:true});
      const url = config.get('vqueueUrl') + '/api/tickets';
      axios.put(url, ticket)
        .then(res => {
          this.props.callbackClose();
          //SessionContext.refreshTicket(ticket, !manage); 
          //history.goBack();
        })
    }
    /*
    if(queue.queueingKind == "FORMULAR" || queue.queueingKind == "FORMULAR_CONFIRM") {
      this.props.history.push({pathname: "/requestTicket",  vTicket: ticket  , queue: queue});
    }    */
  }



  showAlert(message) {
    const msgCtx = this.context
    msgCtx.addMessage(message);
  } 

  loadQueue(uuid) {
    const msgCtx = this.context
    console.log("Room loadQueue "+uuid);
    const url = config.get('vqueueUrl')+'/api/queues/uid?uuid='+uuid;
    axios.get(url)
      .then((data) => {
          let queue = SessionContext.setActiveQueue(data.data);
          queue.currentTicket = SessionContext.getActiveTicketForQueue(queue);
          //Store the Queue to Wallet
          SessionContext.addFavourite( data.data);
          this.setState({ queue: queue });              
      }).catch(function (error) {
        msgCtx.addMessage(error);
        console.log(error);
      });  ;   
  }

  
  loadQueueWithTicket(tuid) {
    const msgCtx = this.context
    console.log("Room loadQueueWithTicket "+tuid);
    axios.get(config.get('vqueueUrl')+'/api/tickets/uuid/'+tuid)
        .then(tresponse => {   
          console.log("Room ticket loaded, now load q "+tresponse.data.queueId);
          //SessionContext.addFavourite(tresponse.data.queue);
          let queue = SessionContext.addTicket(tresponse.data.queue,tresponse.data);
              if(queue) {
                this.setState({ queue: queue });         
              }
          /*axios.get(config.get('vqueueUrl')+'/api/queues/'+tresponse.data.queueId)
            .then(qresponse => {  
              console.log("Room q loaded "+qresponse.data);
              let queue = SessionContext.addTicket(qresponse.data,tresponse.data);
              if(queue) {
                this.setState({ queue: queue });         
              }
            }
          );  */      
         }
      ).catch(function (error) {
        msgCtx.addMessage(error);
        console.log(error);
      }); ;     
  }
  

  loadWaitingData() {
    console.log("Room loadWaitingData");
    clearInterval(this.timer);
      if(this.state.queue) {
        let currentTicket = this.state.queue.currentTicket;
        if(currentTicket) {
          console.log("Room this.state.queue.currentTicket found "+currentTicket.uid);
          axios.get(config.get('vqueueUrl')+'/api/tickets/uuid/'+currentTicket.uid)
            .then(response => {  
              let queue = SessionContext.addTicket(this.state.queue,response.data);
              this.setState({
                currentTicket: response.data,
                queue: queue
              });             
            }
          ); 
        } else {
          console.log("Room NO this.state.queue.currentTicket found!");
          /**/  
        }
      }
    }
 
  
  isWatching() {
    return !this.state.currentTicket ||  this.state.currentTicket.primaryStatus=='FINALIZING';
  }


  getFabButton() {
    return <Fragment> 
      { 
          this.state.queue.currentTicket==null || (this.state.queue.currentTicket!=null && this.state.queue.currentTicket.primaryStatus=='FINALIZING') && 
          <Fragment> 
          { (this.state.queue.queueingKind=="SIMPLE" || this.state.queue.queueingKind=="SIMPLE_CONFIRM") &&
            <Fab color="secondary" aria-label="add" 
              style={{position: 'absolute',
                  zIndex: 1,
                  top: -30,
                  left: 0,
                  right: 0,
                  margin: '0 auto'}}>
              <AddIcon onClick={() => this.pullTicket()}/>
            </Fab>
          }
          { (this.state.queue.queueingKind=="FORMULAR" || this.state.queue.queueingKind=="FORMULAR_CONFIRM") && 
            <Fab color="secondary" aria-label="add" 
              style={{position: 'absolute',
                  zIndex: 1,
                  top: -30,
                  left: 0,
                  right: 0,
                  margin: '0 auto'}}>
              
              <Link to={{pathname: '/requestTicket', entity:this.state.queue}} 
                                    style={{color: "inherit", textDecoration: "none"}}>
                  <AddIcon />
              </Link>  
            </Fab>
          }        
          </Fragment>
      }
      </Fragment>
  }

  returnTicket() {
    this.props.history.push(this.props.location.backTo?this.props.location.backTo:".");
  }

  

  render() {
    return (
        <div>
         
        { this.state.queue && 
          <Fragment>
            { !this.props.hideFrame && <DeskHeader 
              title={<Crumbs queue={this.state.queue}/>}
              backTo={this.props.location.backTo?this.props.location.backTo:null} /> }
            
            <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
                {
                  this.state.queue && 
                  <Grid container spacing={1}>
                        <RoomTicketWrapper watching={this.props.hideFrame} onClickReturn={this.returnTicket} from={this.state.from} to={this.state.to} showActions={true} queue={this.state.queue} pullTicket={this.pullTicket} pullingTicket={this.state.pullingTicket}/>
                  </Grid>
                     
                }
                    
           </Container>
            
           { !this.props.hideFrame && <AppFooter  actionBar={(this.state.queue && (!this.state.queue.currentTicket 
                      || !this.state.queue.currentTicket.id || this.state.queue.currentTicket.primaryStatus=='FINALIZING')) &&
                        <TimeScopeBrowser cycleCallback={(from, to) => {
                          this.setState({from: from, to: to});
                        }}/> 
              } /> }
            
          </Fragment>
        }
            
        </div>            
             
    );
  }
}


export default Room;