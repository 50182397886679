import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppFooter from '../pages/assets/AppFooter';
import RoomHeader from '../pages/assets/RoomHeader';
import Button from '@material-ui/core/Button';
import DeskHeader from '../pages/assets/DeskHeader';
import { Avatar, CssBaseline, makeStyles, Typography } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';

import QRCodeScanner from '../pages/assets/QRCodeScanner';
import { CropFree } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function QRCodeQueueIn(props) {
  const {t} = props;
  const classes = useStyles();
  


  function loadQueue(value) {
    window.open(value, '_blank')
  }

  
  function scannerCallback(uid) {
    console.log("Scanner Callback starts working "+uid);
    loadQueue(uid);
  }


  return (
      <Container maxWidth="xs"  className={classes.paper} style={{position: 'relative', marginTop:"80px", marginBottom:"100px"}}>
          <DeskHeader title={t("scan_code")}   backTo={"/"} />
        <CssBaseline />
          <Avatar className={classes.avatar}>
            <CropFree />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("waiterscanner_text")}
          </Typography>
          <Grid container spacing={3} style={{marginTop:'10px',marginLeft: "-60%;" }}>
            <Grid item xs={12} >
              <QRCodeScanner onScan={scannerCallback} />
            </Grid>            
          </Grid>
        <AppFooter />
      </Container>
    );
}

export default withTranslation()(QRCodeQueueIn);