import React, { Fragment, useContext, useEffect , useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";
import log from 'loglevel';
import WebsocketUtil from '../authorization/WebsocketUtil';

import axios from 'axios';
import config from 'react-global-configuration';
import { CheckCircleOutline } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';




export default function WSProvider(props) {
  const { relatedId } = props;
  const { from } = props;
  const { children } = props;
  
  
  const { setWsTickets } = useContext(MessageContext)
  
  const { addMessage } = useContext(MessageContext)
  const [ connected, setConnected ] = useState(false);
  const history = useHistory();

  const websocketQClient = new WebsocketUtil();

  useEffect(() => {    // Update the document title using the browser API 
      log.debug("WSProvider useEffect "+relatedId+ " - "+from);
      if(from && relatedId) {
        websocketQClient.websocket(
          '/topic/data/GROUP/'+relatedId+"/"+from, 
          ()=> {
            log.debug("WSProvider connectCallback ... ");
            setConnected(true);
            processChange() //after connect fetch latest state
          },
          processChange);
      }

      return () => { cleanup(); };
    }, [relatedId, from] )
  
  function cleanup() {
    log.debug("WSProvider disconnect /topic/data/GROUP/"+relatedId+"/"+from);
    websocketQClient.disconnect(() => {});
  }
  
  function processChange(data) {
    if(data) {
      if (data.constructor.name == "Array") {
        log.debug("WSProvider processChange Websocket Ticket callback ... "+data.length);  
        setWsTickets(data);
      } else {
        log.debug("WSProvider processChange Websocket STH callback ... "+data);  
      }
      
    }
  }  


  return children;
}
