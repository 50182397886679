import React from 'react';
import i18n from '../../../i18n';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

function DropdownLanguage(props) {
    const lngs = {
        de: {nativeName: "Deutsch"},
        en: {nativeName: "English"},
        
      };
    const {t} = props;

    return ( 
        <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label">{t("language")}</InputLabel>
            <Select 
                
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={MenuItem.value}
                onChange={(event) => i18n.changeLanguage(event.target.value)}
            >
                {Object.keys(lngs).map((lng) => (
                    <MenuItem key={lng} value={lng} >{lngs[lng].nativeName}</MenuItem>
                ))}            
            </Select>
        </FormControl>
     );
}

export default withTranslation()(DropdownLanguage);