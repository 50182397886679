import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Fab, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined, FiberManualRecord } from '@material-ui/icons';
import TargetTime from './TargetTime';
import ResourcesTicketStatus from '../../resources/ResourcesTicketStatus';
import { t } from 'i18next';
import { Badge } from 'reactstrap';
import ShortStatus from '../../ticket/assets/ShortStatus';
import Attachements from '../../upload/Attachements';



export default function TicketData(props) {
  const { ticket } = props
  const { from } = props
  const { queue } = props
  const { small } = props
  const { timeline } = props
  const { manage } = props  
  const { pShowMore } = props
  
  const { call } = props
  const [ showMore, setShowMore ] = useState(pShowMore)
  

  const handleClick = (event) => {
    setShowMore(!showMore?event.currentTarget:undefined);
  }

  const isWaiting = () => {
    return ticket.primaryStatus==='WAITING' && ticket.secondaryStatus==='WAIT'
  }


  const isCalled = () => {
    return ticket 
    && ticket.primaryStatus==='WAITING' && ticket.secondaryStatus==='CALL'
    
  }
  
  const isFinished = () => {
    return ticket 
    && ticket.primaryStatus==='FINALIZING'
    
  }
  
  const isSuggestionPending = () => {
    return ticket 
    && ticket.secondaryStatus==='APPOINTMENT_REQUEST_CLIENT_OPERATOR'
    
  }
  

  const isReadyToRedeem = () => {
    return ticket 
    && ticket.primaryStatus==='WAITING' && ticket.secondaryStatus==='LAST_CALL'
  }

  
  const isWatching = () => {
    return (!ticket || !ticket.id ||  ticket.primaryStatus=='FINALIZING')  ;
  }
  
  
  const isApprovalPending = () => {
    return ticket 
    && ticket.primaryStatus==='PREPARING' && ticket.secondaryStatus==='PULL_REQUESTED'
  }

  const isInProcess = () => {
    return ticket 
    && ticket.primaryStatus==='PROCESSING' && ticket.secondaryStatus==='PROCESS'
  }
  const isAnythingElse = () => {
    return !isInProcess() && !isReadyToRedeem() && !isFinished() && !isCalled() && !isWaiting();
  }


  const getDetails = (style) => {
    return <Fragment>
             
             { isAnythingElse() &&
              <WaitingCardInfo
                description="" 
                ticket={ticket}
                style={style}/>
              
            } 
            { isInProcess() &&
              <WaitingCardInfo
                title={t("remaining_process_duration")}
                info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.endPlanned}/> }
                description="" 
                ticket={ticket}
                style={style}/>
              
            } 
            { isReadyToRedeem() &&
              <WaitingCardInfo
                title={t("it_begins")}
                info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.redeemEndTime}/> }
                description={t("your_timeslot_begins")}
                ticket={ticket}
                style={style} />
              
            } 
            {
            isWaiting() && 
              <WaitingCardInfo
                title={t("current_waiting_time")}
                info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.startPlanned}/> }
                description={t("you_will_be_informed")}
                ticket={ticket}
                style={style} />
            }
            {
            isCalled() &&
              <WaitingCardInfo
                    title={t("attention")}
                    info={<CountDown hideLabel={false} hideProgress={true} targetTime={ticket.startPlanned}/> }
                    description={t("your_turn_soon")}
                    ticket={ticket}
                    style={style} />
            }  
            { isFinished() && 
              <Fragment>
                { ticket.secondaryStatus==='COMPLETED' &&
                  <WaitingCardInfo
                    title={t("Done")}
                    description={t("appointment_finished")}
                    ticket={ticket}
                    style={style} />
                  
                }
                { ticket.secondaryStatus==='CALL_MISSEED' &&
                  <WaitingCardInfo
                    title={t("call_missed")}
                    description={t("appointment_over")}
                    ticket={ticket} 
                    style={style}/>
                  
                }
                { ticket.secondaryStatus==='EXPIRED' &&
                  <WaitingCardInfo
                    title={t("Run_out")}
                    description={t("appointment_run_out")}
                    ticket={ticket}
                    style={style} />
                  
                }
                { ticket.secondaryStatus==='RETURNED' &&
                  <WaitingCardInfo
                    title={t("given_back")}
                    info=""
                    description={t("appointment_given_back")}
                    ticket={ticket}
                    style={style} />
                  
                }
              </Fragment>    
            }
        </Fragment>    
                        
  }


  return (
    
     
    <Fragment>
      
      { timeline && 
        /*<Grid container spacing={0} justify="center" >
          <Grid item xs={12} >
                <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.6rem" }}>
                  <span ><DateTimeDisplay timestamp={ticket.startPlanned} hideDate={true}/>  </span>
                  <span > - </span>
                  <span ><TargetTime targetTimestamp={ticket.startPlanned+ticket.expectedProcessingTime}/> {t("clock")}</span>
                </Typography>
          </Grid>
          <Grid item xs={12}  >
            <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.7rem" }}>
              { ticket.contact && ticket.contact}
            </Typography>
          </Grid>
        </Grid>*/
        <Grid container style={{backgroundColor:"rgba(255, 255, 255, 0.65)", color:"black", padding:"3px", borderRadius:"5px"}}>
          <Grid item xs={10}  >
            <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.6rem", fontWeight:"bold", textDecoration:"underline" }}>
              <span ><DateTimeDisplay timestamp={ticket.startPlanned} hideDate={true}/>  </span>
              <span > - </span>
              <span ><TargetTime targetTimestamp={(ticket.endPlanned && ticket.secondaryStatus==='COMPLETED')?ticket.endPlanned:ticket.startPlanned+ticket.expectedProcessingTime}/>
                { 
                  ticket.endPlanned && 
                  <span > (<TargetTime duration={ticket.endPlanned - ticket.startPlanned}/>) </span>
                }
              </span>
            </Typography>
            <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.6rem" }}>
              <span >({ticket.position + "-" + ticket.id})</span>
            </Typography>
          </Grid>
          <Grid item xs={2}  >
          { manage && <ShortStatus ticket={ticket} /> }
           </Grid>
          { manage && 
            <Fragment>
              <Grid item xs={12}  >
                <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"1rem" }}>
                  { ticket.clientName && ticket.clientName} { ticket.createdBy && <span>&nbsp;({ticket.createdBy})</span> }
                </Typography>
              </Grid>
              <Grid item xs={12}  >
                <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.7rem" }}>
                  { ticket.externalRef && ticket.externalRef}
                </Typography>
              </Grid>              
              <Grid item xs={12}  >
                <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.7rem" }}>
                  { ticket.extraData && ticket.extraData}
                </Typography>
              </Grid>
              { /*</Fragment>Grid item xs={small?12:6} style={{paddingLeft:"0"}}>
                  { getDetails({fontSize:"0.6rem", width:"100%", textAlign:"left", fontWeight:"bold", paddingTop:"0.2rem"}) }
          </Grid>*/ }
            </Fragment>
          }
          { !manage && 
            <Grid item xs={12}  >
              <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"1rem" }}>
                BELEGT
              </Typography>
            </Grid>
          }
        </Grid>
  
      }

      { !timeline && 
        <Grid container spacing={2} justify="center" >
          <Grid item xs={12} >
            <Grid container >
              <Grid item xs={12} style={{marginTop:"0.5rem", borderTop:"1px solid", paddingBottom:"0.5rem", paddingTop:"0.5rem"}}>
                <Grid container >
                  <Grid item xs={small?12:6}  style={{borderRight:"1px solid"}} >
                    <Grid container >
                      <Grid item xs={9} >
                        <Grid container>
                          <Grid item xs={12} >
                            <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.8rem",whiteSpace: "nowrap", fontWeight:"bold", textDecoration:"underline"}}>
                              {t("start_point")}
                            </Typography>  
                            <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"1rem", fontWeight:"bold",whiteSpace: "nowrap"}}>
                              <DateTimeDisplay timestamp={ticket.startPlanned} hideTime={true}/>   
                            </Typography>  
                          </Grid>          
                          <Grid item xs={12}  >
                            <Typography  variant="body1" style={{fontSize:"1rem", width:"100%", textAlign:"left", fontWeight:"bold"}}>
                              <span ><DateTimeDisplay timestamp={ticket.startPlanned} hideDate={true}/> {t("clock")}</span>                         
                            </Typography>
                          </Grid>          
                          <Grid item xs={12}  >
                            <Typography  variant="body1" style={{fontSize:"0.6rem", width:"100%", textAlign:"left"}}>
                              <span >{t("end_round_about")} <TargetTime targetTimestamp={ticket.startPlanned+ticket.expectedProcessingTime}/> {t("clock")}</span>                         
                            </Typography>
                          </Grid>  
                          <Grid item xs={12}  >
                            <Attachements ticket={ticket} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3} >
                        <Grid container>
                          <Grid item xs={12} >
                            { /* queue && manage && <Link to={{pathname: '/my/groups/resources', vPlace:queue.place}}  style={{color: "inherit", textDecoration: "none", position: "absolute"}}>
                              <ResourcesTicketStatus from={from} ticket={ticket} />
                            </Link> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      
                              
                    </Grid>
                  </Grid>
                  <Grid item xs={small?12:6} style={{paddingLeft:small?"0":"0.5rem"}}>
                    <Grid container >
                      <Grid item xs={12} >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.8rem",whiteSpace: "nowrap", fontWeight:"bold", textDecoration:"underline"}}>
                          {t("wating_time")}
                        </Typography>       
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.6rem",whiteSpace: "nowrap"}}>
                          {t("hours/minutes/seconds")}
                        </Typography>                 
                      </Grid>
                      <Grid item xs={12} >
                              { getDetails({fontSize:"1.5rem", width:"100%", textAlign:"left", fontWeight:"bold", paddingTop:"0.2rem"}) }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
              {  ticket.queue && !showMore && !props.pShowMore &&  <Fragment>
                  
                  <Grid xs={12} justify="center" >
                    
                      <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%", marginTop:"-25px"}}  onClick={handleClick} >
                        <Avatar style={{backgroundColor:"#ccffff", border:"1px solid"}}>
                          <ExpandMoreOutlined style={{fontSize:"2rem", color:"black"}} />
                        </Avatar>
                      </IconButton>
                    
                  </Grid>
                </Fragment>
              }

              { ticket.queue && showMore && 
                <Fragment>
                  { !props.pShowMore && 
                      <Grid xs={12} justify="center" >
                          <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%", marginTop:"-20px"}}  onClick={handleClick} >
                            <Avatar style={{backgroundColor:"#ffffcc", border:"1px solid"}}>
                              <ExpandLessOutlined style={{fontSize:"2rem", color:"black"}} />
                            </Avatar>
                          </IconButton>                  
                      </Grid>
                  }
                  { 
                    !call && ticket.extraData &&
                    <Grid item xs={12}  >
                      <Typography  variant="body1" style={{width:"100%", textAlign:"left"}}>
                        <span >{ ticket.extraData }</span>                    
                      </Typography>
                    </Grid>
                  }
                  { 
                    !call && ticket.startWish &&
                    <Fragment>
                      <Grid item xs={12}  >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                          {t("preferred_time_slot")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}  >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.8rem"}}>
                          <DateTimeDisplay timestamp={ticket.startWish} hideTime={true}/>                   
                          <span >&nbsp;&nbsp;&nbsp;</span>                    
                          <DateTimeDisplay timestamp={ticket.startWish} hideDate={true}/>  
                          -
                          <DateTimeDisplay timestamp={ticket.endWish} hideDate={true}/> {t("clock")}
                        </Typography>   
                      </Grid>
                      
                    </Fragment>
                  }

                
                  { !call && 
                    <Fragment>
                      
                      { ticket.category && <Fragment>
                            <Grid xs={4} justify="center" >
                              <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                                {t("category")}
                              </Typography>
                            </Grid>
                            <Grid xs={8} justify="center" >
                              <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.6rem"}}>
                                {ticket.category.name} 
                              </Typography>
                            </Grid>
                        </Fragment>
                      } 
                      
                      
                      { ticket.queue && ticket.queue.contact && <Fragment>
                            <Grid xs={4} justify="center" >
                              <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                                {t("contacts")}
                              </Typography>
                            </Grid>
                            <Grid xs={8} justify="center" >
                              <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.6rem"}}>
                                {ticket.queue.contact} 
                              </Typography>
                            </Grid>
                        </Fragment>
                      }
                      <Grid xs={4} justify="center" >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                          {t("tuid")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}  >
                        <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.8rem"}}>
                          <span style={{whiteSpace: "nowrap"}}>{ticket.uid}</span>                                      
                        </Typography>   
                      </Grid>
                    </Fragment>
                  }
                </Fragment> 
              }
              </Grid>
            </Grid>
          </Grid>  
        }
      </Fragment>     
  );
}