import React, { useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import UserTableRow from './UserTableRow';
import UserTableHeader from './UserTableHeader';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




export default function Accounts() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
 
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    const url = config.get('vqueueUrl')+'/api/users';
    axios.get(url )
        .then(res => {
          addMessage(t("users_loaded"));
          setUsers(res.data)
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        })   
  }, [] )

  

  return (
    <Container component="main" maxWidth="md">
      <DeskHeader title={t("all_user")}  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("all_user")}
        </Typography>
        <form >
          <Grid container spacing={5} className={classes.form}>  
            <Grid xs={12} style={{borderBottom:"solid", borderBottomWidth:"1px"}}>
              <UserTableHeader />
            </Grid>
            {  users.map((user, index) => (
                  <Grid item key={user.id} xs={12} style={{borderBottom:"solid", borderBottomWidth:"1px"}}>
                    <UserTableRow user={user}/>
                  </Grid>
                ))
            }
          </Grid>
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}
