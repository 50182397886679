import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TicketGrid from '../pages/assets/CardGrid';
import { ExpandLessOutlined, ExpandMoreOutlined, Grade, LocationCity } from '@material-ui/icons';
import LocationData from '../pages/assets/LocationData';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';
import AuthorizationUtil from '../authorization/AuthorizationUtil';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function Groups(props) {
  const {t} = props;
  const classes = useStyles();
  const { vPlace } = props.location;  
  const [place, setPlace] = useState(vPlace?vPlace:SessionContext.getPlace());
  const [groups, setGroups] = useState([]);
  const [ showMore, setShowMore ] = useState(false)
  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();
  const controller = new AbortController();
  const signal = controller.signal;
    

  useEffect(() => {
    //cleanup function
    return () => {controller.abort();};
   }, []);

  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setGroup(null);    
    SessionContext.setPlace(place);
    SessionContext.setResorucesOrigin("/my/groups"); 
    const url = config.get('vqueueUrl')+'/api/groups/place/'+place.id;// TODO, use current place id later here. +vPlace;
    axios.get(url,{signal: signal} )
        .then(res => {
          setGroups(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })   
  }, [] )

  function getGridContent() {
    let gridContent = [];
    groups.forEach(function (aGroup, index) {
      let entry =  {
        title: <span className="label" style={{whiteSpace: "nowrap"}}>{aGroup.name}</span>  ,
        text: <Grid item xs={12}  >
                <Grid container spacing={0} alignItems="left" justify="left" >
                  <Typography  variant="body1" >
                    <span >{aGroup.decription}</span>
                  </Typography>
                </Grid>
              </Grid>,
        action: <Fragment>
                  <Grid item xs={(!AuthorizationUtil.isUser(aGroup) || (AuthorizationUtil.isUser(aGroup) && !AuthorizationUtil.isManager(aGroup)))?12:6}  >
                    <Link to={{pathname: '/my/groups/slotmanager', vGroup:aGroup, vPlace:vPlace, vManage: AuthorizationUtil.isManager(aGroup)}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="secondary" /*variant="contained"*/ >
                        {t("slotmanager")}
                      </Button>
                    </Link>                      
                  </Grid>
                 { AuthorizationUtil.isManager(aGroup) && 
                  <Grid item xs={6}  >
                    <Link to={{pathname: '/my/groups/edit', vGroup:aGroup}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="primary" /*variant="contained"*/ >
                        {t("Change")}
                      </Button>
                    </Link>
                  </Grid> 
                 }
           </Fragment>,
        menuItems: getMenuItems(aGroup),
        cardStyle:{height: "18.5rem"},
        object: aGroup
      }; 
      if(AuthorizationUtil.isUser(aGroup)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>U</span>;
      }    
      if(AuthorizationUtil.isManager(aGroup)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>M</span>;
      } 
      if(AuthorizationUtil.isAdmin(aGroup)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>A</span>;
      }
      if(AuthorizationUtil.isOwner(aGroup)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>O</span>;
      }
      gridContent.push(entry);
    });
    return gridContent;
  }

  function getMenuItems(vGroup) {
      let menuContent = [
        
        <MenuItem >
          <Link to={{pathname: '/my/groups/slotmanager', vGroup:vGroup, vPlace:vPlace, vManage: true}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
              {t("slotmanager")}
            </Button>
          </Link>
        </MenuItem>
        ]
        if (AuthorizationUtil.isManager(vGroup)) {
          let manageContent = [
            <MenuItem  >
              <Link to={{pathname: '/my/groups/edit', vGroup:vGroup}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
                <Button style={{width:'100%'}} variant="contained" color="primary" /*variant="contained"*/ >
                {t("Change")}
                </Button>
              </Link> 
            </MenuItem>,
            <MenuItem >
              <Link to={{pathname: '/my/groups/resources', vGroup:vGroup, vPlace:vPlace}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
                <Button style={{width:'100%'}} variant="contained" color="secondary" /*variant="contained"*/ >
                  {t("resources")}
                </Button>
              </Link>
            </MenuItem>
            ]
          menuContent = menuContent.concat(manageContent);
        }
      
        if (AuthorizationUtil.isAdmin(vGroup)) {
          let adminContent = [
            <MenuItem >
              <Link to={{pathname: '/authorizations', title:<Crumbs group={vGroup}/>, vBackTo: "/my/groups", vKind: "GROUP", vKindId: vGroup.id}}  style={{color: "inherit", textDecoration: "none"}}>
                <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                  {t("rights")}
                </Button>
              </Link> 
            </MenuItem>
            ]
          menuContent = menuContent.concat(adminContent);
        }
        return menuContent;

  }

  function getFabButton() {
    return  <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={{pathname: '/my/groups/edit', group:{}}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }
  
  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link to={{pathname: '/my/groups/edit', group:{}}} 
          style={{color: "inherit", textDecoration: "none"}}>
            <AddIcon />            
      </Link> 
    );
    return quickActionItems;
  }
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={<Crumbs place={place}/>} backTo={"/my/places"} quickActions={getQuickActionItems()}
          />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
                {t("all_groups")}
        </Typography>
        <Grid container>  
            <Grid xs={12}>
              <Link to={{pathname: '/my/groups/edit', group:{}}}  style={{color: "inherit", textDecoration: "none"}}>
                <Button style={{width:'100%'}} variant="contained" color="secondary" /*variant="contained"*/ >
                    {t("new_group")}
                </Button>
              </Link>                    
            </Grid>
          
          <Grid xs={12}>
            
            <TicketGrid gridContent={getGridContent()}/>
          </Grid>  
        </Grid>
       
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
}

export default withTranslation()(Groups);