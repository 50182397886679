import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../../pages/assets/AppFooter';
import DeskHeader from '../../pages/assets/DeskHeader';
import { MessageContext } from '../../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TicketGrid from '../../pages/assets/CardGrid';
import DateTimeDisplay from '../../pages/assets/DateTimeDisplay';
import { AccessAlarm } from '@material-ui/icons';
import Crumbs from '../Crumbs';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




 function Slots(props) {
  const classes = useStyles();
  const { t } = props;
  const { vQueue } = props.location;
  
  const [slots, setSlots] = useState([]);
  const [slot] = useState({});
 
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setSlot(null);    
    
    let url = config.get('vqueueUrl')+'/api/slots'+"?queueId="+vQueue.id;
    
    axios.get(url )
        .then(res => {
          setSlots(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })   
  }, [] )

  function getGridContent() {
    let gridContent = [];
    slots.forEach(function (aSlot, index) {
      let entry =  {
       
        title: <Grid item xs={12}  >
                <Grid container spacing={0} justify="center">
                  <Grid item xs={6}  >
                    <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontWeight:"bold", textDecoration:"underline"}}>
                      {t("slot_intervall_"+aSlot.interval)}
                    </Typography>
                  </Grid>
                  { (aSlot.interval=="WEEKLY" || aSlot.interval=="NO_INTERVAL" ) &&
                    <Grid item xs={6} >
                      <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontWeight:"bold"}}>
                        <span style={{whiteSpace: "nowrap"}}>
                          { aSlot.interval=="WEEKLY" && <span>ab&nbsp;</span>}
                          { aSlot.interval=="NO_INTERVAL" && <span>am&nbsp;</span>}
                          <DateTimeDisplay timestamp={aSlot.startTime} hideTime={true}/></span>                    
                      </Typography>
                    </Grid>
                  }
                </Grid>
            </Grid>,
        text:<Fragment> 
          <Grid item xs={12}  >
            <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
              {aSlot.decription}
            </Typography>
          </Grid>
          <Grid item xs={12}  >
            <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontWeight:"bold", paddingTop:"0.5rem"}}>
              <span ><DateTimeDisplay timestamp={aSlot.startTime} hideDate={true}/></span>
              <span>&nbsp;-&nbsp;</span>
              <span ><DateTimeDisplay timestamp={aSlot.endTime} hideDate={true}/> {t("clock")}</span>   
            </Typography>
          </Grid> 
      </Fragment>,
        action: <Fragment>
                  
                  <Grid item xs={6}  >
                    <Link to={{pathname: '/my/groups/slotmanager/settings/slots/edit', vSlot:aSlot}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                        {t("Change")}
                      </Button>
                    </Link>
                  </Grid>    
           </Fragment>,
        menuItems: getMenuItems(aSlot),
        cardStyle:{height: "18.5rem"},
        object: aSlot
      };
      gridContent.push(entry);
    });
    return gridContent;
  }

  function getMenuItems(vSlot) {
      return [
        <MenuItem >
          <Link to={{pathname: '/my/groups/slotmanager/settings/slots/edit', vSlot:vSlot, vQueue: vQueue}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
            {t("Change")}
            </Button>
          </Link> 
        </MenuItem>
        ]
  }

  function getFabButton() {
    return  <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={{pathname: '/my/groups/slotmanager/settings/slots/edit', slot:slot, vQueue: vQueue}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
        title={<Fragment><Crumbs queue={vQueue}/> : <Fragment>{t("opening_hours")}</Fragment></Fragment>}
        backTo={"/my/groups/slotmanager/settings"} />
      
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccessAlarm />
        </Avatar>
        <Typography component="h1" variant="h5">
                {t("opening_hours")}
        </Typography>
        <Grid container>  
            <Grid xs={12}>
              <Link to={{pathname: '/my/groups/slotmanager/settings/slots/edit', slot:slot}}  style={{color: "inherit", textDecoration: "none"}}>
                <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
                    {t("new_opening_hour")}
                </Button>
              </Link>                    
            </Grid>
          
          <Grid xs={12}>
            
            <TicketGrid gridContent={getGridContent()}/>
          </Grid>  
        </Grid>
       
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
}

export default withTranslation()(Slots);