import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { t } from 'i18next';
import React, { Component, Fragment, useContext, useEffect } from 'react';
import { Button } from 'reactstrap';
import Card from './Card';
import { MessageContext } from './MessageProvider';
import SessionContext from '../../frame/SessionContext';


export default function CardGrid(props) {
    const { vPlace } = props;
    const { queue } = props;
    const { popupable } = props;
    const { gridContent } = props;
    const { small } = props;
    const { call } = props;
    const { timeline } = props;
    const { manage } = props;
    
    const [open, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({});
    

    function getGridContent() {
      if(gridContent) {
        return gridContent;
      } 
      return [
        {
          title:t("home"),
          text: t("back_to_begin"),
          link:"/"
        }
        
      ] ;
    }

    
  //BEGIN -- Popup Dialog Info stuff
  function zoomIn(e, content) {
    var filteredTickets = SessionContext.getMyTickets().filter((myTicket) => {
      return myTicket && content.ticket && myTicket.uid === content.ticket.uid
    })
    let manageTicket = filteredTickets.length>0 || (manage && popupable);
    if(manageTicket && popupable) {
      e.preventDefault();
      let card = <Card area={content.popup} popupView={true} manage={manage}/>
      setContent(card);
      handleClickOpen();
    }
  }
  function format(time) {
    let date = new Date(parseInt(time,10));      
   return date.toLocaleTimeString("de-DE");
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //END -- Popup Dialog Info stuff


    return (
      <Fragment>
              
            {getGridContent().map((value, index) => (
              <Fragment key={index} >
                <Grid id={"ticketEntryGridElement"+(value.ticket?(value.ticket.id?value.ticket.id:value.ticket.primaryStatus):"NO_TICKET")} onClick={(e) => zoomIn(e, value)} key={index} item  xs={12} 
                    style={{ 
                        position:timeline?"absolute":"relative", 
                        marginBottom: timeline?"0":"20px", 
                        width: "100%", 
                        minHeight: value.height?value.height+"px":"unset",
                        maxHeight: value.height?value.height+"px":"unset", 
                        top:value.distance?value.distance+"px":'inherit', 
                        zIndex: (value.ticket && value.ticket.wishSource != "RESOURCES")?"10":"9",
                        padding:"0" }}
                        
                onMouseOver={e => { 
                  if(value.ticket && value.ticket.id) {
                    e.target.style.zIndex = "20";
                  }
                }}
                onMouseLeave={e => { 
                  if(value.ticket && value.ticket.id) {
                    e.target.style.zIndex = (value.ticket && value.ticket.wishSource != "RESOURCES")?"10":"9";
                  }
                }}
                    >
                  <Card area={value} queue={queue}  small={small} call={call} manage={manage} />
                </Grid>
              </Fragment>
            ))}
            <Dialog
              open={open}
              fullWidth={true}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
                { 
                  //<DialogTitle id="alert-dialog-title">Bafertigung No. 37623kjdsfh</DialogTitle> 
                }
                <DialogContent>
                     { content }           
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    {t("ah_ok")}
                  </Button>
                </DialogActions>
            </Dialog>
        </Fragment>    
      
    );
  }