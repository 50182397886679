import React, { useState,useContext, createContext, useEffect, useRef, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { LocationCity, Save } from '@material-ui/icons';
import WeekDaySelector from '../queue/assets/WeekDaySelector';
import { withTranslation } from 'react-i18next';
import Crumbs from '../queue/Crumbs';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




function EditResource(props) {
  const {t} = props;
  const classes = useStyles();
  const { vResource } = props.location;
  const [resource, setResource ] = useState(vResource?vResource:SessionContext.getResource());
  const { vPlace } = props.location;
  const [place, setPlace ] = useState(vPlace?vPlace:SessionContext.getPlace());
  
  const { vGroup } = props.location;
  const [group, setGroup] = useState(vGroup?vGroup:SessionContext.getGroup());
  
  const [resourceVariants, setResourceVariants] = useState([]);
  

  const [name, setName] = useState(resource?resource.name:"");
  const [decription, setDecription] = useState(resource?resource.decription:"");
  const [kind, setKind] = useState(resource?resource.kind:"HUMAN");
  const [resourceVariant, setResourceVariant] = useState(resource?resource.resourceVariant:{});
  const [resourceworkinghours, setResourceWorkingHours] = useState([]);
  const [timeScopes, setTimeScopes] = useState([]);
  
  

  const { addMessage } = useContext(MessageContext)
  const { addModalMessage } = useContext(MessageContext)
  
  const history = useHistory();

  const Address = <EditLocation />;

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("resource: " + JSON.stringify(resource));
    SessionContext.setResource(resource);    
    let uri = '/api/resource-variants/'; //später noch auf den Ort einschränken
    const url = config.get('vqueueUrl')+uri;
    axios.get(url )
        .then(res => {
          setResourceVariants(res.data);
          loadResourceWorkingHours();
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        }) 
  }, [] )

  useEffect(() => {    
    workingHours2TimeScopes(resourceworkinghours);
  }, [resourceworkinghours] )

  function applyWorkingHoursOnAllOtherResources() {
    const url = config.get('vqueueUrl')+'/api/resource-working-hours/copyfrom/'+resource.id;
    axios.get(url )
      .then(res => {
        addModalMessage(t("other_resources_updated"));
      }).catch(function (error) {
        console.log(error);
        addMessage(error.detail);
      })   
  }

  function loadResourceWorkingHours() {
    const url = config.get('vqueueUrl')+'/api/resource-working-hours/resource/'+resource.id;
    axios.get(url )
      .then(res => {
        setResourceWorkingHours(res.data)
      }).catch(function (error) {
        console.log(error);
        addMessage(error.detail);
      })   
  }

  function validateForm() {
    console.log("resource: " + JSON.stringify(resource));
    return name && name.length > 0;
  }

  function workingHours2TimeScopes(workingHours) {
    console.log("workingHours2TimeScopes: " + JSON.stringify(workingHours));
    let scopes = [];
    workingHours.forEach(function (aWorkingHour, index) {
      let dayIndex = aWorkingHour.index?aWorkingHour.index:0;
      let aDayScope = scopes[dayIndex];
      if(!aDayScope) {
        aDayScope = [];
      }
      let aScope = {};      
      aScope.begin = aWorkingHour.start;
      aScope.end = aWorkingHour.end;
      aDayScope.push(aScope);
      scopes[dayIndex]=aDayScope;
    });
    console.log("workingHours2TimeScopes results: " + JSON.stringify(scopes));
    setTimeScopes(scopes);    
  }  
  function timeScopes2WorkingHours(timeScopes) {
    console.log("timeScopes2WorkingHours: " + JSON.stringify(timeScopes));
    let workingHours = [];
    timeScopes.forEach(function (aDayScope, indexDay) {
      if(aDayScope) {
        aDayScope.forEach(function (aScope, index) {
          let aWorkingHour = {
            index: indexDay,
            start: aScope.begin,
            end: aScope.end
          }
          workingHours.push(aWorkingHour);
        });
      }
    });
    console.log("timeScopes2WorkingHours results: " + JSON.stringify(workingHours));
    setResourceWorkingHours(workingHours);  
  }  

  function changeResourceVariant(id) {
    if(id) {
      let variants  = resourceVariants.filter((variant) => {
        return variant.id==id
      });
      setResourceVariant(variants[0]);
    } else {
      setResourceVariant(null);
    }
  }

  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/resources/'+resource.id;
    axios.delete(url,  resource )
        .then(res => {
          history.push("/my/groups/resources");
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save Resource.")
    let tResource = resource;
    if(!tResource) {
      tResource = {};
    }

    tResource.name = name;
    tResource.decription = decription;
    tResource.kind = kind;
    tResource.resourceVariant = resourceVariant;
    if(group) {
      tResource.group = group;
    } else {
      tResource.place = place;
    }
    if(resourceworkinghours) {
      tResource.resourceWorkingHours=resourceworkinghours;
    }
    const url = config.get('vqueueUrl')+'/api/resources';
    if(!tResource.id) {
        axios.post(url,  tResource )
        .then(res => {
          history.push("/my/groups/resources");
          //addMessage(t("resource_saved"));
        }).catch(function (error) {
          console.log(error);
        })
     } else {
        axios.put(url,  tResource )
        .then(res => {
          history.push("/my/groups/resources");
          //addMessage(t("resource_saved"));
        }).catch(function (error) {
          console.log(error);
        })
      }
  }
  
  function getMoreMenuItems() {
    let gridContent = [
      {
        action: () => applyWorkingHoursOnAllOtherResources(),
        title: t("adapt_work_time"),               
        text: t("adapt_work_time"),
        icon: <AddIcon />
      }
      
    ];
    return gridContent;
  }

  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link onClick={(e) => handleSubmit(e)} style={!validateForm()?{pointerEvents: "none", color: "#ffb1b1"}:{cursor: "pointer"}}>
              <Save />            
        </Link>
    );
    return quickActionItems;
  }
  
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
    
      <DeskHeader title={ <Fragment>{vResource?t("change_resource"):t("create_resource")}&nbsp;<Crumbs group={group}/></Fragment>} backTo="/my/groups/resources" 
          moreMenuItems={getMoreMenuItems()} 
          quickActions={getQuickActionItems()}/>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("resource")}
        </Typography>      
               
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
             
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="name"
                variant="outlined"
                value={name}
                required
                fullWidth
                id="name"
                label={t("resource_name")}
                autoFocus
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12}>
              <Grid container spacing={1}>
                <Grid item  xs={12}>
                  <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-resourceKind-label">{t("resource_kind")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-resourceKind-label"
                      id="demo-simple-select-resourceKind"
                      value={kind} 
                      onChange={(e) => setKind(e.target.value)}
                      label={t("resources_status")}
                    >
                      <MenuItem value={"HUMAN"}>{t("active")}</MenuItem>
                      <MenuItem value={"MATERIAL"}>{t("deactivated")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={12}>
              <Grid container spacing={1}>
                <Grid item  xs={12}>
                  <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-resourceKind-label">{t("resources_alternative")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-resourceKind-label"
                      id="demo-simple-select-resourceKind"
                      value={resourceVariant && resourceVariant.id} 
                      onChange={(e) => changeResourceVariant(e.target.value)}
                      label={t("resources_alternative")}
                    >
                    {resourceVariants.map((variant,i) => {
                          return (
                            <MenuItem  key={i} value={variant.id}>
                              {variant.name} ({variant.speed}&nbsp;{t("min/cyc")})
                            </MenuItem>
                          )
                    })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
                      
            <Grid item  xs={12}>
              <Grid container spacing={3}>
                <Grid item  xs={12}>
                  <Typography component="h2" variant="h5">
                    {t("work_time")}
                  </Typography>   
                </Grid>

                
                <Grid item  xs={12}>
                 <WeekDaySelector 
                    timeScopes={timeScopes} 
                    updateCallback={(timeScopes) => timeScopes2WorkingHours(timeScopes)} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item  xs={6} >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t("save")}
              </Button> 
            </Grid>
            <Grid item  xs={6} >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => deleteEntity()}
                disabled={!resource || !resource.id}
              >
                {t("delete")}
              </Button>  
            </Grid>
          </Grid>
         
                    
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditResource);