import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import Chat from './Chat';
import { useHistory } from "react-router-dom";

import { MessageContext } from '../pages/assets/MessageProvider';
import axios from 'axios';
import config from 'react-global-configuration';
import { Button, Container, CssBaseline } from '@material-ui/core';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { ForumOutlined } from '@material-ui/icons';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    }
  }));
export default function Dialog(props) {
    const classes = useStyles();
    const [ticket, setTicket] = useState(props.location?props.location.ticket:props.ticket);
  
   

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"10vh", marginBottom:"100px"}}>
        <DeskHeader title={t("dialogue")}/>
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <Chat ticket={ticket} />
            </Grid>
          </Grid>
        </div>
        <AppFooter />
    </Container>
  );
}