import React, { Fragment, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Badge, Chip, Divider, Fab, IconButton, Menu, Paper, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { Apps, CalendarToday, DateRange, EventAvailable, ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined, SkipNext, SkipPrevious } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';
import Axios from 'axios';
import config from 'react-global-configuration';
import ActionMenu from './ActionMenu';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from "moment";
import "moment/locale/de";
import { t } from "i18next";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexFlow: "nowrap",
    "& .MuiPaper-root": {
      width: "100%"
    }
  },
  button: {
    display: "block",
    textAlign: "start"
  },
  badge: {
    width: "100%",
    textAlign: "left",
    display: "block"
  },
  root1: {
    display: 'flex',
    alignItems: 'center',
    width: "100%",    
    justifyContent: "space-between"    
  },
  
  bigChip: {
    borderRadius: '9px', //some style
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    width: "17rem",
    '& .MuiChip-label': { fontSize: "1.4rem" }, // sub-selector
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  styledKeyboardDatePicker: {
    MuiInputBaseInput: {
      visibility: "hidden",
      width: "0"
    }
  }
}));

export default function TimeScopeBrowser(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { cycleCallback } = props
  const { toggleSliderCallback } = props
  const { currentDateFrom } = props
  const { currentDateTo} = props
  const [ timeSlider, setTimeSlider ] = useState({})
  
  const [ dateTo, setDateTo ] = useState(SessionContext.getShowTicketsTo()?(Date.now()>SessionContext.getShowTicketsTo()?Date.now():SessionContext.getShowTicketsTo()):Date.now())
  const [ dateFrom, setDateFrom ] = useState(SessionContext.getShowTicketsFrom()?(Date.now()>=dateTo?Date.now():SessionContext.getShowTicketsFrom()):Date.now())
  
   
  useEffect(() => {  
    jumpTo(dateFrom,dateTo);
  }, [])
  
  const roundDate = (date,up) => {
    if(!up) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    } else {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      date.setMilliseconds(999);
    }
    
    console.log("rounded : "+date.getTime());
    return date;
  }

  const toggleSlider = (timeSlider)  => { 
    setTimeSlider(timeSlider)
    if(toggleSliderCallback) {
      toggleSliderCallback(timeSlider);
    }
  }

  const jumpToNearestWithSlots = () => {

  }

  const cycleDays = (days)  => { 
    var from = roundDate(new Date(dateFrom),false);
    var to = roundDate(new Date(dateTo),true);
    
    from.setDate(from.getDate() + days);
    to.setDate(to.getDate() + days);
    
    setDateFrom(from.getTime());
    setDateTo(to.getTime());    

    SessionContext.setShowTicketsFrom(from.getTime());
    SessionContext.setShowTicketsTo(to.getTime());  
    
    cycleCallback(from.getTime(), to.getTime());
  }

  const jumpTo = (dateFrom, dateTo)  => { 
    var from = roundDate(new Date(dateFrom),false);
    var to = roundDate(new Date(dateTo?dateTo:dateFrom),true);
    
    setDateFrom(from.getTime());
    setDateTo(to.getTime());
    
    SessionContext.setShowTicketsFrom(from.getTime());
    SessionContext.setShowTicketsTo(to.getTime());  
    
    cycleCallback(from.getTime(), to.getTime());
  }

  

  return (
    
    <Fragment> 
      <Grid xs container justifyContent="space-between" alignItems="center"  className={classes.root} >
        <Paper elevation={0} square className={classes.root1}>
        
        
            { timeSlider && 
             <IconButton className={classes.iconButton} aria-label="back" onClick={() => cycleDays(-1)} >
               <SkipPrevious />
             </IconButton>
            }
            <Divider className={classes.divider} orientation="vertical" />
             
             <Typography onClick={() => setOpen(true)} variant="p">
                <span className="label">
                  <span style={{fontSize:"2rem"}}>
                    <IconButton onClick={() => jumpToNearestWithSlots()} className={classes.iconButton} aria-label="back"  >
                      <EventAvailable />
                    </IconButton>
                  </span>  
                  <DateTimeDisplay timestamp={dateFrom} hideTime={true} options={{day: '2-digit', month: '2-digit', year: 'numeric'}}/>   
                  <DateTimeDisplay timestamp={dateFrom} hideTime={true} options={{weekday: 'long'}}/>
                  <span style={{fontSize:"2rem"}}>
                    <IconButton onClick={() => setOpen(true)} className={classes.iconButton} aria-label="back"  >
                      <CalendarToday />
                    </IconButton>
                  </span>                    
                </span>                    
                
              </Typography>   
                     
            <MuiPickersUtilsProvider
                   libInstance={moment} utils={MomentUtils} locale={"de"}>
                { <KeyboardDatePicker
                  locale="locale" utils={MomentUtils}
                  showTodayButton
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  disabled={false}
                  variant="dialog"
                  inputVariant="outlined"
                  //label={t("date")}
                  //helperText={t("startdate")}
                  format="DD.MM.yyyy dddd"
                  value={dateFrom}
                  onChange={date => jumpTo(date.toDate().getTime())}
                  InputAdornmentProps={{ v: "start" }}
                  //placeholder="ex: DD/MM/AAAA"
                  style={{visibility:"hidden", width:"0px", display:"none"}}
                   okLabel={t("choose")}
                  clearLabel={t("clrear")}
                  cancelLabel={t("cancel")}
                  todayLabel={t("today")}
                  TextFieldComponent={(props) => (
                    <TextField {...props} onClick={(e) => setOpen(true)} />
                  )}
                /> }
              </MuiPickersUtilsProvider>

            <Divider className={classes.divider} orientation="vertical" />                
            { timeSlider &&  
              <IconButton className={classes.iconButton} aria-label="back" onClick={() => cycleDays(1)} >
                <SkipNext />
              </IconButton>
            }
        </Paper>
            
        </Grid>
           
      </Fragment>    
  );
}