import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Fab, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';


 function BlockerData(props) {
  const { ticket } = props
  const { type } = props
  const {t} = props;


let date = Date.now();

  return (


    <Fragment>

      <Grid container spacing={0} justify="center" style={{backgroundColor:"rgba(255, 255, 255, 0.65)", color:"black", padding:"3px", borderRadius:"5px"}}>
       <Grid item xs={12} >
              <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.6rem", fontWeight:"bold" , textDecoration:"underline"}}>
                <span ><DateTimeDisplay timestamp={ticket.startPlanned} hideDate={true} />  </span>
                <span > - </span>
                <span ><DateTimeDisplay timestamp={ticket.endPlanned<date?date:ticket.endPlanned} hideDate={true} end={true} /></span>
              </Typography>
        </Grid>
        <Grid item xs={12} >
              <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.7rem" }}>
                {type=="SLOT" && t("closed")}
                {type=="RESOURCES" && t("not_bookable")}
              </Typography>
        </Grid>
        
        { /*<Grid item xs={12}  >
          <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontWeight: "bold" , fontSize:"1rem"  }}>
           {props.title}
          </Typography>
        </Grid> */}
            
        
      </Grid>

    </Fragment>
  );
}

export default withTranslation()(BlockerData);