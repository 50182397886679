import React, { useState,useContext, createContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { MyLocation } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




function EditAuthorization(props) {
  const {t} = props;
  const classes = useStyles();
  const { vAuthorization } = props.location;

  const [authorization, setAuthorization] = useState(vAuthorization);

  const { vKind } = props.location;
  const [kind, setKind] = useState(vKind?vKind:SessionContext.getKind());
  
  const { vkindId } = props.location;
  const [kindId, setKindId] = useState(vkindId?vkindId:SessionContext.getKindId());
  
  const [eMail, setEMail] = useState(authorization?authorization.issuedUser.email:null);
  const [role, setRole] = useState(authorization?authorization.role:"USER");

  const { addMessage } = useContext(MessageContext)

  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    
  }, [] )

  function validateForm() {
    console.log("authorization: " + eMail);
    return eMail && eMail.length > 0;
  }


  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/authorization/share/'+authorization.id;
    axios.delete(url,  authorization )
        .then(res => {
          history.push("/authorizations");
          addMessage(t("authorization_deleted"));
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save authorization.")
    let tAuthorization = authorization;
    if(!tAuthorization) {
      tAuthorization = {};
    }
    let issuedUser = tAuthorization.issuedUser;
    if(!issuedUser) {
      issuedUser = {};
    }
    tAuthorization.issuedByUserId=SessionContext.getUser().id;
    issuedUser.email = eMail;
    tAuthorization.role=role;
    tAuthorization.kindId=kindId;
    tAuthorization.kind = kind;
    tAuthorization.issuedUser=issuedUser; // this is only used for temporary reasons to transpornt the mail adress

    const url = config.get('vqueueUrl')+'/api/authorization/share';
    axios.post(url,  tAuthorization )
    .then(res => {
      history.push("/authorizations");
      addMessage(t("authorization_created"));
    }).catch(function (error) {
      addMessage(t("authorization_not_given"));
    })    
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={vAuthorization? t("edit_authorization"): t("create_authorization")}  backTo="/authorizations" />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MyLocation />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("authorization")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="eMail"
                variant="outlined"
                value={eMail}
                required
                fullWidth
                id="eMail"
                label={t("authorization_email")}
                autoFocus
                onChange={(e) => setEMail(e.target.value)}
              />
            </Grid>
          </Grid>
            <Grid item  xs={12}>
                <Grid container spacing={1}>
                  <Grid item  xs={12}>
                    <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-resourceKind-label">{t("role")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-resourceKind-label"
                        id="demo-simple-select-resourceKind"
                        value={role} 
                        onChange={(e) => setRole(e.target.value)}
                        label={t("role")}
                      >
                        <MenuItem value={"ADMIN"}>{t("admin")}</MenuItem>
                        <MenuItem value={"MANAGER"}>{t("manager")}</MenuItem>
                        <MenuItem value={"USER"}>{t("user")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
              </Grid> 
            </Grid>

          <Grid container spacing={2}>
              <Grid item  xs={6} >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!validateForm()}
                >
                  {t("save")}
                </Button> 
              </Grid>
              <Grid item  xs={6} >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => deleteEntity()}
                  disabled={!authorization || !authorization.id}
                >
                  {t("delete")}
                </Button>  
              </Grid>
          </Grid>
         
                    
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditAuthorization);