import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CardGrid from '../pages/assets/CardGrid';
import LocationData from '../pages/assets/LocationData';
import { MyLocation } from '@material-ui/icons';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




 function Authorizations(props) {
  const {t} = props;
  const classes = useStyles();
  const { vDomain } = props.location;

  const { title } = props.location;

  const { vKind } = props.location;
  const [kind, setKind] = useState(vKind?vKind:SessionContext.getKind());

  const { vKindId } = props.location;
  const [kindId, setKindId] = useState(vKindId?vKindId:SessionContext.getKindId());
  
  const { vBackTo } = props.location;
  const [backTo] = useState(vBackTo?vBackTo:SessionContext.getBackTo());
  
  
  const [authorizations, setAuthorizations] = useState([]);
  const [editAuthorization, setEditAuthorization] = useState({});

  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  
  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setBackTo(backTo);
    SessionContext.setKind(kind);
    SessionContext.setKindId(kindId);

    let uri ='/api/authorization/share/'+kind+"/"+kindId;
    const url = config.get('vqueueUrl')+uri;
    axios.get(url )
        .then(res => {
          setAuthorizations(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })   
  }, [] )

  function getGridContent() {
    let gridContent = [];
    authorizations.forEach(function (aAuthorization, index) {
      let entry =  {
        title: <span className="label" style={{whiteSpace: "nowrap"}}>{aAuthorization.issuedUser.email}</span>  ,
        text: aAuthorization.role,
        action: getActionItems(aAuthorization),
        menuItems: getMenuItems(aAuthorization),
        cardStyle:{height: "18.5rem"},
        object: aAuthorization
      };
      gridContent.push(entry);
    });
    return gridContent;
  }

  function getActionItems(aAuthorization) {
    let menuItems = [];
    if(aAuthorization.role!="OWNER" ) {
      menuItems.push(
        <Fragment>
          { <Grid item xs={12}  >
              <Link to={{pathname: '/editAuthorization', vAuthorization:aAuthorization}}  style={{color: "inherit", textDecoration: "none"}}>
                <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
                  {t("Change")}
                </Button>
              </Link>                      
            </Grid>
          }
      </Fragment>
      ) ;
    }
    return menuItems;
  }

  function getMenuItems(aAuthorization) {
    let menuItems = [];
    if(aAuthorization.role!="OWNER" ) {
      menuItems.push(
        <MenuItem >
          <Link to={{pathname: '/editAuthorization', vAuthorization:aAuthorization}}  style={{color: "inherit", textDecoration: "none"}}>
            <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
            {t("Change")}
            </Button>
          </Link>    
        </MenuItem>
      ) ;
    }
    return menuItems;
  }

  function getFabButton() {
    return  <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={{pathname: '/editAuthorization'}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }

  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link to={{pathname: '/editAuthorization'}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link>
    );
    return quickActionItems;
  }
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
        title={<Fragment>{t("authorizations")}&nbsp;{title}</Fragment> }
        backTo={backTo?backTo:"/settings"} 
        quickActions={getQuickActionItems()}/>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MyLocation />
        </Avatar>
        <Typography component="h1" variant="h5">
            {t("authorizations")}
        </Typography>
        <Grid container>  
          <Grid xs={12}>
            
            <CardGrid gridContent={getGridContent()}/>
          </Grid> 
        </Grid>
        
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
}

export default withTranslation()(Authorizations);