import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';



i18n
  .use(initReactI18next)
  /*.use(LanguageDetector)*/
  .init({
    resources: {
      en: {
        translation: {
          //English translations here
          "gap_generic_title_DYNAMIC": "Free queueing",
          "gap_generic_title_TIMESLOT": "Book timeslot",
          "gap_generic_title_SERVICE": "Servicedesk",

          "gap_generic_DYNAMIC": "The next best point of time is yours",
          "gap_generic_TIMESLOT": "The next best time slot is yours",
          "gap_generic_SERVICE": "Create service component",

          "gap_generic_call_to_action_DYNAMIC": "Queue up",
          "gap_generic_call_to_action_TIMESLOT": "Book",
          "gap_generic_call_to_action_SERVICE": "Offer service",

          "gap_generic_action_DYNAMIC": "Yes, please",
          "gap_generic_action_TIMESLOT": "Start",
          "gap_generic_action_SERVICE": "Create",
          "gap_DYNAMIC": "point of time - Book during this time span",
          "gap_TIMESLOT": "timeslot - Book exactly this period",
          "gap_SERVICE": "Service component",
          "find_gap_waiter": "One moment please, free periods must be here somewhere...",


          "slot_intervall_NO_INTERVAL": "Unique",
          "slot_intervall_WEEKLY": "weekly",
          "slot_intervall_DAILY": "daily",
          "slot_intervall_MONTHLY": "monthly",
          "slot_intervall_YEARLY": "yearly",

          "start_page": "Start page",
          "begin": "The beginning",

          "your_slotmanager": "Slot manager made available for you",

          "freigabe_title": "Clearance",
          "freigabe_hint": "Made free for you",
          "favourite_title": "Favourites",
          "for_you_hint": "Only for you",
          "booking_title": "Find and Book",

          "wallet_title": "Wallet",
          "timeslot_headder": "My time slots",
          "actual_timeslots": "Your current time slots will be shown here",
          "aktuell_button": "Current",
          "histories_button": "Histories",

          "suche_title": "Search",
          "suche_hint": "Action",
          "input_holder": "Search term",
          "submit_button": "Go",

          "domain_title": "Domains",
          "all_domains": "All domains",
          "places": "Places",
          "all_domain_places": "All domain places",
          "callboard": "Callboard",
          "verwaltung_hint": "My",

          "my_places": "Places",
          "all_places": "All your places",
          "qs": "Qs",
          "groups": "Groups",
          "create_room": "Create a room",
          "create": "Create",

          "my_queues": "Slotmanager",
          "your_queues": "Your queues",
          "scan": "Scan",

          "queue_up": "Queue up",
          "choose_avaiable_timeslot": "Choose one of the available periods",
          "seatbelt": "Fasten your seatbelt. You will be queued up..",
          "not_queued_up": "In the moment you are not queued up.",
          "current_waiting_time_endures": "Current waiting time is:",
          "Return": "Send back",
          "check_proposal": "Check proposal",
          "next_time_slot_your_slot": "The next best point of time can be yours.",
          "current_informatiion": "Current informatiion",
          "no_avaiable": "No available",
          "your_application_will_be_handled": "Your application will be handled",
          "prefered_begin": "Prefered beginning",
          "prefered_end": "Prefered end",
          "checking_wait_situation": "One moment please, we are checking the waiting situation",

          "available_cycles": "Available cycles",
          "no_resources_please_create": "No resources available. Please make some.",
          "partitionStatus_DIRTY": "outdated",
          "partitionStatus_IN_PROCESS": "Refreshing...",
          "partitionStatus_IN_PROCESS_WAITING_FOR_RESOURCES": "Searching Ressources...",
          "partitionStatus_RUNNING_CYCLES_CALCULATION": "Check Capacities...",
          "partitionStatus_READY": "up to date",

          "settings": "Settings",
          "my_account": "My account",
          "account_management": "Here the own account can be managed, booked rates can be seen and so on, shortly, everything what is important to you.",
          "global_settings": "Global settings",
          "rate_view": "Rate view",
          "rate_view_text": "Here our rates again and the self booked packages - bookings are of course possible here",
          "rights": "Rights",
          "rights_text": "Here other users can be made as co-administrators.",
          "global_settings_text": "Genreal application settings can be done here.",


          "further_settings": "Further settings",
          "any_settings": "Anything can be set here",
          "remove_all": "Remove all (only local)",

          "make_account": "Create account",
          "registrate": "Registrate",
          "email_adress": "Email adress",

          "authorizations": "Authorizations",
          "authorization_created": "Authorization created",
          "authorization_deleted": "Authorization deleted",
          "create_authorization": "Create authorization",
          "authorization": "Authorization",
          "authorization_email": "Authorization email",
          "authorization_not_given": "Authoization not given - is the user in the system?",
          "edit_authorization": "Edit authorization",
          "role": "Role",
          "admin": "Administrator",
          "manager": "manager",
          "user": "User",
          "slotmanager": "Slotmanager",

          "create_group": "Create group",
          "change_group": "Edit group",
          "group": "Group",
          "group_name": "Group name",
          "group_saved": "Group saved",

          "change_domain": "Edit domain",
          "create_domain": "Create domain",
          "domain": "Domain",
          "domain_name": "Domain name",
          "domain_saved": "Domain saved",

          "create_resource": "Create resource",
          "change_resource": "Edit resource",
          "resource": "Resource",
          "resource_name": "Resource name",
          "resource_kind": "Resource kind",
          "active": "Active",
          "deactivated": "Deactivated",
          "resources_alternative": "Resources alternative",
          "resource_alternative": "Resource alternative",
          "work_time": "Work time",
          "adapt_work_time": "Adapt work time",

          "change_place": "Edit place",
          "create_place": "Create place",
          "place": "Place",
          "place_name": "Place name",
          "first_name": "First name",
          "last_name": "Last name",
          "additional": "Additional",
          "street": "Street",
          "home_number": "Home number",
          "zip": "ZIP",
          "city": "City",
          "country": "Country",
          "place_deleted": "Place deleted",
          "place_created": "Place created",
          "place_saved": "Place saved",

          "queued": "Queued",
          "queue": "Queue",
          "planed": "planed",
          "Planed": "Planed",
          "new_planing": "new planing",
          "New_planing": "New planing",
          "planing_begun": "planing begun",
          "later": "Delayed",
          "no_slot": "No slot",
          "planing_fail": "Planing error",
          "queue_ops": "Queue ops",
          "confirmed_ops": "confirmes ops",
          "confirmed": "Confirmed",
          "new": "New",
          "wait": "Wait",
          "called": "called",
          "last_call": "Last call",
          "waiting": "Waiting",
          "beginning": "beginning",
          "changing": "changing",
          "ending": "ending",
          "given_back": "given back",
          "Given_back": "given back",
          "missed": "missed",
          "done": "done",
          "Done": "Done", 
          "aborted": "canceled",
          "run_out": "run out",
          "Run_out": "run out",
          "ended": "ended",
          "solid_blocker": "Solid blocker",
          "dynamic_blocker": "Dynamic blocker",
          "closed": "closed",
          "beak": "Break",
          "blocker": "Blocker",
          "fixed": "fixed",
          "every": "Every",

          "company": "Company",

          "manage_slotmanager": "Management of all slot manager here at ",
          "All": "ALL",
          "choose_other_group": "Here is nothing. Choose another group.",
          "back": "back",

          "scan_code": "Scan a code",
          "waiterscanner_text": "The smartphone cam can often be used as well to scan a QR code - but here it is also possible.",          
          
          "booking_criteria": "Booking criteria",
          "continue": "Continue",          
          "your_variants": "Your variants",           
          "all_categories": "All categories",

          "category_deleted": "category deleted",
          "save_category": "save category",
          "category_saved": "category saved",
          "mapping_failure_DEL": "mapping failure(DEL)",
          "mapping_failure_ADD": "mapping failure(ADD)",
          "create_cateory": "Create cateory",
          "category": "Category",
          "categories": "Categories",
          "category_name": "Category name",
          "timings": "Timings",
          "necessary_work_cycles": "Necessary work cycles",
          "duration_last_call": "Duration last call",
          "call_advance_time": "Call advance time",
          "extension_time": "Extension time",
          "count_down": "Count down",
          
          "server_failure": "Server has reported an error, please try later again."  ,      
          "you_are_offline": "You are offline- or the server.",
          "connected": "Connected wit hserver.",        
          "queues_loaded": "queues loaded",
          "possible_time slots": "Possible time slots",          
          "back_to_begin": "Back ot start",
          "ah_ok": "Ah, ok",

          "lets_go": "Let's go",
          "let's_go": "Let's go",
          "set_settings": "Change settings.",
          "hello": "Hello",
          "logout_de": "Logout",
          "not_logged_in": "Not logged in",
          "to_login": "To login",
          "clear": "Clear",
          
          "choose_qr_code": "Choose QR code",          
          "optimize": "OPTIMIZE",
          "q_optimizing": "Q OPTIMIZING",          
          "login": "Login",
          "logout": "Logout",

          "remaining_process_duration": "Remaining process duration",
          "it_begins": "It begins!",
          "your_timeslot_begins": "Your appointment beginns, move right now to the counter.",
          "current_waiting_time": "Current waiting time",
          "you_will_be_informed": "Here you will be inormed when it is your turn",          
          "attention": "Attention",
          "your_turn_soon": "Soon it is your turn. Move to the counter",
          "appointment_finished": "Your appointment has ended. Thank you very much for waiting.",
          "call_missed": "Call missed",
          "appointment_over": "Unfortunately, your appointment is over. You can queue up again or contakt the operator.",
          "appointment_run_out": "Unfortunately, your appointment has run out. You can queue up again or contakt the operator.",
          "appointment_given_back": "Wether you have given back your appointment or it got cancelled. You can queue up again or contakt the operator.",
          "service_queue": "Service request",
          "suid": "SUID (obvious service recognition)",
          "contacts": "Contacts",
          "further_information": "Further information",

          "item_one": "Item one",
          "item_two": "Item two",
          "your_slot": "Your slot",
          "waits_now": "waits now",
          "got_called": "got called",
          "runs_out_soon": "runs out soon HURRY UP",

          "call": "Call",
          "cash": "Cash",
          "begins_process": "begins process",
          "Wait": "Wait",
          "waits_again": "waits again.",
          "ends_process": "ends process",
          "is_planning_new": "is planning new",
          "no_resources": "no resources",

          "date": "Date",
          "startdate": "Start date of your prefered time slot",
          "choose": "Choose",
          "clrear": "Clrear",
          "cancel": "Cancel",
          "today": "Today",
          "of": "From",
          "now": "Now",
          "enddate": "End date of your prefered time slot",
          "until": "Until",

          "before": "Before",
          "month": "Month",
          "week": "Week",
          "day": "Day",

          "my_waiting": "My waiting",
          "waiting_wizard": "This is the way to your time slot wizard",
          "scanner": "Scanner",
          "qr_scan_for_call": "QR code scanner to call a waiting room with scan.",
          "waiting_rooms": "Waiting rooms",
          "likeable_waiting_rooms": "Waiting rooms, where you like waiting.",

          "ticket_ok": "Ticket ok. Updateing from server",
          "next_called": "Next one was called.",
          "q_waiting_for_tickets": "Q waiting for tickets.",
          "q_handling_ticket": "Q editing a ticket.",
          "q_waiting_for_work" : "Q waiting for work.",
          "ready": "ready",

          "early": "Early",
          "middle": "Middle",
          "late": "Late",

          "week_days": "Week day(s)",
          "all": "All",
          "monday": "Mo",
          "tuesday": "Tu",
          "wednesday": "We",
          "thursday": "Th",
          "friday": "Fr",
          "saturday": "Sa",
          "sunday": "Su",

          "edit_slot": "Edit slot",

          "opening_hours_changed": "Ppening hours changed",
          "opening_hours_saved": "Ppening hours saved",
          "delete_opening_hour": "Delete opening hour",
          "opening_hours_deleted": "Opening hours deleted",
          "change_opening_hour": "Edit opening hour",
          "create_opening_hour": "Create opening hour",
          "opening_hour": "Opening hour",
          "opening_hours": "Opening hours",
          "opening_hours_marking": "Opening hour without repetition marks other time slots as closed.",
          "new_opening_hour": "New opening hour",

          "organise_opening_hours": "Manage opening hours",
          "type": "Type",
          "start": "Start",
          "end": "End",

          "scan_code_and_queue_up": "Scan code and queue up",
          "hold_code_in_front_of_line": "Please hold QR code in front of the lense.",
          "qr_code": "QR code",

          "room_1": "Room 1",
          "room_2": "Room 2",
          "room_3": "Room 3",
          "qr_for_room": "QR Code scanner to call a waiting room per scan.",

          "prices": "Prices",
          "pricing": "Pricing",
          "effective_pricing": "Quickly build an effective pricing table for your potential customers with this layout. It's built with default Material-UI components with little customization.",
          "free": "Free",
          "10_users_included": '10 users included',
          "20_users_included": '20 users included',
          "50_users_included": '50 users included',
          '2_GB_of_storage': '2 GB of storage',
          '10_GB_of_storage': '10 GB of storage',
          '30_GB_of_storage': '30 GB of storage',
          'help_center_access': 'Help center access',
          "email_support": "Email support",
          'sign_up_for_free': 'Sign up for free',
          "pro": "Pro",
          "most_popular": "Most popular",
          "get-started": "Get started",
          'enterprise': 'Enterprise',
          'contact us': 'Contact us',
          'priority_email_support': 'Priority email support',
          "phone_and_email_support": "Phone & email support",
          "dollar": "$",
          "/mo": "/mo",

          "waiting_roons": "Waiting lines",
          "waiting_line": "Waiting line",
          "sended": "sended.",
          "dialogue": "Dialogue",
          "scan_ticket": "Scan ticket",

          "changes_saved": "Changes saved.",
          "waiting_room_created": "Waiting room created.",
          "edit_slotmanager": "Edit slotmanager",
          "create_slotmanager": "Create slotmanager",
          "most_important": "The most important",
          "waiting_room_name": "Waiting room name",
          "email_counter_desk": "Email adress of the counter desk",
          "no_group": "No group",
          "slot_care": "Slot care",
          "basic_work_time": "Basic editing time",
          "cash_dead_line": "Cash dead line",
          "call_advance": "Call advance",
          "slot_offset": "Slot offset",
          "slot_laps": "Slot laps",
          "behaviour": "Behaviour",
          "room_mode": "Room mode",
          "timeslot_based": "Time slot based",
          "time_slot_and_dynamic_hybrid": "Time slot and dynamic hybrid",
          "full_dynamic": "Comlete dynamic",
          "service_mode": "Service mode",
          "sort_of_queuing": "Sort of queuing",
          "directly": "Directly",
          "with_formular_directly": "With formular directly",
          "with_confirmation": "With confirmation",
          "with_formular_and_confirmation": "With formular and confirmation",
          "behavoiur_if_your_turn": "Behavoiur if 'your turn'",
          "terminates_automatically": "terminates automatically",
          "endless_waiting_for_cashing": "Endless waiting for cashing",
          "automatically_new_planing": "Automatically new planing",
          "how_many_positions_back": "How many positions move back",
          "assistance": "Assistance",
          "slotmanagement": "Slotmanagement",
          "opimizer": "Opimizer",
          "opimizer_tart": "Opimizer starts if gaps higher as",
          "optimizer_order": "Order at optimizing",
          "position": "Position",
          "prio": "Prio",
          "defrag": "Defrag",
          "defrag_at_gap": "Defrag starts if gaps higher as",
          "defrag_order": "Order at defraging",
          "result_and_reaction": "Events & Reactions",
          "results_automatically_on_slot": "Use events automatically on slots.",
          "completly_at_change": "Completly at changes ticket",
          "timeslot_at_change": "Time slot at changes ticket",
          "openinh_hour_change": "Opening hour change",

          "special_times": "There are special times someone can wait? These can be created here.",
          "tickets_sorted_after_time": "So tickets can be sorted for 'type..",
          "general_settings": "General settings",
          "other_settings": "Here room name and other rudimentally settings can be set.",
          "other_access": "Grant others direct approval",
          "no_active_slotmanager": "No active slot manager. Back to start..",

          "how_long_you_take": "How long will you take?",
          "1_minute": "1 minute",
          "2_minutes": "2 minutes",
          "5_minutes": "5 minutes",
          "10_minutes": "10 minutes",
          "30_minutes": "30 minutes",

          "everyone": "Everyone",
          "other_resources_updated": "Other resources updated.",
          "resource_saved": "Resource saved",
          "all_resources": "All Resources",
          "new_resource": "New Resource",

          "details_to": "Details to",
          "no_data": "No data",
          "no_resources_and_information": "Here there are no rescources anf no further information. Time point in the past, or nobody is working.",
          "avaiable": "Available",
          "cycl": "Cycl",
          "used": "Used",
          "remaining": "Remaining",
          "missing": "Missing",

          "used_resources": "Used resources",
          "34dummy": "34dummy",
          "working_cycles": "Working cycles",
          "duration": "Duration",
          "todo_cycles": "TODO Cycles*Resurcecapacities",

          "edit_resource_wotk_saved": "Resource work time saved",
          "edit_resource_wotk_time": "Edit resource wotk time",
          "create_resource_wotk_time": "Create resource wotk time",
          "resource_wotk_time": "Resource wotk time",
          "adapt": "Adapt",
          "all_resources_work_time": "All resources work time",
          "new_resources_work_time": "New resources work time",

          "edit_alternative": "Edit alternative",
          "create_alterniative": "Create alternative",
          "alternative_name": "Alternative name",
          "speed": "Speed",
          "buffer_before": "Buffer before",
          "buffer_after": "Buffer after",

          "duration_cycle": "Duration cycle",
          "minute": "Minute",
          "n": "s",
          "all_alternatives": "All alternatives",
          "new_alternaive": "New alternaive",
          "new_q": "Create new Q",
         
          "temporary_save": "Copied in clipboard. You can now send the slot by mail or Whatsapp.",
          "share": "Share",
          "chat": "Chat",
          "return": "Return",
          "was_prefered": "was preferred",
          "was_put_back": "was put back",
          "was_returned": " was returned",
            
          "ticket_updated": " - ticket updated - ",
          "noticed_delays": "Noticed delays",
          "delay_noticed": "Delay noticed:",
          "notice_delay": "Notice delay",

          "timeslot_sended_soon_in_wallet": "Time slot was sended and is soon visible in your wallet.",
          "timeslot_sended": "Time slot was sended.",
          "request/edit_ticket": "Request/Edit ticket",
          "tell_something_about_you": "Tell something about you.",
          "service_data": "Service data",
          "appointment_after_request": "After your request was checked, an appointmnet will be distributed to you",
          "best_appointment_automatically": "You will receive the next possible appointment automtaically distributed.",
          "information_for_service": "Information for the service component",
          "contact_data_information": "Contacts/Information",
          "email_or_phone_number": "Enter email or phone number",
          "sender": "Sender",
          "save_ticket": "save ticket.",
          "choose_timeslot": "Choose time slot",
          "deny_and_other_proposal": "Refuse and counterproposal",
          "confirmation": "Confirmation",
          "check": "Check",
          "noone": "Nobody",
          "none": "None",
          "next_are": "The next are",

          "cycles": "Cycles",
          "home": "Home",
          "registration": "Registration",

          "partition_will_be_optimized": "Partition optimizing",
          "will_be_optimized": "optimizing.",
          "day_view": "Day view",
          "list_view": "List view",
          "optimize_timeslot": "Optimize timeslot",
          "show_qr_code": "Show QR code",
          "q_settings": "Q settings",
          "search_ticket": "Search ticket",

          "users_loaded": "User loaded",
          "all_user": "All user",
          "email": "Email",

          "reset_pw_not_possible": "Resetting PW not possible, missing key",
          "pw_reseted": "Password reset",
          "create_new_pw": "Create new password ",
          "new_pw": "New password",
          "enter_again":"Enter again",
          "ok_thats_it": "Ok that's it",

          "reset_pw": "Reset password",
          "reset_link_received": "Receive reset link",
          "allready_account_this_way": "You allready have an account? Then quickly this way.",
          "this_way_for_new_account": "This way to create an account",
          "pw_reset_started": "PW reset started",

          "user_login": "User login",
          "login_with_access_data": "Login with access data",
          "pw": "Password",
          "remember": "Remember",
          "access_data_forgot": "Forgot access data?",
          "user_activated": "Great, it is you. Now you can log in with your credentials. Have Fun.",
          "user_logged_in": "User logged in",
          "user_state_loaded": "User state loaded",
          "user_state_recoverd": "User state recoverd",
          "user_state_saved": "User state saved",
          "user_logged_out": "User ABGEMELDET",
          "message_user_not_found": "Benutzer konnte nicht angemeldet werden. Bist Du schon registriert?",

          "user_created": "User created",
          "for_juristic_safety": "For our and your legal seurity please read our terms of use.",
          "confirm_use_condition": "Yes, I accept the terma of use.",
          "request_registration": "request registration",
          "allready_have_an_account": "You allready have an account? Then quickly this way.",

          "use_conditions": "Terms of Use",
          "your_data_belongs_to_us": "All your data belongs from now on to US!",
          "yes_understood": "Yes understood",            
          "queues_found_here": ", queues found here",
          "waiing_room-": "Waiting room-",
          "create_waiting_room": "Create room",

          "t": "T",
          "r": "R",
          "days-": "Days - ",
          "Show": "Show",
          "of_Everything": "of EVERYTHING",
          "?": "?",
          "Home": "Home",
          "repetition": "Repetition",
          "interval": "Interval",
          "clock": "o' clock",
          "cycles_per_minute": "Cycles per minute:",

          "q_editor": "Q-Editor",
          "your_postition": "Your Position",
          "current_location": "Current location",
          "markers_title": 'The marker`s title will appear as a tooltip.',
          "soma": "SOMA",
          "dolores_park": 'Dolores park',

          "editors_pick": "Editor's Pick",
          "New": "New",
          "react": "Getting Started with React",
          "PLAN": "PLAN",
          "IS": "IS",
          "the_call": "Call",

          "scan_QR": "Scan QR code",
          "next_timepoint": "The next time point can be yours.",
          "book_now": "Book now",
          "to_room": "To room",
          "management": "Management",
          "show": "Show",
          "save": "Save",
          "delete": "Delete",
          "Change": "Edit",
          "change": "Edit",
          "multiQ": "MultiQ",
          "resources": "Resources",
          "waiting_room": "Waiting room",
          "all_groups": "All groups",
          "new_group": "New group",
          "description": "Description",

          "choose_delay": "Choose delay",

          "show_code_at_operator": "Please show the operator the QR code and let it scan.",
          "your_qr_code_ticket": "Your QR Code ticket",

          "resource_status": "REsource Status:",
          "resources_status": "Resources Status",

          "type_something": "Type Something",
          "your_website": "Your website",

          "Company": "Company",
          "team": "Team",
          "history": "History",
          "location": "Location",
          "feature": "Feature",
          "cool_stuff": "Cool stuff",
          'random_feature': 'Random feature',
          'team_feature': 'Team feature',
          'developer_stuff': 'Developer stuff',
          'another_one': 'Another one',
          'Another_resource': 'Another resource',
          'Final_resource': 'Final resource',
          'Legal': 'Legal',
          'Privacy_policy': 'Privacy policy',
          'Terms_of_use': 'Terms of use',

          "dashboard": "Dashboard",

          "news_to_appointment": "There are news to an appointment",

          "trigger_manually": "Trigger manually",
          "possible_timeslots": "Possible Time slots",
          
          "start_point": "Beginning",
          "end_round_about": "End around",
          "wating_time": "Waiting time",
          "hours/minutes/seconds": "Hours/Minutes/Seconds",
          "preferred_time_slot": "Prefered time slot",
          "tuid": "TUID (clear appointment recognition)",
          "CREATE": "CREATE",

          "min/cyc": "Min/Cyc",
          "user_saved": "User saved",

          "language": "Language",
          "eigene_verwaltung": "Own Management",
          "not_bookable": "not bookable",
          "max_cycles_per_slot": "Maximum cycles/minute per slot",
          "offset_beginning": "Offset beginning",
          "offset_end": "Offset end",
          "automatically_in_progress": "automatically in progress",

          "sing_end": "e",
          "plur_end": "es",

          "allready_queeud_up": "You are allready queued upe here and cannot book again. You can find Your ticket in the Wallet.",
          "zum_wallet": "to wallet",

          "neu_klein": "new",
          "waits": "waits",
          "in_progress": "in progress",
          
          "alert_modal_hint": "Attention"
         
        },
      },
      de: {
        translation: {
            "gap_generic_title_DYNAMIC": "Freies anstellen",
            "gap_generic_title_TIMESLOT": "Timeslot buchen",
            "gap_generic_title_SERVICE": "Servicedesk",
            
            "gap_generic_DYNAMIC": "Der nächstbeste Zeitpunkt gehört Dir.",
            "gap_generic_TIMESLOT": "Der nächstbeste Timeslot gehört Dir.",
            "gap_generic_SERVICE": "Wartungseinheit erstellen",
            
            "gap_generic_call_to_action_DYNAMIC": "einreihen",
            "gap_generic_call_to_action_TIMESLOT": "buchen",
            "gap_generic_call_to_action_SERVICE": "Service anbieten",

            "gap_generic_action_DYNAMIC": "ja, bitte",
            "gap_generic_action_TIMESLOT": "starten",
            "gap_generic_action_SERVICE": "erstellen",
            "gap_DYNAMIC": "Zeitraum - innherhalb dieser Zeitspanne buchen",
            "gap_TIMESLOT": "Timeslot - genau diesen Zeitraum buchen",
            "gap_SERVICE": "Wartungseinheit",
            "find_gap_waiter": "Lade Daten...",
            
            "slot_intervall_NO_INTERVAL": "einmalig",
            "slot_intervall_WEEKLY": "wöchentlich",
            "slot_intervall_DAILY": "täglich",     
            "slot_intervall_MONTHLY": "monatlich",
            "slot_intervall_YEARLY": "jährlich",       

            //Dynamische Titel
            "start_page": "Startseite",
            "begin": "Der Anfang",

            //Freigabe
            "your_slotmanager": " Für dich freigegebene Slotmanager",

            
            "booking_title": "Termin Buchen",

            //Favouriten
            "freigabe_title": "Freigabe",
            "freigabe_hint": "Für Dich freigegeben",
            "favourite_title": "Favouriten",
            "for_you_hint": "Nur für Dich",
            "allready_queeud_up": "Du stehst hier schon an und kannst nicht nochmals buchen. Im Wallet findest Du Dein Ticket.",

            //Wallet
            "wallet_title": "Wallet",
            "timeslot_headder": "Meine Timeslots",
            "actual_timeslots": "Hier werden deine aktuellen Timeslots angezeigt.",
            "aktuell_button": "Aktuell",
            "histories_button": "Histories",
            "zum_wallet": "zum Wallet",

            //Suche
            "suche_title": "Suche",
            "suche_hint": "Aktion",
            "input_holder": "Suchbegriff",
            "submit_button": "los",

            //Domänen
            "domain_title": "Meine Domänen",
            "all_domains": "Alle Domänen",
            "places": "Orte",
            "all_domain_places": "Alle Orte der Domäne",
            "callboard": "Aufruftafel",
            "verwaltung_hint": "Meine",
            "eigene_verwaltung": "Slotmanager",

            //Orte
            "my_places": "Orte",
            "all_places": "Alle Deine Orte",
            "qs": "Qs",
            "groups": "Gruppen",
            
            "create_room": "Mach nen Raum",
            "create": "erstellen",

            //Warteschalnge
            "my_queues": "Slotmanager",
            "your_queues": "Deine Slotmanager",
            "scan": "Scannen",

            //RoomTicketWrapper
            "queue_up": "Anstellen",
            "choose_avaiable_timeslot": "Wählen Sie einen der verfügbaren Zeiträume",
            "seatbelt": "Anschanllen, Du wirst eingereiht..",
            "not_queued_up": "Sie stehen zur Zeit nicht an.",
            "current_waiting_time_endures": "Aktuelle Wartezeit beträgt:",
            "Return": "Zurückgeben",
            "check_proposal": "Vorschlag prüfen",
            "next_time_slot_your_slot": "Der nächstbeste Zeitpunkt kann Dir gehören.",
            "current_informatiion": "Aktuelle Info",
            "no_avaiable": "Keine vorhanden!",
            "your_application_will_be_handled": "Ihre Anfrage wird bearbeitet",
            "prefered_begin": "Wunsch Beginn",
            "prefered_end": "Wunsch Ende",
            "checking_wait_situation": "Einen Moment bitte, wir prüfen die Wartesituation...",

            //ResoureStatus
            "available_cycles": "Verfügbare Arbeitszyklen",
            "no_resources_please_create": "Keine Ressourcen vorhanden, bitte welche anlegen.",
            "partitionStatus_DIRTY": "veraltet",
            "partitionStatus_IN_PROCESS": "Aktualisierung läuft...",
            "partitionStatus_IN_PROCESS_WAITING_FOR_RESOURCES": "Suche Resourcen...",
            "partitionStatus_RUNNING_CYCLES_CALCULATION": "Prüfe Kapazitäten...",
            "partitionStatus_READY": "aktuell",

            //Einstellungen
            "settings": "Einstellungen",
            "my_account": "Mein Konto",
            "account_management": "Hier kann das eigene Konto verwaltet werden, gebuchte Tarife eingesehen usw, kurz, alles was Ihnen wichtig ist.",
            "global_settings": "Globale Einstellungen",
            "global_settings_text": "Allgemeine Applikations Einstellungen können hier gemacht werden.",
            "rate_view": "Tarifübersicht",
            "rate_view_text": "Hier nochmal unsere Tarife, und die eigenen gebuchten Pakete - Buchungen sind hier natürlich auch möglich.",
            "rights": "Berechtigungen",
            "rights_text": "Hier können andere Benutzer als Co-Adminstrator eingerichtet werden.",

            

            //globalSettings
            "further_settings": "Erweiterte Einstellungen",
            "any_settings": "Alles mögliche einstellen geht hier.",
            "remove_all": "Alles entfernen (Nur Lokal)",

            //EditAccount
            "make_account": "Konto anlegen",
            "registrate": "Registrieren",            
            "email_adress": "E-Mail Adresse",      
            "user_saved": "User gespeichert",      

            //Authorizations
            "authorizations": "Authorisierungen",
            "authorization_created": "Authorisierung erstellt",
            "authorization_deleted": "Authorisierung gelöscht",

            //editAuthorization
            "create_authorization": "Authorisierung anlegen",
            "authorization": "Authorisierung",
            "authorization_email": "Authorisierung EMail",
            "authorization_not_given": "Authorisierung nicht erteilt - gibt es den User im System?",
            "edit_authorization": "Authorisierung bearbeiten",
            "role": "Rolle",
            "admin": "Administrator",
            "manager": "Manager",
            "user": "Benutzer",

            //Groups
            "slotmanager": "Slotmanager",

            //EditGroup
            "create_group": "Gruppe anlegen",
            "change_group": "Gruppe bearbeiten",
            "group": "Gruppe",
            "group_name": "Gruppe Name",
            "group_saved": "Gruppe gespeichert",

            //Edit Domain
            "change_domain": "Domäne bearbeiten",
            "create_domain": "Domäne anlegen",
            "domain": "Domäne",
            "domain_name": "Domäne Name",
            "domain_saved": "Domäne gespeichert",
            
            //EditResource
            "create_resource": "Ressource anlegen",
            "change_resource": "Ressource bearbeiten",
            "resource": "Ressource",
            "resource_name": "Ressource Name",
            "resource_kind": "Ressourcen Art",
            "active": "Aktiv",
            "deactivated": "Deaktiviert",
            "resources_alternative": "Ressourcen Variante",
            "resource_alternative": "Ressource Variante",
            "work_time": "Arbeitszeiten",
            "adapt_work_time": "Arbeitszeiten übertragen",
            "min/cyc": "Min/Zyk",

            //EditPlace
            "change_place": "Ort bearbeiten",
            "create_place": "Ort anlegen",
            "place": "Ort",
            "place_name": "Ort Name",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "additional": "Zusatz",
            "street": "Strasse",
            "home_number": "Hausnummer",
            "zip": "PLZ",
            "city": "Stadt",
            "country": "Land",
            "place_deleted": "Ort gelöscht",
            "place_created": "Ort erstellt",
            "place_saved": "Ort gespeichert",

            //StatusTicket
            "queued": "angestellt",
            "queue": "Anfrage",
            "planed": "geplant",
            "Planed": "Geplant",
            "new_planing": "neu planen",
            "New_planing": "Neu planen",
            "planing_begun": "planen begonnen",
            "later": "verspätet",
            "no_slot": "Kein Slot",
            "planing_fail": "Planungsfehler",
            "queue_ops": "Anfrage Ops",
            "confirmed_ops": "Bestätigt Ops",
            "confirmed": "Bestätigt",
            "new": "Neu",
            "wait": "warten",
            "called": "aufgerufen",
            "last_call": "letzter Aufruf",
            "waiting": "Wartend",
            "beginning": "beginne",
            "changing": "bearbeite",
            "ending": "beende",
            "given_back": "zurückgegeben",
            "Given_back": "Zurückgegeben",
            "missed": "verpasst",
            "done": "fertig",
            "Done": "Fertig", 
            "aborted": "abgebrochen",
            "run_out": "abgelaufen",
            "Run_out": "Abgelaufen",
            "ended": "beendet",
            "solid_blocker": "fester Blocker",
            "dynamic_blocker": "dynamischer Blocker",
            "closed": "geschlossen",
            "beak": "Pause",
            "blocker": "Blocker",
            "fixed": "Fix",
            "every": "Alle",

            //EditLocation
            "company": "Firma",

            //ManagerMultiQ
            "manage_slotmanager": "Verwaltung aller Slotmanager hier bei ",
            "All": "ALLE",
            "choose_other_group": "Hier ist nix. Wähle eine andere Gruppe.",
            "back": "zurück",

            //QRCodeQueenIn
            "scan_code": "Scan nen Code",
            "waiterscanner_text": "Oft kann man auch die Handy Cam verwenden, um einen QR Code zu scannen - hier geht das aber auch.",

            //BookingStep1
            "booking_criteria": "Buchungskriterien",
            "continue": "weiter",
            "cycles": "Stellplätze",
            "cycles_units_pieces": "Nötige Stellplätze",

            //BookingVariants
            "your_variants": "Deine Varianten",

            //Categories
            "all_categories": "Alle Kategorien",

            //EditCategories
            "category_deleted": "Kategorie gelöscht",
            "save_category": "Save Kategorie.",
            "category_saved": "Kategorie gespeichert",
            "mapping_failure_DEL": "Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.",
            "mapping_failure_ADD": "Mapping Fehler(ADD)",
            "create_cateory": "Kategorie anlegen",
            "edit_category": " Kategorie bearbeiten",
            "category": "Kategorie",
            "categories": "Kategorien",
            "category_name": "Kategorie Name",
            "timings": "Timings",
            "necessary_work_cycles": "Nötige Stellplätze",
            "duration_last_call": "Dauer letzter Aufruf",
            "call_advance_time": "Aufruf Vorlauf Zeit",
            "extension_time": "Verlängerungszeit",
            "count_down": "Ablaufzeit",
            "externalContextScope": "WANKO-Kundennummer",

            //MesssageWithAxios
            "server_failure": "Server hat einen Fehler gemeldet, bitte später nochmal versuchen.",

            //Offline
            "you_are_offline": "Du bist offline - oder aber der Server.",
            "connected": "Mit Server verbunden.",

            //BookingSelection
            "queues_loaded": "Queues geladen",
            "possible_timeslots": "Mögliche Zeiträume",

            //CargGrid
            "back_to_begin": "Zurück auf Anfang",
            "ah_ok": "Aha, ok",

            //DeskHeader copy
            "lets_go": "Los gehts",
            "let's_go": "Los geht's",
            "set_settings": "Nimm Einstellungen vor.",
            "hello": "Hallo",
            "logout_de": "abmelden",
            "not_logged_in": "Nicht angemeldet",
            "to_login": "zur Anmeldung",
            "clear": "Clear",
            "registration": "anmelden",

            //QRCodeScanner
            "choose_qr_code": "QR Code wählen",

            //QRCode
            "trigger_manually": "Manuell auslösen",

            //QueueActionStatus
            "optimize": "OPTIMIERE",
            "q_optimizing": "Q OPTIMIERUNG",

            //RommHeader
            "login": "Login",
            "logout": "Logout",

            //ServiceCard
            "remaining_process_duration": "Restliche Bearbeitungsdauer",
            "it_begins": "Es geht los!",
            "your_timeslot_begins": "Ihr Termin beginnt, begeben Sie sich umgehend zum Schalter.",
            "current_waiting_time": "Aktuelle Wartezeit",
            "you_will_be_informed": "Sie werden hier informiert, sobald Sie dran kommen",
            "attention": "Achtung",
            "your_turn_soon": "Sie kommen gleich dran. Begeben Sie sich zum Schalter.",
            "appointment_finished": "Ihr Termin ist beendet. Vielen Dank fürs Warten.",
            "call_missed": "Aufruf verpasst",
            "appointment_over": "Ihr Termin ist leider vorbei. Sie können sich erneut anstellen oder an den Operator wenden.",
            "appointment_run_out": "Ihr Termin ist leider abgelaufen. Sie können sich erneut anstellen oder an den Operator wenden.",
            "appointment_given_back": "Entweder haben Sie Ihren Termin zurückgegeben oder er wurde abgesagt. Sie können sich erneut anstellen oder wenden Sie sich an den Operator.",
            "service_queue": "Service Anfrage",
            "suid": "SUID (eindeutige Servicekennung)",
            "contacts": "Kontaktdaten",
            "further_information": "Weitere Infos",

            //SliderGrid
            "item_one": "Item One",
            "item_two": "Item Two",

            //TicketAlarm
            "your_slot": "Dein Slot",
            "waits_now": "wartet jetzt.",
            "got_called": "wurde aufgerufen.",
            "runs_out_soon": "verfällt gleich BEEILUNG.",
            "news_to_appointment": "Es gibt Neues zu einem Termin",

            //TicketData
            "start_point": "Beginn",
            "end_round_about": "Ende ca",
            "wating_time": "Wartezeit",
            "hours/minutes/seconds": "Stunden/Minuten/Sekunden",
            "preferred_time_slot": "Wunschzeitraum",
            "tuid": "TUID (eindeutige Terminkennung)",
            "CREATE": "ANLEGEN",

            //TicketDataUtil
            "call": "Aufrufen",
            "cash": "Einlösen",
            "begins_process": "beginnt Bearbeitung.",
            "Wait": "Warten",
            "waits_again": "wartet wieder.",
            "ends_process": "beendet Bearbeitung.",
            "is_planning_new": "wird neu eingeplant.",
            "no_resources": "keine Ressourcen",

            //TimeRangeSelector
            "date": "Datum",
            "startdate": "Startdatum Ihres Wunschzeitraumes",
            "choose": "Wählen",
            "clrear": "Clrear",
            "cancel": "Abbrechen",
            "today": "Heute",
            "of": "Von",
            "now": "Jetzt",
            "enddate": "Endedatum Ihres Wunschzeitraumes",
            "until": "Bis",

            //Calendar
            "before": "vor",
            "month": "Monat",
            "week": "Woche",
            "day": "Tag",

            //Start
            "my_waiting": "Mein Warten",
            "waiting_wizard": "Hier gehts los zum Warteraum-Wizard",
            "scanner": "Scanner",
            "qr_scan_for_call": "QR Code Scanner um einen Warterraum per Scan aufzurufen.",
            "waiting_rooms": "Warte Räume",
            "likeable_waiting_rooms": "Warterräume, wo Du gerne wartest.",

            //Startpage
            "ticket_ok": "Ticket OK. Aktualisiert von Server",

            //CallNext
            "next_called": "Nächster wurde aufgerufen.",

            //Status
            "q_waiting_for_tickets": "Q wartet auf Tickets",
            "q_handling_ticket": "Q bearbeitet ein Ticket",
            "q_waiting_for_work" : "Q wartet auf Arbeit",
            "ready": "bereit",

            //TimeScopePresetter
            "early": "Früh",
            "middle": "Mittel",
            "late": "Spät",

            //WeekDaySelection
            "week_days": "Wochentag(e)",
            "all": "Alle",
            "monday": "Mo",
            "tuesday": "Di",
            "wednesday": "Mi",
            "thursday": "Do",
            "friday": "Fr",
            "saturday": "Sa",
            "sunday": "So",

            //Slot
            "edit_slot": "Slot bearbeiten",

            //SlotEditor
            "opening_hours_changed": "Öffnungszeit wurden geändert.",
            "opening_hours_saved": "Öffnungszeit wurde gespeichert.",
            "delete_opening_hour": "Öffnungzeit löschen",
            "opening_hours_deleted": "Öffnungszeit wurde gelöscht.",
            "change_opening_hour": "Öffnungszeit bearbeiten",
            "create_opening_hour": "Öffnungszeit anlegen",
            "opening_hour": "Öffnungszeit",
            "opening_hours": "Öffnungszeiten",
            "opening_hours_marking": "Öffnungszeit ohne Wiederholung markiert andere Zeiträume als geschlossen.",
            "new_opening_hour": "Neue Öffnungszeit",

            //SlotManagement
            "organise_opening_hours": "Öffnungszeiten verwalten",
            "type": "Typ",
            "start": "Start",
            "end": "Ende",

            //OperatorDashboard
            "scan_code_and_queue_up": "QR Code Scannen und anstellen.",
            "hold_code_in_front_of_line": "QR Code bitte vor die Linse halten.",
            "qr_code": "QR Code",
            "dashboard": "Dashboard",

            //MyWaiting
            "room_1": "Raum 1",
            "room_2": "Raum 2",
            "room_3": "Raum 3",
            "qr_for_room": "QR Code Scanner um einen Warterraum per Scan aufzurufen.",

            //MyWaiting actually
            "prices": "Preise",
            "pricing": "Pricing",
            "effective_pricing": "Quickly build an effective pricing table for your potential customers with this layout. It's built with default Material-UI components with little customization.",
            "free": "Free",
            "10_users_included": '10 users included',
            "20_users_included": '20 users included',
            "50_users_included": '50 users included',
            '2_GB_of_storage': '2 GB of storage',
            '10_GB_of_storage': '10 GB of storage',
            '30_GB_of_storage': '30 GB of storage',
            'help_center_access': 'Help center access',
            "email_support": "Email support",
            'sign_up_for_free': 'Sign up for free',
            "pro": "Pro",
            "most_popular": "Most popular",
            "get-started": "Get started",
            'enterprise': 'Enterprise',
            'contact us': 'Contact us',
            'priority_email_support': 'Priority email support',
            "phone_and_email_support": "Phone & email support",
            "dollar": "$",
            "/mo": "/mo",

            "Company": "Company",
            "team": "Team",
            "history": "History",
            "location": "Location",
            "feature": "Feature",
            "cool_stuff": "Cool stuff",
            'random_feature': 'Random feature',
            'team_feature': 'Team feature',
            'developer_stuff': 'Developer stuff',
            'another_one': 'Another one',
            'Resources': 'Resources',
            'Resource': 'Resource',
            'Resource_name': 'Resource name',
            'Another_resource': 'Another resource',
            'Final_resource': 'Final resource',
            'Legal': 'Legal',
            'Privacy_policy': 'Privacy policy',
            'Terms_of_use': 'Terms of use',
            "your_website": "Your website",

            //It&apos;s built with default Material-UI components with little customization.

            //Administrations
            "waiting_roons": "Warteschlangen",
            "waiting_line": "Warteschlange",

            //Chat
            "sended": "gesendet.",
            "type_something": "Type Something",

            //Dialog
            "dialogue": "Dialog",

            //OperatorDashboard
            "scan_ticket": "Ticket scannen.",

            //QueueEditor
            "changes_saved": "Änderungen wurden gespeichert.",
            "waiting_room_created": "Warteraum wurde eingerichtet.",
            "edit_slotmanager": "Slotmanager bearbeiten",
            "create_slotmanager": "Slotmanager anlegen",
            "most_important": "Das Wichtigste",
            "waiting_room_name": "Name des Slotmanager",
            "email_counter_desk": "E-Mail Adresse des Counter-Desk",
            "no_group": "keine Gruppe",
            "slot_care": "Slot Behandlung",
            "basic_work_time": "Standard Bearbeitungszeit",
            "cash_dead_line": "Einlöse Frist",
            "call_advance": "Aufruf Vorlauf",
            "slot_offset": "Slot Offset",
            "slot_laps": "Slot Runden",
            "behaviour": "Verhaten",
            "room_mode": "Raum Modus",
            "timeslot_based": "Timeslot basiert",
            "time_slot_and_dynamic_hybrid": "Timeslot und Dynamic Hybrid",
            "full_dynamic": "Volle Dynamic",
            "service_mode": "Service Mode",
            "sort_of_queuing": "Art des Anstellens",
            "directly": "Direkt",
            "with_formular_directly": "Mit Formular direkt",
            "with_confirmation": "Mit Bestätigung",
            "with_formular_and_confirmation": "Mit Formular und Bestätigung",
            "behavoiur_if_your_turn": "Verhalten 'wenn dran'",
            "terminates_automatically": "verfällt automatisch",
            "endless_waiting_for_cashing": "endlos warten auf einlösen",
            "automatically_new_planing": "automatisch neu planen",
            "how_many_positions_back": "Um wie viele Positionen nach hinten verschieben",
            "assistance": "Assistenz",
            "slotmanagement": "Slotmanagement",
            "opimizer": "Opimizer",
            "opimizer_tart": "Opimizer startet wenn Lücken größer als",
            "optimizer_order": "Reihenfolge beim Optimieren",
            "position": "Postion",
            "prio": "Prio",
            "defrag": "Defragmentieren",
            "defrag_at_gap": "Defrag startet wenn Lücken größer als",
            "defrag_order": "Reihenfolge beim Defragementieren",
            "result_and_reaction": "Ereignisse & Reaktion",
            "results_automatically_on_slot": "Ereignisse auf Slots automatisch anwenden.",
            "completly_at_change": "Komplett bei Änderungen Ticket",
            "timeslot_at_change": "Zeitraum bei Änderung Ticket",
            "openinh_hour_change": "Änderung Öffnungzeiten",
            "replan_slots_on_resource_change": "Slots bei Resourcenänderung neu planen",
            "max_cycles_per_slot": "Maximale Zyklen/Minute pro Slot",
            "offset_beginning": "Offset Beginn",
            "offset_end": "Offset Ende",
            "automatically_in_progress": "automatisch in Bearbeitung",


            "message_slotmanager_created": "Der Slotmanager wurde mit Standardwerte erstellt. Du kannst diese nun nach deinen Wünschen in den Einstellungen anpassen - oder einfach erstmal loslegen.",


            //QueueSettings
            "special_times": "Es gibt spezielle Zeiten, zu denen gewartet werden darf? Diese können hier eingerichtet werden.",
            "tickets_sorted_after_time": "Damit können Tickets nach 'Typ' sortiert werden..",
            "general_settings": "Allgemeine Einstellungen",
            "other_settings": "Hier können Raum Name und andere rudimentäre Einstellungen vorgenommen werden.",
            "other_access": "Anderen direkte Freigabe erteilen.",

            //Room
            "no_active_slotmanager": "Kein Slotmanager aktiv. Zurück auf Start...",

            //SelectProcessingTime
            "how_long_you_take": "Wie lange wirst Du brauchen?",
            "1_minute": "1 Minute",
            "2_minutes": "2 Minute",
            "5_minutes": "5 Minute",
            "10_minutes": "10 Minute",
            "30_minutes": "30 Minute",

            //SlotsDisplay
            "everyone": "Jeden",
            
            //EditResources
            "other_resources_updated": "andere Resourcen wurden aktualisiert.",
            "resource_saved": "Ressource gespeichert",
            "resources_status": "Resourcen Status",
            "resource_status": "REssource Status:",

            //Resources
            "all_resources": "Alle Ressourcen",
            "new_resource": "Neue Ressource",

            //ResourcesUsage
            "details_to": "Details zu",
            "no_data": "Keine Daten",
            "no_resources_and_information": "Hier gibt es keine Resourcen und keine weiteren Infos. Zeitpunkt in der Vergangenheit oder es arbeitet keiner.",
            "avaiable": "verfügbar",
            "cycl": "Zykl",
            "used": "verwendet",
            "remaining": "übrig",
            "missing": "fehlt",

            //RessourcesUsage
            "used_resources": "verwendete Resourcen",
            "34dummy": "34dummy",
            "working_cycles": "Arbeitszyklen",
            "duration": "Dauer",
            "todo_cycles": "TODO Cycles*Resurcecapacities",

            //EditResourceWorkingHours
            "edit_resource_wotk_saved": "Ressource Arbeitszeit gespeichert",
            "edit_resource_wotk_time": "Ressource Arbeitszeit bearbeiten",
            "create_resource_wotk_time": "Ressource Arbeitszeit anlegen",
            "resource_wotk_time": "Ressource Arbeitszeit",

            //ResourceWorkingHours
            "adapt": "übertragen",
            "all_resources_work_time": "Alle Ressourcen Arbeitszeiten",
            "new_resources_work_time": "Neue Ressource Arbeitszeit",

            //EditResourceVaraint
            "edit_alternative": "Variante bearbeiten",
            "create_alterniative": "Variante anlegen",
            "alternative_name": "Variante Name",
            "speed": "Geschwindigkeit",
            "buffer_before": "Puffer Vor",
            "buffer_after": "Puffer Nach",

            //ResourceVaraints
            "duration_cycle": "Dauer Zyklus",
            "minute": "Minute",
            "n": "n",
            "all_alternatives": "Alle Varianten",
            "new_alternaive": "Neue Variante",

            //PlaceEditor
            "new_q": "Neue Q erstellen",

            //ActionMenu
            "temporary_save": "In Zwischenablage kopiert. Du kannst den Slot jetzt per Mail oder WhatsApp versenden.",
            "share": "teilen",
            "chat": "Chat",
            "return": "zurückgeben",
            "show_code_at_operator": "Den QR Code bitte beim Operator vorzeigen und scannen lassen.",
            "your_qr_code_ticket": "Ihr QRCode-Ticket",
            //ButtonMove
            "was_prefered": "wurde vorgezogen",
            "was_put_back": "wurde nach hinten geschoben.",

            //ButtonReturn
            "was_returned": " wurde zurückgegeben",

            //ButtonUpdateState
            "ticket_updated": " - Ticket aktualisiert - ",

            //DisplayDelay
            "noticed_delays": "Gemeldete Verspätungen",

            //SendDelay
            "delay_noticed": "Verspätung gemeldet:",
            "notice_delay": "Verspätung melden",
            "choose_delay": "Verspätung wählen",

            //EditTicket
            "timeslot_sended_soon_in_wallet": "Timeslot wurde abgesendet und ist gleich in Deinem Wallet sichtbar.",
            "timeslot_sended": "Timeslot wurde abgesendet.",
            "request/edit_ticket": "Ticket bearbeiten/anfragen",
            "tell_something_about_you": "Verraten Sie etwas über sich.",
            "service_data": "Servicedaten",
            "appointment_after_request": "Nachdem Ihre Anfrage geprüft wurde, wird Ihnen ein Termin zugeteilt.",
            "best_appointment_automatically": "Sie erhalten dann den nächstmöglichen Termin automatisch zugeteilt.",
            "information_for_service": "Infos zur Serviceeinheit",
            "contact_data_information": "Kontaktdaten/Infos",
            "email_or_phone_number": "E-Mail oder Telefonnummer angeben",
            "clientName": "Transporteur",
            "createdBy": "Kunde",
            "externalRef": "externe Referenz",
            "externalContextScope": "WANKO-Kundennummer",
            "responsibleName": "Bearbeiter",
            "sender": "sender",
            "save_ticket": "Save Ticket.",
            "choose_timeslot": "Zeitraum wählen",
            "deny_and_other_proposal": "Ablehnen und Gegenvorschlag",
            "confirmation": "Bestätigung",
            "check": "Prüfen",
            "noone": "keiner",
            "none": "keine",

            //TcketCallBoard
            "next_are": "Die nächsten sind",
            
            //TicketListCalendar
            "partition_will_be_optimized": "Partition wird optimiert.",
            "will_be_optimized": "wird optimiert.",
            "day_view": "Tages Ansicht",
            "list_view": "Listen Ansicht",
            "optimize_timeslot": "Zeitraum optimieren",
            "show_qr_code": "QR Code anzeigen",
            "q_settings": "Q Einstellungen",
            "search_ticket": "Ticket suchen",

            //Accounts
            "users_loaded": "User geladen",
            "all_user": "Alle User",

            //UserTableHeader
            "email": "E-Mail",

            //ConfirmPasswordReset
            "reset_pw_not_possible": "PW rücksetzen nicht möglich, Key fehlt",
            "pw_reseted": "Passwort zurückgesetzt",
            "create_new_pw": "Neues Passwort einrichten ",
            "new_pw": "Neues Passwort",
            "enter_again":"Nochmal eingeben",
            "ok_thats_it": "Ok, das wars",

            //ResetPasswort
            "reset_pw": "Passwort zurücksetzen",
            "reset_link_received": "Rücksetz-Link erhalten",
            "allready_account_this_way": "Sie haben schon ein Konto? Dann schnell hier lang.",
            "this_way_for_new_account": "Hierlang um ein Konto anzulegen.",
            "pw_reset_started": "PW Reset gestartet",

            //SignIn
            "user_login": "Benutzer Anmeldung",
            "login_with_access_data": "Mit Zugangsdaten anmelden",
            "pw": "Passwort",
            "remember": "merken",
            "access_data_forgot": "Zugangsdaten vergessen?",
            "user_activated": "Super, du bist es. Nun kannst du dich mit deinen Zugangsdaten anmelden.",
            "user_logged_in": "User angemeldet",
            "user_state_loaded": "User state loaded",
            "user_state_recoverd": "User state recoverd",
            "user_state_saved": "User state gespeichert",

            //SignOut
            "user_logged_out": "User ABGEMELDET",

            //SignUp
            "user_created": "Benutzer erzeugt - bitte prüfe deine E-Mails und bestätige mit Hilfe des Aktivierungslinks, dass du es bist.",
            "for_juristic_safety": "Für unsere und Ihre Rechtssicherheit lesen Sie bitte unsere Nutzungsbedingungen.",
            "confirm_use_condition": "Ja, ich stimme den Nutzungsbedingungen zu.",
            "request_registration": "Registrierung beantragen",
            "allready_have_an_account": "Du hast schon ein Konto? Dann schnell hier lang.",

            //TermsofUse
            "use_conditions": "Nutzungsbedingungen",
            "your_data_belongs_to_us": "Alle deine Daten gehören fortan UNS!",
            "yes_understood": "Jawoll, verstanden.",

            //Rooms
            "queues_found_here": ", hier gefundene Slotmanager",
            "waiing_room-": "Warteraum-",
            "create_waiting_room": "Raum erzeugen",

            //ColumnDisplayAndPage
            "t": "T",
            "r": "R",

            //CountDown
            "days-": "Tage - ",

            //PagingBrowser
            "Show": "Zeige",
            "of_Everything": "von ALLE",

            //VRPopover
            "?": "?",

            //CardGrid
            "Home": "Home",

            //SlotEditor
            "repetition": "Wiederholung",
            "interval": "Intervall",

            //Slots
            "clock": "Uhr",

            //ResourcesDebug
            "cycles_per_minute": "Cycles per minute:",

            //MapContainer
            "q_editor": "Q-Editor",
            "your_postition": "Your Position",
            "current_location": "Current location",
            "markers_title": 'The marker`s title will appear as a tooltip.',
            "soma": "SOMA",
            "dolores_park": 'Dolores park',

            //Post
            "editors_pick": "Editor's Pick",
            "New": "New",
            "react": "Getting Started with React",

            //TicketStatistic
            "PLAN": "PLAN",
            "IS": "IST",
            "the_call": "Aufruf",

            //BlockerData
            "not_bookable": "nicht buchbar",

            //ResourceUsage
            "sing_end": "us",
            "plur_end": "en",

            //TicketFilterSmall
            "neu_klein": "neu",
            "waits": "wartet",
            "in_progress": "In Bearbeitung",

            //General
            "scan_QR": "QR Code scannen",
            "next_timepoint": "Der nächste Zeitpunkt kann Dir gehören.",
            "book_now": "jetzt buchen",
            "book_here": "hier buchen",
            "to_room": "Zum Raum",
            "management": "Verwaltung",
            "show": "zeigen",
            "save": "Speichern",
            "delete": "Löschen",
            "Change": "Bearbeiten",
            "change": "bearbeiten",
            "multiQ": "MultiQ",
            "resources": "Ressourcen",
            "waiting_room": "Warteraum",
            "all_groups": "Alle Gruppen",
            "new_group": "Neue Gruppe",
            "description": "Beschreibung",

            "language": "Sprache",
          
            "alert_modal_hint": "Hinweis"
        },
      },
    },
    lng: "de",
    fallbackLng: "en",
  });

  

export default i18n;