
var AuthorizationUtil = (function() {
  

  
  var check = function(entity, kind, userId) {
    return entity.authorizations && entity.authorizations.length>0;
  }
  

  var isOwner = function(entity) {
    return hasRole(entity,'OWNER');
  }

  var isAdmin = function(entity) {
    return isOwner(entity) || hasRole(entity,'ADMIN');
  }
  
  var isManager = function(entity) {
    return isAdmin(entity) || isOwner(entity) || hasRole(entity,'MANAGER')
  }

 var isUser = function(entity) {
    return isAdmin(entity) || isManager(entity) || isOwner(entity) || isImplicit(entity) || hasRole(entity,'USER');
  }

  var isImplicit = function(entity) {
    return entity.authorizations && entity.authorizations.length==0 ;
  }
  
  var hasRole = function(entity, role) {
    let result = false;
    if(entity) {
      let authorizations = entity.authorizations;
      if(authorizations) {
        let matching = authorizations.filter((aAuth) => {
          return aAuth.role==role; 
        }) 
        result = matching && matching.length>0;
      }
    }
    return result;
  }
  return {
    check : check,
    isManager : isManager,
    isAdmin : isAdmin,
    isUser : isUser,
    isImplicit : isImplicit,
    isOwner : isOwner
  }

})();

export default AuthorizationUtil;
