import axios from 'axios';
import React, { Fragment, useContext, useEffect } from 'react';
import config from 'react-global-configuration';
import { useHistory } from "react-router-dom";
import SessionContext from '../frame/SessionContext';
import { MessageContext } from '../pages/assets/MessageProvider';
import { t } from 'i18next';


export default function SignOut() {
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();
  useEffect(() => {    // Update the document title using the browser API 
    
      addMessage(t("user_logged_out"));
      let state = {
          favouriteQueues: SessionContext.getFavourites()
        }
      let user = SessionContext.getUser();
      user.sessionState = state;
      let stringUser = JSON.stringify(user);
      
      const url = config.get('vqueueUrl')+'/api/state';
      axios.post(url, stringUser , {headers: {"Content-Type": "text/plain"}} )
        .then(res => {
          addMessage(t("user_state_saved"));
          // SessionContext.setToken(null);
          // SessionContext.setUser(null);
          SessionContext.clear();    
          console.log(res); 
          history.push("/");
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        })            
  } 
  , [] )  

  return (<Fragment></Fragment>);
}
