import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Fab, IconButton, InputAdornment, Menu, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { Edit, ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined } from '@material-ui/icons';
import { withTranslation, withTranslationProps } from 'react-i18next';


function EditLocation(props) {
  const { vAddress } = props;
  const { setAddress } = props;
  const {t} = props;
  const [address] = useState(vAddress?vAddress:{});
 
 
  const [id] = useState(address?address.id:null);
  const [firstName, setFirstName] = useState(address?address.firstName:"");
  const [lastName, setLastName] = useState(address?address.lastName:"");
  const [company, setCompany] = useState(address?address.company:"");
  const [additional, setAdditional] = useState(address?address.additional:"");
  const [street, setStreet] = useState(address?address.street:"");
  const [number, setNumber] = useState(address?address.number:"");
  const [zip, setZip] = useState(address?address.zip:"");
  const [city, setCity] = useState(address?address.city:"");
  const [country, setCountry] = useState(address?address.country:"");
  const [lat, setLat] = useState(address?address.lat:"");
  const [lon, setLon] = useState(address?address.lon:"");
  
  useEffect(() => {    // Update the document title using the browser API 
    console.log("change");
    
  }, [] )

  function changeValue(e, setAttribute, key) {
    setAttribute(e.target.value);
    address[key]=e.target.value;
    setAddress(address);
  }

  return (
    
     
    <Fragment> 
                <Grid item xs={12}  >
                  <Grid container spacing={1} alignItems="left" justify="left" >
                    <Grid item xs={12}  >
                      <TextField
                        autoComplete="fname"
                        name="company"
                        variant="outlined"
                        value={company}
                        fullWidth
                        id="company"
                        label={t("company")}
                        autoFocus
                        onChange={(e) => changeValue(e,setCompany,'company')}
                      />
                    </Grid>
                    <Grid item xs={6}  >
                      <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        value={firstName}
                        fullWidth
                        id="firstName"
                        label={t("first_name")}
                        autoFocus
                        onChange={(e) => changeValue(e,setFirstName,'firstName')}
                      />
                    </Grid>
                    <Grid item xs={6}  >
                      <TextField
                        autoComplete="fname"
                        name="lastName"
                        variant="outlined"
                        value={lastName}
                        fullWidth
                        id="lastName"
                        label={t("last_name")}
                        autoFocus
                        onChange={(e) => changeValue(e,setLastName,'lastName')}
                      />
                    </Grid>
                    <Grid item xs={12}  >
                      <TextField
                        autoComplete="fname"
                        name="additional"
                        variant="outlined"
                        value={additional}
                        fullWidth
                        id="additional"
                        label={t("additional")}
                        autoFocus
                        onChange={(e) => changeValue(e,setAdditional,'additional')}
                      />
                    </Grid>
                    <Grid item xs={8}  >
                      <TextField
                        autoComplete="fname"
                        name="street"
                        variant="outlined"
                        value={street}
                        fullWidth
                        id="street"
                        label={t("street")}
                        autoFocus
                        onChange={(e) => changeValue(e,setStreet,'street')}
                      />
                    </Grid>
                    <Grid item xs={4}  >
                      <TextField
                        autoComplete="fname"
                        name="number"
                        variant="outlined"
                        value={number}
                        fullWidth
                        id="number"
                        label={t("home_number")}
                        autoFocus
                        onChange={(e) => changeValue(e,setNumber,'number')}
                      />
                    </Grid>
                    <Grid item xs={4}  >
                      <TextField
                        autoComplete="fname"
                        name="zip"
                        variant="outlined"
                        value={zip}
                        fullWidth
                        id="zip"
                        label={t("zip")}
                        autoFocus
                        onChange={(e) => changeValue(e,setZip,'zip')}
                      />
                    </Grid>
                    
                    <Grid item xs={8}  >
                      <TextField
                        autoComplete="fname"
                        name="city"
                        variant="outlined"
                        value={city}
                        fullWidth
                        id="city"
                        label={t("city")}
                        autoFocus
                        onChange={(e) => changeValue(e,setCity,'city')}
                      />
                    </Grid>
                    <Grid item xs={12}  >
                      <TextField
                        autoComplete="fname"
                        name="country"
                        variant="outlined"
                        value={country}
                        fullWidth
                        id="country"
                        label={t("country")}
                        autoFocus
                        onChange={(e) => changeValue(e,setCountry,'country')}
                      />
                    </Grid>
                    {
                    /**<Grid item xs={6}  >
                      <TextField
                        autoComplete="fname"
                        name="lat"
                        variant="outlined"
                        value={lat}
                        fullWidth
                        id="lat"
                        label="lat"
                        autoFocus
                        onChange={(e) => changeValue(e,setLat)}
                      />
                    </Grid>
                    <Grid item xs={6}  >
                      <TextField
                        autoComplete="fname"
                        name="lon"
                        variant="outlined"
                        value={lon}
                        fullWidth
                        id="lon"
                        label="lon"
                        autoFocus
                        onChange={(e) => changeValue(e,setLon)}
                      />
                    </Grid>**/}
                    <Grid item xs={12}  >
                      <Grid container spacing={0} alignItems="left" justify="left" >
                        <Typography  variant="body1" >
                        
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
          </Fragment>    
  );
}

export default withTranslation()(EditLocation);