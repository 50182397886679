import React, { Component } from "react";
import UploadService from "./upload-files.service";
import DragDropFile from "./DragDropFile";
import { Button, Grid, IconButton, LinearProgress, Typography } from "@material-ui/core";
import { HighlightOffRounded, Remove } from "@material-ui/icons";
import { Alert } from "reactstrap";

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedFiles: [],
          progressInfos: [],
          message: [],
    
          fileInfos: [],
        };
        this.selectFiles = this.selectFiles.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.addFiles = this.addFiles.bind(this);
      }
      componentDidMount() {
        UploadService.getFiles().then((response) => {
          this.setState({
            fileInfos: response.data,
          });
        });
      }
      selectFiles(event) {
        this.setState({
          progressInfos: [],
          selectedFiles: event.target.files,
        });
      }
      addFiles(files) {
        let selectedFiles = this.state.selectedFiles.concat([...files]);
        this.setState({
          progressInfos: [],
          selectedFiles: selectedFiles,
        });
      }
      uploadFiles() {
        const selectedFiles = this.state.selectedFiles;
    
        let _progressInfos = [];
    
        _progressInfos.push({ percentage: 0, fileName: "Anzahl Dateien "+selectedFiles.length });
        
        this.setState(
          {
            progressInfos: _progressInfos,
            message: [],
          },
          () => {
            const data = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
              data.append(`attachements`, selectedFiles[i], selectedFiles[i].name);
            }
            this.upload(0, data);
    
            
          }
        );
      }
      removeFile(index) {
        const selectedFiles = this.state.selectedFiles;
        selectedFiles.splice(index, 1);
        this.setState({
          selectedFiles: selectedFiles
        });
      }

      upload(idx, files) {
        let _progressInfos = [...this.state.progressInfos];
    
        UploadService.upload(this.props.ticketId, files, (event) => {
          _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
          this.setState({
            _progressInfos,
          });
        })
          .then((response) => {
            this.setState((prev) => {
              let nextMessage = [...prev.message, "Dateien erfolgreich hochgeladen. "];
              return {
                message: nextMessage
              };
            });
            this.props.successCallback();
          })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          })
          .catch(() => {
            _progressInfos[idx].percentage = 0;
            this.setState((prev) => {
              let nextMessage = [...prev.message, "Could not upload the file: " + files.name];
              return {
                progressInfos: _progressInfos,
                message: nextMessage
              };
            });
          });
      }
      render() {
        const { selectedFiles, progressInfos, message, fileInfos } = this.state;
    
        return (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DragDropFile handleFiles={this.addFiles} />
                
              </Grid>
              { selectedFiles &&
                <Grid item xs={12}>
                  <Grid container >
                    { selectedFiles.map((file, index) => (
                        <Grid container key={index} >
                           <Grid item xs={2}>
                              <IconButton   color="inherit" onClick={(index) =>  this.removeFile(index)} aria-label="close">
                                <HighlightOffRounded style={{fontSize:"3rem", color: "red"}} />
                              </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                              <Grid container style={{display:"flex", height:"100%"}} direction="column" justifyContent="center" >
                                {file.name}
                              </Grid>  
                            </Grid>
                           
                        </Grid>
                    ))}
                  </Grid>
                </Grid>
              }
              { selectedFiles && <Grid container >
                  <Grid item xs={12}>
                    <Typography  variant="body1" >
                      <span >Anzahl Dateien: {selectedFiles.length}</span>
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={12}>
                    <Button autoFocus onClick={this.uploadFiles}
                      disabled={!selectedFiles}
                      fullWidth 
                      variant="contained"
                      color="primary" >
                        Hochladen
                    </Button>
                  </Grid>
                </Grid>
              }
              {progressInfos &&
                progressInfos.map((progressInfo, index) => (
                  <Grid container key={index} >
                    <Grid item xs={8} >
                      <LinearProgress color="secondary" variant="determinate" value={progressInfo.percentage}  />
                      
                    </Grid>
                    <Grid item xs={4} >
                      <Typography variant="body2" color="textSecondary">{progressInfo.percentage} %</Typography>
                    </Grid>
                  </Grid>
                ))}
                {message.length > 0 && (
                <Grid item xs={12} >
               
                    {message.map((item, index) => {
                      return <Alert key={index} severity="success">{item}</Alert>;
                    })}
                  </Grid>
                )}
            </Grid>
            
          </div>
        );
      }
}
