import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../../pages/assets/AppFooter';
import DeskHeader from '../../pages/assets/DeskHeader';
import { MessageContext } from '../../pages/assets/MessageProvider';
import TimeRangeSelector from '../../pages/assets/TimeRangeSelector';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../../frame/SessionContext';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import ActionMenu from '../../pages/assets/ActionMenu';
import { t } from 'i18next';
import { Add } from '@material-ui/icons';
import OneWeekday from './OneWeekday';






export default function WeekDaySelector(props) {
 
  const { updateCallback } = props;
  const { timeScopes } = props;
  const { weekdayMode } = props;
  const { disabled } = props;
  
  const [dayChecked, setDayChecked] = React.useState([false, false, false, false, false, false, false]);
  const [startDay, setStartDay] = React.useState([null, null, null, null, null, null, null]);
  const [endDay, setEndDay] = React.useState([null, null, null, null, null, null, null]);
  const [scopesSelected, setScopesSelected] = React.useState([]);

  useEffect(() => {  
    setScopesSelected(timeScopes); 
    let updateDaysChecked = false;
    timeScopes.every(function (aDayScope, indexDay) {
      updateDaysChecked = !(dayChecked[indexDay] && timeScopes[indexDay])
      return !updateDaysChecked;
    });
    if(updateDaysChecked) {
      setDayChecked(timeScopes);
    }
  }, [timeScopes] )
  
  function changeAllDays(event) {
    setDayChecked([event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked]);
  }

  function indeterminate() {
    let lastChecked = dayChecked[0];
    for (var i=0; i<dayChecked.length; i++) {
      if(lastChecked!=dayChecked[i]) { 
        return true;
      }
      lastChecked=dayChecked[i];
    }
    return false;
  }

  function changeDayChecked(index, checked) {
    const days = [...dayChecked];
    days[index]=checked;
    setDayChecked(days);
  }

  function updateTimeScopeFor(index, dayScopes) {
    const scopes = [...scopesSelected];
    scopes[index]=dayScopes;
    updateCallback(scopes); 
    //setScopesSelected(scopes);    
  }
  
  function copyToOthers(index) {
    const scopes = [...scopesSelected];
    let dayScopes = scopes[index];
    scopes[0]=dayScopes;
    scopes[1]=dayScopes;
    scopes[2]=dayScopes;
    scopes[3]=dayScopes;
    scopes[4]=dayScopes;
    scopes[5]=dayScopes;
    scopes[6]=dayScopes;
    updateCallback(scopes); 
    //setScopesSelected(scopes);    
  }


  return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{t("week_days")}
          </FormLabel>
        <FormGroup aria-label="position" row>
          <Grid container>
            <Grid item  xs={12}>
              <FormControlLabel
                  label={t("all")}
                  labelPlacement="bottom"
                  disabled={disabled}
                  control={
                    <Checkbox
                      checked={dayChecked[0] && dayChecked[1] && dayChecked[2] && dayChecked[3] && dayChecked[4] && dayChecked[5] && dayChecked[6]}
                      indeterminate={indeterminate()}
                      onChange={changeAllDays}
                    />
                  }
                />
            </Grid>

            <OneWeekday 
                label={t("monday")}
                vChecked={dayChecked[0]}
                vScopesDay={scopesSelected[0]}
                checkedCallback={(checked) => changeDayChecked(0, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(0, scope)}
                copyToOthers={() => copyToOthers(0)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />
             <OneWeekday 
                label={t("tuesday")}
                vChecked={dayChecked[1]}
                vScopesDay={scopesSelected[1]}
                checkedCallback={(checked) => changeDayChecked(1, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(1, scope)}
                copyToOthers={() => copyToOthers(1)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />
             <OneWeekday 
                label={t("wednesday")}
                vChecked={dayChecked[2]}
                vScopesDay={scopesSelected[2]}
                checkedCallback={(checked) => changeDayChecked(2, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(2, scope)}
                copyToOthers={() => copyToOthers(2)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />
             <OneWeekday 
                label={t("thursday")}
                vChecked={dayChecked[3]}
                vScopesDay={scopesSelected[3]}
                checkedCallback={(checked) => changeDayChecked(3, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(3, scope)}
                copyToOthers={() => copyToOthers(3)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />
             <OneWeekday 
                label={t("friday")}
                vChecked={dayChecked[4]}
                vScopesDay={scopesSelected[4]}
                checkedCallback={(checked) => changeDayChecked(4, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(4, scope)}
                copyToOthers={() => copyToOthers(4)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />
             <OneWeekday 
                label={t("saturday")}
                vChecked={dayChecked[5]}
                vScopesDay={scopesSelected[5]}
                checkedCallback={(checked) => changeDayChecked(5, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(5, scope)}
                copyToOthers={() => copyToOthers(5)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />
             <OneWeekday 
                label={t("sunday")}
                vChecked={dayChecked[6]}
                vScopesDay={scopesSelected[6]}
                checkedCallback={(checked) => changeDayChecked(6, checked)}
                updateValueCallback={(scope) => updateTimeScopeFor(6, scope)}
                copyToOthers={() => copyToOthers(6)}
                disabled={disabled}
                weekdayMode={weekdayMode}
                  />                                      
          </Grid>                                        
        </FormGroup>
      </FormControl> 
  );
}
