import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from './assets/AppFooter';
import DeskHeader from './assets/DeskHeader';
import { MessageContext } from './assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TicketGrid from './assets/CardGrid';
import TicketData from './assets/TicketData';
import ServiceCard from './assets/ServiceCard';
import { SearchOutlined } from '@material-ui/icons';
import Crumbs from '../queue/Crumbs';
import { withTranslation  } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    height: "100%"
  },
}));




export default withTranslation()(function Search(props) {
  
  const classes = useStyles();

  const { t } = props;

  const [results, setResults] = useState([]);
  const [searchString, setSearchString] = useState([]);

 
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  
  
  function validateForm() {
    return searchString.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    axios.get(config.get('vqueueUrl')+'/api/search?searchString='+searchString)
        .then(response => { 
          console.log("Received Data for searchString."+searchString)
          setResults(response.data);
        }
      ); 
  }

  function getGridContent() {
    let gridContent = [];
    results.forEach(function (aResult, index) {
      let activeTicketForQ = SessionContext.getActiveTicketForQueue(aResult);
      let entry =  {
        crumb: <Crumbs queue={aResult}/>,
        title: aResult.name,
        text: <Fragment>
              { activeTicketForQ!=null &&  activeTicketForQ.status!="SERVICE" &&
                  <TicketData ticket={activeTicketForQ} queue={aResult}/>
              }
              { activeTicketForQ!=null &&  activeTicketForQ.status=="SERVICE" &&
                  <ServiceCard ticket={activeTicketForQ} />
              }
              { activeTicketForQ==null && 
                <Fragment> 
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
                      <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                        {t("next_timepoint")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" >
                      <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
                        <span >{t("book_now")}</span>    
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>  
              }            
          </Fragment>,  
        action: <Fragment>
                  <Grid item xs={6}  >
                    <Link to={{pathname: '/room', queue: aResult}}  >
                      <Button color={'secondary'} variant="contained" color="secondary" variant="contained" style={{width:'100%'}}  >
                        {t("show")}                  
                      </Button>
                    </Link>                   
                  </Grid>
                  { (SessionContext.isLoggedIn() && aResult.authorizations && aResult.authorizations.length>0) &&
                    <Grid item xs={6}  >
                      <Link to={{pathname: '/my/groups/slotmanager/dashboard', queue: aResult}} >
                        <Button color="secondary" variant="contained" color="primary" variant="contained" style={{width:'100%'}} >
                          {t("management")}
                        </Button>
                      </Link>                
                    </Grid>    
                  }
           </Fragment>,
        menuItems: getMenuItems(aResult),
        cardStyle:{height: "18.5rem"},
        queue: aResult
      };
      gridContent.push(entry);
    });
    return gridContent;
  }

 
  function getMenuItems(vPlace) {
    return [
      <MenuItem >
        <Link to={{pathname: '/my/places/edit', vPlace:vPlace}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                {t("Change")}
            </Button>
          </Link>  
      </MenuItem>
      ]
}
  

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={t("suche_title")}  backTo={"/"} />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SearchOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
        {t("suche_title")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container>  
            <Grid xs={9}>
              <TextField
                    autoComplete="fname"
                    name="searchString"
                    variant="outlined"
                    value={searchString}
                    required
                    fullWidth
                    id="searchString"
                    label={t("input_holder")}
                    autoFocus
                    onChange={(e) => setSearchString(e.target.value)}
                  />
            </Grid>
            <Grid xs={3}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t("submit_button")}
              </Button>       
            </Grid>
          
          <Grid xs={12}>
            
            <TicketGrid gridContent={getGridContent()}/>
          </Grid> 
        </Grid>
      </form>
      
    </div>
    <AppFooter />
        
    
  </Container>
  );
})
