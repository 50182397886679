import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import LinkCard from './LinkCard';
import { withTranslation } from 'react-i18next';


class NavigationGrid extends Component {

  constructor() {
    super();    
  }

  getGridContent() {
    if(this.props.gridContent) {
      return this.props.gridContent;
    } 
    const {t} = this.props;
    return [
      {
        title: t("Home"),
        text: t("back_to_begin"),
        link:"/"
      }
      
    ] ;
  }

  render() {
    return (
      
        <Grid item xs={12} >
          <Grid container spacing={3}  alignItems="center" justify="center" style={{ justifyContent: "center", display: "flex" ,textAlign: "center" }}>
            {this.getGridContent().map((value) => (
              <Fragment>
                <Grid key={value} item  xs={12} >
                  <LinkCard area={value} />
                </Grid>                
              </Fragment>             
            ))}
          </Grid>
        </Grid>
        
      
    );
  }
}

export default withTranslation()(NavigationGrid);