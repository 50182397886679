import React, { Fragment, useEffect , useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from "react-router-dom";


import TicketsListCalendar from '../../ticket/TicketsListCalendar';
import { CircularProgress, Divider, IconButton, MenuItem, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Edit, Settings, SkipNext, SkipPrevious, Visibility } from '@material-ui/icons';
import TimelineMatrix from './TimelineMatrix';

import ResourcesUsage from '../../resources/ResourcesUsage';
import { t } from 'i18next';
import { Badge } from 'reactstrap';
import AuthorizationUtil from '../../authorization/AuthorizationUtil';
import Crumbs from '../../queue/Crumbs';
import TicketAlarm from './TicketAlarm';
import log from 'loglevel';
import CategoryBadges from '../../category/CategoryBadges';
import MultiQStatusAndActionBar from './MultiQStatusAndActionBar';
import MoreMenu from './MoreMenu';
import TicketsListLoader from '../../ticket/TicketsListLoader';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root1: {
    display: 'flex',
    alignItems: 'center',
    width: "100%",    
    justifyContent: "space-between"    
  },
  iconButton: {
    padding: 10,
    backgroundColor: "#8ffff4",
    border: "solid 1px"
  }
}));



export default function ColumnDisplayAndPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const {columns} = props;
  const {vResourcePeriodStatusDTOMap} = props;
  const { resources } = props;
  const [startIndex, setStartIndex] = useState(0);  
  const [showColsCount, setShowColsCount] = useState(6);
  const { from } = props
  const { to } = props
  const { place } = props
  const { group } = props
  const { createElement } = props
  const { manage } = props
  const { filters } = props
  const { booking } = props  
  const { viewportHeightCallback } = props;
  const { resourceStatusDTOMap } = props;
  
  const [resourcePeriodStatusDTOMap, setResourcePeriodStatusDTOMap] = useState(null);
  const [offsetFrom, setOffsetFrom] = useState(0)
  const [offsetTo, setOffsetTo] = useState(0)
  const [lastUpdate, setLastUpdate] = useState(props.lastUpdate?props.lastUpdate:null)
  const [viewportHeight, setViewportHeight] = useState(0)
  const [unmounted, setUnmounted] = useState(false);
  const [ready, setReady] = useState(false); //if showing resource, view is assumed to be ready - loader only showing on partitions view
  
  const [relatedId] = useState(group?group.id:place.id)
  const [type] = useState(group?'GROUP':'PLACE')
  const history = useHistory();
  


  useMemo(()=>{
    setResourcePeriodStatusDTOMap(null);
    setOffsetFrom(0);
    setOffsetTo(0);
    setViewportHeight(0);
  }, [from])

  useEffect(() => {    // Update the document title using the browser API     
    return () => { setUnmounted(true); };
  }, [] )

  useEffect(() => {    
    log.debug(" useEffect ");
    calcShowColsCount();
  }, [isXs,isSm,isMd,isLg] )

  useEffect(() => {    
    setReady(false);
  }, [from] )

  useEffect(() => {    
    log.debug(" useEffect ready : "+ready);
  }, [ready] )

  useEffect(() => {    
    if(resourceStatusDTOMap!==undefined && resourceStatusDTOMap!==null)  {
      log.debug("useEffect resourceStatusDTOMap status "+resourceStatusDTOMap.partitionStatus);  
      processPartitionResourceDTO();       
    } else {
      log.debug("useEffect resourceStatusDTOMap undefined, no status");  
    
    }

   
  }, [resourceStatusDTOMap] )

  useEffect(() => {   
    if(offsetTo>0) {
      let vpHeight = ((offsetTo-offsetFrom)/1000/60)*2;
      log.debug("useEffect offsetFrom,offsetTo vpHeight "+vpHeight);   
      setViewportHeight(vpHeight+61);  //give some pixels more since the header is inside viewport aswell
    }
  }, [offsetFrom,offsetTo] ) 

  
  useEffect(() => {   
    if(viewportHeightCallback) {
      log.debug("viewportHeightCallback "+viewportHeight);   
      viewportHeightCallback(viewportHeight);
    }
  }, [viewportHeight] )
  

  useEffect(() => {
    log.debug("useEffect vResourcePeriodStatusDTOMap");  
    if(vResourcePeriodStatusDTOMap) {
      setResourcePeriodStatusDTOMap(vResourcePeriodStatusDTOMap);
    }
  }, [vResourcePeriodStatusDTOMap]);



  

  function calcShowColsCount() {
    if(isXs) {
      log.trace("isXs");   
      setShowColsCount(3);       
    }
    if(isSm) {
      log.trace("isSm");   
      setShowColsCount(4);       
    }
    if(isMd) {
      log.trace("isMd");   
      setShowColsCount(6);       
    }
    if(isLg) {
      log.trace("isLg");   
      setShowColsCount(12);
    }
    setStartIndex(0);
  }
 
  function processPartitionResourceDTO() {
    log.debug("ColumnDisplayAndPage ResourceStatus processPartitionResourceDTO");
    log.debug("ColumnDisplayAndPage ResourceStatus partitionResourceDTO.status "+resourceStatusDTOMap.partitionStatus);
    log.trace("ColumnDisplayAndPage ResourceStatus "+ resourceStatusDTOMap.remainingAllDouble);
    log.trace("ColumnDisplayAndPage ResourceStatus "+ resourceStatusDTOMap.usedAllDouble);
    if(resourceStatusDTOMap.partitionStatus==="READY" && resourceStatusDTOMap.resourcePeriodStatusDTOMap) {
    let resourcePeriodStatusMap = [];
      Object.keys(resourceStatusDTOMap.resourcePeriodStatusDTOMap).forEach(function(key) {
        let element = resourceStatusDTOMap.resourcePeriodStatusDTOMap[key];
        element.timePeriod=key;
        resourcePeriodStatusMap.push(element);
      });
      setResourcePeriodStatusDTOMap(resourcePeriodStatusMap);  
      setReady(true);
      setLastUpdate(new Date().getTime());
    } 
  }

  

  function page(step) {
    let newStart = startIndex+step;
    if(newStart>=0) {
      setStartIndex(newStart);
    }
  }

  function disablePage(step) {
    let newStart = startIndex+step;
    return newStart<0 || (newStart+showColsCount)>columns.length;
  }

  function getFillers() {
    let fillers = (startIndex+showColsCount)-columns.length;
    let fillerElements = [];
    if(fillers>0)
    {
      while(fillers>0) {
        fillers--;
        fillerElements.push(
            <Grid item  xs={4}>
              
            </Grid> 
          )
      }
    }   
    return fillerElements;     
  }

  function create() {
    let newStart = columns.length+1-showColsCount; //add one for the new one, and 3 back (last should be filled);
    setStartIndex(newStart);
    createElement();
  }

  function getSize(variant) {
    /*<Grid key={index} item  xs={4} sm={3} md={2} lg={1}>*/
    let size = columns.length;
      if(size==1) {
        return 12;
      } else if(size==2) {
        return 6;
      } else if(variant<2 || size==3 ) {
        return 4;
      } else if(variant<3 || size==4 ) {
        return 3;
      } else if(variant<4 || size<=6 ) {
        return 2;
      } else {
        return 1;
      }    
  }


  function getMoreMenu(value) {
    let gridContent = [];

    if(value.editTo) {
      gridContent.push({
        icon:<Edit />,
        title: "Verwalten",
        to: value.editTo, queue: value
      });
    }
    if(!value.editTo) { //editTo is only set, if resource view is used, so this is used to decide here
      gridContent.push({
        to: {pathname: '/my/groups/slotmanager/settings', vQueue: value, backTo:'/my/groups/slotmanager'},
        title: t("q_settings"),               
        text: t("q_settings"),
        icon: <Settings />
      });
    }
    return gridContent;
  }

  return (
    <Fragment>
    { 
      log.debug("render !")
    }
     { !ready &&
     
      <Grid container spacing={0} alignItems="center" justify="center"
          style={{position: "absolute", 
          background: "rgba(255,255,255,0.8)",
          zIndex: "100",
          height:"100%",
          padding: "10%",
          alignContent:"baseline"}} >
        <Grid item xs={12}  >
          <Grid container >
            <Grid item xs={12}  >
              <CircularProgress/>
            </Grid>
            <Grid item xs={12}  >
              <Typography variant="h5" align="center" color="textSecondary" component="p">
                {t('find_gap_waiter')}                    
              </Typography>            
            </Grid>
          </Grid>
        </Grid>
      </Grid> 
            
      }
        
    
      { <Paper elevation={0} square className={classes.root1}  style={{ position: "fixed",
            backgroundColor: "white", width:"100%", zIndex: "100", left: "0", top: place?((!manage || resources)?"4.5rem":"6rem"):"0" }}>
            <Grid container 
              direction="row"
              justifyContent="center"
              alignItems="center" >
                <Grid item xs="2">
                  <Grid container 
                        justifyContent='flex-end' >
                      <IconButton className={classes.iconButton} aria-label="back" onClick={() => page(-1)} disabled={disablePage(-1)}>
                        <SkipPrevious />
                      </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs="8">
                  <Divider className={classes.divider} orientation="vertical" />
                  { resourceStatusDTOMap && 
                    <MultiQStatusAndActionBar 
                      manage={manage} 
                      ready={ready}
                      partitionResourceDTO={resourceStatusDTOMap} 
                      group={!booking?group:null} 
                      backTo="/my/groups/slotmanager"
                      startIndex={startIndex}
                      countShow={showColsCount}
                      elements={columns}
                      resoruceStatusElement={props.resoruceStatusElement}
                      /> }
                  <Divider className={classes.divider} orientation="vertical" />    
                </Grid>       
                <Grid item xs="2">
                  <Grid container 
                        justifyContent='flex-start' >
                    <IconButton className={classes.iconButton} aria-label="back" onClick={() => page(1)}  disabled={disablePage(1)} >
                      <SkipNext />
                    </IconButton>
                  </Grid>
                </Grid>
            </Grid>
          </Paper> 
        }
          
          <Grid id="timelineAndResourcesGrid" item  xs={props.booking?1:2} md={1}>
            <Grid id="timelineAndResourcesGridContainer" container >
              
              
              <Grid id="timelineLabelGrid" item  xs={props.booking?12:4}>
                <Grid id="timelineLabelContainer" container direction="column" justifyContent="space-between" alignItems="top" style={{borderRight:"solid 1px grey"}} >
                  <Grid id="timelineLabelColumnHeader" item xs={12} style={{borderBottom:"1px solid"}} >
                    <Grid id="timelineLabelCol" container style={{height: "60px", paddingBottom: "0", padding:"10px"}}>
                      <Grid item xs={12} >
                          <Typography  variant="h2" style={{width:"100%", textAlign:"center", fontSize:"1.0rem", fontWeight:"bold"}}>
                            {t("t")}
                          </Typography>                          
                      </Grid>
                      <Grid item xs={12} >
                        <Grid container >
                          <Grid item xs={12} >
                          
                          </Grid>  
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid id="timelineLabelColumnContent" item xs={12} style={{position: 'relative', padding: "0", paddingLeft: "-5px", width:"100vw"}} >
                    { resourceStatusDTOMap && <TimelineMatrix timelineLabel={true}
                      partitionResourceDTO={resourceStatusDTOMap}
                      from={new Date(from+offsetFrom)} 
                      to={new Date(from+offsetTo)}  /> }
                  </Grid>
                </Grid>
              </Grid>   

              <Grid id="timelineResourcesGrid" item  xs={props.booking?0:8}>
                <Grid id="timelineResourcesContainer" container direction="column" justifyContent="space-between" alignItems="top" style={{display:props.booking?"none":"inherit"}} >
                  <Grid id="resourcesColumnHeader" item xs={12} style={{borderBottom:"1px solid"}}>
                    <Grid id="resoruceCylcesCol" container style={{height: "60px", paddingBottom: "0", padding:"10px"}}>
                      <Grid item xs={12} >
                        <Typography  variant="h2" style={{width:"100%", textAlign:"center", fontSize:"1.0rem", whiteSpace: "nowrap", fontWeight:"bold"}}>
                                {t("r")}
                        </Typography>    
                      </Grid>
                      <Grid item xs={12} >
                        <Grid container >
                          <Grid item xs={12} >
                            { !resources && AuthorizationUtil.isManager(group) &&
                              <Link to={{pathname: '/my/groups/resources', vPlace:place, vGroup:group, backTo:{pathname: '/my/groups/slotmanager', vPlace:place, vGroup:group, vManage:manage}}}>
                                <Visibility />
                              </Link>    
                            }   
                            { resources &&
                                <Fragment> <div style={{visibility:"hidden"}}><Visibility /></div> </Fragment> 
                            }
                          </Grid>  
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid id="resourcesColumnContent" item xs={12} style={{position: 'relative', paddingTop: "0", paddingLeft: "0", paddingRight: "0px", overflow: "hidden"}} >
                    { resourcePeriodStatusDTOMap && <ResourcesUsage from={from} to={to} resourcePeriodStatusDTOMap={resourcePeriodStatusDTOMap} offsetFromCallback={setOffsetFrom} offsetToCallback={setOffsetTo}/> }
                  </Grid>
                </Grid> 
              </Grid> 
            


            </Grid>
          </Grid>
          
          <Grid item  xs={props.booking?11:10} md={11}>
            <Grid container >
                { from>0 &&  viewportHeight>0 && offsetTo>0 && columns.map((value, index) => (
                      <Fragment>
                        { !unmounted && index>=startIndex && index<(startIndex+showColsCount) &&
                        
                        <Grid key={index} item  xs={getSize(1)} sm={getSize(2)} md={getSize(3)} lg={getSize(4)}>
                          <Grid container direction="column" justifyContent="space-between" alignItems="top" style={{backgroundColor:(index%2==0)?"lightgrey":"lightblue"}} >
                              <Grid  id={"slotmanagerColumnHeader"+index} item xs={12} style={{borderBottom:"1px solid", borderLeft: "1px solid"}}>
                                <Grid id={"slotmanagerColumnHeaderContainer"+index} container style={{height: "60px", paddingBottom: "0", padding:"10px"}}>
                                  <Grid item xs={10} >
                                      <Typography  variant="h2" style={{width:"100%", textAlign:"center", fontSize:"0.8rem", whiteSpace: "nowrap", fontWeight:"bold", overflow:"hidden"}}>
                                        {value.name} ({value.id})
                                      </Typography>  
                                  </Grid>
                                    
                                  <Grid item xs={2} >
                                      {
                                        AuthorizationUtil.isManager(group) && <MoreMenu iconStyle={{fontSize:"2rem",marginTop:"-0.5rem",marginRight:"-1.5rem"}} plainItems={
                                          getMoreMenu(value)
                                        } />                
                                      }                       
                                  </Grid>
                                  { props.booking && <Grid item xs={12} >
                                      <Typography  variant="h2" style={{width:"100%", textAlign:"center", fontSize:"0.8rem", whiteSpace: "nowrap", fontWeight:"bold", overflow:"hidden"}}>
                                        <Crumbs group={value.group}/>
                                      </Typography>                            
                                    </Grid>
                                  }
                                  { !value.editTo &&
                                    <Grid item xs={12} id="categoryBadges">
                                      <CategoryBadges key="badgesPerColumn" queue={value} />
                                    </Grid>
                                  } 
                                </Grid> 
                              </Grid>
                              {
                              resources &&
                              <Grid id="resourcesModeColumn" container  >
                                  { value.entries && 
                                  <Grid item xs={12} style={{height:viewportHeight+"px",overflow: "hidden"}}>
                                    <Grid container>
                                      <Grid item xs={12} style={{marginTop:"-"+(offsetFrom/1000/60*6/3)+"px"}}>
                                        {value.entries}
                                      </Grid>
                                    </Grid>
                                  </Grid> }
                                  { !value.entries && 
                                    <Grid item xs={12} style={{height:viewportHeight+"px",overflow: "hidden"}}>
                                        {value.info}
                                    </Grid>
                                  }
                                </Grid>
                              }
                              { !resources && // offsetFrom>0 && // lastUpdate>0 &&
                                <Fragment>
                                  <TicketsListLoader 
                                    key={"colDisplayTicketListCal"+index}
                                    offsetFrom={offsetFrom} 
                                    offsetTo={offsetTo} 
                                    partitionResourceDTO={resourceStatusDTOMap}
                                    createTicketLink={props.createTicketLink} 
                                    queue={value} 
                                    from={from} 
                                    filters={filters}
                                    to={to}  
                                    backTo="multiq"  
                                    manage={manage || AuthorizationUtil.isManager(value)} 
                                    lastUpdate={lastUpdate}
                                    readyStatusCallback={setReady}>
                                  </TicketsListLoader>
                                  { manage && <TicketAlarm topic={"/topic/queues/"+value.id} queue={value}  
                                  from={from} group={group} /> }
                                </Fragment> 
                              }
                            </Grid> 
                          </Grid> 
                        }
                      </Fragment>
                    ))
                  }
                  {
                    getFillers()
                  }
            </Grid>
          </Grid>
      
    </Fragment>

  );
}
