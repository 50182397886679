import React, { Component, Fragment } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import { MessageContext } from '../../pages/assets/MessageProvider';

import Button from '@material-ui/core/Button';
import { t } from 'i18next';


class ButtonUpdateState extends Component {
  static contextType = MessageContext

  state = { ticket: null, successMessage: null }
  
  /*componentWillMount() {
    this.setState({
      ticket: this.props.ticket
    });      
  }*/

  componentDidUpdate() {
    if(this.state.message) {
      this.setState({ 
        successMessage: null,
        ticket: this.props.ticket
      });
    }
  }

  save() {
    const url = config.get('vqueueUrl')+'/api/ticketstate';
    this.props.ticket.primaryStatus=this.props.primaryStatus;
    this.props.ticket.secondaryStatus=this.props.secondaryStatus;
    axios.put(url,  this.props.ticket )
      .then(res => {
        
      }).catch(function (error) {
        console.log(error);
        this.context.addMessage(t("ticket_updated"));
      }) 
      this.context.addMessage(this.props.message);  
      if(this.props.onClick) {
        this.props.onClick(this.props.ticket);
      }
  }

  render() {
    return (
      <Fragment>
        { 
          this.props.custom &&
          <span onClick={() => {
            this.save();                
          }}>
             { this.props.custom }
          </span>
        }
        { 
          !this.props.custom &&
          <Button style={{width:'100%', margin: "0", padding: "0"}} variant="contained" color={this.props.color} variant={this.props.variant} onClick={() => {
            this.save();                
          }}  >{this.props.title}</Button>
        }
      </Fragment>
    );
  }
}


export default ButtonUpdateState;