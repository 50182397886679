import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputBase, Slide } from '@material-ui/core';
import { MessageContext } from './MessageProvider';
import { t } from 'i18next';





export default function  VRAlert() { 
  const { message } = useContext(MessageContext);
  const { modal } = useContext(MessageContext);
  const { title } = useContext(MessageContext);
  const { removeMessage } = useContext(MessageContext);
  
  const [ alertTimer, setAlertTimer ] = useState()
  const [ visible, setVisible ] = useState(false)
  
  useEffect(() => {    // Update the document title using the browser API 
    if(message) {
        console.log("Message: "+message)
        setVisible(false);
        if(alertTimer) {
          clearInterval(alertTimer);
        }
        if(!modal) {
        let interval = setInterval(() => {
            closeAlert();
          }, 5000);
          setAlertTimer(interval);
        }
        showAlert();     
    } 
  }, [message] )

  
  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  function showAlert() {
   setVisible(true);
  }

  function closeAlert() {
      setVisible(false);
      removeMessage(); 
  }

    return (
      <Fragment>
        { message && !modal &&
          <Fragment>
            
            <div>
              <Snackbar 
                open={visible}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                <Alert color={message.type?message.type:"success"} toggle={closeAlert}>
                {message}
              </Alert>
              </Snackbar>
            </div>
          </Fragment>
        }
        { message && modal &&
          <Fragment>
            
            <div>
              <Dialog
                open={visible}
                onClose={closeAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{title?title:t("alert_modal_hint")}</DialogTitle>
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeAlert} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Fragment>
        }
      </Fragment>
    );
}
