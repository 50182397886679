import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { AppBar, Dialog, Fab, MenuItem, Slide, Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Attachment, AttachmentOutlined, Category, CloudDownload, Delete, HighlightOff, InsertDriveFile, InsertPhoto } from '@material-ui/icons';
import LocationData from '../pages/assets/LocationData';
import CardGrid from '../pages/assets/CardGrid';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';
import AuthorizationUtil from '../authorization/AuthorizationUtil';
import UploadAttachements from './UploadAttachements';
import UploadFiles from './upload-files.component';
import CloseIcon from '@material-ui/icons/Close';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Attachements(props) {
  const {t}= props;
  const classes = useStyles();
  const { ticket } = props;
  const { domain } = props;
  const { queue } = props;
  
  
  

  const [attachements, setAttachements] = useState([]);
  const [attachementCount, setAttachementCount] = useState(0);
  
 

  const { addMessage } = useContext(MessageContext)
  
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {   
    if(ticket) {
      loadAttachements();
      loadAttachementCount();
    }
  }, [ticket] )


  function successCallback() {
    loadAttachements();    
    loadAttachementCount();
  }

  function loadAttachements() {
    let uri = "/api/attachements/ticket/"+ticket.id;
    const url = config.get('vqueueUrl')+uri;
    
    axios.get(url )
        .then(res => {
          setAttachements(res.data)
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        })   
  }
  
  function loadAttachementCount() {
    let uri = "/api/attachements/count/ticket/"+ticket.id;
    const url = config.get('vqueueUrl')+uri;
    
    axios.get(url )
        .then(res => {
          setAttachementCount(res.data);
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        })   
  }

  function getMenuItems(mAttachement) {
    let menuItems = [];
    let id = mAttachement.id;
    menuItems.push(
        {   action: deleteAttachement ,
            icon: <IconButton   color="inherit" aria-label="close">
                      <Delete  />
                  </IconButton>,
            entity: mAttachement
        }
      )
    return menuItems; 
  }

  function getIconType(attachement) {
    let mimeType = attachement.mimeType;
    let icon = <InsertDriveFile />;
    if(mimeType.indexOf("image")>-1) {
      icon = <InsertPhoto />;
    }
    return icon;
  }

  function getGridContent() {
    let gridContent = [];
    attachements.forEach(function (aAttachement, index) {
      let entry =  {
        title: <span className="label" style={{whiteSpace: "nowrap"}}>{aAttachement.name}</span>  ,
        text: <IconButton attachementid={aAttachement.id} attachementfilename={aAttachement.name} onClick={(e) => downloadAttachement(e)} style={{width: "100%", margin: "-10px"}}>
                <CloudDownload style={{fontSize:"3rem"}} />
              </IconButton>,
        plainItems: getMenuItems(aAttachement),
        cardNumberStyle: {},
        number: <Fragment> {getIconType(aAttachement)}</Fragment>,
        cardStyle:{height: "18.5rem"},
        object: aAttachement
      };
      gridContent.push(entry);
    });
    return gridContent;
  }

  //onClick events from lists have values stored in attributes
  function deleteAttachement(e) {
    let id = e.currentTarget.attributes.entity.value;
    let url = config.get('vqueueUrl');
    url = url+'/api/attachements/'+id;;

    axios.delete(url )
        .then(res => {
          loadAttachements(); 
          loadAttachementCount();
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function downloadAttachement(e) {
    let id = e.currentTarget.attributes.attachementid.value;
    let fileName = e.currentTarget.attributes.attachementfilename.value;
    let url = config.get('vqueueUrl');
    url = url+'/api/attachements/download/'+id;
    
    axios.get(url, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      } )
    .then (response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
  })
  }

  
  return (

    <Fragment>
        
      <IconButton   color="inherit" onClick={handleClickOpen} aria-label="close">
        <Badge badgeContent={attachementCount} color="primary">
          <AttachmentOutlined />
        </Badge>
      </IconButton>
        
        

        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
            <Typography component="h1" variant="h5" edge="start" className={classes.title}>
                Anlagen
              </Typography>
              <IconButton   color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  {
                    ticket && <UploadAttachements ticket={ticket} successCallback={successCallback}/>                
                  }
                </Grid>  <Grid item xs={12}>
                  <CardGrid gridContent={getGridContent()}/>
                </Grid>
                            
              </Grid>
          </Container>
        </Dialog>
    </Fragment>

    
  );
}

export default withTranslation()(Attachements);