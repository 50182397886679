import { DehazeOutlined } from '@material-ui/icons';
import ls, { set } from 'local-storage'

var SessionContext = (function() {
    var place = null;
    var queue = null;
    var manageMode = false;
    var walletMode = false;
    var myStorage = window.sessionStorage;

    var clear = function() {
      ls.clear();
      sessionStorage.clear();
    }
    
    var clearQ = function() {
      /* ls.remove('myQueues');
      sessionStorage.removeItem('myQueues');   */
      
      ls.remove('queue');   
      sessionStorage.removeItem('queue');  
      
    }
    
    var getClientId = function() {
      return ls.get('clientId');   
    };
  
    var setClientId = function(clientId) {      
        ls.set('clientId', clientId);        
    };

    var getCreatorUuid = function() {
      return ls.get('creatorUuid') || null;   
    };
  
    var setCreatorUuid = function(creatorUuid) {
      ls.set('creatorUuid', creatorUuid);  
    };

    // Persists the current time scope FROM 
    var setShowTicketsFrom = function(ticketsFrom) {
      sessionStorage.setItem('ticketsFrom', ticketsFrom);  
      ls.set('ticketsFrom', ticketsFrom);  
    }

    var getShowTicketsFrom = function() {
      let ticketsFrom = sessionStorage.getItem('ticketsFrom');
      if(ticketsFrom==null) {
        ticketsFrom = ls.get('ticketsFrom') || null;  
      }
      return ticketsFrom!=null?parseInt(ticketsFrom,10):ticketsFrom; 
    }

    // Persists the current time scope TO
    var setShowTicketsTo = function(ticketsTo) {
      sessionStorage.setItem('ticketsTo', ticketsTo);  
      ls.set('ticketsTo', ticketsTo);  
    }

    var getShowTicketsTo = function() {
      let ticketsTo = sessionStorage.getItem('ticketsTo');
      if(ticketsTo==null) {
        ticketsTo = ls.get('ticketsTo') || null;  
      }
      return ticketsTo!=null?parseInt(ticketsTo,10):ticketsTo; 
    }

    var getNextTarget = function() {
      let nextTarget = sessionStorage.getItem('nextTarget');
      if(nextTarget) {
        nextTarget = JSON.parse(nextTarget);
      }
      if(nextTarget==null) {
        nextTarget = ls.get('nextTarget') || null;  
      }
      return nextTarget;   
    };
  
    var setNextTarget = function(nextTarget) {
      sessionStorage.setItem('nextTarget', JSON.stringify(nextTarget));  
      ls.set('nextTarget', nextTarget);  
    };
  
    var getToken = function() {
      let authToken = sessionStorage.getItem('authToken');
      if(authToken) {
        authToken = JSON.parse(authToken);
      }
      if(authToken==null) {
        authToken = ls.get('authToken') || null;  
      }
      return authToken;   
    };
  
    var setUser = function(user) {
      sessionStorage.setItem('user', JSON.stringify(user));  
      ls.set('user', user);  
    };
  
    var getUser = function() {
      let user = sessionStorage.getItem('user');
      if(user) {
        user = JSON.parse(user);
      }
      if(user==null) {
        user = ls.get('user') || null;  
      }
      return user;   
    };
  
    var setToken = function(authToken) {
      sessionStorage.setItem('authToken', JSON.stringify(authToken));  
      ls.set('authToken', authToken);  
    };
  
    var getActiveQueue = function() {
      let aQueue = sessionStorage.getItem('queue');
      if(aQueue==null) {
        aQueue = ls.get('queue') || null;  
      }
      if(aQueue) {
        aQueue = JSON.parse(aQueue);
      }
      return aQueue;   
    };
  
    var setActiveQueue = function(queue,permanent) {
      console.log("setActiveQueue " + queue.id);
      //queue.currentTicket = getActiveTicketForQueue(queue); //BACKDRAW here: if someone has more than one active ticket, only the first one found will be set here.

      let exists = getFavourites().filter((aQueue) => {
        return aQueue.id==queue.id
      })
      if(exists && exists.length) {
        addFavourite(queue);
      }
      sessionStorage.setItem('queue', JSON.stringify(queue));  
      ls.set('queue', JSON.stringify(queue));  
      return queue;
    };
    var getGroup = function() {
      return ls.get('group') || null;  
    };
  
    var setGroup = function(group) {
      ls.set('group', group);  
    };

    var getKind = function() {
      return ls.get('kind') || null;  
    };
  
    var setKind = function(kind) {
      ls.set('kind', kind);  
    };

    var getKindId = function() {
      return ls.get('kindId') || null;  
    };
  
    var setKindId = function(kindId) {
      ls.set('kindId', kindId);  
    };
  
    var getBookingOptions = function() {
      return ls.get('bookingOptions') || null;  
    };
  
    var setBookingOptions = function(bookingOptions) {
      ls.set('bookingOptions', bookingOptions);  
    };
  
    var getPlace = function() {
      return ls.get('place') || null;  
    };
  
    var setPlace = function(place) {
      ls.set('place', place);  
    };
  
    var getResource = function() {
      return ls.get('resource') || null;  
    };
  
    var setResource = function(resource) {
      ls.set('resource', resource);  
    };
  
    var getResourceWorkingHour = function() {
      return ls.get('resourceWorkingHour') || null;  
    };
  
    var setResourceWorkingHour = function(resourceWorkingHour) {
      ls.set('resourceWorkingHour', resourceWorkingHour);  
    };
  
    var setCategory = function(category) {
      ls.set('category', category);  
    };
  
    var getCategory = function() {
      return ls.get('category') || null;  
    };
  
    var getResourceVariant = function() {
      return ls.get('resourceVariant') || null;  
    };
  
    var setResourceVariant = function(resourceVariant) {
      ls.set('resourceVariant', resourceVariant);  
    };
    
    
    var getTicket = function() {
      return ls.get('ticket') || {};  
    };
  
    var setTicket = function(ticket) {
      ls.set('ticket', ticket);  
    };
  
    var getSlot = function() {
      return ls.get('slot') || null;  
    };
  
    var setSlot = function(slot) {
      ls.set('slot', slot);  
    };
  
    var getDomain = function() {
      return ls.get('domain') || null;  
    };
  
    var setDomain = function(domain) {
      ls.set('domain', domain);  
    };
  
    var isManageMode = function() {
      return ls.get('manageMode') || null;  
    };
  
    var setManageMode = function(manageMode) {
      ls.set('manageMode', manageMode);  
    };
  
    var isWalletMode = function() {
      return ls.get('walletMode') || null;  
    };
  
    var setWalletMode = function(walletMode) {
      ls.set('walletMode', walletMode);  
    };
  
    

    var getMyTickets = function() {
      var myTickets =  ls.get('myTickets') || [];
      if(Array.isArray(myTickets)) {
        return myTickets;
      } 
      return JSON.parse(myTickets);
    }

    //should always return one for each q
    var getActiveTickets = function() {
      var myActiveTickets = getMyTickets().filter((aTicket) => {
        return aTicket.primaryStatus!="FINALIZING";
      })
      return myActiveTickets;
    }

    var getQueueOfTicket = function(ticket) {
      for (const queue of getFavourites()) {
        if(ticket.queueId==queue.id) {
          return queue;
        }
      }      
      return null;
    }

    var setMyTickets = function(myTickets) {
      ls.set('myTickets', JSON.stringify(myTickets));
    }

    var removeMyTicket = function(removeTicket) {
      console.log("removeTicket "+removeTicket.uid);
      var myTickets = getMyTickets().filter((aTicket) => {
        return aTicket.uid!=removeTicket.uid
      })
      setMyTickets(myTickets);
    }


    var setFavourites = function(favourites) {
      ls.set('favourites', JSON.stringify(favourites));
    }

    var getFavourites = function() {
      var myFavorites =  ls.get('favourites') || [];
      if(Array.isArray(myFavorites)) {
        return myFavorites;
      } 
      return JSON.parse(myFavorites);
    }

    var removeFavourite = function(removeFavourite) {
      console.log("removeFavourite "+removeFavourite.id);
      var favourites = getFavourites().filter((aFavourite) => {
        return aFavourite.id!=removeFavourite.id
      })
      setFavourites(favourites);
    }

    var addFavourite = function(removeFavouriteQ) {
      console.log("addFavourite "+removeFavouriteQ.id);
      removeFavourite(removeFavouriteQ);
      var favourites = getFavourites();
      favourites.unshift(removeFavouriteQ);
      setFavourites(favourites);
    }

    



    var getAndRemoveQueueByTicketIdIn = function(ticketUid, queues) {
      let theQueue = queues.find((aQueue) => {
        return aQueue.currentTicket!=null && aQueue.currentTicket.uid === ticketUid;
      })
      if(theQueue) {
        return theQueue;
      }  else  {
        console.log("No queue for this ticketUid found: "+ticketUid);      
      }
      
    }

    

    var addTicket = function(queue, ticket) {
      if(!queue || (ticket.queueId && ticket.queueId!=queue.id)) {
        return queue;
      }
      console.log("addTicket "+ticket.uid+" to " + queue.id);
      removeMyTicket(ticket);
      ticket.queueId=queue.id;
      let tickets = getMyTickets();
      tickets.unshift(ticket);
      setMyTickets(tickets);
      ticket.queue=null; // to avoid circular error when storing to local storage
      queue.currentTicket = ticket;
      if(getActiveQueue()) {
        setActiveQueue(queue);
      }
      addFavourite(queue);
      return queue;
    }

    var refreshTicket = function(ticket) {
      console.log("refreshTicket "+ticket.id);

      let favourites = getFavourites();
      let theQueue = getAndRemoveQueueByTicketIdIn(ticket.uid,favourites);
      setFavourites(favourites);
      if(theQueue) {
        theQueue.currentTicket = ticket;
        addFavourite(theQueue);
        ticket.queueId=theQueue.id;
        removeMyTicket(ticket);
        let tickets = getMyTickets();
        tickets.unshift(ticket);
        setMyTickets(tickets);   
        return theQueue;
      } else {
        console.log("refreshTicket - can not find a favourites for ticket "+ticket.id);      
      }
         
      
    }

    var removeTicket = function(queue) {
      console.log("removeTicket from " + queue.id);
      removeFavourite(queue);
      queue.currentTicket = null;
      setActiveQueue(queue);
      queue = addFavourite(queue);
      return queue;
    }
    
    var getActiveTicket = function() {
      var queue = getActiveQueue();
      console.log("getActiveTicket from " + queue.id);
      let activeTicket = queue.currentTicket;
      if(!activeTicket) {
        activeTicket=getActiveTicketForQueue(queue)
      }
      return activeTicket;
    }
    
    var getQueuesWithActiveTickets = function(queues) {
      let res = populateQueuesWithActiveTickets(queues)
      res = res.filter((aQueue) => {
        return aQueue.currentTicket
      }) 
      return res;
    }
    


    var populateQueuesWithActiveTickets = function(queues) {
      let res = []
      for (const q of queues) {
        let ticket = getActiveTicketForQueue(q);
        q.currentTicket=ticket;
        res.push(q);
      }      
      return res;
  }


    var getActiveTicketForQueue = function(queue) {
        let theTicket = null;
        for (const ticket of getMyTickets()) {
          if(ticket.queueId==queue.id && (ticket.primaryStatus=="TIMESLOT" || ticket.primaryStatus=="PREPARING" || ticket.primaryStatus=="WAITING" || ticket.primaryStatus=="PROCESSING")) { 
            theTicket = ticket;
          } 
        } 
        return theTicket;
    }

    
    var isLoggedIn = function() {
      return getToken()!=null && getToken().headers!=null && getUser()!=null;
    }
    
    var getQSettingsOrigin = function() {
      var origin =  ls.get('qSettingsOrigin') || [];
      return origin;
    }
    var setQSettingsOrigin = function(origin) {
      ls.set('qSettingsOrigin', origin);
    }
    
    var getResorucesOrigin = function() {
      var origin =  ls.get('resourcesOrigin') || [];
      return origin;
    }
    var setResorucesOrigin = function(origin) {
      ls.set('resourcesOrigin', origin);
    }

    var getRequestResponse = function(url) {
      var response =  ls.get(url) || null;
      return response;
    }
    var setRequestResponse = function(url, response) {
      ls.set(url, response);
    }
    var getOffline = function() {
      var response =  ls.get("offline") || false;
      return response;
    }
    var setOffline = function(offline) {
      ls.set("offline", offline);
    }

    var getBackTo = function() {
      var backTo =  ls.get("backTo") || "";
      return backTo;
    }
    var setBackTo = function(backTo) {
      ls.set("backTo", backTo);
    }

    let refreshQueues = [];

    var addRefreshQueue = function(queueId) {
      refreshQueues.push(queueId);
    }

    var removeRefreshQueue = function(queueId) {
      refreshQueues = refreshQueues.filter((aQueue) => {
        return aQueue.id!=queueId
      })
    }

    var isRefreshQueue = function(queueId) {
      var exists = refreshQueues.filter((aQueue) => {
        return aQueue.id==queueId
      })
      return exists.length>0;
    }

    return {
      addRefreshQueue : addRefreshQueue,
      removeRefreshQueue : removeRefreshQueue,
      isRefreshQueue : isRefreshQueue,
      getBackTo : getBackTo,
      setBackTo : setBackTo,
      getKind: getKind,
      setKind: setKind,
      getKindId: getKindId,
      setKindId: setKindId,
      getBookingOptions: getBookingOptions,
      setBookingOptions: setBookingOptions,
      getOffline: getOffline,
      setOffline: setOffline,
      getResorucesOrigin: getResorucesOrigin,
      setResorucesOrigin: setResorucesOrigin,
      getQSettingsOrigin: getQSettingsOrigin,
      setQSettingsOrigin: setQSettingsOrigin,
      setUser: setUser,
      getUser: getUser,
      setNextTarget: setNextTarget,
      getNextTarget: getNextTarget,
      isLoggedIn: isLoggedIn,
      getToken: getToken,
      setToken: setToken,
      addFavourite: addFavourite,
      getFavourites: getFavourites,
      getQueueOfTicket: getQueueOfTicket,
      removeFavourite: removeFavourite,
      setFavourites: setFavourites,
      addTicket: addTicket,
      getMyTickets: getMyTickets,
      setMyTickets: setMyTickets,
      getActiveTickets: getActiveTickets,
      removeTicket: removeTicket,
      refreshTicket: refreshTicket,
      getActiveTicket: getActiveTicket,
      getActiveTicketForQueue: getActiveTicketForQueue,
      populateQueuesWithActiveTickets: populateQueuesWithActiveTickets,
      getQueuesWithActiveTickets: getQueuesWithActiveTickets,
      getActiveQueue: getActiveQueue,
      setActiveQueue: setActiveQueue,
      getPlace: getPlace,
      setPlace: setPlace,
      getGroup: getGroup,
      setGroup: setGroup,
      getCategory: getCategory,
      setCategory: setCategory,
      getResource: getResource,
      setResource: setResource,
      getResourceWorkingHour: getResourceWorkingHour,
      setResourceWorkingHour: setResourceWorkingHour,
      getResourceVariant: getResourceVariant,
      setResourceVariant: setResourceVariant,
      setDomain: setDomain,
      getTicket: getTicket,
      setTicket: setTicket,
      getSlot: getSlot,
      setSlot: setSlot,
      getDomain: getDomain,
      isWalletMode: isWalletMode,
      setWalletMode: setWalletMode,
      isManageMode: isManageMode,
      setManageMode: setManageMode,
      getCreatorUuid: getCreatorUuid,
      setCreatorUuid: setCreatorUuid,
      getClientId: getClientId,
      setClientId: setClientId,
      clear: clear,
      clearQ: clearQ,
      setRequestResponse: setRequestResponse,
      getRequestResponse: getRequestResponse,
      setShowTicketsFrom,
      getShowTicketsFrom,
      setShowTicketsTo,
      getShowTicketsTo,
  }

})();
  
  export default SessionContext;