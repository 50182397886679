import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Fab, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined } from '@material-ui/icons';



export default function LocationData(props) {
  const { location } = props;
  const [ showMore, setShowMore ] = useState(false)


  const handleClick = (event) => {
    setShowMore(!showMore?event.currentTarget:undefined);
  }


  return (
    
     
    <Fragment> 
      
            <Grid item xs={12}  >
              <Grid container spacing={0} alignItems="left" justify="left" >
                <Grid item xs={12}  >
                  <Grid container spacing={0} alignItems="left" justify="left" >
                    <Typography  variant="body1" >
                      <span >{location.decription}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            { !showMore && location.address && <Fragment>
                
                <Grid xs={12} justify="center" >
                  <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%"}}  onClick={handleClick} >
                      <Avatar style={{backgroundColor:"white", border:"1px solid"}}>
                        <ExpandMoreOutlined style={{fontSize:"2rem", color:"black"}} />
                      </Avatar>
                    </IconButton>
                  
                </Grid>
              </Fragment>
            }
            {showMore && location.address && 
              <Fragment>
                 <Grid xs={12} justify="center" >
                    <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%"}}  onClick={handleClick} >
                      <Avatar style={{backgroundColor:"white", border:"1px solid"}}>
                        <ExpandLessOutlined style={{fontSize:"2rem", color:"black"}} />
                      </Avatar>
                    </IconButton>                  
                </Grid>
                <Grid item xs={12}  >
                  <Grid container spacing={1} alignItems="left" justify="left" >
                    { location.address && 
                    <Grid item xs={12}  >
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.company && location.address.company}
                      </Typography>
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.firstName}                        
                        {location.address.lastName && <Fragment>&nbsp;{location.address.lastName}</Fragment>}
                      </Typography>
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.additional && location.address.additional}
                      </Typography>
                      
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.street && location.address.street}
                        &nbsp;
                        {location.address.number && location.address.number}
                      </Typography>
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.zip && location.address.zip}
                        {location.address.city && <Fragment>&nbsp;{location.address.city}</Fragment>}
                      </Typography>
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.country && location.address.country}
                      </Typography>
                      
                      <Typography  variant="body1" style={{width:"100%"}}>
                        {location.address.lon && location.address.lon}
                        {location.address.lat && <Fragment>&nbsp;-&nbsp;{location.address.lat}</Fragment>}                        
                      </Typography>
                    </Grid>  
                    }                  
                  </Grid>
                </Grid>
              </Fragment>
            }    
    </Fragment>    
  );
}