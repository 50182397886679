import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import NavigationGrid from './assets/NavigationGrid';
import SessionContext from '../frame/SessionContext';
import { withTranslation } from 'react-i18next';


class Settings extends Component {
  
  constructor() {
    super();
  }
    getAreas() {
    const {t} = this.props;
    this.areas = [
      {
        title: t("my_account"),
        text: t("account_management"),
        link:"/myAccount"
      },
      {
        title: t("global_settings"),
        text: t("global_settings_text"),
        link:"/globalSettings"
      },
      {
        title: t("rate_view"),
        text: t("rate_view_text"),
        externalLink:"https://viwaq.de/preise-und-kosten/"
      }      
    ];
    return this.areas;
    };
  


  cleanBrowserStorage() {
    ls.clear();    
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <DeskHeader title={t("settings")}  backTo="/" />
          <Container maxWidth="xs"  style={{position: 'relative', marginTop:"80px", marginBottom:"100px"}}>
            <NavigationGrid gridContent={this.getAreas()} />
          </Container>
      <AppFooter />
          
    </div>
      
    );
  }
}

export default (withTranslation()(Settings));