import React, { Fragment, useContext, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import config from 'react-global-configuration';
import SockJsClient from 'react-stomp';
import SessionContext from '../../frame/SessionContext';
import { MessageContext } from './MessageProvider';

import axios from 'axios';
import TicketData from './TicketData';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import TicketDataUtil from './TicketDataUtil';
import Card from './Card';
import { Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from '@material-ui/core';
import Room from '../../queue/Room';
import { Button } from 'reactstrap';
import WebsocketUtil from '../../authorization/WebsocketUtil';
import log from 'loglevel';

export default function TicketAlarm(props) {
  const { topic } = props;
  const { queue } = props;
  const { from } = props;
  const { group } = props;
  
  const { myTicketsUpdated } = useContext(MessageContext);
  const { setMyTicketsUpdated } = useContext(MessageContext);
  const { wsTickets } = useContext(MessageContext)
  const { removeWsTickets } = useContext(MessageContext)
  const { setTicket } = useContext(MessageContext)
  const { addModalMessage } = useContext(MessageContext);
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState({});
        
  const [ aTicket, setATicket ] = useState(null);
  const history = useHistory();

  const websocketQClients = [];

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {    // Update the document title using the browser API 
    init();
    return () => { cleanup(); };
  }, [] )

  useEffect(() => {    // Update the document title using the browser API 
    log.debug("TicketAlarm useEffect wsTicket "+wsTickets);
    if(wsTickets) {
      log.debug("TicketAlarm useEffect wsTicket "+wsTickets.length);
      if(wsTickets.length===1) {
        log.debug("TicketAlarm useEffect only one Ticket, process directly");
        processMessage(wsTickets[0]);
        removeWsTickets();
      }
    }
    return () => { };
  }, [wsTickets] )

  
  
  
function cleanup() {
  log.debug("cleanup ");
  for (const websocketQClient of websocketQClients) {
    log.debug("disconneting a ws client: "+websocketQClient.topic);
    websocketQClient.disconnect(() => {});
  }
}

  function init() {
    log.debug("Prepare Listening for Tickets in Wallet");
  if (!("Notification" in window)) {
    log.debug("This browser does not support desktop notification");
  } else {
    log.debug("Notifications are supported");
    Notification.requestPermission();
  }
  let walletTickets = SessionContext.getMyTickets();
  let relevantClientIds = [];
  if(queue) {
    /*let websocket = new WebsocketUtil();
    websocket.websocket(
      topic, 
      ()=> { log.debug("connected to "+topic); },
      processMessage);
      websocketQClients.push(websocket);*/
  } else {
      relevantClientIds.push(SessionContext.getClientId());
        for (const ticket of walletTickets) {
          if(ticket.primaryStatus!="FINALIZING") {
            let exists = relevantClientIds.filter((existingClientId) => {
              return ticket.clientId==existingClientId;
            }) 
            let now = new Date().getTime();
            if(exists.length==0 && ticket.clientId) {
              if( ticket.primaryStatus!=="FINALIZING"
                && ticket.endPlanned>now) {
                  relevantClientIds.push(ticket.clientId);
                }
            }          
          }
        } 
    
    
      for (const relevantClientId of relevantClientIds) {
        let websocket = new WebsocketUtil();
        websocket.websocket(
          topic?topic:'/topic/tickets/'+relevantClientId, 
          ()=> {log.debug("connected to "+topic);},
          processMessage);
          websocketQClients.push(websocket);
      }
    }
  }

  function triggerPartitionRefresh() {
    log.debug("triggerPartitionRefresh");
            
    let url = config.get('vqueueUrl')+'/api/resources/status/';
        
     url = url + "group/"+group.id+"/"+from; //TODO !!!!!!!!!!!!!!!!!!!!!
        
      axios.get(url )
        .then(res => {
          log.debug("Doing nothing here - refresh is triggered and response comes via websocket into ResourceStatus that re-renders everything else.  "+res.data.partitionStatus);
        }).catch(function (error) {
          log.debug(error);
          //addMessage(error.detail);
        })
  }

  function processMessage(aTicket) {
    log.trace("Websocket aTicket: " + JSON.stringify(aTicket));
    log.debug("Now processing maybe issue an alarm "+aTicket.uid+ " - "+aTicket.primaryStatus);
    if(aTicket.primaryStatus==="BLOCKER") {
      return;
    }
    let clientId = SessionContext.getClientId();
    let walletTickets = SessionContext.getMyTickets();
    let own = SessionContext.isManageMode() && (aTicket.clientId===clientId);
      let existsInWallet  = walletTickets.filter((ticket) => {
        return ticket.uid==aTicket.uid; 
      });
      let text = "";//getNotificationText(aTicket);
      log.debug("existsInWallet.length:"+existsInWallet.length+" own:"+own+" clientId:"+clientId+" aTicket.clientId:"+aTicket.clientId);
      if(existsInWallet.length>0) {
        if(aTicket.primaryStatus==='WAITING' && aTicket.startWish!==aTicket.startPlanned) {
          text="Dein Termin konnte nicht zum Wunschzeitpunkt eingeplant werden, wir haben den nächstmöglichen Zeitpunkt gebucht. ";
        } else if(aTicket.secondaryStatus==='SCHEDULE_ERROR') {
          text="Termin konnte NICHT gebucht werden.";
        } else if(existsInWallet[0].secondaryStatus!==aTicket.secondaryStatus) { //nur Popup zeigen, wenn sich der Status geändert hat. Ansonsten wird bei jedem Speichern was gezeigt.
          if(aTicket.secondaryStatus==='WAIT') {
            text="Neuigkeiten zu deiner Wartesituation.";
          } else if(aTicket.secondaryStatus==='CALL') {
            text="Es ist soweit, du bist aufgerufen.";
          } else if(aTicket.secondaryStatus==='LAST_CALL') {
            text="Jetzt aber hurtig, es geht los.";
          } else if(aTicket.secondaryStatus==='CALL_MISSEED') {
            text="Du hast deinen Termin verpasst.";
          } else if(aTicket.secondaryStatus==='EXPIRED') {
            text="Dein Termin ist abgelaufen.";
          } else if(aTicket.secondaryStatus==='RETURNED') {
            text="Dein Termin wurde storniert.";
          } else if(aTicket.secondaryStatus==='COMPLETED') {
            text="Dein Termin ist beendet.";
          }  
       }
      }
      if(existsInWallet.length>0 || own) {
        if(aTicket.secondaryStatus==='SCHEDULE_ERROR' || aTicket.secondaryStatus==='NO_SLOT_FOUND') {
          let cardContent = TicketDataUtil.convertToTicketCardContent(aTicket, aTicket.queue, null, false, false, true, false, true);
          let modalText =  <Grid  container spacing={1} >
                  <Grid item xs={12} >
                    <Card area={cardContent} queue={aTicket.queue} hideMenu={true}/>
                  </Grid>
                </Grid>

          if(aTicket.originTicket && SessionContext.isManageMode()) {
            let text = "Ein Slot aus der Serie konnte nicht geplant werden."; 
            addModalMessage(modalText, text);            
          } else if(!aTicket.originTicket) {
            let text = "Der Slot konnte nicht geplant werden - da wohl jemand anderes schneller."; 
            addModalMessage(modalText, text);
            /*let options = {
              ticket: aTicket,
              cycles: aTicket.cycles,
              selectLink: "/booking/data",
              confirmationTarget: "/my/groups/slotmanager",
              infoText: modalText
            }
            //SessionContext.setBookingOptions(options);
            history.push({pathname: "/booking/selection",  vOptions: options});         */            
          }
        } else if((aTicket.primaryStatus=='WAITING' || aTicket.primaryStatus=='FINALIZING') && !queue) {
          let cardContent = TicketDataUtil.convertToTicketCardContent(aTicket, aTicket.queue, null, false, false, true, false, true);
          let modalText =  <Grid  container spacing={1} >
                  <Grid item xs={12} >
                    <Card area={cardContent} queue={aTicket.queue} hideMenu={true}/>
                  </Grid>
                </Grid>
          addModalMessage(modalText, text);
          if(!SessionContext.isManageMode()) {
            showNotification(text, aTicket);
          }
          
        } if(aTicket.primaryStatus=='FINALIZING' && queue) {
          triggerPartitionRefresh();
        }
        
        //}
        if(!SessionContext.isManageMode()) {
          SessionContext.refreshTicket(aTicket);
        }
        log.debug("messageContext.setTicket"); 
        setTicket(aTicket);
      }
      
  }
  
  function isJSON(str) {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

  function showNotification(text, aTicket) {
      var options = {
        body: text,
        icon: "https://www.viwaq.de/wp-content/uploads/2022/01/Q-1.png",
        dir: "ltr"
      };
      Notification.requestPermission().then(function (status) {
        if (status === 'denied') {
          log.debug("Notifications are forbidden by user ! ");
        } else if (status === 'granted') {
          log.debug("Notifications are allowed by user ! ");
          var notification = new Notification(t("news_to_appointment")+" "+aTicket.id, options);
          notification.onclick = function(){
            window.location.href = "/wallet?tuid="+aTicket.uid;
          }
        }
      });
  }

  function getNotificationText(aTicket) {
    if(aTicket.secondaryStatus==='WAIT') {
      return t("your_slot")+" "+aTicket.id+" "+t("waits_now");
    } else if(aTicket.secondaryStatus==='CALL') {
      return t("your_slot")+" "+aTicket.id+" "+t("got_called");
    } else if(aTicket.secondaryStatus==='LAST_CALL') {
      return t("your_slot")+" "+aTicket.id+" "+t("runs_out_soon");
    }
  }

  return ( //TODO: In Management Queue kommen die Änderungen nicht an, da nur an ClientIDs gesendet wird - das ist falsch
    <Fragment>       
      
        <Dialog
              open={open}
              fullWidth={true}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
                { 
                  //<DialogTitle id="alert-dialog-title">Bafertigung No. 37623kjdsfh</DialogTitle> 
                }
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                     { content }           
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    {t("ah_ok")}
                  </Button>
                </DialogActions>
            </Dialog>
    </Fragment>
  );
}
