import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import AppFooter from './assets/AppFooter';
import DeskHeader from './assets/DeskHeader';
import LinkCard from './assets/LinkCard';
import NavigationGrid from './assets/NavigationGrid';
import { AccountCircle, AddBox, ConfirmationNumber, CropFree, EuroSymbol, ExitToApp, LocalActivity, MeetingRoom, Search, Settings, Subscriptions } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import Administration from './Slotmanager';
import SessionContext from '../frame/SessionContext';
import Axios from 'axios';
import config from 'react-global-configuration';
import { MessageContext } from './assets/MessageProvider';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';



class Startpage extends Component {
  
  static contextType = MessageContext

  constructor() {
    super();

  }
  
  showAlert(message) {
    const msgCtx = this.context
    msgCtx.addMessage(message);
  }

  

  componentDidMount() {
    const {t} = this.props;
    SessionContext.setDomain(null);
    const url = config.get('vqueueUrl') + '/api/queues';
    let favouriteQueues = SessionContext.getFavourites(); //should be more ALL Queues
    favouriteQueues.forEach(function (lQueue, index) {
        if (lQueue.currentTicket) {
          Axios.get(config.get('vqueueUrl') + '/api/tickets/uuid/' + lQueue.currentTicket.uid)
            .then(response => {
              console.log(t("ticket_ok")+" " + response.data.uid);
              SessionContext.addTicket(lQueue, response.data);
            }
            );
        } else {
          console.log("Local Queue has no current Ticket that could be refreshed " + lQueue.id);
        }
      });
  }


  getGridContent() {
    const {t} = this.props;
    let gridContent = [
      {
        iconLeft: <Subscriptions />,
        iconRight: <Subscriptions />,
        text: this.getText(t("booking_title"), t("suche_hint")),
        link: "/booking",
        cardNumberStyle: { backgroundColor: "white" },
        cardContentStyle: { backgroundColor: "#CDD6C1" },
        cardMenuStyle: { backgroundColor: "white" }
      },
      {
        iconLeft: <Subscriptions />,
        text: this.getText(t("eigene_verwaltung"), t("for_you_hint")),
        link: "/my",
        cardNumberStyle: { backgroundColor: "white" },
        cardContentStyle: { backgroundColor: "#CDD6C1" },
        cardMenuStyle: { backgroundColor: "white" }
      },
      {
        iconLeft: <Subscriptions />,
        text: this.getText(t("wallet_title"), t("for_you_hint")),
        link: "/wallet",
        cardNumberStyle: { backgroundColor: "white" },
        cardContentStyle: { backgroundColor: "#CDD6C1" },
        cardMenuStyle: { backgroundColor: "white" }
      },
      {
        iconLeft: <MeetingRoom />,
        text: this.getText(t("suche_title"), t("suche_hint")),
        link: "/search",
        cardNumberStyle: { backgroundColor: "white" },
        cardContentStyle: { backgroundColor: "#CDD6C1" },
        cardMenuStyle: { backgroundColor: "white" }
      }
    ];
    if (SessionContext.isLoggedIn()) {
      let manageContent = [];
      gridContent = gridContent.concat(manageContent);
    }
    return gridContent;
  }

  getText(title, hint) {
    return <Fragment>
      <Grid item xs={12}  >
        <Grid container spacing={0} alignItems="center" justify="center" style={{ textAlign: "center" }}>
          <Typography variant="body1" style={{ width: "100%", fontSize: "0.9rem", whiteSpace: "nowrap", fontWeight: "normal", textDecoration: "underline" }}>
          {hint}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}  >
        <Grid container spacing={0} alignItems="center" justify="center" >
          <Typography variant="body1" style={{ fontSize: "2rem", fontWeight: "bold" }}>
            <span >{title}</span>
          </Typography>
        </Grid>
      </Grid>     
    </Fragment>
     
  }

  render() {
    const { t } = this.props;
    
    return (
      
      <div>
        <DeskHeader title={t("begin")} />
        <Container maxWidth="xs" style={{ position: 'relative', marginTop: "90px", marginBottom: "100px" }}>
          <NavigationGrid gridContent={this.getGridContent()} />          
        </Container>     
        <AppFooter />

      </div>
      
    );
  }
}

// export default Startpage;
export default (withTranslation()(Startpage));