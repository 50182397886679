import React, { Component }  from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import config from 'react-global-configuration';
import { useTranslation } from 'react-i18next';
import DateTimeDisplay from '../pages/assets/DateTimeDisplay';
import { Link } from 'react-router-dom';
import { AccessAlarm, Edit } from '@material-ui/icons';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  badge: {
    textAlign: "left",
    display: "block"
  },
}));

export default function SlotsDisplay(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { queue } = props
  const { from } = props
  const { to } = props
  
  const [slots, setSlots] = useState([]);
 
  useEffect(() => {    // Update the document title using the browser API 
    loadSlots();
  }, [from, to] )

  function loadSlots() {
     if(from && to) {
        let url = config.get('vqueueUrl')+'/api/queue/my/groups/slotmanager/settings/slots/'+queue.id+"/"+from+"/"+to
        Axios.get(url)
          .then(response => {  
            if(response.data) {
              response.data.forEach(function (slot, index) {
                  console.log("slot: " + slot);
                });
                setSlots(response.data);
            }
        });
      } else {
        let url = config.get('vqueueUrl')+'/api/my/groups/slotmanager/settings/slots'+"?queueId="+queue.id;
        Axios.get(url )
          .then(response => {
            response.data.forEach(function (slot, index) {
                console.log("slot: " + slot);
              });
            setSlots(response.data);
          }) 
      }
  }   
    
  return (
    
    <Fragment> 
      { (slots && slots.length>0) && 
          <Grid item xs={12} style={{textAlign: "left"}}>
            <Grid item xs={12} style={{textAlign: "left"}}>
              <Typography  variant="body1" style={{fontSize:"0.8rem", fontWeight:"bold"}}>
                {t("opening_hours")} 
                { /*<Link to={{ pathname: '/my/groups/slotmanager/settings/slots', entity: queue, manage: true }}
                  style={{ color: "inherit", textDecoration: "none" }}>
                    <IconButton >
                      <Edit />
                    </IconButton>                  
                  </Link>  */ }
              </Typography>
              
            </Grid>
            {
              (from && to) &&  slots.map((aSlot, index) => (
                <Grid key={index} item  xs={12}>
                  <Typography  variant="body1" style={{fontSize:"0.8rem"}}>
                    <span ><DateTimeDisplay timestamp={aSlot.startTime} hideDate={true}/></span>
                    <span> - </span>
                    <span ><DateTimeDisplay timestamp={aSlot.endTime} hideDate={true}  end={true}/> Uhr</span>  
                  </Typography>
                </Grid>              
              ))
            }
            {
               (!from && !to) && slots.map((aSlot, index) => (
                <Grid key={index} item  xs={12}>
                  <Typography  variant="body1" style={{fontSize:"0.8rem"}}>
                  { aSlot.interval=="NO_INTERVAL" && 
                      <span><DateTimeDisplay timestamp={aSlot.startTime} hideTime={true}/> </span>
                  }
                  { aSlot.interval=="WEEKLY" && 
                      <span>{t("everyone")} <DateTimeDisplay timestamp={aSlot.startTime} hideTime={true} options={{weekday: 'long'}}/> </span>
                  }
                  { aSlot.interval=="DAILY" && 
                    <span>{t("slot_intervall_"+aSlot.interval)} </span>
                  }
                    <span ><DateTimeDisplay timestamp={aSlot.startTime} hideDate={true}/></span>
                    <span> - </span>
                    <span ><DateTimeDisplay timestamp={aSlot.endTime} hideDate={true}  end={true}/> Uhr</span>  
                  </Typography>
                </Grid>              
              ))
            
            }
          </Grid>
        }
      
    </Fragment>    
  );
}