import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import BlockerData from './BlockerData';
import TicketData from './TicketData';
import ServiceCard from './ServiceCard';
import ButtonUpdateState from '../../ticket/assets/ButtonUpdateState';
import ActionMenu from '../../ticket/assets/ActionMenu';
import { t } from 'i18next';
import Crumbs from '../../queue/Crumbs';


var TicketDataUtil = (function() {
  

  function getMenuItemsTicket(ticket, queue, manage) {
    return [
      <ActionMenu key={ticket.id} ticketEditor={manage?'/manageTicket':'/editTicket'} ticket={ticket} queue={queue} manage={manage} />
    ]
  }

  function getCardActions(ticket, queue,cardActions,small,call, manage) {
    if(cardActions) {
      return cardActions;
    }
    return manage && <Fragment>
      {ticket.secondaryStatus === "WAIT" &&
        <Fragment>
          <Grid item xs={12}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("call")}
              message={`${ticket.uid}`+" "+ t("got_called")}
              primaryStatus="WAITING"
              secondaryStatus="CALL"
              variant="contained" />
          </Grid>


        </Fragment>
      }

      {(ticket.secondaryStatus === "LAST_CALL" || ticket.secondaryStatus === "CALL") &&
        <Fragment>
          <Grid item xs={small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("cash")}
              message={`${ticket.uid}`+" "+t("begins_process")}
              primaryStatus="PROCESSING"
              secondaryStatus="START_PROCESS"
              variant="contained" />
          </Grid>
          <Grid item xs={small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="secondary"
              title={t("Wait")}
              message={`${ticket.uid}`+" "+t("waits_again")}
              primaryStatus="WAITING"
              secondaryStatus="WAIT"
              variant="contained" />
          </Grid>
        </Fragment>
      }
      {(ticket.secondaryStatus === "PROCESS") &&
        <Fragment>
          <Grid item xs={small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("Done")}
              message={`${ticket.uid}`+" "+t("ends_process")}
              primaryStatus="PROCESSING"
              secondaryStatus="STOP_PROCESS"
              variant="contained" />
          </Grid>
          <Grid item xs={small?12:6}>
            <ButtonUpdateState
              ticket={ticket}
              color="primary"
              title={t("Wait")}
              message={`${ticket.uid}`+" "+t("waits_again")}
              primaryStatus="WAITING"
              secondaryStatus="WAIT"
              variant="contained" />
          </Grid>
        </Fragment>
      }

      {(ticket.primaryStatus === "FINALIZING" || ticket.secondaryStatus === "REPLAN") &&
        <Fragment>
          <ButtonUpdateState
            ticket={ticket}
            color="primary"
            title={t("New_planing")}
            message={`${ticket.uid}`+" "+t("is_planning_new")}
            primaryStatus="PREPARING"
            secondaryStatus="PULLED"
            variant="contained" />

        </Fragment>
      }
      {(ticket.secondaryStatus === "APPOINTMENT_REQUEST_CLIENT_OPERATOR") &&
        <Fragment>
          <Link to={{ pathname: '/reviewRequest', vTicket: ticket, queue: queue, manage: true }}
            style={{ color: "inherit", textDecoration: "none", width: '100%' }}>
            <Button variant="contained" color="primary" style={{ width: '100%' }} >{t("check")}</Button>
          </Link>

        </Fragment>
      }
    </Fragment>
  }

  function getCardContentStyle(ticket) {
    let style = {borderLeft:"1px solid",borderRight:"1px solid",padding:"5px"}
    if(ticket.primaryStatus=="BLOCKER") {
      if(ticket.wishSource=="SLOT") {
        style.backgroundColor = "rgb(237, 255, 206)";
        style.color = "grey";
      } else if(ticket.wishSource=="RESOURCES") {
        style.backgroundColor = "rgb(222, 135, 161)";
        style.color = "black";
      }
    }
    return style;
  }

  function getCardNumberStyle(ticket) {
    let style = {backgroundColor:"#1BA1E2",padding:"5px"}
    if(ticket.primaryStatus=="BLOCKER") {
      if(ticket.wishSource=="SLOT") {
        style.backgroundColor = "rgb(210, 199, 140)";
      } else if(ticket.wishSource=="RESOURCES") {
        style.backgroundColor = "rgb(0, 59, 92)";
      }
    }
    return style;
  }

  function getTitle(ticket) {
    let title = ticket.contact ? ticket.contact : ticket.uid;
    if((ticket.primaryStatus==="BLOCKER" || ticket.secondaryStatus!=="RESERVED")) {
      if(ticket.wishSource=="SLOT") {
        title = t("closed");
      } else if(ticket.wishSource=="RESOURCES") {
        title = t("no_resources");
      }
    }
    return title;
  }

  var convertToTicketCardContent = function(ticket, queue, cardActions, small, call, showMore, manage, hideMenu) {
    let card = {};      
      card.cardContentStyle= getCardContentStyle(ticket)
      card.number=<Fragment> {(ticket.primaryStatus!=="BLOCKER" || ticket.secondaryStatus==="RESERVED")?(ticket.position + "-" + ticket.id):"*"}</Fragment>;
      card.cardNumberStyle = getCardNumberStyle(ticket);
      card.crumb=<Crumbs ticket={ticket}/>;
      card.title= getTitle(ticket);
      card.text=<Fragment>
            {ticket != null && (ticket.primaryStatus==="BLOCKER" && ticket.secondaryStatus!=="RESERVED") &&
              <BlockerData ticket={ticket} type={ticket.wishSource}/>
            }
            {
            ticket != null && (ticket.primaryStatus!=="BLOCKER" || ticket.secondaryStatus==="RESERVED") && ticket.primaryStatus !== "BOOKABLE" && 
            <Fragment>
              { ticket.status != "SERVICE" &&
                <TicketData ticket={ticket} call={call}  queue={queue} pShowMore={showMore} manage={manage} />
              }
              {ticket.status == "SERVICE" &&
                <ServiceCard ticket={ticket} />
              }
            </Fragment>
            }
          </Fragment>;
      card.menuItems=(ticket.primaryStatus!=="BLOCKER" || ticket.secondaryStatus==="RESERVED")?(!call && !hideMenu && getMenuItemsTicket(ticket, queue, manage)):null;
      card.action=(ticket.primaryStatus!=="BLOCKER" || ticket.secondaryStatus==="RESERVED")?(!call && !hideMenu && getCardActions(ticket, queue, cardActions, small, call, manage)):null;
      card.ticket=ticket;
    return card;
  }
  return {
    convertToTicketCardContent: convertToTicketCardContent,
  }

})();

export default TicketDataUtil;
