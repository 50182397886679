import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';
import { t } from 'i18next';

class UserTableRow extends Component { 
    
    

      render() {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography  variant="body1">
                    {this.props.user.firstName}        
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography  variant="body1">
                    {this.props.user.lastName}  
                  </Typography>
                </Grid>
                <Grid item xs={3}  >
                  <Typography  variant="body1">
                    {this.props.user.email}  
                  </Typography>
                </Grid>
                <Grid item xs={1}  >
                  
                </Grid>
                <Grid item xs={3}  >
                  <Typography  variant="body1">
                    <Link to={{pathname: '/editAccount', user:this.props.user}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                          {t("Change")}
                      </Button>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
    }
}

export default UserTableRow;