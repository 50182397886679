import React, { useState,useContext, createContext, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { LocationCity, Save } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import Crumbs from '../queue/Crumbs';
import MultiQStatusAndActionBar from '../pages/assets/MultiQStatusAndActionBar';
import { Badge } from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




function BookingStep1(props) {
  const {t} = props;
  const classes = useStyles();
  const { vDomain } = props.location;
  const { vQueue } = props.location;
  const { vGroup } = props.location;
  const { manage } = props.location;
  const { backTo } = props.location;
  
  const [domain, getDomain] = useState(vDomain);
  const [queue, setQueue] = useState(vQueue?vQueue:false);
  const [categories, setCategories] = useState([]);
  const [cycles, setCycles] = useState('33');
  
  const [category, setCategory] = useState("");
  const { addMessage } = useContext(MessageContext)

  const history = useHistory();
  useEffect(() => {    // Update the document title using the browser API 
    if(queue) {
      SessionContext.setActiveQueue(queue);   
    }
  }, [queue] )

  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setDomain(domain);   
    
    let url = config.get('vqueueUrl')
    let uri = queue?'/api/category-queues/queue/'+queue.id:manage?'/api/category-domains/domain/'+domain.id:'/api/category-domains/booking/domain/'+domain.id;
    url = url + uri;
    axios.get(url )
        .then(res => {
          setCategories(res.data)
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        }) 
    
  }, [] )

  function validateForm() {
    return category && cycles && (""+cycles).length > 0;
  }


  

  function handleSubmit(event) {
    if(event) {
      event.preventDefault();
    }
    let options = {
      category: category.category,
      cycles: cycles,
      queue: queue,
      domain: domain,
      selectLink: "/booking/data",
      confirmationTarget: manage?"/my/groups/slotmanager":"/wallet"
    }
    //SessionContext.setBookingOptions(options);
    history.push({pathname: "/booking/selection", vGroup: vGroup,  vOptions: options, vQueue: queue, vDomain: domain, manage: manage});
  }
  
  function getQuickActionItems() {
    let quickActionItems = [];
   /*  quickActionItems.push(
      <Link onClick={() => handleSubmit()} style={!validateForm()?{pointerEvents: "none", color: "#ffb1b1"}:{cursor: "pointer"}}>
              <Save />            
        </Link>
    ); */
    return quickActionItems;
  }
  function handleCategoryChange(selectedCategoryId) {
    if(selectedCategoryId) {
      let selectedCategory  = categories.filter((category) => {
        return category.id==selectedCategoryId
      });
      setCategory(selectedCategory[0]);
      setCycles(selectedCategory[0].category.standardTicketProcessingDuration)
    } else {
      setCategory(null);
    }
  }
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
    
      <DeskHeader title={<Crumbs domain={domain} queue={queue}/>}  backTo={backTo} 
        quickActions={getQuickActionItems()} />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("booking_criteria")}
        </Typography>
        
        <MultiQStatusAndActionBar manage={manage}/>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item  xs={12}>
                  <FormControl variant="outlined" style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-outlined-label">{t("category")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={category && category.id} 
                      onChange={(e) => handleCategoryChange(e.target.value)}
                    >
                      <MenuItem value=""><em>{t("none")}</em></MenuItem>
                      {categories.map((category,i) => {
                            return (
                              <MenuItem  key={i} value={category.id}>
                                <Badge style={{backgroundColor: category.category.color, fontSize: "1.5rem"}}>
                                  <span style={{backgroundColor:"rgba(255, 255, 255, 0.65)", color:"black", padding:"3px", borderRadius:"5px"}}>{category.category.name}</span>
                                </Badge>
                              </MenuItem>
                            )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
            <Grid item xs={12}>
              <TextField
                style={{width:"100%"}}
                label={t("cycles_units_pieces")}
                variant="outlined"
                required
                fullWidth
                value={cycles}
                id="cycles_units_pieces"
                name="cycles_units_pieces"
                autoComplete="fcolor"
                disabled={category===null}
                onChange={(e) => setCycles(e.target.value)}
              />
            </Grid>
            

           
         
              <Grid item  xs={12} >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!validateForm()}
                >
                  {t("continue")}
                </Button> 
              </Grid>
          </Grid>
         
                    
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(BookingStep1);