import React, { Component }  from 'react';
import { Redirect, Route } from 'react-router';
import SessionContext from '../frame/SessionContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
        SessionContext.getToken()!=null
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )
}

export default ProtectedRoute;
