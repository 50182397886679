import React, { Component, Fragment } from 'react';
import QrReader from 'react-qr-scanner';
import Button from '@material-ui/core/Button';
import { t } from 'i18next';

class QRCodeScanner extends Component {
  
  constructor(props){
    super(props)
    this.state = {
        delay: 500,
        result: 'No result',
        legacyMode: false
    }

    this.handleScan = this.handleScan.bind(this)
    this.openImageDialog = this.openImageDialog.bind(this)
}

  handleScan = data => {
    console.log("scan data: "+data);
    if (data) {
     this.props.onScan(data);
    }
  }
  handleError = err => {
    console.error(err)
    this.setState(
      {legacyMode: true}
    )
  }
  
  openImageDialog() { this.refs.qrReader1.openImageDialog()  }

  useImage() {
    this.refs.qrReader1.data()
  }
  
  render() {
    return (
      
          <Fragment>
                { this.state.legacyMode &&
                <Button variant="contained" color="primary"  onClick={this.openImageDialog}>
                      {t("choose_qr_code")}
                </Button>    
                }
                <QrReader
                  ref="qrReader1"
                  delay={this.state.delay}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ height: '500px', marginLeft: '-60%'}}
                  legacyMode={this.state.legacyMode}
                />         
        </Fragment>    
    )
  }
}


export default QRCodeScanner;