import React, { Fragment, useEffect , useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import { Button, Typography } from '@material-ui/core';
import DateTimeDisplay from './DateTimeDisplay';
import TargetTime from './TargetTime';
import BookSlotData from './BookSlotData';
import log from 'loglevel';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root1: {
    display: 'flex',
    alignItems: 'center',
    width: "100%",    
    justifyContent: "space-between"    
  },
  iconButton: {
    padding: 10,
  }
}));



function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
 //let equal = prevProps.queue!==null && nextProps.queue!==null;
  //log.debug("areEqual queue neither NULL"+equal);
  //equal =  JSON.stringify(prevProps.queue)===JSON.stringify(nextProps.queue);
  //log.error("areEqual queue ! "+(prevProps.queue?prevProps.queue.id:null)+" -> "+equal);
  //let ticketsEqual =  JSON.stringify(prevProps.tickets,["uid"])===JSON.stringify(nextProps.tickets,["uid"]);
  let ticketsEqual =  prevProps.tickets!==undefined && prevProps.tickets && nextProps.tickets && prevProps.tickets.length===nextProps.tickets.length;
  log.debug("areEqual tickets ! "+(nextProps.queue && nextProps.queue.id)+" - "+(prevProps.tickets && prevProps.tickets.length)+" - "+(nextProps.tickets && nextProps.tickets.length)+" - "+ticketsEqual);
  //equal =  equal && JSON.stringify(prevProps.options)===JSON.stringify(nextProps.options);
  //log.debug("areEqual options ! "+equal);
 // equal =  equal && JSON.stringify(prevProps.partitionResourceDTO)===JSON.stringify(nextProps.partitionResourceDTO);
 // log.debug("areEqual partitionResourceDTO! "+equal);
 //equal =  equal && (prevProps.partitionResourceDTO.partitionStatus==="READY" && nextProps.partitionResourceDTO.partitionStatus!=="READY" || (prevProps.partitionResourceDTO.partitionStatus==="READY" && nextProps.partitionResourceDTO.partitionStatus==="READY"));
 //equal =  equal && (prevProps.partitionResourceDTO.partitionStatus==="READY" && nextProps.partitionResourceDTO.partitionStatus!=="READY");// || (prevProps.partitionResourceDTO.partitionStatus==="READY" && nextProps.partitionResourceDTO.partitionStatus==="READY"));
  //log.debug("areEqual partitionStatus READY! "+equal+" - "+prevProps.partitionResourceDTO.partitionStatus+"-"+nextProps.partitionResourceDTO.partitionStatus);
  
  let equal =  ticketsEqual && (prevProps.partitionResourceDTO.usedAllDouble===nextProps.partitionResourceDTO.usedAllDouble);
  //equal =  equal && ((prevProps.partitionResourceDTO.partitionStatus!=="READY" && nextProps.partitionResourceDTO.partitionStatus!=="READY") || (prevProps.partitionResourceDTO.partitionStatus==="READY" && nextProps.partitionResourceDTO.partitionStatus==="READY"));  
  log.debug("areEqual partitionStatus READY! "+equal+" - "+prevProps.partitionResourceDTO.usedAllDouble+"-"+nextProps.partitionResourceDTO.usedAllDouble);
  return equal;
  
}
export default React.memo(TimelineMatrix, areEqual);

function TimelineMatrix(props) {
  const classes = useStyles();
  const { options } = props;
  const { queue } = props;
  const { partitionResourceDTO } = props;
  const { tickets } = props;
  const { slotsBookableCallback } = props;
  const { timelineLabel } = props;  

  const [mouseDown ,setMouseDown] = useState(false);
  const [clickedMarkerId , setClickedMarkerId] = useState(0);
  const [currentMoveMarkerId , setCurrentMoveMarkerId] = useState(0);
  const [timelineMatrixId] = useState("timelineMatrix_periods"+(queue?queue.id:''))  
  const [distance] = useState((1000*60*1));  
  const [count, setCount] = useState(3);
  const [overlap, setOverlap] = useState(false);
  const [offsetBegin] = useState((queue?parseInt((queue.config && JSON.parse(queue.config).offsetBegin)?JSON.parse(queue.config).offsetBegin:0):0)*distance);  
  const [offsetEnd] = useState((queue?parseInt((queue.config && JSON.parse(queue.config).offsetEnd)?JSON.parse(queue.config).offsetEnd:0):0)*distance); 
  
  

  const { from } = props
  const { to } = props
  

  const history = useHistory();
  
  useEffect(() => {    // Update the document title using the browser API 
    log.debug("useEffect ");   
      
  }, [] )
  
  function isPeriodSubPeriod(period, interval, important) {
    return (period%(interval*60*1000))==0;
  }

  function roundToHour(timeMillis) {
    let p = 60 * 60 * 1000; // milliseconds in an hour
    return Math.round(timeMillis / p ) * p;
  }

  function format(time) {
    let date = new Date(parseInt(time,10));      
   return date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }

  function enrichLinkProps(linkTo, aSlot) {
    if(linkTo) {
      let linkToEnriched = Object.assign({}, linkTo);
      linkToEnriched.startPreset=aSlot?aSlot.startPlanned:getStart();
      linkToEnriched.endPreset=aSlot?aSlot.endPlanned:getEnd();
      linkToEnriched.cyclesMax=aSlot?aSlot.cycles:getCylcesForPeriod();
      linkToEnriched.cyclesPreset=aSlot?aSlot.cycles:getCylcesForPeriod();
      linkToEnriched.queue=(options && options.ticket)?options.ticket.queue:queue;
      return linkToEnriched;
    }
  }

  function activateSelectionMode(e, top) {
    let clickedId = extractId(e.target.id,"_mFrame");
    console.log("activateSelectionMode id:" + clickedId );
    
    if(clickedId<clickedMarkerId || clickedId>currentMoveMarkerId)  {
      setMouseDown(true);
      setClickedMarkerId(clickedId);
      setCurrentMoveMarkerId(clickedId);
      highlightOn(e,clickedId, top);
    } else {
      bookHere(e);
    }
  }

  function toggleSelectionMode(e, top) {
    console.log("toggleSelectionMode "  );
    if(mouseDown) {
      deActivateSelectionMode(e);
    } else if(!clickedMarkerId) {
      activateSelectionMode(e, top);
      
    } else {
      removeLastSeceltion(e);
    }
  }

  
  function deActivateSelectionMode(e) {
    e.stopPropagation();
    if(mouseDown) {
      setMouseDown(false);
      let timelineMatrix = document.getElementById(timelineMatrixId);
      timelineMatrix.style.zIndex = '13';       

      let timelineMatrixElement = document.getElementById(timelineMatrixId+"_marker_container");
      timelineMatrixElement.style.zIndex = '15'; 
    }  
    
    
  }

  function removeLastSeceltion(e) {
      setClickedMarkerId(null);
      setCurrentMoveMarkerId(null);
      highlightOff(e,clickedMarkerId);
  }

  function moveWhenSelectionModeActive(e) {
    if(mouseDown) {
      console.log("moveWhenSelectionModeActive: "+e.target.id);
      if(e.target.id ) {
        let isMarker = e.target.id.indexOf("marker");
        if(isMarker==-1) {
          let overlapBefore = false;
          let overlapAfter = false;
          let theId = extractId(e.target.id,"_mFrame");
          overlapBefore = isPeriodBlocked(theId, true);
          if(options && theId>currentMoveMarkerId) {
            let followUpId = getNextAfterLastPeriod();
            overlapAfter = isPeriodBlocked(followUpId, true);
          }
          if(!options && theId>currentMoveMarkerId) {
            let followUpId = getNextAfterLastPeriod();
            overlapAfter = isFromToBlocked(getStart(), theId, true);      
          }
          if(!overlapAfter && !overlapBefore) {
            setCurrentMoveMarkerId(parseInt(theId)); 
            setOverlap(false);      
          }
        }        
      }
      
    }
  }

  function getNextAfterLastPeriod() {
    return getEnd()+distance;
  }

  function extractId(source,removeStr) {
    let indexOfStrToRemove = source.indexOf(removeStr);
    let extract = source;
    if(indexOfStrToRemove>-1) {
      extract = parseInt(source.substring(0, indexOfStrToRemove));      
    }
    return extract;
  }

  function highlightOn(e,highlightMarkerId,top) {
    let idFrame = highlightMarkerId+"_mFrame"+queue.id;
    console.log("highlightMarkerId: "+idFrame);
    
    let timelineMatrix = document.getElementById(timelineMatrixId);
    timelineMatrix.style.zIndex = '15';       

    let timelineMatrixElement = document.getElementById(timelineMatrixId+"_marker");
    timelineMatrixElement.style.zIndex = '13';  
    timelineMatrixElement.style.display = 'block';

  }
  function highlightOff(e,highlightMarkerId) {
      //e.target.style.background = 'none';
      let timelineMatrixElement = document.getElementById(timelineMatrixId);
      timelineMatrixElement.style.zIndex = null;   

      let timelineMatrixElementContainer = document.getElementById(timelineMatrixId+"_marker_container");
      timelineMatrixElementContainer.style.zIndex = '10'; 

      let timelineMatrix = document.getElementById(timelineMatrixId+"_marker");
      timelineMatrix.style.zIndex = null;    
      timelineMatrix.style.display = 'none';   

  }

  function isShown(pointer) {
    return clickedMarkerId==(pointer);
  }

  function getHeight() {
    if(options) {
      return (parseInt(getEndBasedOnCycles(getStart()))-currentMoveMarkerId)/distance;
    }
    return  Math.abs(currentMoveMarkerId-clickedMarkerId)/distance;
  }

  function getTop() {
    let timelinePlainPointer = from.getTime();
    let timelinePointer = roundToHour(timelinePlainPointer);
    let top;
    if(options) {
      top = currentMoveMarkerId-timelinePointer;
    } else {
      if(currentMoveMarkerId>clickedMarkerId) {
        top = (clickedMarkerId-timelinePointer);
      } else {
        top = (currentMoveMarkerId-timelinePointer);
      }
    }
    top = top/distance *2;;
    return top
  }

  function getStart() {
    let start;
    if(options) {
      start = parseInt(currentMoveMarkerId);
    } else {
      start = parseInt(clickedMarkerId<currentMoveMarkerId?clickedMarkerId:currentMoveMarkerId);
    }
    //console.log("start: "+start);
    return start;
  }

  function getCylcesForPeriod() {
    if(options) {
      return options.cycles;
    }
    let start = getStart();
    let end = getEnd();
    let distance = 1000 * 60;
    let resorucesMap = partitionResourceDTO.resourcePeriodStatusDTOMap;
    let cycles = 0;
    let maxCyclPerPeriod = queue?parseInt((queue.config && JSON.parse(queue.config).resourceLimit)?JSON.parse(queue.config).resourceLimit:0):0;
    maxCyclPerPeriod = maxCyclPerPeriod?maxCyclPerPeriod:0;
    log.trace("getCylcesForPeriod: "+start);  
    log.trace("getCylcesForPeriod: "+end);  
    log.trace("maxCyclPerPeriod: "+maxCyclPerPeriod);  
    while(start<end && resorucesMap &&  resorucesMap[start]) {
      log.trace("getCylcesForPeriod periodState.availableCylcesF: "+resorucesMap[start].availableCylces);  
      let possibleCycles = resorucesMap[start].availableCylces-resorucesMap[start].usedCycles;
      if(maxCyclPerPeriod!=null && possibleCycles>maxCyclPerPeriod) {
        possibleCycles=maxCyclPerPeriod;
      }
      cycles += possibleCycles;
      log.trace("getCylcesForPeriod sum cylces: "+cycles);  
      start += distance;
    }
    return cycles;
  }

  function isFromToBlocked(from, to, dontSet) {
    log.trace("isFromToBlocked tickets: "+tickets);  
    let lOverlap = false;
    if(tickets) {
      let ticketThere = tickets.filter((aTicket) => {
        log.trace("isFromToBlocked aTicket.startPlanned:  "+aTicket.startPlanned);  
        log.trace("isFromToBlocked period:                "+from+" - "+to);  
        log.trace("isFromToBlocked aTicket.endPlanned:    "+aTicket.endPlanned);  
        if(aTicket.startPlanned) {
          return ((aTicket.startPlanned>=from && aTicket.endPlanned<=from) || (aTicket.endPlanned>=to && aTicket.startPlanned<=to) || (aTicket.startPlanned>=from && aTicket.endPlanned<=to));
        }
        return false;
      })
      log.trace("isPeriodBlocked ticketThere: "+ticketThere);  
      
      lOverlap = ticketThere && ticketThere.length>0;
    }
    //if(!overlap && lOverlap && !dontSet) {
    //  setOverlap(lOverlap);
    //}
    return lOverlap;
  }


  function isPeriodBlocked(period, dontSet) {
    //console.log("isPeriodBlocked tickets: "+tickets);  
    let lOverlap = false;
    if(tickets) {
      let ticketThere = tickets.filter((aTicket) => {
        //console.log("isPeriodBlocked aTicket.startPlanned:  "+aTicket.startPlanned);  
        //console.log("isPeriodBlocked period:                "+period);  
        //console.log("isPeriodBlocked aTicket.endPlanned:    "+aTicket.endPlanned);  
        if(aTicket.startPlanned) {
          return aTicket.startPlanned<=period && aTicket.endPlanned>=period;
        }
        return false;
      })
      //console.log("isPeriodBlocked ticketThere: "+ticketThere);  
      
      lOverlap = (ticketThere && ticketThere.length>0)?(ticketThere[0].endPlanned):0 ;
    }
    //if(!overlap && lOverlap && !dontSet) {
    //  setOverlap(lOverlap);
    //}
    return lOverlap;
  }

  function getEndBasedOnCycles(start) {
    let distance = 1000 * 60;
    let initStart = start;
    start=start+offsetBegin;//-distance;
    let end = start;
    let cycles = options.cycles;
    //console.log("getEndBasedOnCycles options.cycles: "+cycles);  
    let confgJson = queue && queue.config?JSON.parse(queue.config):null;
      let resourceLimit= (confgJson && confgJson.resourceLimit)?confgJson.resourceLimit:0;
      resourceLimit = resourceLimit?resourceLimit:0;
    console.log("getEndBasedOnCycles queue confgJson.resourceLimit: "+ resourceLimit);  
    let resorucesMap = partitionResourceDTO.resourcePeriodStatusDTOMap;
    while(cycles>0 && resorucesMap && resorucesMap[start] && !isPeriodBlocked(start, true)) {
      let periodBlocked = isPeriodBlocked(start);
      let periodResource = resorucesMap[start];
      let remainingCylces = !periodBlocked?(periodResource?periodResource.availableCylces-periodResource.usedCycles:0):0;
      //console.log("getEndBasedOnCycles remainingCylces: "+remainingCylces);  
      let cylcesThatCanBeUsed = !periodBlocked?(resourceLimit?(remainingCylces>resourceLimit?resourceLimit:remainingCylces):remainingCylces):0;
      if(resourceLimit!=null && cylcesThatCanBeUsed>resourceLimit) {
        cylcesThatCanBeUsed=resourceLimit;
      }
      //console.log("getEndBasedOnCycles cylcesThatCanBeUsed: "+cylcesThatCanBeUsed);  
      cycles -= cylcesThatCanBeUsed;
      
      //console.log("getEndBasedOnCycles left cylces: "+cycles);  
      start += distance;
      end += distance;
    }
    if(cycles<=0) {
      let endWithOffset = end+offsetEnd;
      let valid = !isPeriodBlocked(endWithOffset, true)
      if(valid) {
        return end+offsetEnd; //substract one minute for correct display and calculation. otherwise it is one Minute to much
      }      
    }
    return initStart;
  }

  function generateSlots() {
    if(timelineLabel || !options || !options.cycles) {
      return;
    }
    if(tickets) {
      let bookables = tickets.filter((aTicket) => {
        return aTicket.primaryStatus==='BOOKABLE';
      })
      if(bookables.length>0) {
        return;
      }
    }
    let nextStartDate = from;
    nextStartDate.setSeconds(0);
    nextStartDate.setMilliseconds(0);
    let endTimelineDate = to;
    endTimelineDate.setSeconds(0);
    endTimelineDate.setMilliseconds(0);
    
    let nextStart = from.getTime();
    let endTimeline = to.getTime();
    
    let slots = [];
    let aSlot = {};      
    while(nextStart<endTimeline) {
      aSlot = {};
      aSlot.startPlanned=nextStart;
      let blockedUntil = isPeriodBlocked(aSlot.startPlanned, true)
      if(!blockedUntil) {
       aSlot.endPlanned=getEndBasedOnCycles(aSlot.startPlanned);
        if(aSlot.endPlanned===aSlot.startPlanned) {
          nextStart=aSlot.startPlanned+distance; //add one minute for display - and calculation. current slot can not start in same minute like other finishes
        } else {
          nextStart = aSlot.endPlanned+distance;
          aSlot.primaryStatus='BOOKABLE';
          aSlot.cycles=options.cycles;
          aSlot.bookableSlot= <BookSlotData slot={aSlot} queue={queue} linkTo={props.linkTo}/>
          slots.push(aSlot);          
        }
      } else {
        blockedUntil = parseInt(blockedUntil/distance)*distance;
        nextStart = blockedUntil+distance;
      }
    }
    //console.log("slots generated: "+JSON.stringify(slots));
    if(slotsBookableCallback && slots.length>0) {
      console.log("slots generated , calling callback!");
    
      slotsBookableCallback(slots);
    }
    
  }

  function getEnd() {
    let end;
    if(options) {
      end = parseInt(getEndBasedOnCycles(getStart()));
    } else {
      end = parseInt(clickedMarkerId<currentMoveMarkerId?currentMoveMarkerId:clickedMarkerId);
    }
    return end;
  }

  function bookHere(e, aSlot) {
    let linkTo = enrichLinkProps(props.linkTo,aSlot);              
    history.push(linkTo);    
  }

  function getDuration() {
    let duration = 0;
    if(options && options.cycles) {
      let start = getStart();
      let end = getEnd();
      let distance = 1000 * 60;
      let resorucesMap = partitionResourceDTO.resourcePeriodStatusDTOMap;
      let cycles = options.cycles;
      let maxCyclPerPeriod = queue?parseInt((queue.config && JSON.parse(queue.config).resourceLimit)?JSON.parse(queue.config).resourceLimit:0):0;
      maxCyclPerPeriod = maxCyclPerPeriod?maxCyclPerPeriod:0;
      console.log("getCylcesForPeriod: "+start);  
      console.log("getCylcesForPeriod: "+end);  
      console.log("maxCyclPerPeriod: "+maxCyclPerPeriod);  
      while(cycles>0 && resorucesMap && resorucesMap[start]) {
        console.log("getDuration periodState.availableCylcesF: "+resorucesMap[start].availableCylces);  
        let possibleCycles = resorucesMap[start].availableCylces-resorucesMap[start].usedCycles;
        if(maxCyclPerPeriod!=null && possibleCycles>maxCyclPerPeriod) {
          possibleCycles=maxCyclPerPeriod;
        }
        cycles -= possibleCycles;
        duration += distance;
        start += distance;
        console.log("getDuration sum duration: "+duration);  
      }
    } else {
      duration = getEnd()-getStart();
    }
    
    duration = duration+offsetBegin+offsetEnd;
    console.log("duration: "+duration);

    let seconds =  ("0" + Math.floor((duration / 1000) % 60)).slice(-2);
    let minutes =  ("0" + Math.floor((duration / 1000 / 60) % 60)).slice(-2);
    let hours = ("0" + Math.floor((duration / (1000 * 60 * 60)) % 24)).slice(-2);
    let days = Math.floor(duration / (1000 * 60 * 60 * 24));
    if(days>0) {
      return <span>{days} {hours}:{minutes}:{seconds}</span>
    } else {
      return <span>{hours}:{minutes}:{seconds}</span>
    }
  }

  function timelineTimingPeriods() {
    let timingElements = [];
    let timelinePlainPointer = from.getTime();
    let timelinePointer = roundToHour(timelinePlainPointer);
    
    let distancer = timelinePointer-timelinePlainPointer;
    let firstTopMargin = distancer/(1000*60/6*3);
    
    
    
    let timelineTarget = to.getTime();
    
    let top = 0;
    while(timelinePointer<timelineTarget) {
      distancer = 0; 
      let fullHour = isPeriodSubPeriod(timelinePointer, 60);
      let halfHour = isPeriodSubPeriod(timelinePointer, 30);
      distancer=distancer+distance;
        top = distancer/(1000*60/6*3);
        let linkTo = props.linkTo;              
        timingElements.push(
              <div id={timelinePointer+"_mFrame"+(queue?queue.id:"")}
                  // onMouseOver={moveWhenSelectionModeActive}
                  //onMouseOut={highlightOff}
                  //onMouseLeave={highlightOff}
                  //onMouseUp={deActivateSelectionMode}
                  onClick={e => toggleSelectionMode(e,top)}
                  onMouseMove={e => moveWhenSelectionModeActive(e)}
                  style={{position:"relative", zIndex: "9",marginTop: firstTopMargin+"px", minHeight:top+"px", maxHeight:top+"px", width:"100vw", textAlign:"left"}} >
                    
                  <div style={{width:"100vw", position:"absolute", backgroundColor:"white", borderBottom:(!linkTo && fullHour)?"1px solid grey":"inherit", marginLeft:"1.5rem", zIndex:"1"}}/>
                    { (fullHour || halfHour) && !linkTo &&
                      <span style={{ width:"100%" , left:"0px", fontSize:"0.6rem", fontWeight:"bold", transform:"rotate(0deg)", position: "absolute", top:"-5px"}}>
                        { format(timelinePointer) }
                      </span>
                    }
                  
                  </div>
        )
        
      timelinePointer=timelinePointer+distancer; //add this distance to the current overall pointer
      firstTopMargin=0;
    }
    if(tickets) {
      generateSlots();
    }
    return timingElements;     
  }

  return (
    <Fragment>
      <Grid container style={{position:"relative", top:"0", overflow: "hidden"}}>
        <Grid id={timelineMatrixId} container alignItems="center" justify="center" style={{ top:"0", overflow: "hidden"}}>
            { 
              from && to && timelineTimingPeriods()
            }
        </Grid>
        <Grid id={timelineMatrixId+"_marker_container"} container alignItems="center" justify="center" style={{position:"absolute", top:"0", zIndex:"10"}}>
          <div id={timelineMatrixId+"_marker"} item  xs={12} 
                style={{ 
                  display: "none",
                    userSelect: "none",
                    position:"absolute", 
                    marginBottom: "0", 
                    width: "100%", 
                    minHeight: (getHeight()*2)+"px",
                    maxHeight: (getHeight()*2)+"px",
                    top:getTop()+"px", 
                    padding:"0", 
                    zIndex: "8",
                    backgroundColor:"rgba(82, 150, 158, 0.73)", 
                    padding:"5px",
                    borderRadius:"2px"}}>
                  <Grid container>
                    <Grid item xs={12}  >
                      <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"0.8rem", fontWeight:"bolder", textDecoration:"underline", color: "white" }}>
                        <span ><DateTimeDisplay timestamp={getStart()} hideDate={true}/>  </span>
                        <span > - </span>
                        <span ><TargetTime targetTimestamp={getEnd()}/></span>
                      </Typography>
                    </Grid>  
                    <Grid item xs={12}  >
                      <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"1rem", fontWeight:"bold", color: "white" }}>
                        {getDuration()} ({getCylcesForPeriod()})
                      </Typography>
                    </Grid>  
                  </Grid>
                  <Button variant="contained" color="primary" style={{ width: '100%' }} disabled={overlap} onClick={bookHere}>hier buchen</Button>                  
              </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
