import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SessionContext from '../../frame/SessionContext';
import ls, { set } from 'local-storage'



const LocationChangeListener = ({ children }, props) => {
  const location = useLocation()
  const [loc, setLoc] = useState(null);
  const localStorage = window.localStorage;

  var getLocation = function(location) {
    let current = localStorage.getItem(location);
    if(current) {
      return JSON.parse(current);   
    }
    return current;
  };

  var setLocation = function(location, data) {      
    localStorage.setItem(location, JSON.stringify(data));        
  };

  React.useMemo(() => {
    
    const allKeys = Object.keys(localStorage);
    allKeys.forEach(function (aKey, index) {
      let indx = aKey.indexOf(location.pathname)
      if(indx===0) {
        let subst = aKey.substring(location.pathname.length);
        if(subst.length!==0) {
          console.log('removeing key', aKey)
          localStorage.removeItem(aKey);
        }
      }
    });

    console.log('handle route change here', location)
    if(location.pathname=="/" ) {
      SessionContext.setDomain(null);       
      SessionContext.setPlace(null);     
      SessionContext.setManageMode(false);        
    }

    if(location.pathname=="/my/domains" ) {     
      SessionContext.setPlace(null);       
    }
    if(location.pathname=="/" 
        || location.pathname=="/my/places"
      ) {
      SessionContext.clearQ(); 
      SessionContext.setPlace(null);
    }
    
      if(location.queue 
        || location.category 
        || location.vDomain 
        || location.vOptions
        || location.vPlace
        || location.vQueue) {
        setLocation(location.pathname,location);
      } else {
        let storedLocation=getLocation(location.pathname);
        if(storedLocation!=null) {
          storedLocation[Symbol.iterator]=Array.prototype[Symbol.iterator];
          if(storedLocation) {
            Object.keys(storedLocation).forEach(function(key) {
              location[key]=(storedLocation[key]);
            });
          }
        }
      }
    //}
     
    /*
    if(location.pathname=="/my/groups" ) {     
      if(location.vPlace) {
        SessionContext.setPlace(location.vPlace);       
      } else {
        location.vPlace=SessionContext.getPlace();
      }
    }
    
    
    if(location.pathname=="/my/groups/slotmanager" ||
        location.pathname=="/booking/options" ||
        location.pathname=="/booking/selection" ) {     
      if(location.vPlace) {
        SessionContext.setGroup(location.vGroup);       
        SessionContext.setManageMode(location.vManage);       
      } else {
        location.vGroup=SessionContext.getGroup();
        location.vManage=SessionContext.isManageMode();
        location.manage=SessionContext.isManageMode();
      }
    }
    
    if(location.pathname=="/my/groups/slotmanager/settings" ) {     
      if(location.vQueue) {
        SessionContext.setActiveQueue(location.vQueue);       
      } else {
        location.vQueue=SessionContext.getActiveQueue();
      }
    }
    */
    
    setLoc(location);
  }, [location])

    return <></>
}

export default LocationChangeListener
