import { Button, CssBaseline, MenuItem, Typography, withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Favorite, Star } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import SessionContext from '../frame/SessionContext';
import AppFooter from '../pages/assets/AppFooter';
import TicketGrid from '../pages/assets/CardGrid';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import Crumbs from './Crumbs';
import { withTranslation } from 'react-i18next';



const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


class Favourites extends Component {
  static contextType = MessageContext
  

  constructor() {
    super();
    this.state={};
  }

  componentDidMount() {
    if(this.props.location.vPlace) {
      SessionContext.setPlace(this.props.location.vPlace);
    }
    this.setState({
      place: SessionContext.getPlace()
    });   
  }
  
  showAlert(message) {
    const msgCtx = this.context
    msgCtx.addMessage(message);
  }


  getGridContent() {
    const { t } = this.props;
    let gridContent = [];
    

    let myQueues = SessionContext.getFavourites();
    
    for (var value in myQueues) {
      let activeTicketForQ = SessionContext.getActiveTicketForQueue(myQueues[value]);
      
     let entry =  {
      
        title:myQueues[value].name,
        crumb: <Crumbs queue={myQueues[value]}/>,
        text:<Fragment>
              { activeTicketForQ!=null && 
                <Typography variant="body1" style={{ width: "100%", textAlign: "center", fontSize:"0.9rem" }}>
                  {t("allready_queeud_up")}
                </Typography>
              }
              { activeTicketForQ==null && 
                <Fragment> 
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
                      <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                        {t("next_timepoint")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" >
                      <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
                        <span >{t("book_now")}</span>    
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>  
              }            
          </Fragment>,
        menuItems: this.getMenuItems(myQueues[value],activeTicketForQ),
        action: <Fragment>
                  { activeTicketForQ!=null && 
                    <Link to={{pathname: '/wallet'}}  style={{width:"100%"}}><Button color={'primary'} variant="contained" style={{width:"100%", height:"2rem", fontWeight:"bold"}} >{t("zum_wallet")}</Button></Link>
                  }
                  { activeTicketForQ==null && myQueues[value].subscription &&
                      <Link to={{pathname: '/booking/options', vQueue:myQueues[value]}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
                        <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                        {t("book")}
                        </Button>
                      </Link> 
                  }
                  {
                    activeTicketForQ==null && !myQueues[value].subscription &&     
                      <Link to={{pathname: '/room', queue: myQueues[value]}}  style={{width:"100%"}}><Button color={'primary'} variant="contained" style={{width:"100%", height:"2rem", fontWeight:"bold"}} >{t("show")}</Button></Link>
                  }
                </Fragment>,
        iconLeft: myQueues[value].subscription && <Star />,    
              
        ticket: myQueues[value].currentTicket
      };
      gridContent.push(entry);
    }
    return gridContent;
  }


  getMenuItems(queue, activeTicketForQ) {
    const { t } = this.props;
    let menuContent = [];
    if(activeTicketForQ==null && !queue.subscription) {
      menuContent.push(<MenuItem key="booking" >
        <Link to={{pathname: '/booking/options', queue: queue}} > <Button color="primary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} >{t("gap_generic_call_to_action_TIMESLOT")}</Button> </Link>
      </MenuItem>);
     }
    if(activeTicketForQ!=null) {
      menuContent.push( <MenuItem key="room" >
        <Link to={{pathname: '/wallet', queue: queue}} > <Button color="secondary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} >{t("verwaltung_hint")}</Button> </Link>
      </MenuItem>)
    }
    return menuContent;
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props; 
    return (
      <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
        <DeskHeader title={"Q "+t("favourite_title")}  backTo={"/"} />
        <CssBaseline />
        <div  className={classes.paper}>
          <Avatar >
            <Favorite />
          </Avatar>
          <Typography component="h1" variant="h5">
              {t("favourite_title")}
          </Typography>
        <Grid container>  
          { 
            <TicketGrid gridContent={this.getGridContent()} />
          }
        </Grid>
      </div>
      <AppFooter />
    </Container>
      
    );
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation()(Favourites));