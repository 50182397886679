import { FiberManualRecord } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';

import {
  Badge
} from 'reactstrap';


export default function ShortStatus(props) {
  const { ticket } = props;
  const { primaryStatus } = props;
  const { secondaryStatus } = props;

  function mustBlink() {
    var primaryStatus = ticket.primaryStatus;
    var secondaryStatus = ticket.secondaryStatus;
    if(primaryStatus==='WAITING' || primaryStatus==='PROCESSING') {
      if(secondaryStatus=='LAST_CALL' || secondaryStatus=='CALL' || secondaryStatus=='PROCESS') {
        return "blink"
      }
    }          
  }

  function getColor() {
    let color = "000000";
    var primaryStatus = ticket.primaryStatus;
    var secondaryStatus = ticket.secondaryStatus;
    if(primaryStatus ) {
      if(primaryStatus=='PREPARING') {
        if(secondaryStatus=='PULLED') {
          color = "ffca38"
        }
        if(secondaryStatus=='PULL_REQUESTED') {
          color =  "ffca38"
        }
        if(secondaryStatus=='CALLED') {
          color =  "ffca38"
        }
        if(secondaryStatus=='DELAYED') {
          color =  "ffca38"
        }
      }
      if(primaryStatus=='WAITING') {
        if(secondaryStatus=='WAIT') {
          color =  "00b020"
        }
        if(secondaryStatus=='CALL') {
          color =  "e0d500"
        }
        if(secondaryStatus=='LAST_CALL') {
          color =  "e0d500"
        }
    }
      if(primaryStatus=='PROCESSING') {
        if(secondaryStatus=='PROCESS') {
          color =  "e0d500"
        }      
      }
      if(primaryStatus=='FINALIZING') {
        if(secondaryStatus=='RETURNED') {
          color =  "70b0ff"
        }
        if(secondaryStatus=='CALL_MISSEED') {
          color =  "70b0ff"
        }
        if(secondaryStatus=='COMPLETED') {
          color =  "70b0ff"
        }
        if(secondaryStatus=='ABORTED') {
          color =  "70b0ff"
        }
        if(secondaryStatus=='EXPIRED') {
          color =  "70b0ff"
        }
      }
      if(primaryStatus=='BLOCKER') {
        if(secondaryStatus=='RESERVED') {
          color =  "4287f5"
        }      
      }
      
     }
     return color;
  }

  return (
    <FiberManualRecord className={mustBlink() } style={{ color: "#"+getColor() }}  />
 );
}