import React, { Component, Fragment } from 'react';

import {
  Badge
} from 'reactstrap';
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from "@material-ui/core/styles";
import {t} from 'i18next';

const useStyles = makeStyles({
  alert: {
    "& .MuiAlert-icon": {
      fontSize: props => props.size?props.size:'1.5rem'
    },
    "& .MuiAlert-message": {
      fontSize: props => props.size?props.size:'1rem',
      lineHeight: "90%",
      padding:"10px"
    }
  }
});

export default function StatusTicket(props) {
  const classes = useStyles(props); 
  const { active } = props
  
  const getStyle = () => {
    let style = {width:"100%", padding:"0", whiteSpace:"nowrap"};
    let highLight = {fontWeight: "bolder", border: "solid 1px"};
    if(active) {
      style=mergeStyle(style, highLight);
    }
    return style;
  }


  function mergeStyle(target, source) {
    Object.assign(target, source);    
    return target;
  }

  function renderBadgeTicketStatus() {
    var primaryStatus = props.ticket?props.ticket.primaryStatus:props.primaryStatus;
    var secondaryStatus = props.ticket?props.ticket.secondaryStatus:props.secondaryStatus;
    primaryStatus = props.primaryStatus?props.primaryStatus:primaryStatus;
    secondaryStatus = props.secondaryStatus?props.secondaryStatus:secondaryStatus;
    if(primaryStatus ) {
      if(primaryStatus=='PREPARING') {
        if(secondaryStatus=='PULLED') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("queued")}</Alert>
        }
        if(secondaryStatus=='PULL_REQUESTED') {
          return <Alert className={classes.alert} style={getStyle()} severity="success">{t("queue")}</Alert>
        }
        if(secondaryStatus=='SCHEDULED') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("planed")}</Alert>
        }
        if(secondaryStatus=='REPLAN') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("new_planing")}</Alert>
        }
        if(secondaryStatus=='REPLAN_STARTED') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("planing_begun")}</Alert>
        }
        if(secondaryStatus=='DELAYED') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("later")}</Alert>
        }
        if(secondaryStatus=='NO_SLOT_FOUND') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("no_slot")}</Alert>
        }  
        if(secondaryStatus=='SCHEDULE_ERROR') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("planing_fail")}</Alert>
        }  
        if(secondaryStatus=='APPOINTMENT_REQUEST_CLIENT_OPERATOR') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("queue")}</Alert>
        }  
        if(secondaryStatus=='APPOINTMENT_REQUEST_OPERATOR_CLIENT') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("queue_ops")}</Alert>
        }  
        if(secondaryStatus=='APPOINTMENT_REQUEST_CLIENT_OPERATOR_CONFIRMED') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("confirmed_ops")}</Alert>
        }  
        if(secondaryStatus=='APPOINTMENT_REQUEST_OPERATOR_CLIENT_CONFIRMED') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("confirmed")}</Alert>
        }          
        return <Alert className={classes.alert} style={getStyle()} severity="info">{t("new")}</Alert>                
     }
      if(primaryStatus=='WAITING') {
        if(secondaryStatus=='WAIT') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("wait")}</Alert>
        }
        if(secondaryStatus=='CALL') {
          return <Alert className={classes.alert} style={getStyle()} severity="success">{t("called")}</Alert>
        }
        if(secondaryStatus=='LAST_CALL') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("last_call")}</Alert>
        }
        return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("waiting")}</Alert>    
      }
      if(primaryStatus=='PROCESSING') {
        if(secondaryStatus=='START_PROCESS') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("beginning")}</Alert>
        }      
        if(secondaryStatus=='PROCESS') {
          return <Alert className={classes.alert} style={getStyle()} severity="success">{t("changing")}</Alert>
        }      
        if(secondaryStatus=='STOP_PROCESS') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("ending")}</Alert>
        }
        return <Alert className={classes.alert} style={getStyle()} severity="success">{t("active")}</Alert>        
      }
      if(primaryStatus=='FINALIZING') {
        if(secondaryStatus=='RETURNED') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("given_back")}</Alert>
        }
        if(secondaryStatus=='CALL_MISSEED') {
          return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("missed")}</Alert>
        }
        if(secondaryStatus=='COMPLETED') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("done")}</Alert>
        }
        if(secondaryStatus=='ABORTED') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("aborted")}</Alert>
        }
        if(secondaryStatus=='EXPIRED') {
          return <Alert className={classes.alert} style={getStyle()} severity="error">{t("run_out")}</Alert>
        }
        return <Alert className={classes.alert} style={getStyle()} severity="error">{t("ended")}</Alert>  
      }
      if(primaryStatus=='BLOCKER') {
        if(secondaryStatus=='FIXED') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("solid_blocker")}</Alert>
        }
        if(secondaryStatus=='DYNAMIC') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("dynamic_blocker")}</Alert>
        }
        if(secondaryStatus=='CLOSED') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("closed")}</Alert>
        }
        if(secondaryStatus=='BREAK') {
          return <Alert className={classes.alert} style={getStyle()} severity="info">{t("beak")}</Alert>
        }
        return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("blocker")}</Alert>  
      }
      if(primaryStatus=='TIMESLOT') {
        return <Alert className={classes.alert} style={getStyle()} severity="warning">{t("fixed")}</Alert>  
      }
      return <Alert className={classes.alert} style={getStyle()} severity="error">{primaryStatus}-{secondaryStatus}</Alert>
    } else {
      return <Alert className={classes.alert} style={getStyle()} severity="info">{t("every")}</Alert>
    }
    
  }

  return (
      <Fragment>
        {renderBadgeTicketStatus()}
      </Fragment>
    );
}