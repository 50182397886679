import React, { Component, Fragment } from 'react';



class TargetTime extends Component {

  renderTargetTime() {
    if(this.props.duration) {
      let seconds =  ("0" + Math.floor((this.props.duration / 1000) % 60)).slice(-2);
      let minutes =  ("0" + Math.floor((this.props.duration / 1000 / 60) % 60)).slice(-2);
      let hours = ("0" + Math.floor((this.props.duration / (1000 * 60 * 60)) % 24)).slice(-2);
      let days = Math.floor(this.props.duration / (1000 * 60 * 60 * 24));
      if(days>0) {
        return <span>{days} {hours}:{minutes}:{seconds}</span>
      } else {
        return <span>{hours}:{minutes}:{seconds}</span>
      }
    }
    if(this.props.targetTimestamp) {
      let ts = this.props.targetTimestamp;
      var then = new Date(ts);
      ts = Math.floor(ts/60000);
      ts = ts*60000;
      if(this.props.end) {
        ts = ts - 1000;  //substract one second just to show the correct ending time.
        let tmp = new Date(ts);
        if(tmp.getHours()==23 && tmp.getMinutes()==59) {
          then = new Date(ts);
        }
      }
    }
    if(this.props.millisAhead) {
      var now = new Date().getTime();
      then = new Date(this.props.millisAhead+now);      
    }
    var options = { hour: '2-digit', minute: '2-digit'}; 
    return <span>{then && then.toLocaleTimeString("de-DE", options)}</span>
  }

 
  render() {
    return (
      <Fragment>{this.renderTargetTime()}</Fragment>
    );
  }
}


export default TargetTime;