import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppFooter from '../pages/assets/AppFooter';
import RoomHeader from '../pages/assets/RoomHeader';
import Button from '@material-ui/core/Button';
import DeskHeader from '../pages/assets/DeskHeader';
import { Avatar, CssBaseline, makeStyles, Typography } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import { Wallpaper } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';


var QRCode = require('qrcode.react');



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 function QQRCode(props) {
  const classes = useStyles();
  const {t} = props;

    return (
      <Container maxWidth="xs"  className={classes.paper} style={{position: 'relative', marginTop:"80px", marginBottom:"100px"}}>
        <DeskHeader title={props.location.title}  />
        <CssBaseline />
        <Avatar className={classes.avatar}>
          <Wallpaper />
        </Avatar>
        <Typography component="h1" variant="h5">
          { props.location.customContent && props.location.customContent }
        </Typography>
        <Grid container spacing={3} style={{marginTop:'10px'}}>
          <Grid item xs={12} justify="center" >
            <QRCode size="500" style={{width:"100%"}} value={props.location.value} /> 
          </Grid>
          <Grid item xs={12} justify="center" >
              <Button style={{width:'100%'}}  variant="contained" color="primary" onClick={() => window.open(props.location.value, '_blank')}>{t("trigger_manually")}</Button>
          </Grid>
        </Grid>
      <AppFooter />
    </Container>
    );
}

export default withTranslation()(QQRCode);