import { Fab, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import SessionContext from '../../../../frame/SessionContext';
import StatusTicket from '../../../assets/StatusTicket';
import DateTimeDisplay from '../../../../pages/assets/DateTimeDisplay';
import CodeIcon from '@material-ui/icons/Code';
import config from 'react-global-configuration';




export default function WaitingCardInfo(props) {
    const { info } = props;
    const { ticket } = props;
    const { style } = props;
  

    const getStyle = () => {
        if(style) {
            return style;
        }
        return {width:"100%", textAlign:"left", fontWeight:"bold"};
    }

    return (
      
          <Grid  container>
            
            
            { info && <Grid item xs={12}>
                <Typography  variant="body1" style={getStyle()}>
                    {info}    
                </Typography>                                                       
            </Grid>  }
            <Grid item xs={12}>
                    <StatusTicket ticket={ticket} style={{size:"1.8rem" }} />                                   
            </Grid>  
          
          </Grid>
    );
}