import axios from "axios";
import config from 'react-global-configuration';

class UploadFilesService {
  upload(ticketId, files, onUploadProgress) {
    let formData = new FormData();

    formData.append("attachements", files);
    const url = config.get('vqueueUrl')+"/api/attachements/ticket/"+ticketId;
    return axios.post(url, files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    const url = config.get('vqueueUrl')+"/files";
    return axios.get(url);
  }
}


export default new UploadFilesService();
