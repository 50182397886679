import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import { t } from 'i18next';

class UserTableHeader extends Component { 
    
    

      render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography  variant="body1">
                        <span className="label">{t("first_name")}</span>  
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography  variant="body1">
                        <span className="label">{t("last_name")}</span>  
                      </Typography>
                    </Grid>
                    <Grid item xs={3}  >
                      <Typography  variant="body1">
                        <span className="label">{t("email")}</span>  
                      </Typography>
                    </Grid>
                    <Grid item xs={1}  >
                      
                    </Grid>
                    <Grid item xs={3}  >
                      <Typography  variant="body1">
                       
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default UserTableHeader;