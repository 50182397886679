import React, { Component, Fragment } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { t } from 'i18next';


class CountDown extends Component {

  constructor() {
    super();
    this.state = {
      date: new Date() 
    };
  }

  componentDidMount() {
    this.timer = setInterval(async () => {
      this.setState({ date: new Date() }) 
    }, 1000)   
  }
  
  componentWillUnmount(){
    clearInterval(this.timer);
  }


  renderCountdown() {
    var rest = this.getRest();
    if(rest<0) {
      return <span>--:--:--</span>
    }
    let seconds =  ("0" + Math.floor((rest / 1000) % 60)).slice(-2);
    let minutes =  ("0" + Math.floor((rest / 1000 / 60) % 60)).slice(-2);
    let hours = ("0" + Math.floor((rest / (1000 * 60 * 60)) % 24)).slice(-2);
    let days = Math.floor(rest / (1000 * 60 * 60 * 24));
    if(days>0) {
      return <span>{days} {t("days-")} {hours}:{minutes}:{seconds}</span>
    } else {
      return <span>{hours}:{minutes}:{seconds}</span>
    }
  }

  getRestLinea() {
    var rest = this.getRest();
    let full = 6000000;
    let calRest = 100/full*rest;
    return calRest>100?100:calRest;
  }

  getRest() {
    var now = new Date().getTime();
    if(this.props.targetTime) {
      var targetTime = this.props.targetTime;
      return targetTime-now;
    } else {
      var millisAhead = this.props.millisAhead;
      return now+millisAhead;
    }
  }

  render() {
    return (
      <Fragment>
        { !this.props.hideLabel && this.props.label}  {!this.props.hideLabel && this.renderCountdown()  }
        { !this.props.hideProgress && <LinearProgress variant="determinate" value={this.getRestLinea()} /> }
      </Fragment>
    );
  }
}


export default CountDown;