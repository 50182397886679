import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../../pages/assets/AppFooter';
import DeskHeader from '../../pages/assets/DeskHeader';
import { MessageContext } from '../../pages/assets/MessageProvider';
import TimeRangeSelector from '../../pages/assets/TimeRangeSelector';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../../frame/SessionContext';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import ActionMenu from '../../pages/assets/ActionMenu';
import { withTranslation } from 'react-i18next';
import WeekDaySelector from '../assets/WeekDaySelector';
import Crumbs from '../Crumbs';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function EditSlot(props) {
  const {t} = props;
  const classes = useStyles();
  
  const { vQueue } = props.location;
  const { vSlot } = props.location;
  const [slot, getSlot] = useState(vSlot);

  const [intervals] = useState(["NO_INTERVAL","DAILY","WEEKLY"]);
  const [startTime, setStartTime] = useState(slot?slot.startTime:"");
  const [endTime, setEndTime] = useState(slot?slot.endTime:"");
  const [interval, setInterval] = useState(slot?slot.interval:"");
  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    SessionContext.setSlot(slot);    
   
  }, [] )

  
  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save Ticket.");
    let queue = vQueue;
    let slotEntity = slot?slot:{};
    slotEntity.startTime = startTime;
    slotEntity.endTime = endTime;
    slotEntity.interval = interval;
    slotEntity.queue = queue;
    const url = config.get('vqueueUrl')+'/api/slots';
    if(slotEntity.id) {
      axios.put(url,  slotEntity )
      .then(res => {
        addMessage(t("opening_hours_changed"));
        history.push("/my/groups/slotmanager/settings/slots")
      })
    } else {
      axios.post(url,  slotEntity )
      .then(res => {
        addMessage(t("opening_hours_saved"));
        history.push("/my/groups/slotmanager/settings/slots")
      })
    } 
  }

  function getMenuItems() {
    return [
      slot.id &&
          <MenuItem onClick={() => deleteEntity()}>{t("delete_opening_hour")}</MenuItem>  
    ]
  }

  function deleteEntity() {
    if(slot.id) {
      const url = config.get('vqueueUrl')+'/api/slots/'+slot.id;
        axios.delete(url)
        .then(res => {
          addMessage(t("opening_hours_deleted"));
          history.push("/my/groups/slotmanager/settings/slots");
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
      } 
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader 
      title={<Fragment><Crumbs queue={vQueue}/> : <Fragment>{vSlot?t("change_opening_hour"): t("create_opening_hour")}</Fragment></Fragment>}
      backTo="/my/groups/slotmanager/settings/slots"  />
      <CssBaseline />
      <div className={classes.paper}>
        
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("opening_hour")}&nbsp;{ slot && slot.id && <ActionMenu menuItems={getMenuItems()} />  }
        </Typography>
         
        <Typography component="p" >
          {t("opening_hours_marking")}
        </Typography>          
            
        <form className={classes.form} onSubmit={handleSubmit}>          
          <Grid container spacing={2}>
            <Grid item xs={12}  >
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}  >

                  <TimeRangeSelector
                    start={new Date(startTime ? startTime : Date.now())}
                    setStartDate={setStartTime}
                    chooseBegin={true}
                        
                    end={new Date(endTime ? endTime : Date.now())}
                    setEndDate={setEndTime}
                    chooseEnd={true}

                    hideSwitch={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <FormLabel id="demo-simple-select-outlined-label">{t("repetition")}</FormLabel>
                  <RadioGroup row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={interval} 
                    onChange={(e) => setInterval(e.target.value)}
                    label={t("interval")}
                  >
                    {intervals.map((interval,i) => {
                      return (
                        <FormControlLabel   key={i} value={interval} control={<Radio />} label={t("slot_intervall_"+interval)} />
                      )
                    })}
                </RadioGroup>
              </FormControl>
            </Grid> 
            
            <Grid item xs={12}  >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                // disabled={!validateForm()}
                >
                {t("save")}
              </Button> 
            </Grid>   
          </Grid>
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditSlot);