import React, { Component, Fragment } from 'react';



class TargetDate extends Component {

  renderTargetDate() {
    if(this.props.targetTimestamp) {
      var then = new Date(this.props.targetTimestamp);
    }
    if(this.props.millisAhead) {
      var now = new Date().getTime();
      then = new Date(this.props.millisAhead+now);      
    }
    var options = this.props.options; 
    return <span style={{whiteSpace: "nowrap"}}>{then && then.toLocaleDateString("de-DE", options)}</span>
  }

 
  render() {
    return (
      <Fragment>
        {this.renderTargetDate()}
      </Fragment>
    );
  }
}


export default TargetDate;