import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TicketGrid from '../pages/assets/CardGrid';
import { ExpandLessOutlined, ExpandMoreOutlined, LocationCity } from '@material-ui/icons';
import LocationData from '../pages/assets/LocationData';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function ResourceVariants(props) {
  const {t} = props;
  const classes = useStyles();
  const { vPlace } = props.location;
  const [resourcevariants, setResourceVariants] = useState([]);
  const [resourcevariant] = useState({});
  const [ showMore, setShowMore ] = useState(false)
  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setResourceVariant(null);    
    const url = config.get('vqueueUrl')+'/api/resource-variants/';// TODO, use current place id later here. +vPlace;
    axios.get(url )
        .then(res => {
          setResourceVariants(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })   
  }, [] )

  function getGridContent() {
    let gridContent = [];
    resourcevariants.forEach(function (aResourceVariant, index) {
      let entry =  {
        title: <span className="label" style={{whiteSpace: "nowrap"}}>{aResourceVariant.name}</span>  ,
        text: <Fragment>   
                
                <Grid item xs={12}  >
                  <Paper style={{paddingLeft:"10px", marginBottom:"5px"}}>
                    <Typography component="p" variant="body1" >
                      {aResourceVariant.decription}    
                    </Typography>
                  </Paper>           
                </Grid>  
                <Grid item xs={4}  >
                  <Typography component="p" variant="subtitle1" align="center">
                    {t("duration_cycle")}     
                  </Typography>
                  <Typography component="p" variant="h4" align="center">
                    {aResourceVariant.speed}          
                  </Typography>    
                  <Typography component="p" variant="caption" align="center">
                    {t("minute")}{aResourceVariant.speed>1?t("n"):''}    
                  </Typography>                  
                </Grid>
                <Grid item xs={4}  >
                    <Typography component="p" variant="subtitle1" align="center">
                      {t("buffer_before")}   
                    </Typography>
                    <Typography component="p" variant="h4" align="center">
                      {aResourceVariant.preBuffer?aResourceVariant.preBuffer:'-'}          
                    </Typography>
                    <Typography component="p" variant="caption" align="center">
                    {t("minute")}{aResourceVariant.preBuffer>1?t("n"):''}    
                    </Typography>  
                </Grid>
                <Grid item xs={4}  >
                  <Typography component="p" variant="subtitle1" align="center">
                    {t("buffer_after")}    
                  </Typography>
                  <Typography component="p" variant="h4" align="center">
                    {aResourceVariant.postBuffer?aResourceVariant.postBuffer:'-'}          
                  </Typography>
                  <Typography component="p" variant="caption" align="center">
                  {t("minute")}{aResourceVariant.postBuffer>1?t("n"):''}    
                  </Typography>        
                </Grid>
                <Grid item xs={12}  >
                  {aResourceVariant.description}        
                </Grid>
              </Fragment>,action: <Fragment>
                  <Grid item xs={12}  >
                    <Link to={{pathname: '/my/groups/resources/variants/edit', vResourceVariant:aResourceVariant}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                        {t("Change")}
                      </Button>
                    </Link>
                  </Grid>    
           </Fragment>,
        menuItems: getMenuItems(aResourceVariant),
        cardStyle:{height: "18.5rem"},
        object: aResourceVariant
      };
      gridContent.push(entry);
    });
    return gridContent;
  }

  function getMenuItems(vResourceVariant) {
      return [
        <MenuItem >
          <Link to={{pathname: '/my/groups/resources/variants/edit', vResourceVariant:vResourceVariant}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
            {t("Change")}
            </Button>
          </Link> 
        </MenuItem>
        ]
  }

  function getFabButton() {
    return  <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={{pathname: '/my/groups/resources/variants/edit', resourcevariant:resourcevariant}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }
  
  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link to={{pathname: '/my/groups/resources/variants/edit', resourcevariant:resourcevariant}} 
          style={{color: "inherit", textDecoration: "none"}}>
            <AddIcon />            
      </Link> 
    );
    return quickActionItems;
  }
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={t("all_resources")} backTo={"/my/groups/resources"} quickActions={getQuickActionItems()}
          />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
                {t("all_alternatives")}
        </Typography>
        <Grid container>  
            <Grid xs={12}>
              <Link to={{pathname: '/my/groups/resources/variants/edit', resourcevariant:resourcevariant}}  style={{color: "inherit", textDecoration: "none"}}>
                <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
                    {t("new_alternaive")}
                </Button>
              </Link>                    
            </Grid>
          
          <Grid xs={12}>
            
            <TicketGrid gridContent={getGridContent()}/>
          </Grid>  
        </Grid>
       
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
}

export default withTranslation()(ResourceVariants);