
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import "moment/locale/de";
import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import config from 'react-global-configuration';
import SockJsClient from 'react-stomp';
import SessionContext from '../frame/SessionContext';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import Crumbs from "../queue/Crumbs";
import TicketList from './TicketList';
import { withTranslation } from "react-i18next";

class TicketCallBoard extends Component {
  static contextType = MessageContext
  
  
  constructor() {
    super();
    this.entryBuffer = [];
    this.state = {
      queuesTickets : []
    };
    this.timer = setInterval(async () => {
      this.renderEntryBuffer();
    }, 1000)   
  }

  componentDidMount() {
    let state = {       
    }
    
    let queue = this.props.location.queue;
    if(!queue || queue==null) {
      queue = SessionContext.getActiveQueue(); 
    } 
    if(queue && queue!=null) {
      state.queue = queue;          
    }  
    state.selectedQueue = queue;  
                
    this.setState(state);        
  }

  renderEntryBuffer() {
    console.log("renderEntryBuffer "+this.entryBuffer);
    if(this.entryBuffer.length>0) {
      this.state.queuesTickets = this.entryBuffer;
      this.entryBuffer = [];
      this.setState(this.state);
    }
  }
  
  showAlert(message) {
    const msgCtx = this.context
    msgCtx.addMessage(message);
  }


  sendMessage = (msg) => {
    this.clientRef.sendMessage('/topics/all', msg);
  }

  processTicket(newTicket) {
    console.log("QueueTicketList "+newTicket.id);
    console.log("QueueTicketList "+newTicket.primaryStatus);
    console.log("QueueTicketList "+newTicket.secondaryStatus);
    console.log("QueueTicketList this.state.queuesTickets "+this.state.queuesTickets);
    let entries = this.state.queuesTickets;
    let index = entries.findIndex(ticket => {
      console.log("QueueTicketList loop newTicket"+newTicket.id);
      console.log("QueueTicketList loop ticket"+ticket.id);
      return ticket.id==newTicket.id; 
    });
    if(index>-1)  {
      console.log("QueueTicketList Ticket IS in List.");    
      if(newTicket.secondaryStatus!="CALL" && newTicket.secondaryStatus!="LAST_CALL") {
        entries.splice(index, 1);         
      } else {
        entries[index] = newTicket;
      }
    } else {
      if(newTicket.secondaryStatus=="CALL" || newTicket.secondaryStatus=="LAST_CALL") {
        entries.unshift(newTicket);
      } 
    }
    this.entryBuffer = entries;
  }

  getDayOfCurrentDate() {
    var dateDay = new Date(this.state.currentDateFrom);
    dateDay.setHours(0);
    dateDay.setMinutes(0);
    dateDay.setSeconds(0);
    dateDay.setMilliseconds(0);
    console.log("dateDay "+dateDay.getTime());    
    return dateDay.getTime();
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        { this.state.selectedQueue && 
        <SockJsClient url={config.get('broadcaster')+'/ws/ws-tickets'}
          topics={['/topic/queues/'+this.state.selectedQueue.id]}
          onConnect={() => {
              console.log("QueueTicketList connected to topic /topic/queues/"+this.state.selectedQueue.id);
          }}
          onDisconnect={() => {
              console.log("QueueTicketList Disconnected topic /topic/queues/"+this.state.selectedQueue.id);
          }}
          onMessage={(ticket) => {
            console.log("QueueTicketList onMessage  "+this.state.selectedQueue.id);
              this.processTicket(ticket);              
          }}
          onConnectFailure={() => {
            console.log("QueueTicketList Could not connect topic /topic/queues/"+this.state.selectedQueue.id);
        }}
          ref={(client) => {
              this.clientRef = client
          }}/> }
          <DeskHeader backTo={SessionContext.getQSettingsOrigin()?SessionContext.getQSettingsOrigin():"/"}
              title={<Crumbs queue={this.state.selectedQueue}/>}
            />
        <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
            <Grid container spacing={5}>  
              <Grid item xs={12} style={{textAlign: "center"}}>
                <Grid container spacing={5}>  
                  <Grid item xs={12}  >
                    <Typography variant="h4">
                        {t("next_are")}
                    </Typography>       
                  </Grid>   
                </Grid>                          
              </Grid>
              <Grid xs={12}>
                  { this.state.queuesTickets && <TicketList primaryStatus={this.state.primaryStatus} 
                      secondaryStatus={this.state.secondaryStatus} 
                      tickets={this.state.queuesTickets} 
                      queue={this.state.selectedQueue}
                      call={true}/>    
                    }
              </Grid>
            </Grid>
          </Container>
    </div>
    );
  }
}


export default withTranslation()(TicketCallBoard);