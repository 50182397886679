import { Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import SessionContext from '../../../frame/SessionContext';
import Ticket from '../../../pages/assets/Card';
import CountDown from '../../../pages/assets/CountDown';
import DateTimeDisplay from '../../../pages/assets/DateTimeDisplay';
import { MessageContext } from '../../../pages/assets/MessageProvider';
import ServiceCard from '../../../pages/assets/ServiceCard';
import TicketData from '../../../pages/assets/TicketData';
import ChatMessage from '../../../queue/ChatMessage';
import Crumbs from '../../../queue/Crumbs';
import Gaps from '../../../queue/Gaps';
import SlotsDisplay from '../../../queue/SlotsDisplay';
import ResourcesStatus from '../../../resources/ResourcesStatus';
import ActionMenu from '../../assets/ActionMenu';
import ButtonUpdateState from '../../assets/ButtonUpdateState';
import WaitingCardInfo from './waitingcard/WaitingCardInfo';
import { withTranslation } from 'react-i18next';

var QRCode = require('qrcode.react');

class RoomTicketWrapper extends Component { 
  static contextType = MessageContext

    constructor() {
      super();
      this.state = {
      
      };
      this.toggleModal = this.toggleModal.bind(this);

      
    }
  
    componentDidMount() {
      this.loadWaitingData();
    }
    
    loadWaitingData() {
      if(this.props.queue && this.props.queue.currentTicket) {
        axios.get(config.get('vqueueUrl')+'/api/tickets/uuid/'+this.props.queue.currentTicket.uid)
          .then(response => { 
            SessionContext.refreshTicket(response.data);         
          }
        ).catch(function (error) {
          console.log(error);
        });      
      }   
  }
    
      
       toggleSubMenu(event) {
        this.setState({
          menuAnchor : event.currentTarget
        })
      }
    
      
      closeSubMenu() {
        this.setState({
          menuAnchor : null
        })
      }
    
     
     
      
 
      componentDidUpdate() {
        const msgCtx = this.context;
        console.log("Room componentDidUpdate: ");
        let uTicket = msgCtx.ticket;
          if(uTicket ) {
            console.log("msgCtx.ticket: "+JSON.stringify(uTicket));
            if(uTicket.queue.id==this.props.queue.id) {
              let queue = this.props.queue;
              queue.currentTicket=uTicket;
              queue = SessionContext.addTicket(queue,msgCtx.ticket);
              if(this.props.ticketChanged) {
                this.props.ticketChanged(msgCtx.ticket);
              }
              this.setState({
                ticket: msgCtx.ticket      
              });  
              msgCtx.removeTicket();
            }                      
          } 
      }
     

      redeemWithProcessingTime(time) {
        console.log("Processing Time: "+time);
        if(this.props.queue.currentTicket) {
          axios.post(config.get('vqueueUrl')+'/api/redeemTicket?ticketUid='+this.props.queue.currentTicket.uid+'&expectedProcessingTime='+time)
          .then(response => { 
              this.toggleModal();          
            }
          ); 
        } 
      }
    
      toggleModal() {
        this.setState({
          showProcessingTimeSelector: !this.state.showProcessingTimeSelector
        })
      } 
    
    
      
      extendProcessingTime() { //after redemption, the ticket starts with a default processing time. This can be extended.
        this.state.processingConfirmationOperation=(time) => this.requestExtendProcessingTime(time);
        this.toggleModal();
      }
      
      requestExtendProcessingTime(extensionTime) { 
          if(this.props.queue.currentTicket) {
          axios.post(config.get('vqueueUrl')+'/api/extendTicket?ticketUid='+this.props.queue.currentTicket.uid+'&extendTime='+extensionTime)
          .then(response => { 
              this.toggleModal();
              console.log("Extending Processing Time DONE.")
            }
          ); 
        } 
      }  

    
      getAddInfo(currentTicket) {
        if(this.props.addInfo) {
          return this.props.addInfo;
        } 
        return <Fragment>
          {  this.props.queue.queue && <Fragment>
                <span>{this.props.queue.queue.name}</span>
              </Fragment> 
          }
          
      </Fragment> ;
      }
    
      getContentWatching() {
        if(this.props.contentWatching) {
          return this.props.contentWatching;
        } 
        const {t} = this.props;
        return <Fragment>
                { this.props.queue && (!this.props.queue.currentTicket || (this.props.queue.currentTicket && this.props.queue.currentTicket.primaryStatus==='FINALIZING'))  &&  
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} md={12}  >
                      { 
                        !this.props.queue.currentTicket &&
                        <span className="label">
                          {t("not_queued_up")}
                        </span>
                      }
                                          
                      { this.props.queue.currentWaitingTime>0 &&
                        <p className="value">
                          <span className="label">{t("current_waiting_time_endures")}</span>
                          <span className="value"><CountDown hideLabel={false} hideProgress={true} targetTime={this.props.queue.currentWaitingTime}/></span> 
                        </p>
                      }
                      
                    </Grid>
                    
                    
                    
                  </Grid>  
              }                                                        
        </Fragment>
      }
  
      getCardActions() {
        if(this.props.actions) {
          return this.props.actions;
        } 
        const {t} = this.props;
        return <Fragment>
           
              {
                (this.isWaiting() || this.isApprovalPending() || this.isCalled() || this.isReadyToRedeem()) &&  
                <Grid item xs  style={{paddingTop:"1rem"}}>
                    <ButtonUpdateState
                        ticket={this.props.queue.currentTicket}
                        onClick={this.props.onClickReturn}
                        color="primary"
                        title={t("Return")}
                        message={`${this.props.queue.currentTicket.uid}`+" "+t("was_returned")}
                        primaryStatus="FINALIZING"
                        secondaryStatus="RETURNED"
                        variant="contained" />    
                    </Grid>
              }
    
              {
                (this.isReadyToRedeem() || this.isCalled()) && 
                <Grid item xs style={{paddingTop:"1rem"}}>
                     <ButtonUpdateState
                      ticket={this.props.queue.currentTicket}
                        color="primary"
                        title={t("cash")}
                        message={`${this.props.queue.currentTicket.uid}`+" "+t("begins_process")}
                        primaryStatus="PROCESSING"
                        secondaryStatus="START_PROCESS"
                        variant="contained" />      
                </Grid>
              }  

              { this.isSuggestionPending() && 
              <Fragment> 
                  <Link to={{pathname: '/reviewRequest', ticket: this.props.queue.currentTicket, queue: this.props.queue, manage: false}}  
                      style={{ color: "inherit", textDecoration: "none", width:'100%'}}>
                      <Button variant="contained" color="primary"  style={{width:'100%'}} >{t("check_proposal")}</Button>
                  </Link>         
                    
              </Fragment>
              }  
         </Fragment>
      }

      //State helper

    isWaiting() {
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.primaryStatus==='WAITING' && this.props.queue.currentTicket.secondaryStatus==='WAIT'
    }


    isCalled() {
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.primaryStatus==='WAITING' && this.props.queue.currentTicket.secondaryStatus==='CALL'
      
    }
    
    isFinished() {
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.primaryStatus==='FINALIZING'
      
    }
    
    isSuggestionPending() {    
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.secondaryStatus==='APPOINTMENT_REQUEST_CLIENT_OPERATOR'
      
    }
    

    isReadyToRedeem() {
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.primaryStatus==='WAITING' && this.props.queue.currentTicket.secondaryStatus==='LAST_CALL'
    }

    
    isWatching() {
      return this.props.watching ||  (this.props.queue && (!this.props.queue.currentTicket || !this.props.queue.currentTicket.id ||  this.props.queue.currentTicket.primaryStatus=='FINALIZING'))  ;
    }
    
    
    isApprovalPending() {
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.primaryStatus==='PREPARING' && this.props.queue.currentTicket.secondaryStatus==='PULL_REQUESTED'
    }

    isInProcess() {
      return this.props.queue && this.props.queue.currentTicket 
      && this.props.queue.currentTicket.primaryStatus==='PROCESSING' && this.props.queue.currentTicket.secondaryStatus==='PROCESS'
    }

    getColorByState() {
      if(this.props.queue.currentTicket ) {
        var primaryStatus=this.props.queue.currentTicket.primaryStatus;
        var secondaryStatus=this.props.queue.currentTicket.secondaryStatus;
        if(primaryStatus==='WAITING') {
          if(secondaryStatus==='WAIT') {
            return "blue";
          }
          if(secondaryStatus==='CALL') {
            return "yellow";
          }
          if(secondaryStatus==='LAST_CALL') {
            return "orange";
          }
        }
      }
      return "grey"
    }

    
    shareLink() {
      const {t} = this.props;
      let loc = window.location.protocol + "//" + window.location.hostname
      if (window.location.port) {
          loc = loc + ":" + window.location.port;
      }
      
      let linkTicket = loc + "/wallet?tuid=" + this.props.queue.currentTicket.uid;
      /* window.open(linkTicket, '_blank'); */
      navigator.clipboard.writeText(linkTicket);
      this.context.addModalMessage(t("temporary_save"));  
  }

    

    
    getTicketContent() {
      const {t} = this.props;
      return {
        crumb: <Crumbs ticket={this.props.queue.currentTicket}/>,
        number: this.props.queue && this.props.queue.currentTicket && <Fragment> { this.props.queue.currentTicket.position +"-"+this.props.queue.currentTicket.id }</Fragment> ,
        title: <span className="label" style={{whiteSpace: "nowrap"}}>
          { this.props.queue.currentTicket && 
            <DateTimeDisplay timestamp={this.props.queue.currentTicket.startPlanned} hideTime={true} options={{day: '2-digit', month: '2-digit', year: 'numeric'}}/>
          }</span>  ,
        text:<Fragment>
            { this.props.queue.currentTicket!=null &&  this.props.queue.currentTicket.status!="SERVICE" &&
              <TicketData ticket={this.props.queue.currentTicket}  queue={this.props.queue}/>
            }
            { this.props.queue.currentTicket!=null && this.props.queue.currentTicket.status=="SERVICE" &&
              <ServiceCard ticket={this.props.queue.currentTicket} />
            }
            { this.props.queue.currentTicket==null && 
              <Fragment> 
                <Grid item xs={12}  >
                  <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
                    <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                      {t("next_time_slot_your_slot")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}  >
                  <Grid container spacing={0} alignItems="center" justify="center" >
                    <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
                      <span >{t("book_now")}</span>    
                    </Typography>
                  </Grid>
                </Grid>
              </Fragment>  
            } 
          </Fragment>,
        menuItems: this.getMenuItemsTicket(),
        //details: this.getDetails(),
        ticket: this.props.queue.currentTicket!=null && this.props.queue.currentTicket
      }
    }

    getLatestMessage() {
      const {t} = this.props;
        let latestMessage = <Grid item xs={12}  >
        <Grid container>
          <Grid item xs={12}  >
            <span className="label" style={{fontSize:"1.1rem"}}>{t("current_informatiion")}</span>
          </Grid>
          <Grid item xs={12}  >
            <Grid container>
              <Grid item xs={10}  >
                <Typography  variant="body1">
                  <span className="label" style={{fontSize:"0.9rem"}}>{t("no_avaiable")}</span>
                </Typography>          
              </Grid>
              <Grid item xs={2}  align="right" >
                
              </Grid>
            </Grid>
          </Grid>          
        </Grid>
      </Grid>;
        let allMessages = this.props.queue.currentTicket.message;
        if(allMessages) {
          let jsonMessages = JSON.parse(this.props.queue.currentTicket.message);
          jsonMessages.forEach(function (aMessage, index) {
            latestMessage=
              <Grid item xs={12}  >
                <Grid container>
                  <Grid item xs={12}  >
                    <span className="label" style={{fontSize:"1rem"}}>{t("current_informatiion")}</span>
                  </Grid>
                  <Grid item xs={9} style={{fontSize:"0.8rem"}} >
                    <ChatMessage message={aMessage} timestamp={true} sender={false}/>
                  </Grid>                                    
                </Grid>
              </Grid>;
          }.bind(this));
      }
      return latestMessage;
    }

    getDetails() {
      const {t} = this.props;
      return <Fragment>
                <Grid container spacing={1} alignItems="center" justify="center"  >
                  <Container maxWidth="sm" >
                  <Grid xs={12} justify="center" >
                        <Grid container spacing={0}>                            
                        {
                          this.isInProcess() &&
                          <WaitingCardInfo
                            title={t("remaining_process_duration")}
                            info={<CountDown hideLabel={false} hideProgress={true} targetTime={this.props.queue.currentTicket.endPlanned}/> }
                            description="" 
                            ticket={this.props.queue.currentTicket}/>
                          
                          } 
                        { this.isReadyToRedeem() &&
                          <WaitingCardInfo
                            title={t("it_begins")}
                            info={<CountDown hideLabel={false} hideProgress={true} targetTime={this.props.queue.currentTicket.redeemEndTime}/> }
                            description={t("your_timeslot_begins")}
                            ticket={this.props.queue.currentTicket} />
                          
                        } 
                        {
                        this.isWaiting() && 
                          <WaitingCardInfo
                            title={t("current_waiting_time")}
                            info={<CountDown hideLabel={false} hideProgress={true} targetTime={this.props.queue.currentTicket.startPlanned}/> }
                            description={t("you_will_be_informed")}
                            ticket={this.props.queue.currentTicket} />
                        }
                        {
                        this.isCalled() &&
                          <WaitingCardInfo
                                title={t("attention")}
                                info={<CountDown hideLabel={false} hideProgress={true} targetTime={this.props.queue.currentTicket.startPlanned}/> }
                                description={t("your_turn_soon")}
                                ticket={this.props.queue.currentTicket} />
                        }  
                        { this.isFinished() && 
                          <Fragment>
                            { this.props.queue.currentTicket.secondaryStatus==='COMPLETED' &&
                              <WaitingCardInfo
                                title={t("Done")}
                                description={t("appointment_finished")}
                                ticket={this.props.queue.currentTicket} />
                              
                            }
                            { this.props.queue.currentTicket.secondaryStatus==='CALL_MISSEED' &&
                              <WaitingCardInfo
                                title={t("call_missed")}
                                description={t("appointment_over")}
                                ticket={this.props.queue.currentTicket} />
                              
                            }
                            { this.props.queue.currentTicket.secondaryStatus==='EXPIRED' &&
                              <WaitingCardInfo
                                title={t("Run_out")}
                                description={t("appointment_run_out")}
                                ticket={this.props.queue.currentTicket} />
                              
                            }
                            { this.props.queue.currentTicket.secondaryStatus==='RETURNED' &&
                              <WaitingCardInfo
                                title={t("Given_back")}
                                info=""
                                description={t("appointment_given_back")}
                                ticket={this.props.queue.currentTicket} />
                              
                            }
                          </Fragment>    
                          }    
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>                            
                        { this.isWatching()  &&
                          this.getContentWatching()
                        }

                        { this.isApprovalPending() &&
                            <Grid container spacing={1}>
                              <Grid xs={12} justify="center" >
                                <span className="label-important">
                                    {t("your_application_will_be_handled")}
                                </span>
                              </Grid>
                              { this.props.queue.currentTicket.startWish && 
                                <Fragment>
                                  <Grid xs={12} md={2}  justify="center" >
                                    <Typography gutterBottom variant="body1">
                                      <span className="label">{t("prefered_begin")}</span>
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={10}  justify="center" >
                                    <span className="value">
                                        <DateTimeDisplay timestamp={this.props.queue.currentTicket.startWish} />
                                    </span>
                                  </Grid>
                                </Fragment>
                              } 
                              { this.props.queue.currentTicket.endWish && 
                                <Fragment>
                                  <Grid xs={12} md={2} justify="center" >
                                    <Typography gutterBottom variant="body1">
                                      <span className="label">{t("prefered_end")}</span>
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={10} justify="center" >
                                      <span className="value">
                                        <DateTimeDisplay timestamp={this.props.queue.currentTicket.endWish} />
                                      </span>
                                  </Grid>
                                </Fragment>
                              }
                              <Grid xs={12} justify="center" >
                                <Typography gutterBottom variant="body1">
                                  <span className="label">{t("contacts")}</span>
                                </Typography>
                              </Grid>
                              <Grid xs={12} justify="center" >
                                  <span className="value">
                                  {this.props.queue.currentTicket.contact} 
                                  </span>
                              </Grid>
                              <Grid xs={12} justify="center" >
                                <Typography gutterBottom variant="body1">
                                  <span className="label">{t("further_information")}</span>
                                </Typography>
                              </Grid>
                              <Grid xs={12} justify="center" >
                                  <span className="value">
                                  {this.props.queue.currentTicket.extraData} 
                                  </span>
                              </Grid>
                          </Grid>
                        }
                      </Grid>
                      
                      <Grid xs={12} justify="center" style={{marginBottom:"5px", marginTop:"5px", paddingBottom:"0.5rem"}} >
                        <Grid container spacing={0}>    
                         {this.getLatestMessage()} 
                        </Grid>
                      </Grid>


                      
                      
                     

                      {/*Grid xs={12} justify="center" style={{paddingBottom:"1rem"}}>
                        <Grid container spacing={1}>       
                        {this.getCardActions(this.props.queue.currentTicket)} 
                        </Grid>                                                                                                     
                      </Grid>   */ }                                                                                                           
                    </Grid>
                  </Container>
                </Grid>
              </Fragment>   
    }

    getMenuItemsTicket() {
      return [
        <ActionMenu onClickReturn={this.props.onClickReturn} ticketEditor='/editTicket' queue={this.props.queue} ticket={this.props.queue.currentTicket}/>        
      ]
    }



      render() {
        const {t} = this.props;
        return (
            <Fragment>
              <Grid container spacing={1}>
              {
                !this.isWatching()  &&
                <Grid item xs={12}>
                   
                  { !this.props.queue &&
                      
                      <Grid container spacing={0} alignItems="center" justify="center" >
                        <Grid item xs={12}  >
                          <CircularProgress style={{marginLeft: '50%'}}/>
                        </Grid>
                        <Grid item xs={12}  >
                          <Typography variant="h5" align="center" color="textSecondary" component="p">
                              {t("checking_wait_situation")}
                          </Typography>
                          
                        </Grid>
                      </Grid> 
                  }
                  { this.props.queue && this.props.queue.queueingKind!="SERVICE" &&
                    <Fragment>
                      <Grid container spacing={0} alignItems="center" justify="center" >
                        <Grid item  xs={12} >
                          <Grid container>
                            <Grid item  xs={12}>
                              <Ticket area={this.getTicketContent()} manage={this.props.manage} />
                            </Grid>
                          </Grid>
                        </Grid>                            
                      </Grid>
                  </Fragment>
                  }
                </Grid>
              }
              
              { 
              
                  (this.isWatching() || 
                    ((!this.props.queue.currentTicket 
                      || !this.props.queue.currentTicket.id)
                      && (  this.props.queue.currentTicket.secondaryStatus==='RETURNED' 
                        ||  this.props.queue.currentTicket.secondaryStatus==='CALL_MISSEED'
                        ||  this.props.queue.currentTicket.secondaryStatus==='NO_SLOT_FOUND')))  
                      &&
                  <Fragment>
                    { !this.props.hideTitle &&
                      <Grid item xs={12}>
                        <Typography variant="h4">
                          {t("queue_up")}
                        </Typography>
                        <Typography variant="p">
                          {t("choose_avaiable_timeslot")}
                        </Typography>
                          
                      </Grid>
                    }
                    <Grid item xs={12} style={{position:"relative"}}>
                      { this.props.pullingTicket &&
                        <Grid container style={{position:"absolute",height:"100%", width:"100%", backgroundColor:"white", margin:"-4px", zIndex:"1000"}}>
                          <Grid item xs={12} >
                            <Grid container spacing={0} alignItems="center" justify="center" >
                              <Grid item xs={12}  >
                                <CircularProgress style={{marginLeft: '50%'}}/>
                              </Grid>
                              <Grid item xs={12}  >
                                <Typography variant="h5" align="center" color="white" component="p">
                                    {t("seatbelt")}
                                </Typography>
                              </Grid> 
                            </Grid> 
                          </Grid> 
                        </Grid> 
                      }
                      {
                        this.props.queue &&
                        <Fragment>
                          {
                            !this.props.hideTitle &&
                            <Grid container spacing={1}  alignItems="center" justify="center" style={{ justifyContent: "center", display: "flex" ,textAlign: "center" }}>
                              <Grid item xs={12} >
                                <SlotsDisplay from={this.props.from} to={this.props.to} queue={this.props.queue} />
                              </Grid>
                              <Grid item xs={12} >
                                  <ResourcesStatus vPlace={this.props.queue.place} vGroup={this.props.queue.group}  from={this.props.from} to={this.props.to} queue={this.props.queue}/>
                              </Grid>
                            </Grid>
                          }
                           <Gaps from={this.props.from} to={this.props.to} pullTicket={this.props.pullTicket} queue={this.props.queue} xs />
                        </Fragment>
                      }
                    </Grid>
                  </Fragment>
                }


                
            </Grid>
            
        </Fragment>        
        );
    }
}

export default withTranslation()(RoomTicketWrapper);