import React, { Component, Fragment } from 'react';
import TargetTime from './TargetTime';
import TargetDate from './TargetDate';



class DateTimeDisplay extends Component {

  getOptions(){
    if(this.props.options) {
      return this.props.options;
    }
    return { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' };
  }

  render() {
    return (
      <Fragment>
        { this.props.timestamp && 
          <Fragment>
            { !this.props.hideDate && <TargetDate targetTimestamp={this.props.timestamp} options={this.getOptions()}/>}
            { !this.props.hideDate && <Fragment>&nbsp;</Fragment>}           
            { !this.props.hideTime && <TargetTime targetTimestamp={this.props.timestamp} options={this.getOptions()} end={this.props.end}/>  }
          </Fragment>
        }
        { !this.props.timestamp && 
          <span>--</span>
        }
      </Fragment>
    );
  }
}


export default DateTimeDisplay;