
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import "moment/locale/de";
import React, { Component, Fragment } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import SessionContext from '../frame/SessionContext';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import TimeScopeBrowser from "../pages/assets/TimeScopeBrowser";
import TicketList from './TicketList';
import PagingBrowser from "../pages/assets/PagingBrowser";
import ResourcesStatus from "../resources/ResourcesStatus";
import { withTranslation } from "react-i18next";
import WebsocketUtil from "../authorization/WebsocketUtil";
import log from 'loglevel';
import { MessageContext } from '../pages/assets/MessageProvider';
import { Typography } from '@material-ui/core';


const now = new Date();
const abortController = new AbortController();


class TicketsListCalendar extends Component {
static contextType = MessageContext;

  constructor() {
    super();
    this.websocketQClient = new WebsocketUtil();
    this.entryBuffer = [];
    this.entryBufferUnplanned = [];
    this.state = {
      initialized: false,
      displayShort : true,
      displayNormal : false,
      displaySlots : false,
      date: new Date(),
      filterMenuAnchor: null,
      overView: false,
      defaultView:"day",
      plannedTickets : [],
      unPlannedTickets : [],
      tickets: false,
      showFilters: false,
      filters: [],
      counts: {},
      timeSlider: true,
      currentStartIndex: 0,
      currentCount: 10
    };
    //this.loadTicketData = this.loadTicketData.bind(this);
    
    this.refreshDataPaging = this.refreshDataPaging.bind(this); 
    //this.loadPagingTickets = this.loadPagingTickets.bind(this); 
    this.renderEntryBuffer = this.renderEntryBuffer.bind(this);    
   /*this.setFrom = this.setFrom.bind(this);
    this.setTo = this.setTo.bind(this);*/
    this.setFilters = this.setFilters.bind(this);
    this.processTicket = this.processTicket.bind(this);
    this.processWebsocket = this.processWebsocket.bind(this);
    
    this.slotsBookableCallback = this.slotsBookableCallback.bind(this);
    
    
   // this.timer = setInterval(async () => {
   //   this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo);
   // }, 5000)
   /* this.circuitBreakter = setInterval(async () => {
      this.lastBroadcast=0;
      this.circuitOpen=true;
      clearInterval(this.circuitBreakter);
      // on websocket connect this is done -> this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo);
    }, 3000);  //break for 3 Seconds*/
    this.lastBroadcast=0;
    this.circuitOpen=false;            
  }


  handleFilter() {
    this.setState({ showFilters: !this.state.showFilters });    
  }

  renderEntryBuffer(timeSlider) {
    log.trace("renderEntryBuffer "+this.entryBuffer);
    let state = {}
    if(this.state.timeSlider!=timeSlider) {
      state.initialized= false; 
      state.timeSlider= timeSlider; 
    }
    if(!this.circuitOpen) {
      state.initialized=false;
    }
    if(this.entryBuffer && this.entryBuffer.length>0 || (!timeSlider && this.state.counts)) {
        let counts = this.extractStatusCounts(this.entryBuffer);
      state.counts= null;
      if(this.entryBuffer && this.entryBuffer.length>0) {
        state.tickets=this.entryBuffer;
      }       
      if(timeSlider) {
        state.counts= counts;      
      } else {
        state.currentStartIndex=0;
        state.currentCount=10;
      }
      this.entryBuffer= [];
      this.setState(state);
    } else if(timeSlider!=this.state.timeSlider) {
      let counts = this.extractStatusCounts(this.state.tickets);
      state.counts= counts;
      state.filters=this.props.filters;
      this.setState(state);
    }
  }

  componentWillUnmount(){
    log.trace("componentWillUnmount "+this.stompClient);
    if(this.websocketQClient) {
        log.trace("componentWillUnmount stompClient disconnect");
        this.websocketQClient.disconnect(this.disconnectCallback());
    }
    clearInterval(this.timer);
    abortController.abort();
    
  }

  componentDidMount() {
    SessionContext.setTicket(null);    
    
    

    

    let uid = null;
    if(this.props.location) {
      let query = new URLSearchParams(this.props.location.search);
      uid = query.get('uid')     
    }
    
    
    let state = { 
      uid: uid,
      filters: this.props.filters      
    }

    state.timeSlider = (this.props && this.props.timeSlider!=undefined)?this.props.timeSlider:this.state.timeSlider;
    
      state.currentDateFrom=this.props.location && this.props.location.from?this.props.location.from:SessionContext.getShowTicketsFrom();
      state.currentDateTo=this.props.location && this.props.location.to?this.props.location.to:SessionContext.getShowTicketsTo();  

      log.trace("found  currentDateFrom-currentDateTo -"+state.currentDateFrom+ "- -"+state.currentDateTo+"-");
    
      if(!state.currentDateFrom || state.currentDateFrom=='undefined') {
        var from = new Date(Date.now());
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);
        log.trace("from: "+from.getTime());
        state.currentDateFrom=from.getTime();
      } else {
        var from = parseInt(state.currentDateFrom);
        state.currentDateFrom=from;
      }

      if(!state.currentDateTo || state.currentDateTo=='undefined') {
        var to = new Date(Date.now());
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(999);
        log.trace("to: "+to.getTime());
        state.currentDateTo=to.getTime();
      } else {
        var to = parseInt(state.currentDateTo);
        state.currentDateTo=to;
      }

    
    
    if(uid!=null) {
      this.loadQueue(uid);
    } else {
      let queue = this.props.queue;
     
      if(queue && queue!=null) {
        state.queue = queue;          
       // this.loadQueue(queue.uid);
      }  
      if(!queue) {
        this.props.history.push({
          pathname: this.props.backTo?this.props.backTo:'/my/groups/slotmanager/dashboard', 
          queue: this.state.selectedQueue, 
          presets: this.state.presets, 
          manage: true
        })
      }
      state.selectedQueue = queue;               
    }
    log.trace("MOUNT DONE  setting State "+state.currentDateFrom+ " "+state.currentDateTo);
                  
    this.setState(state);   
     
  }

  initWithTickets(tickets) {
    log.debug("initWithTickets ");
    let displayCalendar = tickets.length>0;
    let counts = null;
    if(this.state.timeSlider) {
      counts = this.extractStatusCounts(tickets);
    }
    let state = {
      displayCalendar : displayCalendar,
      tickets: tickets,
      counts: counts,
      initialized: true,
      refreshView: false,
      slotsBookable: undefined,
      selectedQueue: this.props.queue
    }

    log.trace("initWithTickets DONE  setting State ");
    if(this.state.timeSlider) {
      state.currentDateFrom=this.props.from;
      state.currentDateTo=this.props.to;
      SessionContext.setShowTicketsFrom(state.currentDateFrom);
      SessionContext.setShowTicketsTo(state.currentDateTo);  
    } 
    

     
    this.setState(state); 

  }

  
  componentDidUpdate(prevProps) {
      log.debug(" componentDidUpdate ");
      log.trace("TicketsListCalendar componentDidUpdate this.state.timeSlider "+this.state.timeSlider);
      log.trace("TicketsListCalendar componentDidUpdate this.state.initialized "+this.state.initialized);
      log.debug("TicketsListCalendar componentDidUpdate this.state.tickets "+(this.state.tickets?this.state.tickets.length:'undefined'));
      log.trace("TicketsListCalendar this.state.slotsBookable "+this.state.slotsBookable);
        //hier läuft was falsch - die Anzahl der Tickets wird in einer View öfters geändert.
      
      log.trace("TicketsListCalendar props: ");
      log.trace("TicketsListCalendar componentDidUpdate this.props.offsetFrom "+this.props.offsetFrom+" prevProps.offsetFrom "+prevProps.offsetFrom);
      log.trace("TicketsListCalendar componentDidUpdate this.props.offsetTo "+this.props.offsetTo+" prevProps.offsetTo "+prevProps.offsetTo);
      log.debug("TicketsListCalendar componentDidUpdate this.props.tickets "+(this.props.tickets?this.props.tickets.length:'undefined')+" prevProps.tickets "+(prevProps.tickets?prevProps.tickets.length:'undefined'));
      log.trace("TicketsListCalendar componentDidUpdate this.props.createTicketLink "+this.props.createTicketLink+" prevProps.createTicketLink "+prevProps.createTicketLink);
      log.trace("TicketsListCalendar componentDidUpdate this.props.from "+this.props.from+" prevProps.from "+prevProps.from);
      log.trace("TicketsListCalendar componentDidUpdate this.props.to "+this.props.to+" prevProps.to "+prevProps.to);
      log.trace("TicketsListCalendar componentDidUpdate this.props.manage "+this.props.manage+" prevProps.manage "+prevProps.manage);
      log.trace("TicketsListCalendar componentDidUpdate this.props.lastUpdate "+this.props.lastUpdate+" prevProps.lastUpdate "+prevProps.lastUpdate);
      log.trace("TicketsListCalendar ----- ");
      
     
      /*if(this.props.readyStatusCallback) {
        this.props.readyStatusCallback(false);
      }*/

      //log.trace("this.state: " + JSON.stringify(this.state));
      
      if((this.props.tickets && !this.state.initialized) || ((prevProps.tickets && this.props.tickets && this.props.tickets.length!==prevProps.tickets.length))) {
        this.initWithTickets(this.props.tickets);
        return;
      }

      
      /*if(!this.state.tickets) {
          if((this.props.from!=prevProps.from) || (this.props.to!=prevProps.to)) {
            this.refreshDataPaging(this.props.from,this.props.to)
            log.debug("loadTicketData 1");
            this.loadTicketData( this.props.from,this.props.to);
          } else {
            
            log.trace("TicketsListCalendar refreshView"+ this.state.refreshView );
            log.trace("TicketsListCalendar filter "+ this.props.filters?this.props.filters.length:0 );
            if(this.state.refreshView || (this.props.lastUpdate!=prevProps.lastUpdate)) {
              if(this.state.timeSlider) {
                log.debug("loadTicketData 2");
                this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo);
              }
              else { // was soll das hier?
                log.debug("loadTicketData 3");
                this.loadTicketData( this.state.currentStartIndex, this.state.currentCount);
              }
            }
          }
        }*/

        
        if(this.state && this.state.selectedQueue && this.state.currentDateFrom && this.state.tickets) {
          log.debug("ok, everything there, issue websocket OR check messageContext for news");
           // this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo);

           /* if(!this.props.manage && !this.websocketQClient.initialized()) {
              this.websocketQClient.websocket(
                "/topic/queues/"+this.state.selectedQueue.id+"/"+this.state.currentDateFrom, 
                () => this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo),
                this.processTicket);
           } else*/ 
           if(this.props.partitionResourceDTO && this.props.partitionResourceDTO.partitionStatus==="READY") {
              this.timer = window.setTimeout(() => {      
                if(this.props.readyStatusCallback) {
                  this.props.readyStatusCallback(true);    
                }         
              }, 2000);
           } else {    
              clearInterval(this.timer);        
              if(this.props.readyStatusCallback) {      
                if(this.props.readyStatusCallback) {
                  this.props.readyStatusCallback(false);    
                }      
              }
           }
            this.processWebsocket();
            /*else if(!this.state.tickets && !this.state.slotsBookable) {
              log.debug("TicketsListCalendar componentDidUpdate loadTickets start "+this.state.tickets.length);
              this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo)
            }*/

      }
    } 

    processWebsocket() {
      const msgCtx = this.context;
      if(false) { //msgCtx.wsTickets ) {
        let tickets = msgCtx.wsTickets;
        log.debug("tickets.length: "+tickets.length+ "  this.state.selectedQueue: "+ this.state.selectedQueue.id);
        //if(tickets.length>1) {
          this.processTicket(tickets);
          msgCtx.removeWsTickets();
          clearInterval(this.timer);           
          if(this.props.readyStatusCallback) {
            this.props.readyStatusCallback(false);    
          }
        //} else {
        //  log.debug("tickets.length is : "+tickets.length+ "  NOT processing here.");
        //}
       
          
      } else {    
        this.timer = window.setTimeout(() => {        
          if(this.props.readyStatusCallback) {
            this.props.readyStatusCallback(true);    
          }   
        }, 2000);  
     }
    }

    disconnectCallback() {
      log.debug("disconnectCallback : "+this.stompClient);
      
    }
 
  toggleFilterMenu(event) {
    this.setState({
      filterMenuAnchor : event.currentTarget
    })
  }

  refreshView(state) {
    if(!state) {
      state = {};
    }
    state.overView=!this.state.overView;
    state.defaultView=!this.state.overView?"week":"day";
    this.setState(state); 
  }
  
  closeFilterMenu() {
    this.setState({
      filterMenuAnchor : null
    })
  }

  loadQueue(uuid) {
    const url = config.get('vqueueUrl')+'/api/queues/uid?uuid='+uuid;
    axios.get(url, {signal: abortController.signal})
      .then((data) => {
        this.setState({ selectedQueue: data.data });              
      });   
  }

  /*loadPagingTickets(startIndex,count) {
    log.debug("loadTicketData 5");
           this.loadTicketData(startIndex,count);
  }*/

  refreshDataPaging(from, to) {
    let state = {
      initialized: false
    };
    if(this.state.timeSlider) {
      state.currentDateFrom=from;
      state.currentDateTo=to;
      state.refreshView=true;
    } 
    else {
      state.currentStartIndex=from;
      state.currentCount=to;                  
    } 
    this.setState(state);  
  }

  loadTicketData(from, to) {/*
    let selectedQueue = this.state.selectedQueue;
    SessionContext.removeRefreshQueue(selectedQueue.id);
    log.trace("loadTicketData "+selectedQueue.id +" "+ from +" "+ to);
   
    let filterString = "";
      if(this.props.filters) {
        this.props.filters.forEach(function (aFilter, index) {
          if(filterString.length!=0) {
            filterString = filterString.concat(",");
          }
          filterString = filterString.concat(aFilter.primaryStatus);
        })
    }
    if(filterString.length==0) {
      filterString = filterString.concat("PREPARING").concat(",").concat("WAITING").concat(",").concat("FINALIZING").concat(",").concat("PROCESSING");
    }

    let url = config.get('vqueueUrl')+'/api/ticketByQID/'+selectedQueue.id+"/"+from+"/"+to+"/"+filterString;
    if(!this.state.timeSlider) {
      url = config.get('vqueueUrl')+'/api/ticketByQIDPageable/'+selectedQueue.id+"/"+from+"/"+to+"/"+filterString;
    }
    const controller = new AbortController();
    const signal = controller.signal;
             
    
    axios.get(url, {signal: abortController.signal})
          .then(response => { 
                let displayCalendar = response.data && response.data.length>0;
                let tickets = response.data;
                let counts = null;
                if(this.state.timeSlider) {
                  counts = this.extractStatusCounts(tickets);
                }
                let state = {
                  displayCalendar : displayCalendar,
                  tickets: tickets,
                  counts: counts,
                 /*  plannedTickets: plannedTickets,
                  unPlannedTickets: unPlannedTickets, /
                  //selectedQueue: this.state.selectedQueue,
                  initialized: true,
                  viewStatusDirty: false,
                  refreshView: false,
                  slotsBookable: undefined
                  //calenderELements: calenderELements,
                  //defaultDisplayStart: defaultDisplayStart,
                  //defaultDisplayEnd: defaultDisplayEnd
                }

                

                log.trace("loadTicketData DONE  setting State ");
                if(this.state.timeSlider) {
                  state.currentDateFrom=from;
                  state.currentDateTo=to;
                  SessionContext.setShowTicketsFrom(state.currentDateFrom);
                  SessionContext.setShowTicketsTo(state.currentDateTo);  
                } 
                else {
                  state.currentStartIndex=from;
                  state.currentCount=to;                  
                } 

                 
              this.setState(state);                
          }
      ); */
    }
  
  extractStatusCounts(tickets) {
    var timeslot = tickets.filter((ticket) => {
      return ticket.primaryStatus=="TIMESLOT" 
    })
    var preparing = tickets.filter((ticket) => {
      return ticket.primaryStatus=="PREPARING" 
    })
    var waiting = tickets.filter((ticket) => {
      return ticket.primaryStatus=="WAITING" 
    })
    var processing = tickets.filter((ticket) => {
      return ticket.primaryStatus=="PROCESSING" 
    })
    var finalizing = tickets.filter((ticket) => {
      return ticket.primaryStatus=="FINALIZING" 
    })
    let counts = {
      all : tickets.length,
      timeslot : timeslot.length,
      preparing : preparing.length,
      waiting : waiting.length,
      process : processing.length,
      finalizing : finalizing.length    
    }
    return counts;   
  }

  getTime(date) {
    var options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }; 
    return <span>{date.toLocaleTimeString("de-DE", options)}</span>
  }

  setFilters( filters) {    
    this.setState({
      filters: filters,
      initialized: false,
      refreshView: true
    });
  }

  optimizePartition() {
    const {t} = this.props;
    axios.get(config.get('vqueueUrl')+'/api/queues/optimize/'+this.state.selectedQueue.id+"/"+this.state.currentDateFrom, {signal: abortController.signal})
            .then(response => { 
              this.showAlert(t("partition_will_be_optimized"));
            }
        );
  }
  defragQ()  {
    const {t} = this.props;
    axios.get(config.get('vqueueUrl')+'/api/queues/defrag/'+this.state.selectedQueue.id)
            .then(response => { 
              this.showAlert(t("waiting_room")+" "+response.data.name+" "+t("will_be_optimized"));
            }
        );
  }
 

  

  getCustomQRCodeContent() {
    const {t} = this.props;
    return <Fragment>
      {t("scan_code_and_queue_up")}
    </Fragment>
  }

  getCustomScannerContent() {
    const {t} = this.props;
    return <Fragment>
      {t("hold_code_in_front_of_line")}
    </Fragment>
  }

  showAlert(message) {
    //const msgCtx = this.context
    //msgCtx.addMessage(message);
  }

  /*setFrom(date) {
    log.trace("setFrom "+date +" - "+this.state.currentDateTo);
    log.debug("loadTicketData 6");
          this.loadTicketData( date, this.state.currentDateTo);
  }

  setTo(date) {
    log.trace("setTo "+this.state.currentDateFrom +" - "+date);
    log.debug("loadTicketData 7");
          this.loadTicketData( this.state.currentDateFrom, date);
  }*/

  partitionReadyCallback() {
    //this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo);
  }

  

 
getFabButton() {
  return  <Fab color="secondary" aria-label="add" 
        style={{position: 'absolute',
            zIndex: 1,
            top: -30,
            left: 0,
            right: 0,
            margin: '0 auto'}}>

      <Link to={{pathname: '/manageTicket', manage: true, queue: this.state.selectedQueue}} 
          style={{color: "inherit", textDecoration: "none"}}>
          <AddIcon />
      </Link> 

      </Fab>   
     
}

  sendMessage = (msg) => {
    this.clientRef.sendMessage('/topics/all', msg);
  }

  /*circuitBreaker() {
    log.debug("circuitBreaker -> "+this.circuitOpen);
    let now = new Date().getTime();
    if((now-this.lastBroadcast)<2000) { //the amount of time since the last message in millis
      this.circuitOpen=false;
      this.circuitBreakter = setInterval(async () => {
        this.lastBroadcast=0;
        this.circuitOpen=true;
        clearInterval(this.circuitBreakter);
        log.debug("loadTicketData 8");
          this.loadTicketData( this.state.currentDateFrom, this.state.currentDateTo);
      }, 3000);  //break for 3 Seconds
    } else {
      this.lastBroadcast = now;
    }
  }*/

  processTicket(ticketUpdates) {
    log.debug("TicketsListCalendar ------------------------------------------------------");
    log.debug("-----------------    TicketsListCalendar processTicket ticketUpdates "+ticketUpdates.length);
    //this.circuitBreaker();
    //PROBLEM: websocket disconnected wenn Ticket angelegt wird, danach reconnect, aber zu spät.
    // if(this.circuitOpen) {
    ticketUpdates = ticketUpdates.filter((ticket) => {
      return ticket.queueId===this.state.queue.id; 
    })
    log.debug("TicketsListCalendar processTicket ticketUpdates after filter "+ticketUpdates.length);
    let entries = this.state.tickets; 
    for(let i=0; i < ticketUpdates.length; i++){
        let updatedTicket = ticketUpdates[i];
        log.debug("TicketsListCalendar currentQ:"+this.state.queue.id+" - "+updatedTicket.queueId+" - "+updatedTicket.primaryStatus+" - "+updatedTicket.secondaryStatus);
        log.trace("TicketsListCalendar updatedTicket.startPlanned"+updatedTicket.startPlanned);
        log.trace("TicketsListCalendar this.state.currentDateTo"+this.state.currentDateTo);
        log.trace("TicketsListCalendar this.state.currentDateFrom"+this.state.currentDateFrom);
        log.trace("TicketsListCalendar this.state.plannedTickets "+this.state.plannedTickets);
        
        if((this.state.currentDateFrom>=updatedTicket.startPlanned && this.state.currentDateTo<=updatedTicket.endPlanned))
        {
          log.debug("TicketsListCalendar Ticket out of current timescope for current q "+this.state.queue.id);
          log.trace("TicketsListCalendar currentDateFrom"+this.state.currentDateFrom);
          log.trace("TicketsListCalendar updatedTicket.startPlanned"+updatedTicket.startPlanned);
          log.trace("TicketsListCalendar updatedTicket.endPlanned"+updatedTicket.endPlanned);
          log.trace("TicketsListCalendar currentDateTo"+this.state.currentDateTo);
          return;      
        }
        log.debug("TicketsListCalendar Ticket IN timescope and/or IT IS for current q");
          
        //this.loadTicketData(this.state.currentDateFrom,this.state.currentDateTo);
        //return;
       

        let index = -1;
        if(updatedTicket.primaryStatus==='BLOCKER' && updatedTicket.secondaryStatus==="RETURNED") {
          index = entries.findIndex(ticket => {
            if(ticket.primaryStatus==='BLOCKER') {
              log.debug("TicketsListCalendar "+ this.state.queue.id +"  loop BLOCKER updatedTicket "+updatedTicket.secondaryStatus);
              log.debug("TicketsListCalendar ticket.uid "+ ticket.uid +"  loop updatedTicket.uid "+updatedTicket.uid);
              return ticket.uid===updatedTicket.uid; 
            } 
            return false;
          });
        } else if(updatedTicket.primaryStatus!=='BLOCKER') {
          index = entries.findIndex(ticket => {
            if(ticket.primaryStatus!=='BLOCKER') {
            log.debug("TicketsListCalendar "+ this.state.queue.id +"  loop updatedTicket "+updatedTicket.id);
            log.debug("TicketsListCalendar "+ this.state.queue.id +"  loop ticket "+ticket.id);
            return ticket.id===updatedTicket.id; 
          } 
            return false;
          });
        }
        if(index>-1)  {
          log.debug("TicketsListCalendar "+ this.state.queue.id +"  Ticket IS in List.");    
          log.debug("TicketsListCalendar "+ this.state.queue.id +"  Ticket IS in List updatedTicket "+updatedTicket.primaryStatus+ " " +updatedTicket.secondaryStatus);
              entries[index] = updatedTicket;
        } else {
          log.debug("TicketsListCalendar "+ this.state.queue.id +"  entries.push(updatedTicket).");    
          entries.push(updatedTicket);
          log.debug("TicketsListCalendar inside loop entry count of Q "+ entries.length);    
        
        } 
       
      }
      entries = entries.filter((ticket) => {
        return ticket.primaryStatus!=="BLOCKER" || (ticket.primaryStatus==="BLOCKER" && ticket.secondaryStatus!=="RETURNED"); 
      })
      log.debug("TicketsListCalendar entry count of Q "+ entries.length);    
      entries.forEach(function (ticket, index) {
        log.debug("TicketsListCalendar entries: "+ticket.queueId+" - "+ticket.primaryStatus+" - "+ticket.secondaryStatus);
        
      })
      log.debug("TicketsListCalendar ------------------------------------------------------");    
      this.entryBuffer = entries;
      this.state.tickets=entries;
      this.setState({ 
        tickets : this.state.tickets
      });
    //} else {
    //  log.trace("TicketsListCalendar "+ this.state.queue.id +"  process websocket disabled ");      
    //}
  }

  processStats(stats) {
    log.trace("stats " + stats);
    log.trace("stats.wait " + stats.wait);
    this.setState({
      stats: stats
    });
  }


  getDayOfCurrentDate() {
    var dateDay = new Date(this.state.currentDateFrom);
    dateDay.setHours(0);
    dateDay.setMinutes(0);
    dateDay.setSeconds(0);
    dateDay.setMilliseconds(0);
    log.trace("dateDay "+dateDay.getTime());    
    return dateDay.getTime();
  }

  




  
  slotsBookableCallback(slotsBookable) {
    let state = {
        slotsBookable : slotsBookable
      }
     this.setState(state);
   }
 
  

  render() {
    const {t} = this.props;
    return (
      <Fragment>
        
        { this.state.selectedQueue && 
            <Container id={"ticketListContainer"+this.state.selectedQueue.id} maxWidth="xs"  style={{maxWidth: "100%", height:this.props.viewportHeight+"px", position: 'relative', marginTop:'0', marginBottom:'0', paddingLeft: "9px", paddingRight: "17px", overflow: this.props.small?"hidden":"inherit",borderLeft: this.props.timeline?"1px solid":"" }}>
              <Grid id={"ticketListContainer"+this.state.selectedQueue.id} container style={{position: 'relative'}} >
              { !this.props.hideStatus && this.state.timeSlider &&
                  <Grid item xs={12} style={{ width: "100%" }} >
                      <ResourcesStatus key={"ticketListCalenderResStatus"} from={this.state.currentDateFrom} to={this.state.currentDateTo} vPlace={this.state.selectedQueue && this.state.selectedQueue.place} vGroup={this.state.selectedQueue && this.state.selectedQueue.group}/>
                  </Grid>
              }
              { this.state.tickets && 
                    <TicketList 
                      offsetFrom={this.props.offsetFrom} 
                      offsetTo={this.props.offsetTo}
                      filters={this.props.filters}
                      tickets={this.state.tickets} 
                      currentDate={Date.now()} 
                      currentDateFrom={new Date(this.state.currentDateFrom)} 
                      currentDateTo={new Date(this.state.currentDateTo)} 
                      defaultView={this.state.defaultView}
                      overView={this.state.overView}
                      createTicketLink={this.props.createTicketLink}
                      queue={this.state.selectedQueue}
                      small={this.props.small}
                      timeline={this.props.timeline}
                      popupable={this.props.popupable} 
                      manage={this.props.manage}
                      partitionStatusDTO={this.props.partitionResourceDTO}
                      slotsBookableCallback={this.slotsBookableCallback}
                      slotsBookable={this.state.slotsBookable}
                    />    
                }
                { !this.props.ready &&
                    <Grid container spacing={0} alignItems="center" justify="center"
                        style={{position: "absolute", 
                        background: "rgba(255,255,255,0.8)",
                        zIndex: "100",
                        height:"100%",
                        padding: "10%",
                        alignContent:"baseline"}} >
                      <Grid item xs={12}  >
                        <Grid container >
                          <Grid item xs={12}  >
                            <CircularProgress/>
                          </Grid>
                          <Grid item xs={12}  >
                            <Typography variant="h5" align="center" color="textSecondary" component="p">
                              {t('find_gap_waiter')}                    
                            </Typography>            
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> 
                }
              </Grid>
          </Container>
        }
        
       
      
        
    </Fragment>
    );
  }
}
export default withTranslation()(TicketsListCalendar);