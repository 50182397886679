import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Main from './components/frame/Main';
import MessageWithAxios from './components/frame/MessageWithAxios';
import InitializeClientId from './components/pages/assets/InitializeClientId';
import MessageProvider from './components/pages/assets/MessageProvider';
import VRAlert from './components/pages/assets/VRAlert';
import LocationChangeListener from './components/pages/assets/LocationChangeListener';
import TicketAlarm from './components/pages/assets/TicketAlarm';
import SessionContext from './components/frame/SessionContext';


const App = () => (
  
  <div>
    
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MessageProvider >
            <MessageWithAxios>
              
                <VRAlert />
                <InitializeClientId />
                <Main >
                  <LocationChangeListener />
                </Main>
                { !SessionContext.isManageMode() && <TicketAlarm /> }
            </MessageWithAxios>
        </MessageProvider>
    </MuiPickersUtilsProvider>
    
    
  </div>
);

export default App;