import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Badge, Chip, Fab, IconButton, ListItemIcon, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { BlockOutlined, DirectionsRun, Done, EventSeat, ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined, HourglassEmpty, NewReleases } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';
import Axios from 'axios';
import config from 'react-global-configuration';
import { t } from "i18next";
import { Block } from 'react-color/lib/components/block/Block';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  badge: {
    textAlign: "left",
    display: "block"
  },
}));

export default function TicketFilterSmall(props) {
  const classes = useStyles();
  const { filterCallback } = props
  const { counts } = props
  const { selectedQueue } = props
  const { displayOnly } = props
  const { filterReset } = props
  const { filterFunction } = props
  const { filters } = props
  
  const [ filterActive, setFilterActive ] = useState(false)
  



  useEffect(() => {    
    console.log("TicketFilterSmall -> filters")  ;    
  }, [filters] )

  const getStyle = (primaryStatus, secondaryStatus) => {
    let style = {margin:"5px"};
    let highLight = {fontWeight: "bolder", border: "solid 1px", backgroundColor:"#ffeec4" };
    if(isActive(primaryStatus, secondaryStatus)) {
      style=mergeStyle(style, highLight);
    }
    return style;
  }
  function mergeStyle(target, source) {
    Object.assign(target, source);    
    return target;
  }


  

  const setFilter = (primaryStatus, secondaryStatus) => {  
    if(filterFunction)   {
      let tFilters = filters;
      if(!primaryStatus && !secondaryStatus) {
        tFilters=[];
      } else {
        let existingFilter = filters.filter((filter) => {
          return filter.primaryStatus==primaryStatus;// && filter.secondaryStatus==secondaryStatus; 
        }) 
        if(secondaryStatus) {
            existingFilter = existingFilter.filter((filter) => {
            return filter.secondaryStatus===secondaryStatus; 
          }) 
        }
        if(!existingFilter || existingFilter.length==0) {
          let filter = {
            primaryStatus: primaryStatus,
            secondaryStatus: secondaryStatus
          }
          tFilters.push(filter);
        } else {
          let leftFilters = filters.filter((filter) => {
            return filter.primaryStatus!=primaryStatus || filter.secondaryStatus!=secondaryStatus; 
          }) 
          tFilters=leftFilters;
        }
      }
      filterCallback(tFilters);
    } else {
      filterCallback(primaryStatus, secondaryStatus);        
    }
  }

  const isActive = (primaryStatus, secondaryStatus) => { 
    if(filterFunction) {
      if(!primaryStatus && filters.length==0) {
        return true;
      } 
      let existingFilter = filters.filter((filter) => {
        return filter.primaryStatus===primaryStatus && (secondaryStatus === undefined && filter.secondaryStatus!=="RESERVED"); 
      }) 
      if(secondaryStatus) {
        existingFilter = existingFilter.filter((filter) => {
        return filter.secondaryStatus===secondaryStatus; 
      })  
      } 
      if(existingFilter && existingFilter.length>0) {
        return true;
      }
    }
    return false;
  }


  const toggleFilter = (event) => { 
    setFilterActive(event.currentTarget);
  }

  return (
    
    <Fragment> 
      { filters &&
<IconButton edge="end" color="inherit" className={classes.iconButton} aria-label="search">
                
                <Badge invisible={!filters || filters.length==0} badgeContent={"!"} color="primary">
                  <FilterListIcon onClick={(e) => toggleFilter(e)} />
                </Badge>
                <Menu
                    anchorEl={filterActive}
                    open={filterActive}
                    onClose={(e) => toggleFilter(false)}
                  > 
                   
                  <MenuItem color={isActive("PREPARING")?"primary":"secondary"} variant={isActive("PREPARING")?"contained":"outlined"} onClick={(event) => setFilter("PREPARING")}  style={getStyle("PREPARING")}>
                          <ListItemIcon><IconButton  style={{backgroundColor:'#94d7ff'}}><NewReleases style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                          {t("neu_klein")}
                  </MenuItem>
                  <MenuItem color={isActive("WAITING")?"primary":"secondary"} variant={isActive("WAITING")?"contained":"outlined"}  onClick={(event) => setFilter("WAITING")} style={getStyle("WAITING")}>
                        <ListItemIcon><IconButton  style={{backgroundColor:'#fff894'}}><HourglassEmpty style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                        {t("waits")}                      
                  </MenuItem>
                  <MenuItem  color={isActive("PROCESSING")?"primary":"secondary"} variant={isActive("PROCESSING")?"contained":"outlined"}  onClick={(event) => setFilter("PROCESSING")} style={getStyle("PROCESSING")} >
                        <ListItemIcon><IconButton  style={{backgroundColor:'#b3e3db'}}><DirectionsRun style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                        {t("in_progress")}
                  </MenuItem>
                  <MenuItem  color={isActive("FINALIZING")?"primary":"secondary"} variant={isActive("FINALIZING")?"contained":"outlined"}  onClick={(event) => setFilter("FINALIZING")}  style={getStyle("FINALIZING")} >
                    <ListItemIcon><IconButton  style={{backgroundColor:'#bebdbd'}}><Done style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                    {t("done")}
                  </MenuItem>
                  <MenuItem  color={isActive("BLOCKER")?"primary":"secondary"} variant={isActive("BLOCKER")?"contained":"outlined"}  onClick={(event) => setFilter("BLOCKER")}  style={getStyle("BLOCKER")} >
                    <ListItemIcon><IconButton  style={{backgroundColor:'#b8a6bf'}}><BlockOutlined style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                    {t("blocker")}
                  </MenuItem>
                  <MenuItem  color={isActive("BLOCKER","RESERVED")?"primary":"secondary"} variant={isActive("BLOCKER","RESERVED")?"contained":"outlined"}  onClick={(event) => setFilter("BLOCKER","RESERVED")}  style={getStyle("BLOCKER","RESERVED")} >
                    <ListItemIcon><IconButton  style={{backgroundColor:'#4287f5'}}><EventSeat style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                    {t("reserved")}
                  </MenuItem>
                  <MenuItem >
                        <Button variant="contained" style={{width:"50%", marginTop:"1rem"}} color="inherit" variant="contained" 
                              onClick={() => {
                                setFilter(); 
                                toggleFilter(false);
                              }} >{t("clear")}</Button>
                        <Button variant="contained" style={{width:"50%", marginTop:"1rem"}} color="secondary" variant="contained" onClick={(e) => toggleFilter(false)} >{t("Done")}</Button>
                  </MenuItem>
                 
                
              </Menu>
            </IconButton>
        }
    </Fragment>    
  );
}