import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import TimeRangeSelector from '../pages/assets/TimeRangeSelector';
import SendDelay from '../ticket/assets/SendDelay';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { FormControl, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch } from '@material-ui/core';

import { Edit, Info, Save, Schedule } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import MultiQStatusAndActionBar from '../pages/assets/MultiQStatusAndActionBar';
import WeekDaySelector from '../queue/assets/WeekDaySelector';
import DateTimeDisplay from '../pages/assets/DateTimeDisplay';
import UploadFiles from '../upload/upload-files.component';
import Attachements from '../upload/Attachements';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function EditTicket(props) {
  const {t} = props;
  const classes = useStyles();
  const { vTicket } = props.location?props.location:props;
  const { vOptions } = props.location?props.location:props;
  
  const { queue } = props.location?props.location:props;
  const { startPreset } = props.location?props.location:props;
  const { endPreset } = props.location?props.location:props;
  const { cyclesMax } = props.location?props.location:props;
  const { cyclesPreset } = props.location?props.location:props;
  const { manage } = props.location?props.location:props;

  const { backTo } = props.location?props.location:props;
  
  const [ticket, setTicket] = useState(vTicket?vTicket:{});


  
  const [editTimings, setEditTimings] = useState(false);
  const [reserve, setReserve] = useState(false);
  const [categories, setCategories] = useState([]);
  const [accepted, setAccepted] = useState([]);
  //const [ manage ] = useState(SessionContext.isManageMode());
  const [contact, setContact] = useState(ticket.contact?ticket.contact:!manage?SessionContext.getUser().email:"");
  
  
  const [ticketConfig, setTicketConfig] = useState(ticket.config?ticket.config:"{}");
  const [externalRef, setExternalRef] = useState(ticket.externalRef?ticket.externalRef:"");
  const [createdBy, setCreatedBy] = useState(ticket.createdBy?ticket.createdBy:(!manage!==undefined && SessionContext.getUser() && ticket.id)?SessionContext.getUser().name:"");
  const [clientName, setClientName] = useState(ticket.clientName?ticket.clientName:"");
  const [responsibleName, setResponsibleName] = useState(ticket.responsibleName?ticket.responsibleName:"");
  
  const [startWish, setStartWish] = useState(startPreset?parseInt(startPreset):(ticket?(ticket.startPlanned?ticket.startPlanned:ticket.startWish):null));
  const [endWish, setEndWish] = useState(endPreset?parseInt(endPreset):(ticket?(ticket.endPlanned?ticket.endPlanned:ticket.endWish):null));
  const [options, setOptions] = useState(vOptions);
  const [toggleBegin, setToggleBegin] = useState(startWish);
  const [toggleEnd, setToggleEnd] = useState(endWish);//endWish);
  const [extraData, setExtraData] = useState(ticket?ticket.extraData:"");
  
  const [cycles, setCycles] = useState((ticket && (ticket.id || ticket.cycles))?ticket.cycles:cyclesPreset?parseInt(cyclesPreset):(options?options.cycles:""));
  
  const [originTicket, setOriginTicket] = useState(ticket && ticket.originTicket);
  const [serialElement, setSerialElement] = useState((ticket && ticket.originTicket)?ticket:null);
  const [serialTiming, setSerialTiming] = useState((originTicket || ticket.state==='INTERVAL')?true:false);
  const [editSerial, setEditSerial] = useState(false);
  const [timeScopes, setTimeScopes] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const [intervals] = useState(["NO_INTERVAL","WEEKLY"]);
  const [interval, setInterval] = useState("WEEKLY");
  

  const [category, setCategory] = useState((ticket && (ticket.id || ticket.category))?ticket.category:options?options.category:null);
  const { addMessage } = useContext(MessageContext)
  const { addModalMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    
    if(SessionContext.getUser().config) {
      update("externalContextScope", false, null, JSON.parse(SessionContext.getUser().config)["externalContextScope"], false);
    }
    if(queue) {
      SessionContext.setTicket(ticket);    
      let uri = '/api/category-queues/queue/'+queue.id;
      const url = config.get('vqueueUrl')+uri;
      axios.get(url )
          .then(res => {
            setCategories(res.data)
          //  history.goBack();//history.push("/queuemanagement")
          }).catch(function (error) {
            // handle error
            console.log(error);
            addMessage(error.detail);
            
          })   
          if(ticket.uid) {
            axios.get(config.get('vqueueUrl')+'/api/tickets/uuid/'+ticket.uid)
              .then(response => { 
                setTicket(response.data);  
              }
            );      
          }
      }
  }, [] )

  

  useEffect(() => {  
    if(SessionContext.getUser().config && JSON.parse(SessionContext.getUser().config)["externalContextScope"]) {
      return;
    }  
    if(category && category.config) {
      let lConfig = JSON.parse(category.config);
      if(!lConfig) {
        lConfig = {};      
      }
      update("externalContextScope", false, null, lConfig["externalContextScope"], false);
    } else {
      if(SessionContext.getUser().config) {
        update("externalContextScope", false, null, JSON.parse(SessionContext.getUser().config)["externalContextScope"], false);
      } else {
        update("externalContextScope", false, null, "", false);
      }
    }
  }, [category] )



  useEffect(() => {   
    if(editTimings) {
      if(ticket.originTicket) {
        setEditTimings(false);
        addModalMessage("Um die Timings zu bearbeiten, muss zum Haupt-Serien Element gewechselt werden.", true);        
      }
    }    
  }, [editTimings] )


  useEffect(() => {    
    weekdaySelection2TimeScopes(weekdays);
  }, [weekdays] )

  useEffect(() => {    
    if(reserve) {
      addModalMessage("Der Slot ist nur vorgemerkt und nicht in der tatsächlichen Gesamtplanung enthalten.", true);        
    }  
  }, [reserve] )
  
  useEffect(() => {   
    if(reserve && serialTiming)  {
      setReserve(false);
      addModalMessage("Serientermine können nicht vorgemerkt werden, daher wird 'Nur vormerken' deaktiviert.", true);     
    }
  }, [serialTiming] )

  
  useEffect(() => {  
    if(originTicket) {  
      if(editSerial) {
        setTicket(originTicket);
      } else {
        setTicket(serialElement);
      }
    }
  }, [editSerial] )
  
  useEffect(() => { 
    presetDefaultWeekdaysa(ticket);
    setStartWish(startPreset?parseInt(startPreset):(ticket?(ticket.startPlanned?ticket.startPlanned:ticket.startWish):null));    
    setEndWish(endPreset?parseInt(endPreset):(ticket?(ticket.endPlanned?ticket.endPlanned:ticket.endWish):null));
    if(ticket.secondaryStatus==="RESERVED") {
      setReserve(true);
      addModalMessage("Der Slot ist nur vorgemerkt und nicht in der tatsächlichen Gesamtplanung enthalten.", true);        
    }
  }, [ticket] )

  useEffect(() => {    
    presetWeekday();
  }, [startWish] )
  
  useEffect(() => {  
    presetWeekday();
    if(!serialTiming && serialElement ) {
      setStartWish(serialElement.startWish);
      setEndWish(serialElement.endWish);
    }
  }, [serialTiming] )
  
  function presetDefaultWeekdaysa(aTicket) {
    if(aTicket.id) {
      let serialInterval = aTicket.serialInterval;
      if(serialInterval) {
        let interval;
        let weekdays = [];
        let intervalTokens = serialInterval.split("|");
        intervalTokens.forEach(function (aToken, indexDay) {
          if(!interval) { //Follows the pattern that first token must be the interval type
            interval=aToken;
          } else { //and all following the weekday if present.
            weekdays.push(aToken);
          }
          setInterval(interval);
          setWeekdays(weekdays);
          setSerialTiming(interval!=="NO_INTERVAL");
        });
      } else {
        presetWeekday();
        //setInterval(originTicket?"NO_INTERVAL":null);
        //setWeekdays([]);
        //setSerialTiming(originTicket?originTicket:false);
      }
    } else {
      presetWeekday();
    }
    
    
  }

  function presetWeekday() {
    let dateStart = new Date(startWish);
    let dayIndex = dateStart.getDay();
    let timeScopes =[];
    dayIndex=dayIndex-1;
    if(dayIndex<0) {
      dayIndex=6;
    }
    timeScopes[dayIndex]=[{}];
    if(editTimings) {
      timeScopes2WeekdaySelection(timeScopes);
    }
  }

  function handleCategoryChange(selectedCategoryId) {
    if(selectedCategoryId) {
      let selectedCategory  = categories.filter((category) => {
        return category.category.id==selectedCategoryId
      });
      setCategory(selectedCategory[0].category);
    } else {
      setCategory(null);
    }
  }

  
  function addComMessage(ticketEntity,aMessage) {
    console.log("addComMessage."+aMessage)
    let jsonMessages = null;
    if(ticketEntity.message) {
      jsonMessages = JSON.parse(ticketEntity.message);    
    }
    if(!jsonMessages) {
      jsonMessages = [];
    }
    let tmpMessage = {};
    tmpMessage.clientId = SessionContext.getClientId();
    tmpMessage.message = aMessage;
    tmpMessage.sender = t("sender");
    tmpMessage.timestamp = new Date().getTime();

    jsonMessages.push(tmpMessage);
    return jsonMessages;
  }

  function update (name, millis, e, aValue, float)  {
    
    console.log("update."+name+" - "+millis)
    let value = "";
    if(e && e.target) {
      value = e.target.value;
    }
    console.log("update. value - "+value)
       
    if(aValue != undefined && (typeof aValue === 'string' || aValue instanceof String)) {
      value = aValue;
    } else if(!value) {
      value = e.target.textContent; //mui slider value is here
    }
    if(millis && !isNaN(value)) {
      value = value*1000*60;
    }
    if(float) {
      value = value.replace(",", ".");
    }

    let lConfig = JSON.parse(ticketConfig);
    if(!lConfig) {
      lConfig = {};      
    }
    lConfig[name] = value;
    lConfig = JSON.stringify(lConfig);
    setTicketConfig(lConfig );
  }

  
  function handleSubmit(event) {
    event.preventDefault();
    console.log(t("save_ticket"))
    let ticketEntity = ticket?ticket:{};
    ticketEntity.contact = contact;
    ticketEntity.externalRef = externalRef;
    ticketEntity.createdBy = createdBy;
    ticketEntity.clientName = clientName;
    ticketEntity.responsibleName = responsibleName;
    ticketEntity.config = ticketConfig;
    if(category) {
      ticketEntity.categoryId = category.id;
    }
    ticketEntity.extraData = extraData;


    if(!originTicket || (originTicket && editSerial)) { //save values only, if working on serial main element.
      ticketEntity.cycles = cycles;

      if(!toggleBegin) {
        setStartWish("");      
      }
      if(!toggleEnd) {
        setEndWish("");      
      }

      if(toggleBegin) {
        ticketEntity.startWish = startWish;
      } else {
        ticketEntity.startWish = null;
      }
      if(toggleEnd) {
        ticketEntity.endWish = endWish;
      } else {
        ticketEntity.endWish = null;
      }

      if(serialTiming && interval!=="NO_INTERVAL") {
        ticketEntity.primaryStatus = 'INTERVAL';
        ticketEntity.secondaryStatus = 'SUSPENDED';
        ticketEntity.status = 'INTERVAL';
        let serialInterval = weekdays;
        serialInterval.unshift(interval);
        ticketEntity.interval = serialInterval;
      } else {
        ticketEntity.status = 'STANDARD';
        if(reserve) {          
          ticketEntity.primaryStatus = "BLOCKER";
          ticketEntity.secondaryStatus = "RESERVED";
        } else {
          ticketEntity.primaryStatus = null;
          ticketEntity.secondaryStatus = null;
        }
        
        ticketEntity.interval = null;
        
      }
    }
    

    ticketEntity.category = category;
    if(extraData && !ticketEntity.message) {
      ticketEntity.message = JSON.stringify(addComMessage(ticketEntity, extraData));  
    }
    
    

    const url = config.get('vqueueUrl')+'/api/users';
    

    if (ticketEntity.id) {
      //change an existing ticket.
      if(ticketEntity.secondaryStatus=='APPOINTMENT_REQUEST_OPERATOR_CLIENT' || ticketEntity.secondaryStatus=='APPOINTMENT_REQUEST_CLIENT_OPERATOR') {
        if(accepted) {
          if(manage) {
            ticketEntity.secondaryStatus = 'APPOINTMENT_REQUEST_CLIENT_OPERATOR_CONFIRMED';
          } else {
            ticketEntity.secondaryStatus = 'APPOINTMENT_REQUEST_OPERATOR_CLIENT_CONFIRMED';
          }            
        }  else {
          if(manage) {
            ticketEntity.secondaryStatus = 'APPOINTMENT_REQUEST_OPERATOR_CLIENT';
          } else {
            ticketEntity.secondaryStatus = 'APPOINTMENT_REQUEST_CLIENT_OPERATOR';
          }  
        }
      }
     

      const url = config.get('vqueueUrl') + '/api/tickets';
      axios.put(url, ticketEntity)
        .then(res => {
          addMessage(t("changes_saved"));
          if(props.confirmationTarget) {
            addMessage(t("timeslot_sended_soon_in_wallet"));
            history.push(props.confirmationTarget);
          } else {
            SessionContext.refreshTicket(ticketEntity, !manage); 
            history.goBack();
          }
        })
    } else {
      //request Ticket via Bartender
      ticketEntity.queueId = queue.id;
      // primaryStatus: 'PREPARING',
      // secondaryStatus: (queue.queueingKind=="SIMPLE"?null:'PULL_REQUESTED'),
      
      
      if(queue.status=="SERVICE") {
        ticket.primaryStatus="SERVICE";
      } else
      if (!ticketEntity.break && (queue.queueingKind == "FORMULAR_CONFIRM" || queue.queueingKind == "FORMULAR")) {
       ticketEntity.primaryStatus = 'PREPARING';
       if(ticketEntity.secondaryStatus!='APPOINTMENT_REQUEST_OPERATOR_CLIENT' && ticketEntity.secondaryStatus!='APPOINTMENT_REQUEST_CLIENT_OPERATOR') {
          if(manage) {
              ticketEntity.secondaryStatus = 'APPOINTMENT_REQUEST_OPERATOR_CLIENT';
            } else {
              ticketEntity.secondaryStatus = 'APPOINTMENT_REQUEST_CLIENT_OPERATOR';
            }
          }          
      }
      //if(!manage) { 
        ticketEntity.clientId = SessionContext.getClientId();
     // } 
      
      axios.post(config.get('bartenderUrl') + '/api/requestTicket', ticketEntity)
        .then(response => {
          if(!manage) {
            ticketEntity.uid = response.data.uid;
            SessionContext.addFavourite(queue);
            SessionContext.addTicket(queue,ticketEntity);   
          }
          if(props.confirmationTarget) {
            addMessage(t("timeslot_sended_soon_in_wallet"));
            history.push(props.confirmationTarget);
          } else {
            addMessage(t("timeslot_sended"));
            if(backTo) {
              history.push(backTo);
            } else {
              history.goBack();
            }
          }
        }
        );
    }    
  }

  function handleToggleBegin() {
    let newToggle = !toggleBegin;
    setToggleBegin(newToggle);
    if(!newToggle) {
      setStartWish(null);
    }
  }

  function handleToggleEnd() {
    let newToggle = !toggleEnd;
    setToggleEnd(newToggle);
    if(!newToggle) {
      setEndWish(null);
    }
  }
  
  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link onClick={() => handleSubmit()} > 
              <Save />            
        </Link>
    );
    return quickActionItems;
  }

  function weekdaySelection2TimeScopes(weekdaySelection) {
    console.log("weekdaySelection2TimeScopes: " + JSON.stringify(weekdaySelection));
    let scopes = [];
    weekdaySelection.forEach(function (aWeekday, indexDay) {
      let dayIndex;

      switch(aWeekday) {
        case 'MONDAY':
          dayIndex=0;
          break;
        case 'TUESDAY':
          dayIndex=1;
          break;
        case 'WEDNESDAY':
          dayIndex=2;
          break;
        case 'THURSDAY':
          dayIndex=3;
          break;
        case 'FRIDAY':
          dayIndex=4;
          break;
        case 'SATURDAY':
          dayIndex=5;
          break;
        case 'SUNDAY':
          dayIndex=6;
          break;          
      }
      scopes[dayIndex]=[{}]; // empty array since we only need "selection" for a certain day
    });
    console.log("weekdaySelection2TimeScopes results: " + JSON.stringify(scopes));
    setTimeScopes(scopes);    
  }  
 
  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Attachements ticket={ticket} />
    );
    return quickActionItems;
  }

  function timeScopes2WeekdaySelection(timeScopes) {
    console.log("timeScopes2WeekdaySelection: " + JSON.stringify(timeScopes));
    let weekdays = [];
    timeScopes.forEach(function (aDayScope, indexDay) {
      let weekday; 
      if(aDayScope && aDayScope.length>0) {
        switch(indexDay) {
          case 0:
            weekday='MONDAY';
            break;
          case 1:
            weekday='TUESDAY';
            break;
          case 2:
            weekday='WEDNESDAY';
            break;
          case 3:
            weekday='THURSDAY';
            break;
          case 4:
            weekday='FRIDAY';
            break;
          case 5:
            weekday='SATURDAY';
            break;
          case 6:
            weekday='SUNDAY';
            break;
              
        }
        if(weekday) {
          weekdays.push(weekday);
        } 
      }
    });
    if(weekdays.length===0) {
      presetDefaultWeekdaysa(originTicket?originTicket:ticket);
    } else {
      console.log("timeScopes2WeekdaySelection results: " + JSON.stringify(weekdays));
      setWeekdays(weekdays);  
    }
  }  

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={t("request/edit_ticket")} 
            quickActions={getQuickActionItems()}
              backTo={backTo} />
      <CssBaseline />
      { queue &&
        <div className={classes.paper}>
          
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          
          
          
          { !ticket.status && ticket.status!="SERVICE" &&
            <Typography component="h1" variant="h5">
              {t("tell_something_about_you")}
            </Typography>          
          }    
          { ticket.status && ticket.status=="SERVICE" && 
            <Typography component="h1" variant="h5">
              {t("service_data")}
            </Typography>   
          }
          <MultiQStatusAndActionBar manage={manage}/>
          
          { ticket && ticket.uid && <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"0.6rem"}}>
                {ticket.uid}
          </Typography>
          }
          
          { (originTicket) && //only switchable if both elements are there
            <Fragment>
              <Grid item xs={8}  >
                { !editSerial &&
                  <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"0.8rem"}}>
                      Dies ist ein Serientermin. Du bearbeitest ein Serienelement.
                  </Typography>
                }
                { editSerial &&
                  <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"0.8rem"}}>
                      Dies ist ein Serientermin. Du bearbeitest die ganze Serie.
                  </Typography>
                }
              </Grid>
              <Grid item xs={4}  >
                <Switch
                    color="primary"
                    checked={editSerial}
                    onChange={() => setEditSerial(!editSerial)}
                    name="editSerial"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </Grid>
            </Fragment>
          } 
          <form className={classes.form} onSubmit={handleSubmit}>          
            <Grid container spacing={2}>
            
            { queue.queueingKind == "FORMULAR_CONFIRM" && 
                <Grid item xs={12}  >
                  {t("appointment_after_request")}
                </Grid>
              }
              { queue.queueingKind == "FORMULAR" && 
                <Grid item xs={12}  >
                  {t("best_appointment_automatically")}
                </Grid>
              }
              { ticket.status && ticket.status=="SERVICE" && 
                <Grid item xs={12}  >
                  <h3>{t("information_for_service")}</h3>
                </Grid>
              }
              { !ticket.status || ticket.status!="SERVICE" && 
                  <Grid item xs={12}  >
                    <h3>{t("contact_data_information")}</h3>
                  </Grid>
              }
              <Grid item xs={12}  >
                  <TextField style={{width:"100%"}}
                        label={t("email_or_phone_number")}
                        variant="outlined"
                        required
                        fullWidth
                        focused={true}
                        value={contact}
                        id="contact"
                        name="contact"
                        autoComplete="lname"
                        onChange={(e) => setContact(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                                <Edit/>
                            </InputAdornment>
                          )
                        }}
                      />
                </Grid>

                <Grid item xs={12}  >
                  <TextField style={{width:"100%"}}
                        label={t("clientName")}
                        variant="outlined"
                        
                        fullWidth
                        value={clientName}
                        id="clientName"
                        name="clientName"
                        autoComplete="lname"
                        onChange={(e) => setClientName(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                                <Edit/>
                            </InputAdornment>
                          )
                        }}
                      />
                </Grid>

                <Grid item xs={12}  >
                  <TextField style={{width:"100%"}}
                        label={t("createdBy")}
                        variant="outlined"
                        
                        fullWidth
                        value={createdBy}
                        id="createdBy"
                        name="createdBy"
                        autoComplete="lname"
                        onChange={(e) => setCreatedBy(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                                <Edit/>
                            </InputAdornment>
                          )
                        }}
                      />
                </Grid>

                <Grid item  xs={12}>
                  <FormControl variant="outlined" style={{width:"100%"}}>
                    <TextField style={{width:"100%"}}
                      label={t("externalContextScope")}
                      variant="outlined" 
                      disabled={!manage}
                      fullWidth
                      id="externalContextScope"
                      name="externalContextScope"
                      value={ticketConfig?JSON.parse(ticketConfig).externalContextScope:""}
                      onChange={(e) => update("externalContextScope", false, e, false, false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                              <Info/>
                          </InputAdornment>
                        )
                      }}/>
                  </FormControl>
                </Grid>

                <Grid item xs={12}  >
                  <TextField style={{width:"100%"}}
                        label={t("externalRef")}
                        variant="outlined"
                        
                        fullWidth
                        value={externalRef}
                        id="externalRef"
                        name="externalRef"
                        autoComplete="lname"
                        onChange={(e) => setExternalRef(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                                <Edit/>
                            </InputAdornment>
                          )
                        }}
                      />
                </Grid>

                <Grid item xs={12}  >
                  <TextField style={{width:"100%"}}
                        label={t("responsibleName")}
                        variant="outlined"
                        
                        fullWidth
                        value={responsibleName}
                        id="responsibleName"
                        name="responsibleName"
                        autoComplete="lname"
                        onChange={(e) => setResponsibleName(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                                <Edit/>
                            </InputAdornment>
                          )
                        }}
                      />
                </Grid>

                
    
    
    
    



                <Grid item xs={12}  >
                  <TextField style={{width:"100%"}}
                        label={t("further_information")}
                        variant="outlined"
                        
                        fullWidth
                        multiline
                        id="extraData"
                        name="extraData"
                        autoComplete="extraData"
                        rows={4}
                        value={extraData}
                        onChange={(e) => setExtraData(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                                <Edit/>
                            </InputAdornment>
                          )
                        }}
                      />
              </Grid> 
                        

              <Grid item  xs={12} >
                <Grid container spacing={1}>
                  <Grid item  xs={12}>
                  {t("category")}
                  </Grid>
                  <Grid item  xs={12}>
                    <FormControl variant="outlined" style={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-outlined-label">{t("category")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={category && category.id} 
                        onChange={(e) => handleCategoryChange(e.target.value)}
                      >
                        <MenuItem value=""><em>{t("none")}</em></MenuItem>
                        {categories.map((category,i) => {
                              return (
                                <MenuItem  key={i} value={category.category.id}>
                                  {category.category.name}
                                </MenuItem>
                              )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  { manage &&
                    <Fragment>
                      <Grid item xs={8}  >
                        <Typography component="h5" variant="p">
                          Nur vormerken
                        </Typography>   
                      </Grid>
                      <Grid item xs={4}  >
                        <Switch
                            color="primary"
                            checked={reserve}
                            disabled={serialTiming}
                            onChange={() => setReserve(!reserve)}
                            name="reserve"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                      </Grid>
                    </Fragment>
                  }
                </Grid>
              </Grid> 
              
            { (manage && (!ticket.status || (ticket.status!="SERVICE" && queue.status!="SERVICE"))) &&
              <Grid item xs={12}  >
                <Grid container spacing={1} style={{border:"solid 1px", padding:"1rem"}} >
                  <Grid item xs={8}  >
                    <Typography component="h4" variant="p">
                      Timings bearbeiten
                    </Typography>   
                  </Grid>
                  <Grid item xs={4}  >
                    <Switch
                        color="primary"
                        checked={editTimings}
                        onChange={() => setEditTimings(!editTimings)}
                        name="editTimings"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                  </Grid>
                  <Grid item  xs={12} >
                    <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"0.8rem"}}>
                        Expertenfunktion. Änderungen hier können dazu führen dass dieser Slot nicht planbar wird. 
                    </Typography>
                  </Grid>
                  
                  <Grid item  xs={12} >
                    <Grid container spacing={1}>
                      <Grid item  xs={12}>
                      {t("working_cycles")}
                      </Grid>
                      <Grid item  xs={12}>
                        <FormControl variant="outlined" style={{width:"100%"}}>
                          <TextField type="number" 
                              autoComplete="fcycles"
                              required
                              disabled={!editTimings}
                              name="cycles_units_pieces"
                              variant="outlined"
                              value={cycles} 
                              fullWidth
                              id="cycles_units_pieces"
                              label={t("necessary_work_cycles")}
                              onChange={(e) => setCycles(e.target.value)}
                          />
                        
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}  >

                    <TimeRangeSelector
                      editTimings={editTimings}
                      chooseBegin={toggleBegin}
                      toggleBegin={() => handleToggleBegin()}
                      start={new Date(startWish ? startWish : Date.now())}
                      setStartDate={setStartWish}
                      
                      chooseEnd={toggleEnd}
                      toggleEnd={() => handleToggleEnd()}
                      end={new Date(endWish ? endWish : Date.now())}
                      setEndDate={setEndWish}
                    />
                  </Grid>
                  <Grid item xs={8}  >
                    <Typography component="h4" variant="p">
                      Serientermin
                    </Typography>   
                  </Grid>
                  <Grid item xs={4}  >
                    <Switch
                        color="primary"
                        checked={serialTiming}
                        onChange={() => setSerialTiming(!serialTiming)}
                        name="serialTiming"
                        disabled={!editTimings}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                  </Grid>
                  { serialTiming &&
                    <Fragment>
                      <Grid item  xs={12}>
                        <FormControl variant="outlined" style={{width:"100%"}}>
                          <FormLabel id="demo-simple-select-outlined-label">{t("repetition")}</FormLabel>
                            <RadioGroup row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              value={interval} 
                              disabled={!editTimings}
                              onChange={(e) => setInterval(e.target.value)}
                              label={t("interval")}
                            >
                              {intervals.map((interval,i) => {
                                return (
                                  <FormControlLabel 
                                  disabled={!editTimings}  key={i} value={interval} control={<Radio />} label={t("slot_intervall_"+interval)} />
                                )
                              })}
                          </RadioGroup>
                        </FormControl>
                      </Grid> 
                        <Grid item  xs={12}>
                            
                          { interval==='WEEKLY' &&
                            <WeekDaySelector 
                                weekdayMode={true}
                                timeScopes={timeScopes} 
                                disabled={!editTimings}
                                updateCallback={(timeScopes) => timeScopes2WeekdaySelection(timeScopes)} />
                          }
                          { interval==='MONTHLY' &&
                            <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"1rem"}}>
                                Dieser Termin findet immer am <strong>{new Date(startWish).getDate()}. um <DateTimeDisplay timestamp={startWish} hideDate={true} /></strong> Uhr eines Monats statt.
                            </Typography>
                          } 
                          { interval==='YEARLY' &&
                            <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"1rem"}}>
                                Dieser Termin findet jedes Jahr am <strong>{new Date(startWish).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit' })} um <DateTimeDisplay timestamp={startWish} hideDate={true} /></strong> Uhr statt.
                            </Typography>
                          } 
                          { interval==='NO_INTERVAL' &&
                            <Typography  variant="body1" style={{width:"100%", textAlign:"center", fontSize:"1rem"}}>
                                Dieser Termin findet einmalig statt am <strong>{new Date(startWish).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' })} um <DateTimeDisplay timestamp={startWish} hideDate={true} /></strong> Uhr.
                            </Typography>
                          }
                        </Grid>      
                      
                    </Fragment>
                  }
                  
                </Grid>
              </Grid>
            }
            { //!manage && 
              (ticket.secondaryStatus!='APPOINTMENT_REQUEST_CLIENT_OPERATOR' && ticket.secondaryStatus!='APPOINTMENT_REQUEST_OPERATOR_CLIENT') && 
                <Grid item xs={12}  >
                <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      // disabled={!validateForm()}
                      >
                      {t("save")}
                    </Button> 
                </Grid>
              }  
              { (ticket.secondaryStatus==='APPOINTMENT_REQUEST_CLIENT_OPERATOR' || ticket.secondaryStatus==='APPOINTMENT_REQUEST_OPERATOR_CLIENT') &&
                <Grid container spacing={2}>
                  <Grid item xs={6}  >
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => setAccepted(false)}
                        // disabled={!validateForm()}
                        >
                        {t("deny_and_other_proposal")}
                      </Button> 
                  </Grid>
                  <Grid item xs={6}  >
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => setAccepted(true)}
                        // disabled={!validateForm()}
                        >
                        {t("confirmation")}
                      </Button> 
                  </Grid>
                </Grid>
              }           
            </Grid>
                    
          </form>
        </div>
      }
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditTicket);