import React, { useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import config from 'react-global-configuration';
import { useHistory } from "react-router-dom";
import { MessageContext } from '../pages/assets/MessageProvider';
import SessionContext from '../frame/SessionContext';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function ResetPassword() {
  const classes = useStyles();
  const { addMessage } = useContext(MessageContext)
  const [email, setEmail] = useState("");
  const history = useHistory();

  function validateForm() {
    return email.length > 0;
  }
  
  function handleSubmit(event) {
    event.preventDefault();
    console.log("Submit User for login.")
    var userLang = navigator.language || navigator.userLanguage;
    let entity = {
      email: email
    }
    
    const url = config.get('vqueueUrl')+'/api/account/reset-password/init';
  
    axios.post(url,  email, {headers: {"Content-Type": "text/plain"}} )
    .then(res => {
      
      addMessage(t("pw_reset_started"));
      history.push("/login");
     
    }).catch(function (error) {
      // handle error
      console.log(error);
      
    })
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={t("reset_pw")}  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("reset_pw")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email_adress")}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!validateForm()}
          >
            {t("reset_link_received")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                {t("allready_account_this_way")}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" >  
                {t("this_way_for_new_account")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}
