import React, { useState,useContext, createContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { AccountBox } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function EditAccount(props) {
  const {t} = props;
  const classes = useStyles();
  const { user } = props.location;

  const [firstName, setFirstName] = useState(user?user.firstName:SessionContext.getUser().firstName);
  const [lastName, setLastName] = useState(user?user.lastName:SessionContext.getUser().lastName);
  const [email, setEmail] = useState(user?user.email:SessionContext.getUser().email);
  const [lng, setLanguage] = useState(user?user.lng:SessionContext.getUser().lng);
  const [userConfig, setUserConfig] = useState(user?user.config:"{}");
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  const lngs = {
    de: {nativeName: "Deutsch"},
    en: {nativeName: "English"},
    
  };

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("user: " + user);
    
        
        
  }, [] )

  function validateForm() {
    return firstName.length > 0 && lastName.length > 0 && email.length > 0;
  }

  function handleSubmit(event) {
    
    event.preventDefault();
    console.log("Save User.")
    var userLang = navigator.language || navigator.userLanguage;
    let userEntity = user?user:SessionContext.getUser();
      userEntity.firstName = firstName;
      userEntity.lastName = lastName;
      userEntity.email = email;
      userEntity.lng = lng;
      userEntity.config = userConfig;
    const url = config.get('vqueueUrl')+'/api/users';
    axios.put(url,  userEntity )
        .then(res => {
          if(!user) {
            SessionContext.setUser(res.data);
            i18n.changeLanguage(lng);
          } else {
            history.push("/accounts");

          }
          addMessage(t("user_saved"));
          
        //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          
        })
  }

  

  function update (name, millis, e, cfg, aValue, float)  {
    
      console.log("update."+name+" - "+millis+" - "+e.target.value)
      let value = e.target.value;
        
        
        if(aValue != undefined && aValue) {
          value = aValue;
        } else if(!value) {
          value = e.target.textContent; //mui slider value is here
        }
        if(millis && !isNaN(value)) {
          value = value*1000*60;
        }
        if(float) {
          value = value.replace(",", ".");
        }

        let lConfig = JSON.parse(userConfig);
        if(!lConfig) {
          lConfig = {};      
        }
        lConfig[name] = value;
        lConfig = JSON.stringify(lConfig);
        setUserConfig(lConfig );
  }
  
  
  return (
    <>
    <Container component="main" maxWidth="md">
      <DeskHeader title={t("make_account")}  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountBox />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("registrate")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                value={firstName}
                required
                fullWidth
                id="firstName"
                label={t("first_name")}
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={lastName}
                id="lastName"
                label={t("last_name")}
                name="lastName"
                autoComplete="lname"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={email}
                id="email"
                label={t("email_adress")}
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("language")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={MenuItem.value}
                  onChange={(e) => {setLanguage(e.target.value); i18n.changeLanguage(e.target.value)}}
                >{Object.keys(lngs).map((lng) => (
                  <MenuItem value={lng}>{lngs[lng].nativeName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item  xs={12}>
              <FormControl variant="outlined" style={{width:"100%"}}>
                <TextField style={{width:"100%"}}
                  label={t("externalContextScope")}
                  variant="outlined" 
                  value={userConfig?JSON.parse(userConfig).externalContextScope:""}
                  onChange={(e) => update("externalContextScope", false, e, true, false, false)}/>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!validateForm()}
          >
            {t("save")}
          </Button>          
        </form>
      </div>
      <AppFooter />
      
    </Container>
    </>
  );
}

export default withTranslation()(EditAccount);