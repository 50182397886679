import {
  Badge
} from 'reactstrap';
import React, { Fragment } from "react";
import { Grid } from '@material-ui/core';


export default function Crumbs(props) {
  
  return <Fragment>
     { 
      props.ticket && props.ticket.queue && <Fragment>
          {props.ticket.queue && props.ticket.queue.place && props.ticket.queue.place.domain && props.ticket.queue.place.domain.domainName+" - "}  {props.ticket.queue.place && props.ticket.queue.place.name+" - "} {props.ticket.queue.group && props.ticket.queue.group.name+" - "} {props.ticket.queue.name}</Fragment>
    }
     {
     props.ticket && props.ticket.category && <Fragment><br/><Badge style={{backgroundColor: props.ticket.category.color, fontSize:"0.8rem"}}><span style={{backgroundColor:"rgba(255, 255, 255, 0.65)", color:"black", padding:"3px", borderRadius:"5px"}}>{props.ticket.category.name}</span></Badge></Fragment> 
      }
     { 
      !props.ticket && props.queue && <Fragment>
        {props.queue.place && props.queue.place.domain && props.queue.place.domain.domainName+" - "}  {props.queue.place && props.queue.place.name+" - "} {props.queue.group && props.queue.group.name+" - "} {props.queue.name} 
      </Fragment> 
      }
      {
      !props.ticket && !props.queue && props.group && <Fragment>
        {props.group.place.domain && props.group.place.domain.domainName+" - "} {props.group.place.name+" - "} {props.group && props.group.name}
      </Fragment> 
    }
    {
      !props.ticket && !props.queue && !props.group && props.place && <Fragment>
        {props.place.domain && props.place.domain.domainName+" - "}  {props.place.name}
      </Fragment> 
    }
    {
      !props.ticket && !props.queue && !props.group && !props.place && props.domain && props.domain.domainName
    }
  
  </Fragment>
};
