import { Button, CircularProgress, IconButton, MenuItem, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Add, CropFree } from '@material-ui/icons';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import SessionContext from '../frame/SessionContext';
import AppFooter from '../pages/assets/AppFooter';
import CardGrid from '../pages/assets/CardGrid';
import DeskHeader from '../pages/assets/DeskHeader';
import LinkCard from '../pages/assets/LinkCard';
import { MessageContext } from '../pages/assets/MessageProvider';
import ServiceCard from '../pages/assets/ServiceCard';
import TicketData from '../pages/assets/TicketData';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';

class Rooms extends Component {
  static contextType = MessageContext
  
  constructor() {
    super();
    this.state={};
  }
  
  componentDidMount() {
    SessionContext.setQSettingsOrigin("/rooms");      
    let place = this.props.location.vPlace;
    if(place) {
      SessionContext.setPlace(this.props.location.vPlace);
    } 
    place = SessionContext.getPlace();
    let uri = place?'/api/queues?placeId='+place.id:'/api/queues';
    const url = config.get('vqueueUrl')+uri;
   axios.get(url )
      .then(res => {
        this.setState({
          queues: res.data
        });  
      })

    this.setState({
      place: place
    });   
    
  }

  componentDidUpdate() {
    const msgCtx = this.context;
    console.log("Room componentDidUpdate: ");
      if(msgCtx.ticket ) {
        console.log("msgCtx.ticket: "+JSON.stringify(msgCtx.ticket));
        SessionContext.refreshTicket(msgCtx.ticket);
        this.setState({
          changedTicket: msgCtx.ticket
        });   
        msgCtx.removeTicket();                   
      } 
  }

  getCreateRoom() {
    const { t } = this.props;
    return {
      title: t("create_waiting_room"),
      text:<Fragment> 
            <span >{t("create_room")}</span>  
          </Fragment>,
      menuItems: this.getMenuItemsCreateRoom(),
      sub: "",
      action: <Button color="secondary" variant="contained" style={{width:"100%", height:"2rem", fontWeight:"bold"}} onClick={() => this.createRoom()} >{t("create")}</Button>
      
    }
  }

  getScan() {
    const { t } = this.props;
    return  {
      title: t("scan"),
      icon:<CropFree />,
      link:"/waiterscanner",
      color: "#DAE8FC"
    }
  }

  createRoom() {
    const { t } = this.props;
    const url = config.get('vqueueUrl')+'/api/queues';
    let entity = {
      name: t("waiing_room-")+new Date().getTime(),
      queueingKind: "SIMPLE",
      roomView: "STANDARD",
      autoReturn: "ON",
      moveBackPosCount: 3,
      standardTicketProcessingDuration: 600000,
      standardTicketExtendDuration: 300000,
      standardTicketExpiryDuration: 180000,
      standardTicketRedeemDuration: 120000,
      standardCallingTimeDuration: 120000,
      place: this.state.place
    }
    axios.post(url,  entity )
    .then(res => {
      SessionContext.addFavourite(res.data);
      this.state.queues.push(res.data);
      this.setState({ queues: this.state.queues });
      //this.showAlert("Neuer Raum wurde erzeugt."+new Date());    
    })
  }
  showAlert(message) {
    
    const msgCtx = this.context
    msgCtx.addMessage(message);
  }
  getMenuItemsCreateRoom() {
    const { t } = this.props;
    return [
      <MenuItem key="room" >
        <Button color="secondary" variant="contained" style={{width:"100%", height:"2rem", fontWeight:"bold"}} onClick={() => this.createRoom()} >{t("create")}</Button>
      </MenuItem>
   ]
  }

  getQuickActionItems() {
    let quickActionItems = [];
    if(this.state.place) {
      quickActionItems.push(
        <IconButton onClick={() => this.createRoom()} 
            style={{color: "inherit", textDecoration: "none", padding:"0px"}}>
              <Add />            
        </IconButton> 
      );
    }
    return quickActionItems;
  }
  

  getGridContent() {
    const { t } = this.props;
    let gridContent = [];
    
    
    /*if(this.state.place) {
      gridContent.push(this.getCreateRoom())
    }*/
    for (var value in this.state.queues) {
      let activeTicketForQ = SessionContext.getActiveTicketForQueue(this.state.queues[value]);
      let entry =  {
        title:this.state.queues[value].name,
        crumb: <Crumbs queue={this.state.queues[value]} ticket={this.state.queues[value].currentTicket}/>,
        text:<Fragment> 
              { activeTicketForQ!=null &&  activeTicketForQ.status!="SERVICE" &&
                <TicketData ticket={activeTicketForQ} queue={this.state.queues[value]}/>
              }
              { activeTicketForQ!=null &&  activeTicketForQ.status=="SERVICE" &&
                  <ServiceCard ticket={activeTicketForQ} />
              }
              { activeTicketForQ==null && 
                <Fragment> 
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
                      <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                        {t("next_timepoint")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" >
                      <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
                        <span >{t("book_now")}</span>    
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>  
              }            
          </Fragment>,
        menuItems: this.getMenuItems(this.state.queues[value]),
        action: 
        <Fragment>
          <Grid item xs={6}  >
            <Link to={{pathname: '/room', queue: this.state.queues[value], ticket: activeTicketForQ}}  >
              <Button color={'secondary'} variant="contained" color="secondary" variant="contained" style={{width:'100%'}}  >
                {t("show")}                  
              </Button>
            </Link>                   
          </Grid>
          { SessionContext.isLoggedIn() &&
          <Grid item xs={6}  >
            <Link to={{pathname: '/my/groups/slotmanager/dashboard', queue: this.state.queues[value]}} >
              <Button color="secondary" variant="contained" color="primary" variant="contained" style={{width:'100%'}} >
                {t("management")}
              </Button>
            </Link>                
          </Grid> }   
        </Fragment>
      
        //link:{ pathname: '/room', queue: myQueues[value] }
      };
      gridContent.push(entry);
    }
    return gridContent;
  }


  getMenuItems(queue) {
    const { t } = this.props;
    return [
      <MenuItem key="room" >
        <Link to={{pathname: '/room', queue: queue}} > <Button color="primary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} >{t("to_room")}</Button> </Link>
      </MenuItem>,      
      <MenuItem key="manage" >
        <Link to={{pathname: '/my/groups/slotmanager/dashboard', queue: queue}} > <Button color="secondary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} >{t("management")}</Button> </Link>
      </MenuItem>
   ]
  }

  render() {
    const { t } = this.props;
    return (
      <div> 
        <DeskHeader title={this.state.place?<Crumbs place={this.state.place}/>:t("your_queues")}  
            backTo={this.state.place?"/my/places":"/"} 
            quickActions={this.getQuickActionItems()}/>
      
        <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
          <Grid container spacing={1}  alignItems="center" justify="center" style={{ justifyContent: "center", display: "flex" ,textAlign: "center" }}>
             <Grid item xs={6} >
              <LinkCard area={this.getScan()} />
            </Grid>
          </Grid>
          <CardGrid gridContent={this.getGridContent()} />
        </Container>
        <AppFooter />
    </div>
      
    );
  }
}

export default withTranslation()(Rooms);