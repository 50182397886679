// WithAxios.js

import { useContext, useMemo, useState } from 'react'
import { useHistory } from "react-router";
import axios from 'axios'

import { MessageContext } from '../pages/assets/MessageProvider';
import SessionContext from './SessionContext';
import {t} from 'i18next';
import log from 'loglevel';

const MessageWithAxios = ({ children }, props) => {
    const { addMessage } = useContext(MessageContext);
    const { addModalMessage } = useContext(MessageContext);
    const { setOffline } = useContext(MessageContext)
    const history = useHistory();
    
    useMemo(() => {
        axios.defaults.timeout = 50000;
        axios.defaults.headers = {
            "Content-type": "application/json"
        };
        // Add a request interceptor
        // https://axios-http.com/docs/req_config
        axios.interceptors.request.use(function (request) {
            
            log.debug("request "+request.url);
            /* if(SessionContext.getOffline() && request.url.indexOf("/api/authenticate")<0) {
                let response = SessionContext.getRequestResponse(request.method+":"+request.url);
                if(response!=null) {
                    log.trace("Found a cached Response for "+request.url);
                    request.adapter = () => {
                        response["__cached"]=true;
                        return Promise.resolve(response);
                        return new Promise(() => {
                          
                        })
                      }
                }
                
                
            } */
            if(SessionContext.isLoggedIn()) {
                if(!request.params) {
                    request.params={};
                }
                request.params.userId=SessionContext.getUser().id;
            }
            return request;
        }, function (error) {
            log.trace("request error "+error.title+" - "+error.status+" - "+error.detail+" - "+error.message);
            addMessage(t("server_failure"));
            return Promise.reject(error);
        });
        axios.interceptors.response.use(
            response => {
                if(response && response.status>399) {         
                    addMessage("response "+response.title+" - "+response.status+" - "+response.detail+" - "+response.message);
                    return Promise.reject(response);
                } 
                if(!response.__cached) {
                    setOffline(false);
                }
                log.trace("response "+response.config.url);
                /* if(response.config.url.indexOf("/api/authenticate")<0) { //do NOT store the test connection
                    SessionContext.setRequestResponse(response.config.method+":"+response.config.url, response);
                } */
                return response;
                },
            error => {
                if(error.response) {
                   /* if(error.response.config.url.indexOf("/api/authenticate")>-1) { 
                        return error.response;
                    } */
                    if(error.response.status===401) {
                        history.push("/login");   
                        if(error.response.config.url.indexOf("/api/authenticate")>-1) { 
                            addModalMessage(t("message_user_not_found"));
                        } else {
                            addModalMessage("Keine Berechtigung, bitte melde Dich an oder prüfe Dein Konto.");
                        }                            
                        return new Promise(() => {});//Promise.reject(error);             
                    } 
                    if(error.response.status===409 || error.response.status===404) {
                        log.trace("response error "+error.response.data+" - "+error.response.statusText+" - "+error.response.status);
                        return new Promise(() => {});
                        //return error.response;
                    }
                }
                
                    let errorMessage = error.name + "-" +error.message + "-" +error.fileName;
                    if(!error.response) {
                        /*  setOffline(true);   */
                        log.trace("response error "+JSON.stringify(error));    
                    } else {
                        log.trace("response error "+error.response.data+" - "+error.response.statusText+" - "+error.response.status);
                        errorMessage = errorMessage + " - " + error.response.data+" - "+error.response.statusText+" - "+error.response.status;
                        if(error.response.config.url.indexOf("api/")>0) {
                            addMessage(errorMessage);
                        }
                        return Promise.reject(error);
                    //log.trace(JSON.stringify(error.response));
                   
                }
                return new Promise(() => {});
                }
        )
        
    }, [addMessage])

    return children
}

export default MessageWithAxios
