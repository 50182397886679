import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../../pages/assets/AppFooter';
import DeskHeader from '../../pages/assets/DeskHeader';
import { MessageContext } from '../../pages/assets/MessageProvider';
import TimeRangeSelector from '../../pages/assets/TimeRangeSelector';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../../frame/SessionContext';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import ActionMenu from '../../pages/assets/ActionMenu';
import { useTranslation } from 'react-i18next';
import { Add, FileCopy, Remove } from '@material-ui/icons';




export default function OneWeekday(props) {
  const { t, i18n } = useTranslation();
  const { label } = props;
  const { vChecked } = props;
  const { vScopesDay } = props;
  const { checkedCallback } = props;
  const { updateValueCallback } = props;
  const { copyToOthers } = props;
  const { weekdayMode } = props;
  const { disabled } = props;
  
  const [ scopesDay, setScopesDay ] = useState([]);
  const [ checked, setChecked ] = useState(vChecked?vChecked:false);
  
  useEffect(() => {  
    if(scopesDay.length==0) {
      addScope();  
    }
    if(!vChecked) {
      clearScopes();
    } else if(vChecked && scopesDay.length>0) { 
      updateValueCallback(scopesDay);
      checkedCallback(scopesDay.length>0);
    }
    setChecked(vChecked);    
  }, [vChecked] )

  useEffect(() => { 
    if(vScopesDay) {
      setScopesDay(vScopesDay);    
      setChecked(vScopesDay.length>0);
      checkedCallback(vScopesDay.length>0);
    }
  }, [vScopesDay] )


  

  function clearScopes() {
    console.log("clear timescopse");
    const scopes = [];
    updateValueCallback(scopes);
  }
  function addScope() {
    console.log("Adding timescope");
    const scopes = [...scopesDay];
    let aScope = {};
    scopes.push(aScope);
    updateValueCallback(scopes);
  }
  function removeScope(index) {
    console.log("removing timescope");
    const scopes = [...scopesDay];
    scopes.splice(index,1);
    updateValueCallback(scopes);
  }

  
  
  function beginns(index, time) {
    const scopes = [...scopesDay];
    let scope = scopes[index];
    if(!scope) {
      scope={};
    }
    scope.begin = time;
    scopes[index]=scope;
    updateValueCallback(scopes);
  }
  function ends(index, time) {
    const scopes = [...scopesDay];
    let scope = scopes[index];
    if(!scope) {
      scope={};
    }
    scope.end = time;
    scopes[index]=scope;
    updateValueCallback(scopes);
  }

  function getBeginOf(index) {
    let scope = scopesDay[index];
    if(scope.begin) {
      return scope.begin;
    }
    return  Date.now();
  }

  function getEndOf(index) {
    let scope = scopesDay[index];
    if(scope.end) {
      return scope.end;
    }
    return  Date.now();
  }

  function getTimeScopeSelector(index) {
    return 
  }

  return (
    <Fragment>
      { weekdayMode && 
          <FormControlLabel
            checked={ checked }
            control={<Checkbox />}
            label={ label }
            disabled={disabled}
            labelPlacement="right"
            onClick={(event) => { setChecked(event.target.checked); checkedCallback(event.target.checked)  }}
        />
      }
      { !weekdayMode && <Grid container style={{marginBottom:"5px"}}>
        <Grid item  xs={2}>
          <Grid container  justify="center" >
            <Grid item  xs={12}>
              <FormControlLabel
                checked={ checked }
                control={<Checkbox />}
                label={ label }
                labelPlacement="right"
                onClick={(event) => { setChecked(event.target.checked); checkedCallback(event.target.checked)  }}
              />
            </Grid>
            { checked && <Grid item  xs={12}>
              <IconButton onClick={copyToOthers}>
                  <FileCopy />
              </IconButton>
            </Grid> }
          </Grid>
        </Grid>
        <Grid item  xs={10}>
          { checked && scopesDay &&
            scopesDay.map((value, index) => (
              <Grid container style={{marginBottom:"5px"}}>
                <Grid item  xs={2}>
                  { scopesDay.length>1 && 
                    <IconButton onClick={() => removeScope(index)}>
                      <Remove />
                    </IconButton>
                  }
                </Grid>
                <Grid item  xs={8}>
                  <TimeRangeSelector
                      hideDateFrom={true}
                      hideDateTo={true}
                      start={new Date(getBeginOf(index))}
                      setStartDate={(result) => { beginns(index,result) }}
                      chooseBegin={true}
                      editTimings={!disabled}    
                      end={new Date(getEndOf(index))}
                      setEndDate={(result) => { ends(index,result) }}
                      chooseEnd={true}
                      hideLabels={true}
                      hideSwitch={true}
                    />
                </Grid>
                <Grid item  xs={2}>
                  <IconButton onClick={addScope}>
                      <Add />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      }

    </Fragment>
    
  );
}
