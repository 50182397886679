import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Badge, Chip, Divider, Fab, IconButton, Menu, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { Apps, DateRange, ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined, SkipNext, SkipPrevious } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';
import Axios from 'axios';
import config from 'react-global-configuration';
import ActionMenu from './ActionMenu';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexFlow: "nowrap",
    "& .MuiPaper-root": {
      width: "100%"
    }
  },
  button: {
    display: "block",
    textAlign: "start"
  },
  badge: {
    width: "100%",
    textAlign: "left",
    display: "block"
  },
  root1: {
    display: 'flex',
    alignItems: 'center',
    width: "100%",    
    justifyContent: "space-between"    
  },
  
  bigChip: {
    borderRadius: '9px', //some style
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    width: "17rem",
    '& .MuiChip-label': { fontSize: "1.4rem" }, // sub-selector
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function PagingBrowser(props) {
  const classes = useStyles();
  const { pagerCallback } = props
  const { currentStartIndex } = props
  const { currentCount} = props
  
  const [ startIndex, setStartIndex ] = useState(currentStartIndex?currentStartIndex:0)
  const [ count, setCount ] = useState(currentCount?currentCount:0)
  
   
  useEffect(() => {   
    if(currentStartIndex) {
      setStartIndex(currentStartIndex);
    }
    if(currentCount) {
      setCount(currentCount);
    }
    
  })
  
  
  const page = (elements)  => { 
    var newStartIndex = currentStartIndex+elements;
    pagerCallback(newStartIndex, count);
  }

  return (
    
    <Fragment> 
      <Grid xs container justifyContent="space-between" alignItems="center"  className={classes.root} >
        <Paper elevation={0} square className={classes.root1}>
        
        
            {
             <IconButton className={classes.iconButton} aria-label="back" onClick={() => page(-1*count)} >
              <SkipPrevious/>
            </IconButton>
            }
            <Divider className={classes.divider} orientation="vertical" />
             
            <Typography  variant="p">
              <span className="label">{t("Show")} {count} => {startIndex} - {startIndex+count} {t("of_Everything")}</span>                    
            </Typography>
            
            <Divider className={classes.divider} orientation="vertical" />                
            { <IconButton className={classes.iconButton} aria-label="back" onClick={() => page(1*count)} >
                <SkipNext />
              </IconButton>              
            }
        </Paper>
      </Grid>
    </Fragment>    
  );
}