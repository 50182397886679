import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Badge, Chip, Fab, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';
import Axios from 'axios';
import config from 'react-global-configuration';

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    textAlign: "start"
  },
  badge: {
    width: "100%",
    textAlign: "left",
    display: "block"
  },
}));

export default function TicketFilter(props) {
  const classes = useStyles();
  const { filterCallback } = props
  const { counts } = props
  const { selectedQueue } = props
  const { displayOnly } = props
  const { filtersOpen } = props
  const { filterFunction } = props
  
  const [ showFilters, setShowFilters ] = useState(filtersOpen?true:false)
  const [ filters, setFilters ] = useState(props.filters)
  const [ qCounts, setQCounts ] = useState({})
  
   
  useEffect(() => {    // Update the document title using the browser API 
    let interval = null;
    interval = setInterval(() => {
      loadStatistics(); 
    }, 1500);
    return () => clearInterval(interval);
  }, [])

  const handleFilter = (event) => {
    setShowFilters(!showFilters?event.currentTarget:undefined);
    /* if(filterFunction)   {
      filterCallback(filters);
    } */
  }

  const loadStatistics = () => {
    console.log("loadStatistics " + selectedQueue);

    Axios.get(config.get('vqueueUrl') + '/api/tickets/statistics/' + selectedQueue.id)
      .then(response => {
        setQCounts(response.data);
        console.log("LOAD loadStatistics DONE  setting State ");        
      }
      );
  }

  const setFilter = (primaryStatus, secondaryStatus) => {  
    if(filterFunction)   {
      let tFilters = filters;
      if(!primaryStatus && !secondaryStatus) {
        tFilters=[];
      } else {
        let existingFilter = filters.filter((filter) => {
          return filter.primaryStatus==primaryStatus && filter.secondaryStatus==secondaryStatus; 
        }) 
        if(!existingFilter || existingFilter.length==0) {
          let filter = {
            primaryStatus: primaryStatus,
            secondaryStatus: secondaryStatus
          }
          tFilters.push(filter);
        } else {
          let leftFilters = filters.filter((filter) => {
            return filter.primaryStatus!=primaryStatus || filter.secondaryStatus!=secondaryStatus; 
          }) 
          tFilters=leftFilters;
        }
      }
      setFilters(tFilters);
      filterCallback(tFilters);
    } else {
      filterCallback(primaryStatus, secondaryStatus);        
    }
  }

  const isActive = (primaryStatus) => { 
    if(filterFunction) {
      if(!primaryStatus && filters.length==0) {
        return true;
      } 
      let existingFilter = filters.filter((filter) => {
        return filter.primaryStatus==primaryStatus; 
      }) 
      if(existingFilter && existingFilter.length>0) {
        return true;
      }
    }
    return false;
  }

  return (
    
    <Fragment> 
          { !showFilters && !displayOnly &&   <Fragment>
              <Grid xs={12} justify="center" >
                
                  <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%"}}  onClick={(event) => handleFilter(event)}>
                    <Avatar style={{backgroundColor:"white", border:"1px solid"}}>
                      <FilterListIcon style={{fontSize:"2rem", color:"black"}}/>
                    </Avatar>
                  </IconButton>
                
              </Grid>
            </Fragment>
          }
          { (showFilters || displayOnly) && <Fragment>
            { !displayOnly && <Grid xs={12} justify="center" >
              <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%"}}  onClick={(event) => handleFilter(event)} >
                <Avatar style={{backgroundColor:"white", border:"1px solid"}}>
                  <FilterListIcon style={{fontSize:"2rem", color:"black"}}/>
                </Avatar>
              </IconButton>
            </Grid> }
            <Grid item xs={12}  >
              <Grid container spacing={0}>  
                <Grid item xs={6} >
                  <Button style={{width:'100%'}}  onClick={(event) => setFilter()} block="true" className={classes.button}><Badge max={9999} color="secondary" badgeContent={qCounts.all && counts?counts.all+"/"+qCounts.all:qCounts.all}  className={classes.badge} anchorOrigin={{vertical: 'top',horizontal: 'left'}}><StatusTicket active={isActive()} /></Badge></Button>
                  <Button style={{width:'100%'}} onClick={(event) => setFilter("TIMESLOT")} block="true" className={classes.button}><Badge max={9999} color="secondary" badgeContent={qCounts.all && counts?counts.timeslot+"/"+qCounts.timeslot:qCounts.timeslot}  className={classes.badge} anchorOrigin={{vertical: 'top',horizontal: 'left'}}><StatusTicket active={isActive("TIMESLOT")} primaryStatus="TIMESLOT" /></Badge></Button>
                  <Button style={{width:'100%'}} onClick={(event) => setFilter("PREPARING")} block="true" className={classes.button}><Badge max={9999} color="secondary" badgeContent={qCounts.all && counts?counts.preparing+"/"+qCounts.preparing:qCounts.preparing}  className={classes.badge} anchorOrigin={{vertical: 'top',horizontal: 'left'}}><StatusTicket active={isActive("PREPARING")} primaryStatus="PREPARING" /></Badge></Button>
                </Grid>
                <Grid item xs={6} >
                  <Button style={{width:'100%'}} onClick={(event) => setFilter("WAITING")} block="true" className={classes.button}><Badge max={9999} color="secondary" badgeContent={qCounts.all && counts?counts.waiting+"/"+qCounts.waiting:qCounts.waiting}  className={classes.badge} anchorOrigin={{vertical: 'top',horizontal: 'left'}}><StatusTicket active={isActive("WAITING")} primaryStatus="WAITING" /></Badge></Button>
                  <Button style={{width:'100%'}} onClick={(event) => setFilter("PROCESSING")} block="true" className={classes.button}><Badge max={9999} color="secondary" badgeContent={qCounts.all && counts?counts.process+"/"+qCounts.process:qCounts.process}  className={classes.badge} anchorOrigin={{vertical: 'top',horizontal: 'left'}}><StatusTicket active={isActive("PROCESSING")} primaryStatus="PROCESSING" /></Badge></Button>
                  <Button style={{width:'100%'}} onClick={(event) => setFilter("FINALIZING")} block="true" className={classes.button}><Badge max={9999} color="secondary" badgeContent={qCounts.all && counts?counts.finalizing+"/"+qCounts.finalizing:qCounts.finalizing}  className={classes.badge} anchorOrigin={{vertical: 'top',horizontal: 'left'}}><StatusTicket active={isActive("FINALIZING")} primaryStatus="FINALIZING" /></Badge></Button>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        }
      </Fragment>    
  );
}