import React, { Fragment, useContext, useState } from 'react';
import config from 'react-global-configuration';
import SockJsClient from 'react-stomp';
import SessionContext from '../../frame/SessionContext';
import { MessageContext } from './MessageProvider';

import axios from 'axios';


export default function SocketHandler(props) {
  const { topic } = props;
  const { topicCallback } = props;
  const { entity } = props;
  
  function processMessage(message) {
    console.log("Websocket Message: " + JSON.stringify(message));
    topicCallback(message);      
  }
  
  
  return ( //TODO: In Management Queue kommen die Änderungen nicht an, da nur an ClientIDs gesendet wird - das ist falsch
    <Fragment> 
      { <SockJsClient url={config.get('broadcaster')+'/ws/ws-tickets'}
              topics={[topic]}
              onConnect={() => {
                  console.log("Connected SocketHandler Socket  "+topic+" changes.");
              }}
              onDisconnect={() => {
                  console.log("Disconnected SocketHandler Socket Changes on Client "+topic);
              }}
              onMessage={(message) => {
                console.log("Got a Message SocketHandler Socket Changes on Client "+topic);
                processMessage(message);              
              }}
              onConnectFailure={() => {
                console.log("Could not connect SocketHandler Socket Changes on Client "+SessionContext.getClientId());
              }}
            /> 
      }
    </Fragment>
  );
}
