import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TicketGrid from '../pages/assets/CardGrid';
import { ExpandLessOutlined, ExpandMoreOutlined, LocationCity, ZoomIn, Subject } from '@material-ui/icons';
import LocationData from '../pages/assets/LocationData';
import TimeScopeBrowser from '../pages/assets/TimeScopeBrowser';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import SockJsClient from 'react-stomp';
import ResourcesUsage from './ResourcesUsage';
import ColumnDisplayAndPage from '../pages/assets/ColumnDisplayAndPage';
import ResourcesStatus from './ResourcesStatus';
import { withTranslation } from 'react-i18next';
import Crumbs from '../queue/Crumbs';
import WebsocketUtil from '../authorization/WebsocketUtil';
import log from 'loglevel';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function ResourcesPartition(props) {
  const {t} = props;
  const classes = useStyles();
  const { vPlace } = props.location;
  const { vGroup } = props.location;
  const { vManage } = props.location?props.location:props;  
  const { backTo } = props.location;
  const [place, setPlace] = useState(vPlace?vPlace:SessionContext.getPlace());
  const [group, setGroup] = useState(vGroup?vGroup:SessionContext.getGroup());
  const [resources, setResources] = useState([]);
  const [resource] = useState({});
  const [ zoomPeriodState, setZoomeriodState ] = useState({})
  const [ from, setFrom ] = useState(null)
  const [ to, setTo ] = useState(null)
  const [partitionStatus, setPartitionStatus] = useState({});
  const [resourcePeriodStatusDTOMap, setResourcePeriodStatusDTOMap] = useState([]);
  const [resourceQueuePeriodStatusDTOMap, setRsourceQueuePeriodStatusDTOMap] = useState([]);
  const [viewportHeight, setViewportHeight] = useState({})
  const [firstResourcePeriod, setFirstResourcePeriod] = useState(null);
  const [lastResourcePeriod, setLastResourcePeriod] = useState(null);
  const [manage] = useState(vManage?vManage:SessionContext.isManageMode());
  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  const websocketQClient = new WebsocketUtil();

  
  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setResource(null);    
    SessionContext.setPlace(place);
    if(group) {
      SessionContext.setGroup(group);
    }
   
    let url = config.get('vqueueUrl')+'/api/resources/';
    if(group) {
      url = url + 'group/'+group.id;
    } else {
      url = url + 'place/'+place.id;
    }
    
    axios.get(url )
        .then(res => {
          let resources = res.data;
         resources.forEach(function (aResource, index) {
            aResource.style=getResourcesCylcesStyle(aResource);
          });
          setResources(resources)
          //loadResourcePartitionStatus();
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        }) 
  }, [] )

  function cleanup() {
      log.trace("cleanup ");
      websocketQClient.disconnect(() => {});
    }

  function processChange(partitionResourceDTO) {
    log.debug("ResourcesPartition processChange ");
      
    setPartitionStatus(partitionResourceDTO);
    convertResourcesMap(partitionResourceDTO.resourcePeriodStatusDTOMap);
  }

  function getContent(periodState) {
    return <span>
        { periodState.usedCycles } / { periodState.availableCylces }
      </span>
  }

  function getStateStyleColorFor(periodState, style) {
    let warning = 125;
    let full = 255;
    
    let usedPercent = 1/periodState.availableCylces*periodState.usedCycles;
    
    let red = usedPercent * full ;
    let green = full - (warning*(1-usedPercent)) ;
    let blue = 0;
    
    if(usedPercent>1) {
      blue=0;
      green=0;
    }

    style.backgroundColor = "rgb(" + red + "," + green + "," + blue + ")";
    /*if(periodOk(periodState)) {
      style.backgroundColor="green";
    }
    if(periodWarning(periodState)) {
      style.backgroundColor="orange";
    }
    if(periodDanger(periodState)) {
      style.backgroundColor="red";
    }*/
    //R:{value.remainingCycles} - U:{value.uncovered}
    return style;
  }

  function getStateStyle(periodState) {
    let style = {width:"10px", height:"10px", backgroundColor:"green"};
    let growth = 5 * periodState.usedCycles;;
    let size = 10 + growth;
    style.width=size+"px";
    style.height=size+"px";
    style = getStateStyleColorFor(periodState, style);
    return style;
  }
  function periodOk(periodState) {
    return periodState.usedCycles<periodState.availableCylces;
  }
  function periodWarning(periodState) {
    return periodState.usedCycles==periodState.availableCylces;
  }
  function periodDanger(periodState) {
    return periodState.usedCycles>periodState.availableCylces;
  }
  
  function currentPreviousChanged(periodState, index) {
    let previousState = resourcePeriodStatusDTOMap[index-1];
    if(previousState) {
      return (periodOk(periodState)!=periodOk(previousState)) 
      || (periodWarning(periodState)!=periodWarning(previousState))
      || (periodDanger(periodState)!=periodDanger(previousState))
    }
    return true;
  }

  function isPeriodSubPeriod(periodState, interval, important) {
    return (periodState.periodStart%(interval*60*1000))==0;
  }

  
  

  function getBarStateStyle(periodState) {
    let style = {width:"100%", height:"4px", margin: "0", padding: "0", display: "block", borderRight: "solid 1px black"};
    style = getStateStyleColorFor(periodState, style);
    let fullHour = isPeriodSubPeriod(periodState, 60);
    let halfHour = isPeriodSubPeriod(periodState, 30);
    let tenMinutes = isPeriodSubPeriod(periodState, 10);
    if(tenMinutes) {
      style.borderTop="solid 1px black";
    }
    if(halfHour) {
      style.borderTop="solid 2px black";
    }
    if(fullHour) {
      style.borderTop="solid 6px black";
    }
    return style;
  }
  
  
  function loadResourcePartitionStatus() {
    if(from) {
      let url = config.get('vqueueUrl')+'/api/resources/status/';
      
      if(group) {
        url = url + "group/"+group.id+"/"+from; //TODO !!!!!!!!!!!!!!!!!!!!!
      } else {
        url = url + "place/"+place.id+"/"+from;
      } 
      
      axios.get(url )
        .then(res => {
          if(res.data.resourcePeriodStatusDTOMap) {
            setPartitionStatus(res.data);
            convertResourcesMap(res.data.resourcePeriodStatusDTOMap);
          }
      }).catch(function (error) {
        console.log(error);
        addMessage(error.detail);
      })
    }
  }

  function convertResourcesMap(resourcePeriodStatusDTOMap) {
    setResourcePeriodStatusDTOMap(resourcePeriodStatusDTOMap);
    /*if(resourcePeriodStatusDTOMap.partitionStatus==="READY" && resourcePeriodStatusDTOMap.resourcePeriodStatusDTOMap) {
      let resourcePeriodStatusMap = [];
      let first = null;
      let last = null;
      Object.keys(resourcePeriodStatusDTOMap.resourcePeriodStatusDTOMap).forEach(function(key) {  //DUPPLICATE CODE -> ColumnDisplay...
        if(!first) {
          first = key;
        }
        last = key;
        let element = resourcePeriodStatusDTOMap.resourcePeriodStatusDTOMap[key];
        element.timePeriod=key;
        resourcePeriodStatusMap.push(element);            
      });
      setFirstResourcePeriod(first);
      setLastResourcePeriod(last);
    }*/
  }

  function getResourceStateHeaderCells(periodState) {
    let gridContent = [];
      resources.forEach(function (aResource, index) {
        
        let entry = 
            <div style={{display: "table-cell", width: "20px", textAlign: "center", overflow: "hidden", writingMode: "vertical-rl", border: "solid 1px", paddingTop: "4px"}}>
              <Link to={{pathname: '/my/groups/resources/edit', vResource:aResource}}  style={{color: "inherit", textDecoration: "none"}}>
                <div style={{fontSize: "0.8rem", fontWeight: "bold"}}>
                  {aResource.name}
                </div>
              </Link>
            </div>
          ;
        gridContent.push(entry);
     });
    return gridContent;
  }



  // BEGINN -- Resource Rendering here 
  function getResourceStateCells(periodState) {
    let gridContent = [];
      resources.forEach(function (aResource, index) {
        
        let entry = <div style={{display: "table-cell", width: "20px"}}>
            <span style={getResourceWorkingHourElement(aResource, periodState)}>

            </span>
          </div>;
        gridContent.push(entry);
     });
    return gridContent;
  }
  
  function getResourceWorkingHourElement(aResource, periodState) {
      let style = getBarStateStyle(periodState);
     style.backgroundColor="";
     var workingHourMatch = aResource.resourceWorkingHours.filter((workingHour) => {
      return normalizeTimeSameDay(periodState.periodStart,workingHour.start)<=periodState.periodStart && normalizeTimeSameDay(periodState.periodStart,workingHour.end)>=periodState.periodStart; ; 
    })
    if(workingHourMatch && workingHourMatch.length>0) {

      let warning = 125;
      let full = 255;
      let red = full ;
      let green = full;
      let blue = 0;
      if(aResource.resourceVariant) {
        let cyclesPerMinute = 1-(1/aResource.resourceVariant.speed);
          
          red = cyclesPerMinute * full ;
          green = full - (warning*(1-cyclesPerMinute)) ;
      }
      style.backgroundColor = "rgb(" + red + "," + green + "," + blue + ")";
      
      
    }
    return style;
  }

  function normalizeTimeSameDay(dateSource,timeSource) {
    var aDateSource = new Date(dateSource);
    var aTimeSource = new Date(timeSource);
    aDateSource.setHours(aTimeSource.getHours());
    aDateSource.setMinutes(aTimeSource.getMinutes());
    aDateSource.setSeconds(aTimeSource.getSeconds());
    aDateSource.setMilliseconds(aTimeSource.getMilliseconds());
    return aDateSource.getTime();
  }
  // END -- Resource Rendering here 
  



  //for one Resource always same, must be set once
  function getResourcesCylcesStyle(aResource) {
    let distance = (1000*60*60)/(1000*60/6*3)/60; 
    let style = {width:"100%", maxHeight:distance+"px", minHeight:distance+"px", margin: "0", padding: "0", display: "block"};
    
    let warning = 125;
    let full = 255;
    let red = full ;
    let green = full;
    let blue = 0;
    if(aResource.resourceVariant) {
      let cyclesPerMinute = 1-(1/aResource.resourceVariant.speed);
        
        red = cyclesPerMinute * full ;
        green = full - (warning*(1-cyclesPerMinute)) ;
    }
    style.backgroundColor = "rgb(" + red + "," + green + "," + blue + ")";
    return style;
  }

  function getResourcesElements() {
    let resourceElements = [];
    let dayOfWeek = new Date(from).getDay()-1;
    if(dayOfWeek<0) {
      dayOfWeek=6;
    }
    
    resources.forEach(function (aResource, index) {
      let active = aResource.kind==='HUMAN'; //field used for active/inactive
      let resourcEntry = {};
      resourcEntry.name=aResource.name;
      resourcEntry.id=aResource.id;
      resourcEntry.editTo={pathname: '/my/groups/resources/edit', vResource:aResource};
      if(active) {
          let entries = [];
        var todaysWorkingHours = aResource.resourceWorkingHours.filter((workingHour) => {
          return workingHour.index==dayOfWeek;
        })
        todaysWorkingHours.sort(function (a, b) {
          return a.start - b.start;
        })

        let firstWorkingHour = todaysWorkingHours[0];
      
          let date = new Date();
          if(firstWorkingHour) {
            date.setTime(firstWorkingHour.start);
          }
          date.setHours(0, 0, 0, 0);
          let beginOfDay = date.getTime();
          
          if(firstWorkingHour) {
            date.setTime(firstWorkingHour.end);
          }
          date.setHours(23, 59, 59, 999);
          let endOfDay = date.getTime();
          let start = normalizeTimeSameDay(beginOfDay,(firstWorkingHour?firstWorkingHour.start:endOfDay));          
          let fillerBefore = getFillerBarStateStyle(start-beginOfDay);
          entries.push(fillerBefore);
          let lastWorkingHour;
          let aMinute = 1000*60;
          todaysWorkingHours.forEach(function (aWorkingHour, index) {
            
            if(lastWorkingHour) { 
              let start = normalizeTimeSameDay(lastWorkingHour.end,aWorkingHour.start);
              let fillerInbetween = getFillerBarStateStyle(start-lastWorkingHour.end);
              entries.push(fillerInbetween);
            }
            let workingHourPointer = aWorkingHour.start;
            if(workingHourPointer!=0) {
              while(workingHourPointer<aWorkingHour.end) {
                entries.push(<span style={aResource.style}/>);
                workingHourPointer+=aMinute;
              }
              lastWorkingHour=aWorkingHour;
            }
          });

          if(lastWorkingHour) {
            let start = normalizeTimeSameDay(lastWorkingHour.end,endOfDay);          
            let fillerEnd = getFillerBarStateStyle(start-lastWorkingHour.end);
            entries.push(fillerEnd);
          }

          resourcEntry.entries=<Grid container>  
            <Grid xs={12}>
              {entries}
            </Grid>
          </Grid>;
          
        } else {
          resourcEntry.info=<Typography variant="h5" align="center" color="textSecondary" component="p">
            Ressource ist deaktiviert !              
          </Typography>;
        }
          resourceElements.push(resourcEntry);
    });

    return resourceElements;    
  }

  function getFillerBarStateStyle(diff) {
    
    //diff = diff/1000/60; // diff in minutes
    diff = (diff-60000)/(1000)/30; 
    let distance = (1000*60*60)/(1000*60/6*3)/60; //broken down to minutes and then multiplied with diff

    let style = {width:"100%", maxHeight:diff>2?0:distance+"px", minHeight:diff>2?0:distance+"px", marginTop: diff+"px", padding: "0", display: "block"};
    //style.backgroundColor = "rgb(255,255,255)";
    
    return <span name={"distancer_"+diff} style={style}/>;
  }

  
  function createResource() {
    history.push({pathname: '/my/groups/resources/edit', resource:null});
  }

  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link to={{pathname: '/my/groups/resources/edit', resource:null}} 
          style={{color: "inherit", textDecoration: "none"}}>
            <AddIcon />            
      </Link> 
    );
    return quickActionItems;
  }
  

  function getMoreMenuItems() {
    let gridContent = [
      {
        to: {pathname: '/my/groups/resources/variants', vPlace:vPlace },
        title: t("resource_alternative"),               
        text: t("resource_alternative"),
        icon: <Subject />
      }
      
    ];
    return gridContent;
  }

  return (
    <Container  maxWidth={false} disableGutters style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      
      <DeskHeader title={<Fragment>{t("resources")}&nbsp;<Crumbs group={group}/></Fragment>} backTo={SessionContext.getResorucesOrigin()?SessionContext.getResorucesOrigin():"/my/places"}  
          quickActions={getQuickActionItems()}
          moreMenuItems={getMoreMenuItems()}/>
        <Grid container spacing={1}  alignItems="center" justify="center" style={{ justifyContent: "center", display: "flex" ,textAlign: "center",position: "fixed",
            backgroundColor: "white", width:"100%", zIndex: "100", top: "75px", left: "0" }}>
          <Grid item xs={12} style={{ marginLeft: "2rem"}}>
              <ResourcesStatus key={"resourcePartitionResStatus"} vPlace={place} vGroup={group} partitionID={from}  from={from} to={to}  resourceStatusCallback={convertResourcesMap}/>
          </Grid> 
        </Grid>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid id="colsViewport"  container  justify="center" style={{borderBottom: "1px solid", overflow: "hidden", height:viewportHeight+"px",justifyContent: "center", display: "flex" ,textAlign: "center", marginTop:"3.5rem", paddingLeft:"10px", paddingRight:"0", marginBottom:"80px" }}>
          <ColumnDisplayAndPage
            key={"resourcesColumns"}
            manage={manage} 
            viewportHeightCallback={setViewportHeight} 
            resources={true} 
            resourceStatusDTOMap={resourcePeriodStatusDTOMap} 
            columns={getResourcesElements()}  
            from={from} 
            to={to} 
            place={place} 
            group={group} 
            createElement={createResource} />               
        </Grid>        
      </div>
      
      <AppFooter  actionBar={<TimeScopeBrowser cycleCallback={(from, to) => {
                          setFrom(from);
                          setTo(to);
                        }}/> }/>  
      
    </Container>
  );
}

export default withTranslation()( ResourcesPartition);