import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AppFooter from './assets/AppFooter';
import DeskHeader from './assets/DeskHeader';
import NavigationGrid from './assets/NavigationGrid';
import { MeetingRoom, Subscriptions } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';


class Slotmanager extends Component {
  
  constructor() {
    super();
  }
  
  getGridContent() {
    const {t} = this.props;
    let gridContent = [{
      iconLeft: <Subscriptions />,
      text: this.getText(t("domain_title"), t("verwaltung_hint")),
      link: "/my/domains",
      cardNumberStyle: { backgroundColor: "white" },
      cardContentStyle: { backgroundColor: "#CDD6C1" },
      cardMenuStyle: { backgroundColor: "white" }
    },
    {
      iconLeft: <Subscriptions />,
      text: this.getText(t("my_places"), t("verwaltung_hint")),
      link: "/my/places",
      cardNumberStyle: { backgroundColor: "white" },
      cardContentStyle: { backgroundColor: "#CDD6C1" },
      cardMenuStyle: { backgroundColor: "white" }
    }];
    return gridContent;
  }

  getText(title, hint) {
    return <Fragment>
      <Grid item xs={12}  >
        <Grid container spacing={0} alignItems="center" justify="center" style={{ textAlign: "center" }}>
          <Typography variant="body1" style={{ width: "100%", fontSize: "0.9rem", whiteSpace: "nowrap", fontWeight: "normal", textDecoration: "underline" }}>
            {hint}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}  >
        <Grid container spacing={0} alignItems="center" justify="center" >
          <Typography variant="body1" style={{ fontSize: "2rem", fontWeight: "bold" }}>
            <span >{title}</span>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  }

  cleanBrowserStorage() {
    ls.clear();    
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <DeskHeader title={t("management")} backTo="/"  />
          <Container maxWidth="xs" style={{ position: 'relative', marginTop: "90px", marginBottom: "100px" }}>
            <NavigationGrid gridContent={this.getGridContent()} />
          </Container>
        <AppFooter />            
      </div>
      
    );
  }
}

export default withTranslation()(Slotmanager);