import React, { useState,useContext, createContext, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

import axios from 'axios';
import config from 'react-global-configuration';
import { t } from 'i18next';
import { AppBar, Dialog, Divider, IconButton, List, ListItem, ListItemText, Slide, Toolbar } from '@material-ui/core';
import SessionContext from '../frame/SessionContext';
import { Attachment, Publish } from '@material-ui/icons';
import UploadFiles from './upload-files.component';
import DragDropFile from './DragDropFile';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadAttachements(props) {
  const classes = useStyles();
  const { ticket } = props;

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function successCallback() {
    handleClose();
    props.successCallback();
  }

  return (
    <Fragment>
      <IconButton   color="inherit" onClick={handleClickOpen} aria-label="close" style={{width: "100%", padding: "-10px", borderBottom: "solid 1px"}}>
        <Publish style={{fontSize: "3rem"}} />
      </IconButton>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
           <Typography component="h1" variant="h5" edge="start" className={classes.title}>
              Anlagen Hochladen
            </Typography>
            <IconButton   color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                { ticket && <UploadFiles ticketId={ticket.id} successCallback={successCallback}/> }
                
              </Grid>
              
            </Grid>
        </Container>
      </Dialog>
    </Fragment>
  );
}
