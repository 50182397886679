import React, { Fragment, useEffect, useState } from 'react';
import log from 'loglevel';
import axios from 'axios';
import config from 'react-global-configuration';

import {
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col
} from 'reactstrap';

export default function CategoryBadges(props) {
  const { queue } = props;
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  
  useEffect(() => {    
    log.debug(" useEffect ");
    let uri = '/api/category-queues/queue/'+queue.id;
    const url = config.get('vqueueUrl')+uri;
    
    axios.get(url)
      .then(res => {
        setCategories(res.data)
      }).catch(function (error) {
        console.log(error);          
      })   
  }, []);

  const toggleModal = () => setModal(!modal);

  const handleBadgeClick = (category) => {
    setSelectedCategory(category);
    toggleModal();
  };

  return ( 
    <Fragment>
      {categories.map((aCategory, index) => ( 
        <Fragment key={index}>
          <Badge style={{backgroundColor: aCategory.category.color}} 
            onClick={() => handleBadgeClick(aCategory)}><span style={{backgroundColor:"rgba(255, 255, 255, 0.65)", color:"black", padding:"3px", borderRadius:"5px"}}>i</span></Badge>&nbsp;
        </Fragment>
      ))}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          Am Slotmanager "{queue.name}" gibt es folgende Kategorien
        </ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col xs="auto">
              {/* Hier werden alle Badges im Modal Body angezeigt */}
              {categories.map((aCategory, index) => ( 
                <Fragment key={index}>
                  <Badge style={{backgroundColor: aCategory.category.color}}>
                    <span style={{backgroundColor:"rgba(255, 255, 255, 0.65)", color:"black", padding:"3px", borderRadius:"5px"}}>
                      {aCategory.category.name}
                    </span>
                  </Badge>&nbsp;
                </Fragment>
              ))}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Alles klar</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
