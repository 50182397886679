
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import CommonHeaderFragment from "./CommonHeaderFragment";
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SessionContext from '../../frame/SessionContext';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Avatar, Grid } from '@material-ui/core';
import { Fragment } from 'react';
import { MoreVert } from '@material-ui/icons';


const AVATAR = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

class ActionMenu extends Component {
  
  constructor() {
    super();
    this.state = {
      menuAnchor: null
    };
  }

  toggleSubMenu(event) {
    this.setState({
      menuAnchor : event.currentTarget
    })
  }

  
  closeSubMenu() {
    this.setState({
      menuAnchor : null
    })
  }


  render() {
      return (
     
        
      <IconButton edge="end" color="inherit" style={{padding: 0, width: "100%"}}  >
        <Avatar style={{backgroundColor:"white", border:"1px solid"}}>
          <MoreVert onClick={(event) => this.toggleSubMenu(event)} style={{fontSize:"2rem", color:"black"}}/>
          <Menu
            id="desk-sub-menu"
            anchorEl={this.state.menuAnchor}
            keepMounted
            open={Boolean(this.state.menuAnchor)}
            onClose={() => this.closeSubMenu()}
          >
            { this.props.menuItems }
            
          </Menu>
        </Avatar>
      </IconButton>
              
    );
  }
}

export default ActionMenu;
