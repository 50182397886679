

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import SessionContext from '../../frame/SessionContext';

const AVATAR = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';

const useStyles =(theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  list: {
    width: 250,
  }
});


class CommonHeaderFragment extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      mainMenu: false
    };
  }

  toggleDrawer(show) {
    this.setState({
      mainMenu : show
    })
  }

  goBack() {
    if(this.props.backTo) {
      this.props.history.push(this.props.backTo);
    } else {
      this.props.history.goBack();
    }
  }

 

   

    

  render() {
      const { classes } = this.props;
      

      return (
        <Fragment>
             <Typography variant="h6" className={classes.title}>
                {(SessionContext.getActiveQueue()?SessionContext.getActiveQueue().name+" ":"")+(this.props.title?this.props.title:"")}
              </Typography>         
        </Fragment>
        
    );
  }
}
export default withRouter(withStyles(useStyles)(CommonHeaderFragment))

