import { Button, CssBaseline, MenuItem, Typography, withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { AccountBalanceWallet } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../frame/SessionContext';
import AppFooter from '../pages/assets/AppFooter';
import TicketGrid from '../pages/assets/CardGrid';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import ServiceCard from '../pages/assets/ServiceCard';
import TicketData from '../pages/assets/TicketData';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';
import ActionMenu from './assets/ActionMenu';
import axios from 'axios';
import config from 'react-global-configuration';
import TicketDataUtil from '../pages/assets/TicketDataUtil';
import Card from '../pages/assets/Card';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Wallet extends Component {
  static contextType = MessageContext

  constructor() {
    super();
    this.state={
      active:true
    };
  }

  componentDidMount() {
    if(this.props.location.vPlace) {
      SessionContext.setPlace(this.props.location.vPlace);
    }
    let tuid = null;

    if(this.props.location) {
      let query = new URLSearchParams(this.props.location.search);
      tuid = query.get('tuid');  
      if(tuid!=null) { 
        this.loadTicket(tuid);
      }
    }

    this.loadAllClientsTickets();

    this.setState({
      myTickets : SessionContext.getMyTickets()
    });   
    
  }

  loadAllClientsTickets() {
    const msgCtx = this.context
    let clientId = SessionContext.getClientId();
    console.log("Wallet loadAllClientsTickets "+clientId);
    axios.get(config.get('vqueueUrl')+'/api/tickets/client/'+clientId)
        .then(response => {   
          console.log("tickets loaded for client"+response.data.length);
          response.data.forEach(function (aTicket, index) {
            SessionContext.refreshTicket(aTicket);
          });
          this.setState({
            myTickets :  response.data
          }); 
         }
      ).catch(function (error) {
        msgCtx.addMessage(error);
        console.log(error);
      });  
  }

  loadTicket(tuid) {
    const { t } = this.props;
    const msgCtx = this.context
    console.log("Wallet loadTicket "+tuid);
    axios.get(config.get('vqueueUrl')+'/api/tickets/uuid/'+tuid)
        .then(tresponse => {   
          console.log("ticket loaded, now load q "+tresponse.data.queueId);
          let ticket = tresponse.data;
          ticket.queue=tresponse.data.queue;
          let cardContent = TicketDataUtil.convertToTicketCardContent(ticket, ticket.queue, null, false, false, true, false, false);
          let modalText =  <Grid  container spacing={1} >
                  <Grid item xs={12} >
                    <Card area={cardContent} queue={ticket.queue}/>
                  </Grid>
                </Grid>
          msgCtx.addModalMessage(modalText, "Slot Info");
          let cloneTicket = {}
          Object.assign(cloneTicket, ticket); 
          SessionContext.addTicket(cloneTicket.queue,cloneTicket);
          msgCtx.setTicket(cloneTicket);
          //SessionContext.refreshTicket(ticket);
         }
      ).catch(function (error) {
        msgCtx.addMessage(error);
        console.log(error);
      }); ;     
  }

  componentDidUpdate() {
    const msgCtx = this.context;
    console.log("Wallet componentDidUpdate: "+JSON.stringify(msgCtx.ticket));
      if(msgCtx.ticket ) {
        console.log("msgCtx.ticket: "+JSON.stringify(msgCtx.ticket));
        //SessionContext.refreshTicket(msgCtx.ticket);
        this.setState({
          myTickets : SessionContext.getMyTickets()
        });   
        msgCtx.removeTicket();                   
      } 
  }

  showAlert(message) {
    const msgCtx = this.context
    msgCtx.addMessage(message);
  }

  getGridContent() {
    const { t } = this.props;
    let gridContent = [];
      if(this.state.myTickets) {
      let myTickets = this.state.myTickets;
      myTickets.sort((a, b) => b.id - a.id);
      /* myTickets.sort(function (a, b) {
        return b.id - a.id;
      }) */
      //myTickets = myQueues.filter((queue) => {
      //  return queue.currentTicket;
      //})
      for (const ticket of myTickets) {http://localhost:3000/wallet?tuid=9a305d71-7aae-456a-a0f6-4c0e7e4437b3
        if((this.state.active && (ticket.primaryStatus==="FINALIZING")) ||
            (!this.state.active && ticket.primaryStatus!=="FINALIZING")) {
          continue;
        } 
        //let queue = SessionContext.getQueueOfTicket(ticket);
          if(ticket.queue) {
          let entry =  {
            number: <Fragment> { ticket.position +"-"+ticket.id }</Fragment> ,
            crumb: <Crumbs ticket={ticket}/>,
          title: ticket.queue.name,
            text: <Fragment>
              { ticket!=null &&  ticket.status!="SERVICE" &&
                  <TicketData ticket={ticket} queue={ticket.queue}/>
              }
              { ticket!=null &&  ticket.status=="SERVICE" &&
                  <ServiceCard ticket={ticket} />
              }
              { ticket==null && 
                <Fragment> 
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
                      <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                        {t("next_timepoint")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" >
                      <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
                        <span >{t("book_now")}</span>    
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>  
              } 
              </Fragment> ,
            menuItems: this.getMenuItems(ticket),
            action: this.state.active && <Link to={{pathname: '/room', ticket: ticket, backTo: '/wallet'}}  style={{width:"100%"}}><Button color={'secondary'} variant="contained" style={{width:"100%", height:"2rem", fontWeight:"bold"}} >{t("show")}</Button></Link>,
            ticket: ticket.primaryStatus!='FINALIZING'?ticket:null
          };
          gridContent.push(entry);
        }
      }
    }  
    return gridContent;
  }

  returnTicket(ticket) {
    SessionContext.refreshTicket(ticket);    
    const msgCtx = this.context;
    msgCtx.setTicket(ticket);
  }

  getMenuItems(ticket) {
    const { t } = this.props;
    return [
      <ActionMenu ticketEditor='/editTicket' queue={ticket.queue} ticket={ticket} />        
    ]
  }

  render()
   {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
        <DeskHeader title={t("timeslot_headder")}  backTo={"/"} />
        <CssBaseline />
        <div  className={classes.paper}>
          <Avatar >
            <AccountBalanceWallet />
          </Avatar>
          <Typography component="h1" variant="h5">
              {t("wallet_title")}
          </Typography>
          <Typography component="body">
              {t("actual_timeslots")}
          </Typography>
          
        <Grid container>  
            <Grid xs={6}>
                <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" onClick={() => this.setState({active:true})}>
                    {t("aktuell_button")}
                </Button>
            </Grid>
            <Grid xs={6}>
                <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" onClick={() => this.setState({active:false})}>
                    {t("histories_button")}
                </Button>
            </Grid>
          
          <Grid xs={12}>
            
            <TicketGrid gridContent={this.getGridContent()} />
          </Grid> 
          
        </Grid>
      </div>
      <AppFooter />
    </Container>
      
    );
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation()(Wallet));