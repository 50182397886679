import React, { useState, useCallback } from 'react';
import log from 'loglevel';

export const MessageContext = React.createContext({
  message: null,
  addMessage: () => {},
  modal: null,
  title: null,
  addModalMessage: () => {},
  removeMessage: () => {},
  wsTickets: null,
  setWsTickets: () => {},
  getWsTickets: () => {},
  ticket: null,
  setTicket: () => {},
  getTicket: () => {},
  removeTicket: () => {},
  offline: null,
  setOffline: () => {}
});

export default function MessageProvider({ children }) {
  const [message, setMessage] = useState(null);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [wsTickets, setWsTickets] = useState(null);
  const [myTicketsUpdated, setMyTicketsUpdated] = useState(null);
  const [queue, setQueue] = useState(null);
  const [offline, setOffline] = useState(null);
  
  const removeMessage = () => setMessage(null);

  const addMessage = (message) => { 
    setModal(false); 
    setMessage(message); 
    log.debug("addMessage: "+message);
    
  };
  const addModalMessage = (message, title) => { 
    setModal(true); 
    setTitle(title); 
    setMessage(message); 
  };
  const addTitle = (title) => setTitle({ message });

  const removeTicket = () => setTicket(ticket);
  const getTicket = () => {return ticket};

  const removeWsTickets = () => setWsTickets(wsTickets);
  const getWsTickets = () => {return wsTickets};

  const removeQueue = () => setQueue(null);
  
  

  const contextValue = {
    message,
    addMessage: useCallback((message) => addMessage(message), []),
    modal,
    title,
    addModalMessage: useCallback((message, modal) => addModalMessage(message, modal), []),
    removeMessage: useCallback(() => removeMessage(), []),
    myTicketsUpdated,
    setMyTicketsUpdated: useCallback((myTicketsUpdated) => setMyTicketsUpdated(myTicketsUpdated), []),
    title,
    addTitle: useCallback((message) => addTitle(message), []),
    ticket,
    setTicket: useCallback((ticket) => setTicket(ticket), []),
    removeTicket: useCallback(() => removeTicket(), []),
    getTicket: useCallback(() => getTicket(), []),
    wsTickets,
    setWsTickets: useCallback((wsTickets) => setWsTickets(wsTickets), []),
    removeWsTickets: useCallback(() => removeWsTickets(), []),
    getWsTickets: useCallback(() => getWsTickets(), []),
    queue,
    setQueue: useCallback((queue) => setQueue(queue), []),
    removeQueue: useCallback(() => removeQueue(), []),
    offline,
    setOffline: useCallback((offline) => setOffline(offline), []),
  };

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );  
}