import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { ChevronRight } from '@material-ui/icons';
import TicketDataUtil from './TicketDataUtil';
import WebsocketUtil from '../../authorization/WebsocketUtil';
import log from 'loglevel';
import MoreMenu from './MoreMenu';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "200px"
  },
});

export default function Card(props) {
  const classes = useStyles();
  const websocketQClient = new WebsocketUtil();
  const [menuOpen, setMenuOpen] = useState(false)
  const [area, setArea] = useState(props.area?props.area:null)
  const [ticket, setTicket] = useState(area?area.ticket:null);
  //const [ cTicket, setCTicket] = useState(ticket?ticket:(area.ticket?area.ticket:null));
  const { vTicket } = props;
  const { small } = props;
  const { call } = props;
  const { queue } = props;
  const { popupView } = props;
  const { manage } = props;
  const { hideMenu } = props;

  useEffect(() => {    // Update the document title using the browser API 
    if(ticket && area.ticket && (ticket.id==area.ticket.id)) {
      log.trace("Ticket updated ID :"+ticket.id);
      log.trace("Ticket updated primaryStatus :"+ticket.primaryStatus);
      let newArea = area; 
      let newCard = TicketDataUtil.convertToTicketCardContent(ticket, queue!=null?queue:ticket.queue, null, small, call, !popupView, manage, hideMenu );
      newArea = { ...newArea, ...newCard };
     setArea(newArea);
     let now = new Date().getTime();
     if(!small 
          && !websocketQClient.initialized()
          && ticket.primaryStatus!=="FINALIZING"
          && ticket.endPlanned>now) {
      websocketQClient.websocket(
        '/topic/tickets/'+ticket.uid, 
        ()=> {},
        handleWebsocket);
     }
    } return () => { cleanup(); };

  }, [ticket] )

  
  
function cleanup() {
  log.trace("cleanup ");
  websocketQClient.disconnect(() => {});
}

  useEffect(() => {    
    setArea(props.area);
  }, [props.area] )


  const handleWebsocket = (aTicket) => {
    setTicket(aTicket);
  }


  const getCard  = () => {
    if(area.externalLink) {
      return <a href={area.externalLink} style={{textDecoration:"none", color:"black"}}>
        {getCardContent()}
      </a>
    } else if(area.link) { 
      return <Link to={area.link} style={{textDecoration:"none", color:"black"}}>  
        {getCardContent()}
      </Link>
    }  
   
    {
      return getCardContent();
    }
  }

  const getCardContentStyle = () => {
    let style = {borderLeft:"1px solid",borderRight:"1px solid",padding:"5px",backgroundColor: area.color};
    return mergeStyle(style, area.cardContentStyle );
  }

  const getCardNumberStyle = () => {
    let style = {backgroundColor:"#1BA1E2",padding:"5px"};
    return mergeStyle(style, area.cardNumberStyle );
  }

  const getCardMenuStyle = () => {
    let style = {padding:"5px"};
    return mergeStyle(style, area.cardMenuStyle );
  }
  
  function mergeStyle(target, source) {
    Object.assign(target, source);    
    return target;
  }


  

  const getCardContent  = () => {
    let cardContent = area; //descide here wether to use the popup content to 
    return <Fragment>
        { area.custom && !popupView &&
            area.custom 
        }
        { (!area.custom || popupView ) &&
          <Grid id={"ticketEntryCard"+(area.ticket?area.ticket.id:"NO_TICKET")} container spacing={1} style={{position: 'relative', marginTop:area.custom?"0":"0.5rem", padding:"0"}} >
            <Grid item xs={12}  style={{border:"1px solid",padding:"0"}}>
              <Grid container>
                <Grid item xs={1}  style={getCardNumberStyle()}>
                  <Grid container  style={{height:"100%"}} >
                    {cardContent.number && 
                        <Typography  variant="body1" style={{width:"100%", textOrientation:"mixed", writingMode:"vertical-rl", textAlign:"center"}}>
                                <span style={{width:"100%", fontSize:"1.5rem", fontWeight:"bold", color:"white", lineHeight:"0rem"}}>{cardContent.number}</span>  
                        </Typography>
                    }
                    {cardContent.iconLeft && 
                      cardContent.iconLeft
                    }
                  </Grid>
                </Grid>
                <Grid item xs={10}  style={getCardContentStyle()}>
                    <Grid container >
                          <Grid item xs={12}  >
                            <Grid container>
                              <Typography  variant="body1" style={{width:"100%", textAlign:"left", fontSize:"0.7rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                              {cardContent.object?cardContent.object.id:''}{cardContent.crumb}
                              </Typography>
                            </Grid>
                          </Grid>
                        <Grid item xs={12} >
                          { !cardContent.customTitle && 
                            <Grid container style={{overflow:"hidden"}}>
                              <Typography  variant="body1">
                                  <span className="label" style={{whiteSpace: "nowrap"}}>{cardContent.title}</span>  
                                </Typography>
                            </Grid>
                          }
                          { cardContent.customTitle && 
                            cardContent.customTitle
                          }
                        </Grid>
                        <Grid item xs={12} style={{paddingLeft:"3px"}}>
                          <Grid container>
                            {cardContent.text}
                          </Grid>
                        </Grid>   
                    </Grid>
                  </Grid>
                  <Grid item xs={1}  style={getCardMenuStyle()}>
                    <Grid container style={{height:"100%"}}>
                      { ((cardContent.menuItems && cardContent.menuItems.length>0) || (cardContent.plainItems && cardContent.plainItems.length>0)) && 
                          <MoreMenu iconStyle={{fontSize:"3rem"}} menuItems={cardContent.menuItems} plainItems={
                            cardContent.plainItems
                          } />                        
                    }
                    { !cardContent.menuItems && (cardContent.iconLeft && !cardContent.menuItems && !cardContent.plainItems) &&
                      <Typography  variant="body1" style={{width:"100%", textOrientation:"mixed", writingMode:"vertical-rl", textAlign:"center"}}>
                        <ChevronRight />
                      </Typography>
                    }
                    </Grid> 
                  </Grid>
                  { (cardContent.sub )  &&
                      <Fragment>
                        <Grid item xs={12} >
                          <Grid container style={{borderTop:"1px solid", paddingTop:"4px"}}>
                            {cardContent.sub}                   
                          </Grid>
                        </Grid>  
                      </Fragment>                                   
                  }
                  { (cardContent.action) && ((cardContent.action.length>0) || (cardContent.action.props && cardContent.action.props.children.length>0)) &&
                      <Fragment>
                        <Grid item xs={12} >
                          <Grid container style={{borderTop:"1px solid", paddingTop:"4px"}}>
                            {cardContent.action}                   
                          </Grid>
                        </Grid>  
                      </Fragment>                                   
                  }
                  { cardContent.details &&
                    <Grid item xs={12}  style={{border:"1px solid"}}>
                      <Grid container>
                        { cardContent.details }       
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            
          </Grid>
        }
      </Fragment>    
  }

  return (
     area && getCard()  
  );
}