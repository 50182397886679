import MomentUtils from "@date-io/moment";
import { Grid } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from "moment";
import "moment/locale/de";
import React, { Component } from 'react';
import { t } from "i18next";

moment.locale("de"); // it is required to select default locale manually


class TimeRangeSelector extends Component {


  setStartDate(cDate) {
    let date = cDate.toDate();
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.props.setStartDate(date.getTime());
  }

  setEndDate(cDate) {
    let date = cDate.toDate();
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.props.setEndDate(date.getTime());
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
        { !this.props.hideLabels && <Grid xs={12}>
            <h5>{t("start_point")}
            { !this.props.hideSwitch &&
              <Switch
                color="primary"
                checked={this.props.chooseBegin}
                onChange={this.props.toggleBegin}
                name="checkedB"
                disabled={!this.props.editTimings}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }</h5>
          </Grid> }
          {!this.props.hideDateFrom &&
            <Grid xs={12}>
      
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de"}>
                <DatePicker
                  locale="locale" utils={MomentUtils}
                  showTodayButton
                  disabled={!this.props.chooseBegin || !this.props.editTimings}
                  variant="dialog"
                  inputVariant="standard"
                  label={t("date")}
                  helperText={t("startdate")}
                  format="DD.MM.yyyy"
                  value={new Date(this.props.start)}
                  onChange={date => this.setStartDate(date)}
                  InputAdornmentProps={{ position: "start" }}
                  //placeholder="ex: DD/MM/AAAA"
                  okLabel={t("choose")}
                  clearLabel={t("clrear")}
                  cancelLabel={t("cancel")}
                  todayLabel={t("today")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          <Grid xs={12}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de"}>
              <TimePicker
                showTodayButton
                disabled={!this.props.chooseBegin || !this.props.editTimings}
                value={new Date(this.props.start)}
                label={t("of")}
                ampm={false}
                onChange={date => this.setStartDate(date)}
                InputAdornmentProps={{ position: "start" }}
                okLabel={t("choose")}
                clearLabel={t("clrear")}
                cancelLabel={t("cancel")}
                todayLabel={t("now")} />
            </MuiPickersUtilsProvider>
          </Grid>         
        </Grid>


        
        
        <Grid item xs={6}>
        { !this.props.hideLabels && <Grid xs={12}>
             <h5>{t("end")}
                { !this.props.hideSwitch &&
                  <Switch
                    checked={this.props.chooseEnd}
                    onChange={this.props.toggleEnd}
                    color="primary"
                    name="checkedB"
                    disabled={!this.props.editTimings}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }</h5>
           </Grid>  }
           {!this.props.hideDateTo &&
              <Grid xs={12}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de"}>
                      <DatePicker
                        showTodayButton
                        autoOk
                        disabled={!this.props.chooseEnd || !this.props.editTimings}
                        minDate={new Date(this.props.start)}
                        // minDateMessage={`A data deve ser maior que ${dayjs(minDate).format(
                        //  'DD/MM/YYYY',
                        // )}.`}
                        variant="dialog"
                        inputVariant="standard"
                        label={t("date")}
                        helperText={t("enddate")}
                        format="DD.MM.yyyy"
                        value={new Date(this.props.end)}
                        onChange={date => this.setEndDate(date)}
                        InputAdornmentProps={{ position: "start" }}
                        okLabel={t("choose")}
                        clearLabel={t("clrear")}
                        cancelLabel={t("cancel")}
                        todayLabel={t("today")} />
                    </MuiPickersUtilsProvider>
              </Grid> 
            } 
           <Grid xs={12}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de"}>
                  <TimePicker
                    showTodayButton
                    disabled={!this.props.chooseEnd || !this.props.editTimings}
                    minDate={new Date(this.props.start)}
                    label={t("until")}
                    ampm={false}
                    value={new Date(this.props.end)}
                    onChange={date => this.setEndDate(date)}
                    InputAdornmentProps={{ position: "start" }}
                    okLabel={t("choose")}
                    clearLabel={t("clrear")}
                    cancelLabel={t("cancel")}
                    todayLabel={t("now")} />
                </MuiPickersUtilsProvider>
            </Grid>  
          </Grid>
        </Grid>
    );
  }
}


export default TimeRangeSelector;