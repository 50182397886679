import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import React, { Component, Fragment, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import LinkCard from '../pages/assets/LinkCard';
import NavigationGrid from '../pages/assets/NavigationGrid';
import SessionContext from '../frame/SessionContext';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import { AccessAlarm, Category, Settings } from '@material-ui/icons';
import Crumbs from './Crumbs';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


  function QueueSettings(props) {
    const {t} = props;
    const { vQueue } = props.location;
    const [queue, setQueue] = useState(vQueue);
  

    useEffect(() => {    // Update the document title using the browser API 
      console.log("vQueue changed");
      setQueue(vQueue)    ;
    }, [vQueue] )

   

  const getGridContent = (event) => {
    return [
      {
        title:t("opening_hours"),
        iconLeft: <AccessAlarm />,
        text:t("special_times"),
        link:{pathname: '/my/groups/slotmanager/settings/slots', vQueue: queue, vBackTo: "/my/groups/slotmanager/settings"}        
      },
      {
        title:t("categories"),
        iconLeft: <Category />,
        text:t("tickets_sorted_after_time"),
        link:{pathname: '/my/groups/slotmanager/settings/categories', vDomain: queue.place.domain,  vQueue: queue, vBackTo: "/my/groups/slotmanager/settings"}
      },
      {
        title:t("general_settings"),
        iconLeft: <Settings />,
        text:t("other_settings"),
        link:{pathname: '/my/groups/slotmanager/settings/base', vQueue: queue, vBackTo: "/my/groups/slotmanager/settings"}   
      },
      {
        title:t("rights"),
        text:t("other_access"),
        link:{pathname: '/authorizations', title:<Crumbs queue={queue}/>, vBackTo: "/my/groups/slotmanager/settings", vKind: "QUEUE", vKindId: queue.id}
      }
      
      /*,
      {
        title:"Erweiterte Einstellungen",
        text:"Spezielle Einstellungen wie gewartet werden soll, können hier vorgenommen werden",
        link:"/expertsettings"
      },
      {
        title:"Statistiken",
        text:"Interessante Einblicke in die Wartesituationen.",
        link:"/qstatistics"
      }*/
      
      
    ];
  }

  return (
    <div> { queue &&
      <Fragment>
        <DeskHeader 
          title={<Fragment><Crumbs queue={queue}/> : <Fragment>{t("settings")}</Fragment></Fragment>}
          backTo={SessionContext.getQSettingsOrigin()}/>
        <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
          <NavigationGrid gridContent={getGridContent()} />
        </Container>
        <AppFooter /> 
      </Fragment>
      }
    </div>
  );
}

export default withTranslation()(QueueSettings);