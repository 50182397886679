import React, { Fragment } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import DateTimeDisplay from "../pages/assets/DateTimeDisplay";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    bottom: 0
    // position: "fixed" // remove this so we can apply flex design
  },
  bubbleContainer: {
    display: "flex" //new added flex so we can put div at left and right side
    //check style.css for left and right classnaeme based on your data
  },
  bubble: {
    border: "0.5px solid black",
    borderRadius: "10px",
    margin: "5px",
    padding: "10px",
    display: "inline-block"
  }
}));

const ChatMessage = (props) => {
  
  return <Grid container  >
  { props.sender && 
      <Grid item xs={6} >
        <Typography  variant="body1">
            <span className="label" style={{fontSize: "0.8rem"}}>{props.message.sender}</span>  
        </Typography>
          
      </Grid> 
   }
  { props.timestamp && 
      <Grid item xs={12}>
        <Grid container  >
          <Grid item>
            <Typography  variant="body1">
              <span className="label" style={{fontSize: "0.6rem", lineHeight: "0.4rem"}}><DateTimeDisplay timestamp={props.message.timestamp}/></span><br/>  
              <span className="label" style={{fontSize: "0.8rem", lineHeight: "0.7rem"}}>{props.message.sender}</span>  
            </Typography>
            
          </Grid>
        </Grid>
      </Grid>
  }
  
  <Grid item xs={12}>
    {props.message.message}
  </Grid>              
</Grid>;
};

export default ChatMessage;