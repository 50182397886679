import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from './SessionContext';
import { CircularProgress, Fab, MenuItem } from '@material-ui/core';
import { CheckCircleOutline, Error, HighlightOff, HighlightOffOutlined, OfflineBolt, Warning, WarningOutlined } from '@material-ui/icons';
import Axios from 'axios';
import {t} from 'i18next';




export default function Offline(props) {
  const { offline } = useContext(MessageContext);
  const { setOffline } = useContext(MessageContext);
  const { addMessage } = useContext(MessageContext)
  const [ interval, setInterval ] = useState(null);
  
  useEffect(() => {    // Update the document title using the browser API 
      console.log("Mode change offline="+offline);
      if(offline!=null && offline!=SessionContext.getOffline()) {
        SessionContext.setOffline(offline);
        showNotification();
        if(offline) {
          addMessage(t("you_are_offline"));              
        } else {
          setOffline(false);
          addMessage(t("connected"));    
        }
      }
    }, [offline] )

    useEffect(() => {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else {
        console.log("Notifications are supported");
        Notification.requestPermission();
      }
      const interval = setInterval(async () => {
        testConnection(); 
      }, 1500);
    
      return () => {
        console.log(`clearing testConnection interval`);
        clearInterval(interval);
      };
    }, []);

    const showNotification = () => {
      var options = {
        body: offline?"Offline":"Online",
        icon: "https://www.viwaq.de/wp-content/uploads/2022/01/Q-1.png",
        dir: "ltr"
      };
      var notification = new Notification("viWaQ App Status", options);
    }

    const testConnection = () => {
      console.log("testConnection ");
      if(SessionContext.getOffline()) {
        console.log("offline TEST");
        Axios.get(config.get('vqueueUrl') + '/api/authenticate')
        .then(response => {
          console.log("testConnection SUCCEEDED. Should switch to ONLINE");    
          setOffline(false);    
        }
        ).catch(function (error) {
          console.log("testConnection NOT SUCCEEDED. Stays offline");  
          let interval = null;
          
        }) ;
        
      } else {
        console.log("online.. NO TEST");
      }
    
    }

  return (
    <Fragment>
      {  offline && <OfflineBolt />
      }
    </Fragment>
    );
}
