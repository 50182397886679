import React, { useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import config from 'react-global-configuration';
import { useHistory } from "react-router-dom";
import { MessageContext } from '../pages/assets/MessageProvider';
import SessionContext from '../frame/SessionContext';
import { t } from 'i18next';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SignIn() {
  const classes = useStyles();
  const query = useQuery();
  const { addMessage } = useContext(MessageContext)
  const { addMessageModal } = useContext(MessageContext)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  
  useEffect(() => {    // Update the document title using the browser API 
    console.log("query: " + query);
    if(query.get("key")) {
      const url = config.get('vqueueUrl')+'/api/activate?key='+query.get("key");
      document.title = `You clicked ${url} times`;  
      console.log("url: " + url);
      axios.get(url )
          .then(res => {
            addMessageModal(t("user_activated"));
            console.log(res);
          //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
          }).catch(function (error) {
            // handle error
            console.log(error);
            addMessage(error.detail);
            
          })   
        }
        
        
  }, [] )

  

  function validateForm() {
    return email.length > 0 && password.length > 5;
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Submit User for login.")
    var userLang = navigator.language || navigator.userLanguage;
    let entity = {
      password: password,
      username: email
    }
    
    const url = config.get('vqueueUrl')+'/api/authenticate';

    axios.post(url,  entity )
    .then(res => {
      
      //addMessage(t("user_logged_in"));
      console.log(JSON.stringify(res));
      SessionContext.setToken(res);
      axios.get(config.get('vqueueUrl')+'/api/account')
        .then((data) => {
          console.log(data.data)
          SessionContext.setUser(data.data);
          const urlState = config.get('vqueueUrl')+'/api/state/'+data.data.login;
          axios.get(urlState )
            .then(res => {
              //addMessage(t("user_state_loaded"));
              console.log(res);
              if(res.data.favouriteQueues && res.data.favouriteQueues.length>0) {
                SessionContext.setFavourites(res.data.favouriteQueues);  
              }
              //addMessage(t("user_state_recoverd"));
                          
              //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
            }).catch(function (error) {
              // handle error
              console.log(error);
              addMessage(error.detail);
              
            })  
          history.push("/");
        });
      
    //  this.props.history.goBack();//this.props.history.push("/queuemanagement")
    }).catch(function (error) {
      // handle error
      console.log(error);
      
    })
  }
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={t("user_login")}  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("login_with_access_data")}
        </Typography>
        <form className={classes.form} noValidate  onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email_adress")}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("pw")}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          { /*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t("remember")}
          /> */ }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!validateForm()}
          >
            {t("let's_go")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/resetPassword" > 
                {t("access_data_forgot")}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" >  
                {t("this_way_for_new_account")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}
