import React, { Fragment, useContext, useEffect , useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { Fab, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TicketGrid from '../pages/assets/CardGrid';
import { ExpandLessOutlined, ExpandMoreOutlined, Grade, LocationCity, Settings } from '@material-ui/icons';
import LocationData from '../pages/assets/LocationData';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import AuthorizationUtil from '../authorization/AuthorizationUtil';
import Crumbs from '../queue/Crumbs';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




function Domains(props) {
  const {t} = props;
  const classes = useStyles();
  const { bookingMode } = props;  
  const [domains, setDomains] = useState([]);
  const [domain] = useState({});
  const [ showMore, setShowMore ] = useState(false)
  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    SessionContext.setDomain(null);
    SessionContext.setQSettingsOrigin("/my/domains");
    const url = config.get('vqueueUrl')+'/api/domains/';// TODO, use User later here +SessionContext.getActiveQueue().id;
    axios.get(url )
        .then(res => {
          setDomains(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })   
  }, [] )

  const handleClick = (event) => {
    setShowMore(!showMore?event.currentTarget:undefined);
  }

  function getGridContent() {
       
    let gridContent = [];
    domains.forEach(function (aDomain, index) {
      let entry =  {
        title: <span className="label" style={{whiteSpace: "nowrap"}}>{aDomain.domainName}</span>  ,
        text: <LocationData location={aDomain}/>,
        action: <Fragment>
                  {bookingMode && <Fragment>
                  <Grid item xs={12}  >
                    <Link to={{pathname: '/booking/options', vDomain:aDomain, backTo: "/booking"}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
                        {t("book_here")}
                      </Button>
                    </Link>                        
                  </Grid> 
                </Fragment> }
                {!bookingMode && <Fragment>
                <Grid item xs={(!AuthorizationUtil.isUser(aDomain) || (AuthorizationUtil.isUser(aDomain) && !AuthorizationUtil.isManager(aDomain)))?12:6}  >
                  <Link to={{pathname: '/my/places', vDomain:aDomain}}  style={{color: "inherit", textDecoration: "none"}}>
                    <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
                      {t("places")}
                    </Button>
                  </Link>                        
                </Grid>
                { AuthorizationUtil.isManager(aDomain) && 
                  <Grid item xs={6}  >
                    <Link to={{pathname: '/my/domains/edit', vDomain:aDomain}}  style={{color: "inherit", textDecoration: "none"}}>
                      <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                        {t("Change")}
                      </Button>
                    </Link>
                  </Grid>  
                }
              </Fragment>  }
            </Fragment>,
        menuItems: getMenuItems(aDomain),
        cardStyle:{height: "18.5rem"},
        object: aDomain
      };

      if(AuthorizationUtil.isUser(aDomain)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>U</span>;
      }
      if(AuthorizationUtil.isManager(aDomain)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>M</span>;
      }
      if(AuthorizationUtil.isAdmin(aDomain)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>A</span>;
      }

      if(AuthorizationUtil.isOwner(aDomain)) {
        //entry.cardNumberStyle =  {backgroundColor:"#9003fc"};
        entry.number =  <span style={{ fontSize:"1.5rem", writingMode: "horizontal-tb"}}>O</span>;
      }
      gridContent.push(entry);
    });
    
    return gridContent;
  }

  function getMenuItems(vDomain) {
     let menuContent = [
      <MenuItem >
          <Link to={{pathname: '/my/places', vDomain:vDomain}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="secondary" variant="contained" >
            {t("places")}
            </Button>
          </Link>   
        </MenuItem>,
        <MenuItem >
        <Link to={{pathname: '/my/domains/categories', vDomain:vDomain, vBackTo: "/my/domains"}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
          <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
            {t("categories")}
          </Button>
        </Link> 
       </MenuItem>,
        <MenuItem >
          <Link to={{pathname: '/booking/options', vDomain:vDomain}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
              {t("gap_generic_call_to_action_TIMESLOT")}
            </Button>
          </Link> 
        </MenuItem>
        ]
    
     if (AuthorizationUtil.isManager(vDomain)) {
      let manageContent = [
        <MenuItem >
          <Link to={{pathname: '/my/domains/edit', vDomain:vDomain}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
            <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
            {t("Change")}
            </Button>
          </Link> 
        </MenuItem>
        ]
        menuContent = menuContent.concat(manageContent);
      }
      
      if (AuthorizationUtil.isAdmin(vDomain)) {
        let adminContent = [
          <MenuItem >
            <Link to={{pathname: '/authorizations', title:<Crumbs domain={vDomain}/>, vBackTo: "/my/domains", vKind: "DOMAIN", vKindId: vDomain.id}}  style={{color: "inherit", textDecoration: "none"}}>
              <Button style={{width:'100%'}} variant="contained" color="primary" variant="contained" >
                {t("rights")}
              </Button>
            </Link> 
          </MenuItem>
          ]
        menuContent = menuContent.concat(adminContent);
      }
    return menuContent;
  }
     

  function getFabButton() {
    return  <Fab color="secondary" aria-label="add" 
          style={{position: 'absolute',
              zIndex: 1,
              top: -30,
              left: 0,
              right: 0,
              margin: '0 auto'}}>
        <Link to={{pathname: '/my/domains/edit', domain:domain}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      </Fab>          
  }
  
  function getQuickActionItems() {
    let quickActionItems = [];
      quickActionItems.push(
        <Link to={{pathname: '/my/domains/edit', domain:domain}} 
            style={{color: "inherit", textDecoration: "none"}}>
              <AddIcon />            
        </Link> 
      );
    return quickActionItems;
  }

  function getMoreMenuItems() {
    
    let gridContent = [
      {
        to: {pathname: '/callBoard', vDomain:domain },
        title: t("callboard"),               
        text: t("callboard"),
        icon: <Settings />
      }
      
    ];

    return gridContent;
  }
  
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
      <DeskHeader title={t("all_domains")} backTo={"/"} 
        quickActions={getQuickActionItems()}
        moreMenuItems={getMoreMenuItems()}  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
                {t("all_domains")}
        </Typography>
        <Grid container>  
          <Grid xs={12}>
            <TicketGrid gridContent={getGridContent()}/>
          </Grid>  
        </Grid>
       
      </div>
      <AppFooter fabButton={getFabButton()} />
          
      
    </Container>
  );
}

export default withTranslation()(Domains);