
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { t } from 'i18next';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
      {t("your_website")}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

function getTiers(t) {
const tiers = [
  {
    title: t("free"),
      price: '0',
      description: [t("10_users_included"), t('2_GB_of_storage'), t('help_center_access'), t("email_support")],
      buttonText: t('sign_up_for_free'),
    buttonVariant: 'outlined',
  },
  {
    title: t("pro"),
      subheader: t("most_popular"),
      price: '15',
      description: [
        t("20_users_included"),
        t('10_GB_of_storage'),
        t('help_center_access'),
        t('priority_email_support'),
      ],
      buttonText: t("get-started"),
    buttonVariant: 'contained',
  },
  {
    title: t('enterprise'),
      price: '30',
      description: [
        t("50_users_included"),
        t('30_GB_of_storage'),
        t('help_center_access'),
        t("phone_and_email_support"),
      ],
      buttonText: t('contact us'),
    buttonVariant: 'outlined',
  },
];

return tiers;
}


const footers = [
  {
    title: t("Company"),
      description: [t("team"), t("history"), t('contact us'), t("location")],
    },
    {
      title: t("feature"),
      description: [t("cool_stuff"), t('random_feature'), t('team_feature'), t('developer_stuff'), t('another_one')],
    },
    {
      title: t('Resources'),
      description: [t('Resource'), t('Resource_name'), t('Another_resource'), t('Final_resource')],
    },
    {
      title: t('Legal'),
      description: [t('Privacy_policy'), t('Terms_of_use')],
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      
      <DeskHeader title="Preise"  />
        
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        {t("pricing")}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
        {t("effective_pricing")}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {getTiers(t).map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                    {t("dollar")}{tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                    {t("/mo")}
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <AppFooter />
      {/* End footer */}
    </React.Fragment>
  );
}
