import React, { useEffect , useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { useHistory } from "react-router-dom";
import { ClearAll, CropFree, Settings, Update, ViewAgenda,  ViewList,  Wallpaper } from "@material-ui/icons";
import ViewDay from '@material-ui/icons/ViewDay';

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import IconButton from '@material-ui/core/IconButton';
import { Add } from '@material-ui/icons';
import TimeScopeBrowser from '../pages/assets/TimeScopeBrowser';
import ResourcesStatus from '../resources/ResourcesStatus';
import ColumnDisplayAndPage from '../pages/assets/ColumnDisplayAndPage';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';
import AuthorizationUtil from '../authorization/AuthorizationUtil';
import log from 'loglevel';
import WSProvider from '../frame/WSProvider';
import TicketFilterSmall from "../pages/assets/TicketFilterSmall";
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);


export function ManageMultiQ(props) {
  const {t} = props;
  const classes = useStyles();
  const timerId = useRef(null)

  const { vGroup } = props.location?props.location:props;
  const { vPlace } = props.location?props.location:props;  
  const { vManage } = props.location?props.location:props;  
  const { vQueues } = props;
  const { booking } = props;
  
  const [manage] = useState(vManage!=null?vManage:SessionContext.isManageMode());
  const [place, setPlace] = useState(vPlace?vPlace:SessionContext.getPlace());

  const [group, setGroup] = useState(vGroup?vGroup:SessionContext.getGroup());

  const [resoruceStatus, setResoruceStatus] = useState(null)
  const [filters, setFilters] = useState([
      {
        primaryStatus: "WAITING"
      },
      {
        primaryStatus: "PREPARING"
      },
      {
        primaryStatus: "PROCESSING"
      },
      {
        primaryStatus: "BLOCKER"
      }
    ])
  
  const [viewportHeight, setViewportHeight] = useState({})
  const [queues, setQueues] = useState([]);
  
  const[from,setFrom] = useState(SessionContext.getShowTicketsFrom()?SessionContext.getShowTicketsFrom():null);
  const[to,setTo] = useState(SessionContext.getShowTicketsTo()?SessionContext.getShowTicketsTo():null);

  const [lastUpdate, setLastUpdate] = useState({})
  const[activeQueues, setActiveQueues]  = useState([]);

  
  const history = useHistory();

  useEffect(() => {
    
    return () => {
        // componentwillunmount in functional component.
        // Anything in here is fired on component unmount.
        clearTimeout(timerId.current);
    }
  }, []) 

  useEffect(() => {    // Update the document title using the browser API 
    console.log("ManageMultiQ -> useEffect")  ;
    SessionContext.setGroup(group);
    SessionContext.setManageMode(manage);
    SessionContext.setQSettingsOrigin("/my/groups/slotmanager");
    SessionContext.setResorucesOrigin("/my/groups/slotmanager");   
    if(!vQueues) {   
      loadMultiQs();
    } else {
      setQueues(vQueues);
      //one must be there otherwise faulty
      //let group = vQueues[0].group;
      //setGroup(group);
    }
  }, [vQueues] )

  useEffect(() => {    
    console.log("ManageMultiQ -> filters")  ;    
  }, [filters] )


    function ticketChanged(ticket) {
      SessionContext.refreshTicket(ticket);
     // prepareMultiQs(queues);
    }

    function returnTicket(ticket) {
      SessionContext.refreshTicket(ticket);
      //prepareMultiQs(queues);
    }

    function loadMultiQs() {
      let uri = group?'/api/queues?groupId='+group.id:'/api/queues';
      const url = config.get('vqueueUrl')+uri;
      axios.get(url )
        .then(res => {
          let queues = res.data;
          setQueues(queues);
          //prepareMultiQs(queues);
        });   
    }

    const createRoom = async () => {
      const url = `${config.get('vqueueUrl')}/api/queues`;
      const entity = {
        name: `Warteraum-${new Date().getTime()}`,
        queueingKind: 'SIMPLE',
        roomView: 'STANDARD',
        autoReturn: 'ON',
        moveBackPosCount: 3,
        standardTicketProcessingDuration: 600000,
        standardTicketExtendDuration: 300000,
        standardTicketExpiryDuration: 180000,
        standardTicketRedeemDuration: 120000,
        standardCallingTimeDuration: 120000,
        place: place,
        group: group,
        config: '{   "resourceLimit": "2" , "offsetEnd": "0" ,"offsetBegin": "0"  }'
      };
      try {
        const res = await axios.post(url, entity);
        //addModalMessage(t("message_slotmanager_created"));
        setQueues([...queues, res.data]); // add the newly created room to the queues array in state
      } catch (error) {
        console.error(error);
      }
    };
    //END --- Create new Slotmanager and map to this group. Externalize in Component later

    function getQuickActionItems() {
      let quickActionItems = [];
      if(AuthorizationUtil.isManager(group)) {
        quickActionItems.push(
          <IconButton onClick={() => createRoom()} 
              style={{color: "inherit", textDecoration: "none", padding:"0px"}}>
                <Add />            
          </IconButton> 
        );
      }
      return quickActionItems;
    }

    
    function searchTickets(searchString) {
      log.trace("searchTickets: "+searchString);
    }

    function setFiltersCallbach(filters)  {
      setFilters([...filters]);
    }

    function getFilterActionItems() {
      return  booking?null:<TicketFilterSmall 
          filterActive={filters && filters.length>0}
          filterReset={setFiltersCallbach} 
          filterCallback={setFiltersCallbach} 
          filters={filters} 
          filterFunction={true}/>;    
    }

    function getMoreMenuItems() {
      let gridContent = [
        
       {
          to: {pathname: '/callBoard', entity:group},
          title: t("callboard"),               
          text: t("callboard"),
          icon: <ViewAgenda />
        }
        /*,
        {
          to: {pathname: '/operatorscanner', title: t("scan_ticket"), customContent:this.getCustomScannerContent()},
          title: t("scan_QR"),               
          text: t("scan_QR"),
          icon: <CropFree />
        },
        {
          to: {pathname: '/qrcode', value: config.get('baseUrl')+"/room?uid="+(this.state.selectedQueue && this.state.selectedQueue.uid), title: t("qr_code")+" "+(this.state.selectedQueue && this.state.selectedQueue.name), customContent: this.getCustomQRCodeContent()},
          title: t("show_qr_code"),               
          text: t("show_qr_code"),
          icon: <Wallpaper />
        }*/
        
        
      ];
      return booking?null:gridContent;
    }

  return (
      <Container  maxWidth={false} disableGutters  style={{position: 'relative', marginTop:"90px", marginBottom:"100px", paddingLeft:"5px", paddingRight:"5px"}}>    
      
          <DeskHeader title={props.title?props.title:<Crumbs group={group}/>}  
            backTo={props.backTo?props.backTo:"/my/groups"} 
            filterActions={getFilterActionItems()}
            quickActions={getQuickActionItems()}
            
            moreMenuItems={getMoreMenuItems()}
            searchAction={booking?null:(searchString) => searchTickets(searchString)}
            searchTitle={t("search_ticket")}
            />
          <Grid container spacing={1}  alignItems="center" justify="center" style={{ justifyContent: "center", display: "flex" ,textAlign: "center",position: "fixed",
                backgroundColor: "white", width:"100%", zIndex: "100", top:"75px", left: "0" }}>
              <Grid item xs={12} style={{ marginLeft: "2rem"}}>
                { <ResourcesStatus key={props.booking?"multiQBooking":"multiQColumns"} manage={manage} vPlace={place} vGroup={group} partitionID={from}  from={from} to={to} resourceStatusCallback={setResoruceStatus}/> }
              </Grid> 
            </Grid>
          <CssBaseline />
          { resoruceStatus && <WSProvider from={from} relatedId={group.id} >
            <div className={classes.paper}>
              <Grid id="colsViewport"   container justify="center" style={{borderBottom: "1px solid", overflow: "hidden", height:viewportHeight+"px", justifyContent: "center", display: "flex" ,textAlign: "center", marginTop:place?"3.5rem":"0", paddingLeft:"10px", paddingRight:"0", marginBottom:"80px" }}>
                <ColumnDisplayAndPage 
                  key={"multiQColumns"}
                  viewportHeightCallback={setViewportHeight} 
                  columns={queues} 
                  createTicketLink={props.createTicketLink?props.createTicketLink:
                    {
                      link:"/booking/data",
                      backTo:"/my/groups/slotmanager" 
                    }
                  }
                  booking={props.booking} 
                  from={from} 
                  to={to} 
                  group={group} 
                  place={place} 
                  filters={filters}
                  createElement={createRoom}
                  manage={manage}
                  lastUpdate={lastUpdate}
                  resourceStatusDTOMap={resoruceStatus}
                  >
                    
                  </ColumnDisplayAndPage>
              </Grid>
              { (!queues || queues.length==0) &&
                <Grid item  xs={12} style={{ width: "100%" }}>
                  {t("choose_other_group")}
                  <Link to={{pathname: '/my/groups'}}  style={{color: "inherit", textDecoration: "none", width:'100%'}}>
                    <Button style={{width:'100%'}} variant="contained" color="secondary" >
                      {t("back")}
                    </Button>
                  </Link>
                </Grid>  
              }
            </div>
            
        </WSProvider>
        }
          <AppFooter  actionBar={(!activeQueues || activeQueues.length==0) &&
                        /*<TimeScopeBrowser cycleCallback={(from, to) => {
                            setFrom(from);
                            setTo(to);
                            setLastUpdate(new Date().getTime());
                          ;
                        }}/>*/
                        <TimeScopeBrowser cycleCallback={(from, to) => {
                          clearTimeout(timerId.current);
                          timerId.current = window.setTimeout(() => {
                            setFrom(from);
                            setTo(to);
                            setLastUpdate(new Date().getTime());
                          }, 500);
                          
                        }}/>
                                                    
                  } />
      </Container>
  );
}

export default withTranslation()(ManageMultiQ);