import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import LinkCard from '../pages/assets/LinkCard';
import NavigationGrid from '../pages/assets/NavigationGrid';
import Avatar from '@material-ui/core/Avatar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import SessionContext from '../frame/SessionContext';
import DateTimeDisplay from '../pages/assets/DateTimeDisplay';
import StatusTicket from '../ticket/assets/StatusTicket';
import SockJsClient from 'react-stomp';
import config from 'react-global-configuration';
import { Button, CircularProgress, MenuItem, Typography } from '@material-ui/core';

import axios from 'axios';
import TicketGrid from '../pages/assets/CardGrid';
import { withTranslation } from 'react-i18next';
import TimeScopeBrowser from '../pages/assets/TimeScopeBrowser';
import SocketHandler from '../pages/assets/SocketHandler';
import SlotsDisplay from './SlotsDisplay';
import Crumbs from './Crumbs';


class Gaps extends Component {
  

  constructor(props) {
    super();
    this.state = {
     
    }    

    this.timer = setInterval(async () => {
      this.loadGaps(this.state.from,this.state.to);
    }, 60000)
    this.loadGaps = this.loadGaps.bind(this);
    this.timeSlider = this.timeSlider.bind(this);
    this.processingMessages = false;  
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }
 
  componentDidUpdate(prevProps) {
    let changed = prevProps.from!=this.props.from;
    if(changed) {
      this.setState({from: this.props.from, to:this.props.to, initialized:false})
    } else if(this.state.from && !this.state.initialized) {
      this.loadGaps(this.state.from,this.state.to);
    }
  }

  componentDidMount(){
    let state = { 
    }
    state.from=SessionContext.getShowTicketsFrom();
      state.to=SessionContext.getShowTicketsTo();  
      console.log("found  from-to -"+state.from+ "- -"+state.to+"-");
    
      if(!state.from || state.from=='undefined' || Date.now()>state.from) {
        state.to=null;
        var from = new Date(Date.now());
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);
        console.log("from: "+from.getTime());
        state.from=from.getTime();
      } else {
        var from = parseInt(state.from);
        state.from=from;
      }

      if(!state.to || state.to=='undefined') {
        var to = new Date(Date.now());
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(999);
        console.log("to: "+to.getTime());
        state.to=to.getTime();
      } else {
        var to = parseInt(state.to);
        state.to=to;
      }
    this.loadGaps(state.from,state.to);
  }

  getRequestQueueIn(t) {
    let queue = this.props.queue;
    return {
      title:t('gap_generic_title_'+queue.status),
      text:
      <Fragment> 
        <Grid item xs={12}  >
          <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
            <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
              {t('gap_generic_'+queue.status)}              
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}  >
          <Grid container spacing={0} alignItems="center" justify="center" >
            <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
              {t('gap_generic_call_to_action_'+queue.status)}              
            </Typography>
          </Grid>
        </Grid>
      </Fragment>,
      menuItems: this.getMenuItems(null,t),        
      //sub: "60 Minuten",  
      action: <Button color="secondary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} onClick={this.props.pullTicket(null,queue)}>{t('gap_generic_action_'+queue.status)}</Button>,           
      cardStyle:{height: "18.5rem"}
    }
  }

  timeSlider(timeSlider) {
      let state = {}
      if(!timeSlider) {
        state.from = undefined;
        state.to = undefined;
      } 
      this.setState(state);
  }

  


  loadGaps(from,to) {
    if(!this.processingMessages) {
      let queue = this.props.queue;
      let url = config.get('vqueueUrl')+'/api/queue/gaps/'+queue.id;
      if(from && to) {
        url = config.get('vqueueUrl')+'/api/queue/gaps/'+queue.id+"/"+from+"/"+to
      }
      axios.get(url)
        .then(response => {  
          if(response.data) {
            this.setState({
              gaps: response.data,
              from: from,
              to: to,
              initialized: true
            });              
          }
        });
      }
    }
    
  

  getGridContent(t) {
    let queue = this.props.queue;
    let gridContent = [];
    if(!this.state.from && !this.state.to) {
      gridContent.push(this.getRequestQueueIn(t))
    }
    if(queue.status!="SERVICE") {
      for (var value in this.state.gaps) {
        //TODO: Das hier in eigene Componente auslagern, dort dann Socket Listener für dieses Gap
        let gap = this.state.gaps[value];
        console.log("Lücke: "+JSON.stringify(gap));
      let entry =  {
        gap: gap,
        crumb: <Crumbs queue={queue}/>,
        title: <span className="label" style={{whiteSpace: "nowrap"}}><DateTimeDisplay timestamp={gap.start} hideTime={true} options={{day: '2-digit', month: '2-digit', year: 'numeric'}}/></span>  ,
          text:<Fragment> 
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" style={{textAlign:"center"}}>
                      <Typography  variant="body1" style={{width:"100%", fontSize:"0.9rem",whiteSpace: "nowrap", fontWeight:"normal", textDecoration:"underline"}}>
                        <span >{t('gap_'+queue.status)}</span>                    
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}  >
                    <Grid container spacing={0} alignItems="center" justify="center" >
                      <Typography  variant="body1" style={{fontSize:"2rem", fontWeight:"bold"}}>
                        <span ><DateTimeDisplay timestamp={gap.start} hideDate={true}/></span>
                        <span> - </span>
                        <span ><DateTimeDisplay timestamp={gap.end} hideDate={true}  end={true}/> Uhr</span>  
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>,
          //sub: "645 Minuten",  
          action: <Button color="secondary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} onClick={() => this.props.pullTicket(gap, this.props.queue)}>{t('gap_generic_action_'+queue.status)}</Button>,
          menuItems: this.getMenuItems(gap,t),
          cardStyle:{height: "18.5rem"},
          socketHandler: <SocketHandler topic={"/topic/gaps/"+this.state.from} entity={gap} topicCallback={() => this.processGapUpdate}/>
        };
        gridContent.push(entry);
      }
    }
    return gridContent;
  }

  processGapUpdate(gap, gapUpdate) {
    console.log("processGapUpdate " + gap + " - " + gapUpdate);
  }

  getMenuItems(gap,t) {
    let queue = this.props.queue;
    return [
     <MenuItem key="queueIn" ><Button color="secondary" variant="contained" style={{width:"100%", height:"1.5rem", fontWeight:"bold"}} onClick={(gap) => this.props.pullTicket(gap, queue)}>{t('gap_generic_action_'+queue.status)}</Button></MenuItem>,      
   ]
 }

  processGapsChange(changedGaps) {
    console.log("processGapsChange - Gaps changedGaps "+JSON.stringify(changedGaps));
    
    if(changedGaps) {
      if(changedGaps.partitionKey!=this.state.from) {
        console.log("------------  processGapsChange -  Gaps processGapsChange not for current Timescope - changedGaps "+this.state.from+" - "+changedGaps.partitionKey);        
      } else {
        this.processingMessages = true;
        console.log("processGapsChange - ok, update is for us -  current Timescope - changedGaps "+this.state.from+" - "+changedGaps.partitionKey);
        var allGaps = changedGaps.gaps;
        var removedGaps = changedGaps.removedGaps;
        var newGaps = changedGaps.newGaps;
        let clientGaps = this.state.gaps?this.state.gaps:{};
        if(!clientGaps || !clientGaps.findIndex) {
          console.log("Strange, sth wrong with clientGaps (coming from this.state.gaps). "+JSON.stringify(clientGaps));    
          return;  
        }

        console.log("processGapsChange - one existing allGaps allGaps? "+JSON.stringify(allGaps));    
          
        for (var i in allGaps) {
          let gapUpdate = allGaps[i];
          //finde das existieren auf dem Client hier
          let index = clientGaps.findIndex(gap => {
            console.log("processGapsChange - UPDATE gap.id "+gap.id);
            return gap.id==gapUpdate.id; 
          });
          //entsprechend behandeln
          if(index>-1)  {
            console.log("gap IS in List.");    
            clientGaps[index] = gapUpdate;
          } 

          
        } 
        
        //remove all that shall not exist anymore
        if(removedGaps) {
          console.log("processGapsChange - one existing allGaps? "+JSON.stringify(allGaps));    
            clientGaps = clientGaps.filter( existingGap => {
            console.log("processGapsChange - one existing existingGap? "+JSON.stringify(existingGap));    
            console.log("processGapsChange - one existing removedGaps? "+JSON.stringify(removedGaps));    
            let res = removedGaps.find(rm => (existingGap.id === rm.id) );
            console.log("processGapsChange - one existing is indremovedGaps res? "+res);    
            return !res;
          
          });
        }
        console.log("processGapsChange - clientGaps." + JSON.stringify(clientGaps));    
          
        //add new ones
        if(newGaps) {
          let doNotExist = clientGaps.filter( existingGap => {
            let res = newGaps.find(newGap => (existingGap.id === newGap.id) );
            console.log("processGapsChange - one existing is newGaps res? "+res);    
            return res;
          
          });
          console.log("processGapsChange - doNotExist." + JSON.stringify(doNotExist));    
          
        }
        clientGaps = clientGaps.concat(newGaps);
        console.log("processGapsChange - clientGaps+newGaps." + JSON.stringify(clientGaps));    
        
      /*  if(this.state.from && this.state.to) {
          fGaps = gaps.filter((gap) => {
            return gap.start>=this.state.from && gap.start<this.state.to;
          })
        } */
        //ACHTUNG: Gaps die gebucht werden bzw nicht mehr verfügbar sind, werden nicht entfernt!
        //Außerdem werden evt vorhanden UND gültige entfernt, nur weil ein neuer Schwung reingekommen ist. SCHLECHT      
        if(clientGaps.length>0) {
          console.log("processGapsChange - setting changed gaps"+JSON.stringify(clientGaps));
      
          this.setState({
            gaps: clientGaps
          }); 
        }
        this.processingMessages = false;
      }
     
    
    }
         
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        {
        //TODO: Nicht mehr auf Q, stattdessen auf die Partition (from) subscriben. Das dann außerdem im TicketGrid
        }
        <SockJsClient url={config.get('broadcaster')+'/ws/ws-tickets'}
                topics={['/topic/gaps/'+this.state.from+"/"+this.props.queue.id]}
                onConnect={() => {
                    console.log("connected Gaps for Gap-Updates on Queue  "+this.props.queue.id+" .");
                }}
                onDisconnect={() => {
                    console.log("Disconnected Gaps for Gap-Updates Changes on Queue "+this.props.queue.id);
                }}
                onMessage={(gaps) => {
                  console.log("Got a Message Gaps for Gap-Updates Changes on Queue : "+gaps);
                    this.processGapsChange(gaps);              
                }}
                onConnectFailure={() => {
                  console.log("Could not connect Gaps for Gap-Updates Changes on Queue "+this.props.queue.id);
                }}
                  ref={(client) => {
                      this.clientRef = client
                }}
              /> 
         
        { this.state.gaps && 
            <Fragment>
              <TicketGrid gridContent={this.getGridContent(t)}/>
           </Fragment>

        }
        { !this.state.gaps && 
            <Grid container spacing={0} alignItems="center" justify="center" >
              <Grid item xs={12}  >
                <CircularProgress style={{marginLeft: '50%'}}/>
              </Grid>
              <Grid item xs={12}  >
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                  {t('find_gap_waiter')}                    
                </Typography>
                
              </Grid>
            </Grid> 
            
        }
      </Fragment>
    );
  }
}

export default withTranslation()(Gaps)