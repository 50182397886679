import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Avatar, Fab, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CardHeader, Container, Grid } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DateTimeDisplay from './DateTimeDisplay';
import StatusTicket from '../../ticket/assets/StatusTicket';
import SessionContext from '../../frame/SessionContext';
import WaitingCardInfo from '../../ticket/calendar/components/waitingcard/WaitingCardInfo';
import CountDown from './CountDown';
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined } from '@material-ui/icons';
import TargetTime from './TargetTime';
import ResourcesTicketStatus from '../../resources/ResourcesTicketStatus';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



export default function BookSlotData(props) {
  const { slot } = props
  const { queue } = props
  const { linkTo } = props
  const { small } = props
  const history = useHistory();
  
  function bookHere(e) {
    linkTo.startPreset=slot.startPlanned;
    linkTo.endPreset=slot.endPlanned;
    linkTo.cyclesMax=slot.cycles;
    linkTo.cyclesPreset=slot.cycles;
    linkTo.queue=queue;           
    history.push(linkTo);    
  }
  
 

  return (
    
     
    <Fragment>
      
      

      { slot &&
          <Grid container >
              <Grid item xs={12}  style={{ minHeight:"500px"}} onClick={(e) => bookHere(e)} >
                <Typography variant="body1" style={{ width: "100%", textAlign: "left", fontSize:"1rem", fontWeight:"bold"}}>
                  <DateTimeDisplay timestamp={slot.startPlanned} hideDate={true}/> 
                  &nbsp;-&nbsp;
                  <DateTimeDisplay timestamp={slot.endPlanned} hideDate={true}/> &nbsp;&nbsp;
                  { 
                  //<Button variant="contained" color="primary" onClick={(e) => bookHere(e)}>hier buchen</Button>  
                  }
                </Typography>
             
            </Grid>
          </Grid>
      }
    </Fragment>     
  );
}