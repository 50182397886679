
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../../frame/SessionContext';
import CommonHeaderFragment from "./CommonHeaderFragment";
import { t } from 'i18next';




const AVATAR = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

class RoomHeader extends Component {
  
  constructor() {
    super();
    this.state = {
      menuAnchor: null
    };
  }

  toggleSubMenu(event) {
    this.setState({
      menuAnchor : event.currentTarget
    })
  }

  
  closeSubMenu() {
    this.setState({
      menuAnchor : null
    })
  }

  render() {
      return (
      <header>
        <AppBar position="static">
          <Toolbar>
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={3}>
              <Grid container spacing={0} >
                { this.props.queue && 
                    <CommonHeaderFragment title={this.props.queue.name}/>
                  }
                  { this.props.title && 
                    <CommonHeaderFragment title={this.props.title}/>
                  }
              </Grid>
            </Grid>   
            <Grid item xs={2}>
              { this.props.content }     
            </Grid> 
            <Grid item xs={2}>
                { this.props.actions }     
              </Grid>   
              <Grid item xs={1}>
                { SessionContext.isLoggedIn() &&
                  <Link to="/myAccount" style={{color: "inherit", textDecoration: "none"}}>
                    <AccountBoxIcon />
                  </Link>
                }
              </Grid>   
              <Grid item xs={1}>
                <IconButton edge="end" color="inherit"  >
                  <MoreIcon onClick={(event) => this.toggleSubMenu(event)}/>
                  <Menu
                    id="desk-sub-menu"
                    anchorEl={this.state.menuAnchor}
                    keepMounted
                    open={Boolean(this.state.menuAnchor)}
                    onClose={() => this.closeSubMenu()}
                  >
                    { this.props.menuItems }
                    { !SessionContext.isLoggedIn() &&
                    <MenuItem  key="login">
                      <Link to={{pathname: '/login'}} 
                        style={{color: "inherit", textDecoration: "none"}}>  
                          {t("login")}
                      </Link> 
                    </MenuItem> 
                    }
                    { SessionContext.isLoggedIn() &&
                    <MenuItem  key="logout">
                      <Link to={{pathname: '/logout'}} 
                        style={{color: "inherit", textDecoration: "none"}}>  
                          {t("logout")}
                      </Link> 
                    </MenuItem> 
                    }
                  </Menu>
                </IconButton>
              </Grid>                    
              </Grid> 
          </Toolbar>
        </AppBar>
        
      </header>
    );
  }
}

export default RoomHeader;
