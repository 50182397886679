import React, { useState,useContext, createContext, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { LocationCity } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




function EditResourceVariant(props) {
  const {t} = props;
  const classes = useStyles();
  const { vResourceVariant } = props.location;

  const [resourcevariant, setResourceVariant ] = useState(vResourceVariant?vResourceVariant:SessionContext.getResourceVariant());
  const [address, setAddress] = useState(resourcevariant?resourcevariant.address:{});

  

  const [name, setName] = useState(resourcevariant?resourcevariant.name:"");
  const [decription, setDecription] = useState(resourcevariant?resourcevariant.decription:"");
  const [speed, setSpeed] = useState(resourcevariant?resourcevariant.speed:"");
  const [preBuffer, setPreBuffer] = useState(resourcevariant?resourcevariant.preBuffer:"");
  const [postBuffer, setPostBuffern] = useState(resourcevariant?resourcevariant.postBuffer:"");
 
  const { addMessage } = useContext(MessageContext)

  const history = useHistory();

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("resourcevariant: " + JSON.stringify(resourcevariant));
    SessionContext.setResourceVariant(resourcevariant);    
  }, [] )

  function validateForm() {
    console.log("resourcevariant: " + JSON.stringify(resourcevariant));
    return name && name.length > 0;
  }


  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/resource-variants/'+resourcevariant.id;
    axios.delete(url,  resourcevariant )
        .then(res => {
          history.push("/my/groups/resources/variants");
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save ResourceVariant.")
    let tResourceVariant = resourcevariant;
    if(!tResourceVariant) {
      tResourceVariant = {};
    }

    tResourceVariant.name = name;
    tResourceVariant.decription = decription;
    tResourceVariant.speed = speed;
    tResourceVariant.preBuffer = preBuffer;
    tResourceVariant.postBuffer = postBuffer;

    const url = config.get('vqueueUrl')+'/api/resource-variants';
    if(!tResourceVariant.id) {
        axios.post(url,  tResourceVariant )
        .then(res => {
          history.push("/my/groups/resources/variants");
          //addMessage(t("resource_saved"));
        }).catch(function (error) {
          console.log(error);
        })
     } else {
        axios.put(url,  tResourceVariant )
        .then(res => {
          history.push("/my/groups/resources/variants");
         // addMessage(t("resource_saved"));
        }).catch(function (error) {
          console.log(error);
        })
      }
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
    
      <DeskHeader title={vResourceVariant? t("edit_alternative"): t("create_alterniative")} backTo="/my/groups/resources/variants"  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("resource")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="name"
                variant="outlined"
                value={name}
                required
                fullWidth
                id="name"
                label={t("alternative_name")}
                autoFocus
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{width:"100%"}}
                label={t("description")}
                variant="outlined"
                fullWidth
                value={decription}
                id="decription"
                multiline
                rows={4}
                name="decription"
                autoComplete="fcolor"
                onChange={(e) => setDecription(e.target.value)}
              />
            </Grid>
            <Grid item  xs={4}>
              <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("speed")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={speed}
                    onChange={(e) => setSpeed(e.target.value)}
                  label={t("speed")}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item  xs={4}>
              <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("buffer_before")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={preBuffer}
                    onChange={(e) => setPreBuffer(e.target.value)}
                  label={t("buffer_before")}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item  xs={4}>
              <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("buffer_after")}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={postBuffer}
                    onChange={(e) => setPostBuffern(e.target.value)}
                  label={t("buffer_after")}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>
                      
         
            <Grid item  xs={6} >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t("save")}
              </Button> 
            </Grid>
            <Grid item  xs={6} >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => deleteEntity()}
                disabled={!resourcevariant || !resourcevariant.id}
              >
                {t("delete")}
              </Button>  
            </Grid>

          </Grid>
         
                    
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditResourceVariant);