import React, { Fragment, useContext, useEffect , useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import { CircularProgress } from '@material-ui/core';
import {t} from 'i18next';
import log from 'loglevel';
import WebsocketUtil from '../authorization/WebsocketUtil';

function areEqual(prevProps, nextProps) {
  let equal =  prevProps.vGroup.id===nextProps.vGroup.id && prevProps.from===nextProps.from && JSON.stringify(prevProps.resourcesStatus)===JSON.stringify(nextProps.resourcesStatus);
  
  log.debug("areEqual ResourcesStatus ! "+equal);
  return equal;
  
}
export default React.memo(ResourcesStatus, areEqual);

function ResourcesStatus(props) {
  const { manage } = props;
  const { vPlace } = props;
  const { vGroup } = props;
  const { resourceStatusCallback } = props;
  const { small } = props;
  const { from } = props;
  
  // Timer State
  const [timer, setTimer] = useState(null);

  const [place, setPlace ] = useState(vPlace?vPlace:SessionContext.getPlace()); 
  const [group, setGroup] = useState(vGroup?vGroup:SessionContext.getGroup());
  
  const [resourcesStatus, setResourcesStatus] = useState({});
  
  //const [from] = useState(props.from)
  const [relatedId] = useState(group?group.id:place.id)
  const [type] = useState(group?'GROUP':'PLACE')

  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  const websocketQClient = new WebsocketUtil();


  useEffect(() => {    // Update the document title using the browser API 
    if(from) { 
        websocketQClient.websocket(
        '/topic/resoruces/'+type+"/"+relatedId+"/"+from, 
        ()=> {
          log.debug("processChange Websocket connectCallback ... ");
          processChange() //after connect fetch latest state
        },
        processChange);
      }
      return () => { cleanup(); };
    }, [from] )

    
  
    function cleanup() {
      log.trace("cleanup ");
      websocketQClient.disconnect(() => {});
      // Clear the timer on cleanup
      clearTimeout(timer);
    }
  
    function resetTimer() {
      log.debug("ResourceStatus timer setting up processChange ");
        // Clear previous timer
      clearTimeout(timer);
      // Set new timer
      const newTimer = setTimeout(() => {
        log.debug("ResourceStatus timer fire processChange ");
        clearTimeout(timer);
        processChange(); // Timer expired, call processChange        
      }, 1000); // 5000 milliseconds = 5 seconds, adjust as needed
      setTimer(newTimer);
    }

  function processChange(partitionResourceDTO) {
    log.debug("ResourceStatus processChange - "+(partitionResourceDTO !== undefined?partitionResourceDTO.partitionStatus:'undefined'));
    if(partitionResourceDTO && partitionResourceDTO.partitionStatus!=="READY") {
      log.trace("ResourceStatus "+ partitionResourceDTO.remainingAllDouble);
      log.trace("ResourceStatus "+ partitionResourceDTO.usedAllDouble);
      setResourcesStatus(partitionResourceDTO);
      if(resourceStatusCallback) {
        log.debug("ResourceStatus processChange - resourceStatusCallback "+partitionResourceDTO.partitionStatus);
        log.debug("ResourceStatus resourceStatusCallback  "+partitionResourceDTO);
        resourceStatusCallback(partitionResourceDTO);
      }
    } else {
      log.debug("ResourceStatus processChange - fetching stuff");
      if((vGroup || vPlace) && from) {

        let url = config.get('vqueueUrl')+'/api/resources/status/';
        
        if(vGroup) {
          url = url + "group/"+vGroup.id+"/"+from; //TODO !!!!!!!!!!!!!!!!!!!!!
        } else {
          url = url + "place/"+vPlace.id+"/"+from;
        }
        axios.get(url )
        .then(res => {
          log.debug("ResourceStatus resourceStatusCallback - fetching stuff "+res.data);
          setResourcesStatus(res.data);
          if(resourceStatusCallback) {
            resourceStatusCallback(res.data);
          }
          log.debug("!!!!! STEP 1 !!!!!  Loaded partitionStatus for current period >>>>>>>>>>>: "+res.data.partitionStatus);
        }).catch(function (error) {
          log.debug(error);
          addMessage(error.detail);
        })
      }
    }
  }  

  function format(toFormat) {
    return (toFormat).toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

  return (
    <Fragment>     
      { 
      place && manage && small &&
      <Fragment>
        <Fragment>        
          { (resourcesStatus.availableAllDouble>0) && 
              <Fragment>  
                 <span>{ format(resourcesStatus.remainingAllDouble-resourcesStatus.usedAllDouble) } / { format(resourcesStatus.availableAllDouble) } </span>
                 <span>( { format(100 / resourcesStatus.availableAllDouble * (resourcesStatus.remainingAllDouble-resourcesStatus.usedAllDouble))} %)</span>
              </Fragment>
          }
        </Fragment>  
        <Fragment>        
            {
              (resourcesStatus.availableAllDouble==0) && 
                <span>---</span>
            } 
        </Fragment>  
      </Fragment>
      }
      {
        place && manage && !small &&
        <Grid container justifyContent='center' direction="row">
          <Grid item xs>
            <Grid container spacing={1} justifyContent='center'> {/* Hier wird das Grid zentriert */}
              <Grid xs="0" sm="1" md="3" lg="4"></Grid>
              <Grid xs="6" sm="5"  md="3" lg="2">
                <Typography variant="body1" style={{fontSize:"1rem", fontWeight:"bold"}}>
                  {t("available_cycles")}:
                </Typography>
              </Grid>
              <Grid xs="6" sm="5"  md="3" lg="2">
                { (resourcesStatus.availableAllDouble > 0) && 
                  <Typography variant="body1" style={{fontSize:"1rem", fontWeight:"bold"}}>
                    { format(resourcesStatus.availableAllDouble > 0 ? resourcesStatus.remainingAllDouble - resourcesStatus.usedAllDouble : 0) } / { format(resourcesStatus.availableAllDouble > 0 ? resourcesStatus.availableAllDouble : 0) } ( { resourcesStatus.availableAllDouble > 0 ? format(100 / resourcesStatus.availableAllDouble * (resourcesStatus.remainingAllDouble - resourcesStatus.usedAllDouble)) : 0} %)
                  </Typography>
                } 
              </Grid>
              <Grid xs="0" sm="1" md="3" lg="4"></Grid>
              
            </Grid>
          </Grid>
        </Grid>

      
      }
      {
        !place && <Grid container spacing={0} alignItems="center" justify="center" >
          <Grid item xs={12}  >
            <CircularProgress style={{marginLeft: '50%'}}/>
          </Grid>
        </Grid> 
      }
    </Fragment>
    );
}
