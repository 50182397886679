import React, {  } from 'react';
import Container from '@material-ui/core/Container';

import { withTranslation } from 'react-i18next';
import TicketsListCalendar from '../ticket/TicketsListCalendar';

function ManageQ(props) {
  const {t} = props;
  
  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
              

      <TicketsListCalendar  
          manage={props.location.manage}
          key={"manageQTicketListCal"}
          queue={props.location.queue} 
          backTo="/my/groups/slotmanager/dashboard" 
          filter={props.location.filter} 
          timeSlider={props.location.timeSlider} 
        />
      
    </Container>
  );
}

export default withTranslation()(ManageQ);