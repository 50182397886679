import React, { Fragment } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import DateTimeDisplay from "../pages/assets/DateTimeDisplay";
import { Typography } from "@material-ui/core";
import ChatMessage from "./ChatMessage";

const useStyles = makeStyles(theme => ({
  container: {
    bottom: 0
    // position: "fixed" // remove this so we can apply flex design
  },
  bubbleContainer: {
    height: '50vh',
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex", //new added flex so we can put div at left and right side
    //check style.css for left and right classnaeme based on your data
    flexDirection: "column-reverse"
  },
  bubble: {
    border: "0.5px solid black",
    borderRadius: "10px",
    margin: "5px",
    padding: "10px",
    display: "inline-block"
  }
}));

const ChatLayout = (props) => {
  const { messages } = props.messages;
  const classes = useStyles();
  

  const chatBubbles = props.messages.map((obj, i) => (
    
    <Grid item xs={12} key={i}>
        <Grid container  style={{justifyContent:obj.direction}} >
            <Grid item style={{backgroundColor:obj.color}} className={classes.bubble}>
              <ChatMessage message={obj}  timestamp={true} key={i}/>
            </Grid>            
        </Grid>
    </Grid>
  ));
  return <Grid container className={classes.bubbleContainer}>
            <Grid item xs={12}>
                <Grid container>
                  {chatBubbles}
                </Grid>
            </Grid>
          </Grid>;
};

export default ChatLayout;