import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { CropFree, Settings, ViewAgenda, Wallpaper } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import SockJsClient from 'react-stomp';
import SessionContext from '../frame/SessionContext';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import TicketFilter from '../pages/assets/TicketFilter';
import SlotsDisplay from './SlotsDisplay';
import Crumbs from './Crumbs';
import { withTranslation } from 'react-i18next';







var QRCode = require('qrcode.react');

class OperatorDashboard extends Component {
  static contextType = MessageContext

  constructor(props) {
    super(props);
    this.state = {

    }
    this.filterAction = this.filterAction.bind(this);
    this.getMoreMenuItems = this.getMoreMenuItems.bind(this);
  }



  componentDidMount() {
    let uid = null;
    if (this.props.location) {
      let query = new URLSearchParams(this.props.location.search);
      uid = query.get('uid')
    }
    let state = {
      uid: uid
    }

    if (uid != null) {
      this.loadQueue(uid);
      return;
    } else {
      let queue = this.props.location.queue?this.props.location.queue:this.props.queue;
      if(!queue || queue==null) {
        queue = SessionContext.getActiveQueue(); 
      } 
      SessionContext.setActiveQueue(queue); 
      state.selectedQueue = queue;
      this.loadStatistics(state.selectedQueue);
    }

    this.setState(state);
  }

  loadQueue(uuid) {
    const url = config.get('vqueueUrl') + '/api/queues/uid?uuid=' + uuid;
    axios.get(url)
      .then((data) => {
        SessionContext.setActiveQueue(data.data);
        this.loadStatistics(data.data);
        this.setState({ selectedQueue: data.data });
      });
  }

  loadStatistics(selectedQueue) {
    console.log("loadStatistics " + selectedQueue);

    axios.get(config.get('vqueueUrl') + '/api/tickets/statistics/' + selectedQueue.id)
      .then(response => {
        let state = {
          stats: response.data
        }
        for (const [key, value] of Object.entries(state.stats)) {
          console.log(`${key}: ${value}`);
        }
        console.log("LOAD loadStatistics DONE  setting State ");
        this.setState(state);
      }
      );
  }

  getFabButton() {
    const {t} = this.props;
    return <Fab color="secondary" aria-label="add"
      style={{
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto'
      }}>

      <Link to={{ pathname: '/manageTicket', queue: this.state.selectedQueue, manage: true }}
        style={{ color: "inherit", textDecoration: "none" }}>
        <AddIcon />
      </Link>

    </Fab>

  }


  getCustomQRCodeContent() {
    const {t} = this.props;
    return <Fragment>
      {t("scan_code_and_queue_up")}
    </Fragment>
  }

  getCustomScannerContent() {
    const {t} = this.props;
    return <Fragment>
      {t("hold_code_in_front_of_line")}
    </Fragment>
  }


  filterAction(primaryStatus, secondaryStatus) {
    console.log("filterAction");
    let filter = {
      primaryStatus: primaryStatus
    }
    this.props.history.push({
      pathname: '/my/groups/slotmanager',
      filter:filter,
      manage: true,
      filtersOpen: true,
      timeSlider: true
    });
  }

  processStats(stats) {
    console.log("stats " + stats);
    console.log("stats.wait " + stats.wait);
    this.setState({
      stats: stats
    });
  }

  
  getMoreMenuItems() {
    const {t} = this.props;

    let gridContent = [
      {
        to: {pathname: '/qrcode', value: config.get('baseUrl')+"/room?uid="+(this.state.selectedQueue && this.state.selectedQueue.uid), title: t("qr_code")+" "+(this.state.selectedQueue && this.state.selectedQueue.name), customContent: this.getCustomQRCodeContent()},
        title: t("show_qr_code"),               
        text: t("show_qr_code"),
        icon: <Wallpaper />
      },
      {
        to: {pathname: '/operatorscanner', title:t("scan_ticket"), customContent:this.getCustomScannerContent()},
        title: t("scan_QR"),               
        text: t("scan_QR"),
        icon: <CropFree />
      },
      {
        to: {pathname: '/callBoard', entity:this.state.selectedQueue},
        title: t("callboard"),               
        text: t("callboard"),
        icon: <ViewAgenda />
      },
      {
        to: {pathname: '/my/groups/slotmanager/settings', vQueue:this.state.selectedQueue},
        title: t("q_settings"),               
        text: t("q_settings"),
        icon: <Settings />
      }
      
    ];
    return gridContent;
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        { this.state.selectedQueue && 
        <SockJsClient url={config.get('vqueueUrl') + '/ws/ws-tickets'}
          topics={['/topic/queues/statistics/' + this.state.selectedQueue.id]}
          onConnect={() => {
            console.log("connected to queue " + this.state.selectedQueue.id);
          }}
          onDisconnect={() => {
            console.log("Disconnected queue " + this.state.selectedQueue.id);
          }}
          onMessage={(stats) => {
            console.log("onMessage  " + this.state.selectedQueue.id);
            this.processStats(stats);
          }}
          onConnectFailure={() => {
            console.log("Could not connect queue " + this.state.selectedQueue.id);
          }}
          ref={(client) => {
            this.clientRef = client
          }} />}

        { this.state.selectedQueue &&
          <Fragment>
            <DeskHeader backTo={SessionContext.getQSettingsOrigin()?SessionContext.getQSettingsOrigin():"/"}
              title={<Crumbs queue={SessionContext.getActiveQueue()}/>}
              moreMenuItems={this.getMoreMenuItems()}
            />
            <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
              <Fragment>
                <Grid container spacing={3} justify="center" style={{position: 'relative', marginTop:"20px"}}>
                  <Grid container spacing={1}  alignItems="center" justify="center" style={{ justifyContent: "center", display: "flex" ,textAlign: "center" }}>
                    <Grid item xs={6} >
                      <SlotsDisplay queue={SessionContext.getActiveQueue()} />
                    </Grid>
                  </Grid>
                  <TicketFilter selectedQueue={SessionContext.getActiveQueue()}  filterCallback={this.filterAction} displayOnly={true}/>
                </Grid>
              </Fragment>
            </Container>

            <AppFooter fabButton={this.getFabButton()} />

          </Fragment>
        }

      </div>

    );
  }
}


export default withTranslation()(OperatorDashboard);