import { Fab, IconButton, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { AssignmentReturn, ForumOutlined, Wallpaper } from '@material-ui/icons';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import CodeIcon from '@material-ui/icons/Code';
import EditIcon from '@material-ui/icons/Edit';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import React, { Component, Fragment } from 'react';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import { MessageContext } from '../../pages/assets/MessageProvider';
import ButtonUpdateState from './ButtonUpdateState';
import { t } from 'i18next';

class ActionMenu extends Component {
  static contextType = MessageContext

  
  componentWillMount() {
    this.setState({
      ticket: this.props.ticket
    });      
  }

  shareLink(e) {
    let loc = window.location.protocol + "//" + window.location.hostname
    if (window.location.port) {
        loc = loc + ":" + window.location.port;
    }
    
    let linkTicket = loc + "/wallet?tuid=" + this.props.ticket.uid;
    /* window.open(linkTicket, '_blank'); */
    navigator.clipboard.writeText(linkTicket);
    this.context.addModalMessage(t("temporary_save"));  
}

  render() {
    return (
      <Fragment>
        { 
        this.props.ticket &&
        <Fragment>
            <MenuItem key={this.props.ticket+"_1"}>
              <Link to={{pathname: this.props.ticketEditor, vTicket: this.props.ticket, queue: this.props.queue, manage: this.props.manage}} 
                style={{color: "inherit", textDecoration: "none"}}>
                    <ListItemIcon><IconButton  style={{backgroundColor:'#94d7ff'}}><EditIcon style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                    {t("change")}
              </Link> 
            </MenuItem>
            <MenuItem onClick={(e) => this.shareLink(e)} key={this.props.ticket+"_1"}>
                  <ListItemIcon><IconButton  style={{backgroundColor:'#fff894'}}><ShareOutlinedIcon style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                  {t("share")}
                
            </MenuItem>
            <MenuItem  key={this.props.ticket+"_2"}>
              <Link to={{pathname: '/chat', ticket: this.props.ticket , manage: true}} 
                    style={{color: "inherit", textDecoration: "none"}}>
                  <ListItemIcon><IconButton  style={{backgroundColor:'#b3e3db'}}><ForumOutlined style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                  {t("chat")}
              </Link> 
            </MenuItem>
            <MenuItem  key={this.props.ticket+"_3"}>
            <Link to={{pathname: '/qrcode', value: config.get('baseUrl')+"/wallet?tuid="+this.props.ticket.uid, title: t("your_qr_code_ticket"), customContent: t("show_code_at_operator")}} 
                  style={{color: "inherit", textDecoration: "none"}}>
                  <ListItemIcon><IconButton  style={{backgroundColor:'#bebdbd'}}><Wallpaper style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                  {t("qr_code")}
              </Link>
          </MenuItem>
          <MenuItem  key={this.props.ticket+"_4"}>
              <ButtonUpdateState 
                  ticket={this.props.ticket}
                  onClick={this.props.onClickReturn} 
                  message={`${this.props.ticket.uid}`+" "+t("was_returned")}
                  primaryStatus="FINALIZING"
                  secondaryStatus="RETURNED"
                  custom={<Fragment>
                        <ListItemIcon><IconButton  style={{backgroundColor:'#ff5733 '}}><AssignmentReturn style={{fontSize: "1rem"}} /></IconButton></ListItemIcon>
                            {t("return")}
                    </Fragment>
                  }>
              </ButtonUpdateState> 
          </MenuItem>
        </Fragment>
        }
      </Fragment>
    );
  }
}


export default ActionMenu;