import React, { Fragment } from 'react';
import SessionContext from '../../frame/SessionContext';



export default function InitializeClientId() {
  console.log("InitializeClientId");

  let cUid = SessionContext.getClientId();
  console.log("Client cUid " + cUid);
  if(!cUid || cUid==null) {
    let uuid =  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    })

    console.log("Client ID " + uuid);

    SessionContext.setClientId(uuid);
  }  
    

  return ( 
    <Fragment> 
      
    </Fragment>
  );
}
