
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React, { Component } from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Fab, IconButton, Paper, Typography, withStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Offline from '../../frame/Offline';

const AVATAR = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';

const useStyles =(theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

class AppFooter extends Component {
  
  constructor() {
    super();
    this.state = {
      menuAnchor: null,
      date: new Date() ,
      open: false
    };
  }



  componentDidMount() {
    
    this.timer = setInterval(async () => {
      this.setState({ date: new Date() }) 
    }, 1000)   
  }
  
  
  componentWillUnmount(){
    clearInterval(this.timer);
  }

  toggleSubMenu(event) {
    this.setState({
      menuAnchor : event.currentTarget
    })
  }

  
  closeSubMenu() {
    this.setState({
      menuAnchor : null
    })
  }

  getTime(date) {
    var options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }; 
    return <span>{date.toLocaleTimeString("de-DE", options)}</span>
  }


  render() {
    const { classes } = this.props;
    return (
        <AppBar position="fixed" color="primary" style={{top: 'auto',bottom: 0}}>
          <Grid container >
            
            <Grid item xs  >                                  
             { this.props.fabButton && <Grid container  justify="center" style={{position: "absolute", top: "0px" }} >
                <Grid >
                   {this.props.fabButton}     
                </Grid> 
              </Grid> }
            </Grid>
            <Grid item xs={12}  >
              <Grid container justifyContent="space-between" alignItems="center" direction="row" >
                <Typography  variant="body1"  style={{fontSize: "0.8rem", marginLeft:"1rem" }}>
                  <span>
                    {process.env.REACT_APP_NAME} ({process.env.REACT_APP_VERSION})
                  </span> 
                  <Offline />  
                </Typography>
                
                <Typography  variant="body1"  style={{fontSize: "1.3rem", marginRight:"1rem" }}>
                  <span>
                    {this.state.date && this.getTime(this.state.date)}
                  </span> 
                </Typography>                
              </Grid>
            </Grid>
            <Grid item xs={12}  >
              { this.props.actionBar && 
                    this.props.actionBar
              }
            </Grid> 
          </Grid>
        </AppBar>
    );
  }
}

export default withRouter(withStyles(useStyles)(AppFooter));
