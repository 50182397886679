import React, { useState,useContext, createContext, useEffect, useRef, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import ManageMultiQ from '../multiq/ManageMultiQ';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




 function BookingSelection(props) {
  const {t} = props;
  const classes = useStyles();
  const { vQueue } = props.location;
  const { vDomain } = props.location;
  const { vOptions } = props.location;
  const { vGroup } = props.location;
  const { manage } = props.location;
  

  const [options, setOptions] = useState(vOptions);
  const [domain, setDomain] = useState(vDomain?vDomain:SessionContext.getDomain());
  const [queue, setQueue] = useState(vQueue?vQueue:((options && options.ticket)?options.ticket.queue:false));
  const [group, setGroup] = useState(queue?queue.group:vGroup);
  const [queues, setQueues] = useState(null);
  const [canShow, setCanShow] = useState(false);
  
  const { addMessage } = useContext(MessageContext)
  const history = useHistory();


  useEffect(() => {    // Update the document title using the browser API 
    if(queues)  {
      if(queues.length>0) {
        setCanShow(true);
        setGroup(queues[0].group); //at least one must be there since categories were found before!!
      } else {
        addMessage("Kein Slotmanager für diese Kategorie.");
        history.push("/booking/options");
      }
    }
  }, [queues] )

  
  useEffect(() => {    // Update the document title using the browser API 
    console.log("options: " + JSON.stringify(options));
    SessionContext.setDomain(domain);   
    
    if(!queue) {
    const url = config.get('vqueueUrl')+'/api/queues/options/'
    let uri = domain.id+"/"+options.category.id+"/33";//+options.cylces;
    if(group) {
      uri = 'group/'+group.id+"/"+options.category.id+"/33";//+options.cylces;
    }
    axios.get(url+uri )
        .then(res => {
          addMessage(t("queues_loaded"));
          setQueues(res.data)
          setGroup(res.data[0].group);
        //  history.goBack();//history.push("/queuemanagement")
        }).catch(function (error) {
          // handle error
          console.log(error);
          addMessage(error.detail);
          
        }) 
      } else {
        let lQueues = [];
        lQueues.push(queue);
        setQueues(lQueues);
        setGroup(lQueues[0].group);
      }
    
  }, [] )
  
  return (
    <Fragment>
      { canShow && queues && group && <ManageMultiQ 
        key="manageMutliQ_Booking"
        vQueues={queues} 
        vGroup={group}
        vPlace={group && group.place}
        vDomain={group?group.place.domain:domain}
        backTo="/booking/options" 
        hideResource={true}
        booking={true}
        title="Buchungsauswahl"
        createTicketLink={
          {
            link:"/booking/data",
            options: options,
            manage: manage,
            backTo:"/booking/selection" 
          }
        }
      vManage={manage}/>
    }
    </Fragment>
  );
}

export default withTranslation()(BookingSelection);