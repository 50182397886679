import { FiberManualRecord } from '@material-ui/icons';
import React, { Component, Fragment, useEffect } from 'react';
import { t } from 'i18next';
import { Link } from "react-router-dom";

import {
  Badge, Button
} from 'reactstrap';
import { Grid, Typography } from '@material-ui/core';
import log from 'loglevel';


export default function MultiQStatusAndActionBar(props) {
  const { group } = props;
  const { manage } = props;
  const { backTo } = props;
  const { startIndex } = props;
  const { elements } = props;
  const { countShow } = props;
  const { ready } = props;
  
  const { resoruceStatusElement } = props;
  
  const { partitionResourceDTO } = props;

  useEffect(() => {    
    log.debug("MultiQStatusAndActionBar useEffect resourceStatusDTOMap status "+(partitionResourceDTO?partitionResourceDTO.partitionStatus:'undefined'));      
  }, [partitionResourceDTO] )

  function getStatusColor() {
    if(partitionResourceDTO.partitionStatus==="READY" && ready) {
      return "08c21a";
    } else if(partitionResourceDTO.partitionStatus==="DIRTY"  || !ready) {
      return "eb4034";
    } 
    return "d9ca00";
  }

  function getAdminModeStatusColor() {
    if(manage) {
      return "d90000";
    } 
    return "d9ca00";
  }

  function mustBlink() {
    if(partitionResourceDTO.partitionStatus!=="READY" || !ready) {
      return "blink";
    } 
    return "";  
  }

  return (
    <Fragment>
      <Grid container 
        alignItems="center" >
          <Grid item xs={2} >
              <Grid container spacing={1}
                  justifyContent='flex-end' >
                  <Grid item style={{ border: "solid 1px", borderRadius:"15px", backgroundColor: "none" }} >
                    <FiberManualRecord  className="align-left" style={{ color: "#"+getAdminModeStatusColor(), fontSize: '1.5rem' }}  />
                    &nbsp;
                    { partitionResourceDTO && partitionResourceDTO.partitionStatus && 
                      <FiberManualRecord  className="align-left" className={mustBlink() } style={{ color: "#"+getStatusColor(), fontSize: '1.5rem' }}  /> 
                    }
                  </Grid>
                  
              </Grid>
          </Grid>
          <Grid item xs={8} >
            { group &&  <Link to={{pathname: '/booking/options', vGroup: group, vDomain:group.place.domain, manage: manage, backTo: backTo}}  style={{color: "inherit", textDecoration: "none"}}>
              <Button style={{width:'90%'}} variant="contained" color="secondary" variant="contained" >
                {t("book_here")}
              </Button>
            </Link> }
          </Grid>
          <Grid item xs={2}  >
            <Grid container justifyContent='flex-start' >
                { 
                  elements && <Badge pill color="info" className="align-left" style={{ fontSize: '1rem' }}>{countShow>elements.length?elements.length:(startIndex+1)+"-"+(startIndex+countShow)}&nbsp;/&nbsp;{elements.length}</Badge>
                }
            </Grid>
        </Grid>
      </Grid>
    </Fragment>
 );
}