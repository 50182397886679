import React, { useState,useContext, createContext, useEffect, useRef, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { LocationCity } from '@material-ui/icons';
import Crumbs from '../queue/Crumbs';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




function EditGroup(props) {
  const {t} = props;
  const classes = useStyles();
  const { vGroup } = props.location;
  const { vPlace } = props.location;  
  
  const [group, setGroup ] = useState(vGroup?vGroup:SessionContext.getGroup());
  const [place, setPlace] = useState(vPlace?vPlace:SessionContext.getPlace());
  
  

  const [name, setName] = useState(group?group.name:"");
  const [decription, setDecription] = useState(group?group.decription:"");

  const { addMessage } = useContext(MessageContext)

  const history = useHistory();

  const Address = <EditLocation />;

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("group: " + JSON.stringify(group));
    SessionContext.setGroup(group);    
    
  }, [] )
  function validateForm() {
    console.log("group: " + JSON.stringify(group));
    return name && name.length > 0;
  }

  

  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/groups/'+group.id;
    axios.delete(url,  group )
        .then(res => {
          history.push("/my/groups");
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save Group.")
    let tGroup = group;
    if(!tGroup) {
      tGroup = {};
    }

    tGroup.name = name;
    tGroup.decription = decription;
    tGroup.place = place;
    const url = config.get('vqueueUrl')+'/api/groups';
    if(!tGroup.id) {
        axios.post(url,  tGroup )
        .then(res => {
          history.push("/my/groups");
          //addMessage(t("group_saved"));
        }).catch(function (error) {
          console.log(error);
        })
     } else {
        axios.put(url,  tGroup )
        .then(res => {
          history.push("/my/groups");
          //addMessage(t("group_saved"));
        }).catch(function (error) {
          console.log(error);
        })
      }
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
    
      <DeskHeader title={<Fragment><Crumbs place={place}/> : <Fragment>{vGroup?t("change_group"):t("create_group")}</Fragment></Fragment>} backTo="/my/groups"  />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("group")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="name"
                variant="outlined"
                value={name}
                required
                fullWidth
                id="name"
                label={t("group_name")}
                autoFocus
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{width:"100%"}}
                label={t("description")}
                variant="outlined"
                fullWidth
                value={decription}
                id="decription"
                multiline
                rows={4}
                name="decription"
                autoComplete="fcolor"
                onChange={(e) => setDecription(e.target.value)}
              />
            </Grid>
            <Grid item  xs={6} >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t("save")}
              </Button> 
            </Grid>
            <Grid item  xs={6} >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => deleteEntity()}
                disabled={!group || !group.id}
              >
                {t("delete")}
              </Button>  
            </Grid>
          </Grid>
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditGroup);
