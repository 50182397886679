import Button from '@material-ui/core/Button';
import { blue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import config from 'react-global-configuration';
import { useAPIMessage } from '../../pages/assets/useAPIMessage';
import { t } from 'i18next';


const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, items, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t("choose_delay")}</DialogTitle>
      <List>
        {items.map((item) => (
          <ListItem button onClick={() => handleListItemClick(item)} key={item}>
            
            <ListItemText primary={item.name} secondary="ttest"/>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default function SendDelay(props) {
  const apiMessage = useAPIMessage();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const delays = [
      { delayMillis : 60000, name: t("1_minute")},
      { delayMillis : 300000, name: t("5_minutes")}, 
      { delayMillis : 600000, name: t("10_minutes")}, 
      { delayMillis : 1800000, name: t("30_minutes")}
    ];
    const [selectedValue, setSelectedValue] = React.useState(delays[1]);
    const { currentTicket, callback } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    sendDelay(value);
  };

  const sendDelay = (value) => {
    const url = config.get('vqueueUrl')+'/api/delays';
    const delay = { 
        ticketId : currentTicket.id,
        duration : value.delayMillis
    }
    axios.post(url,  delay )
        .then(res => {
        apiMessage.addMessage(t("delay_noticed")+" "+value.name);
    }) 
   
  }

  return (
    <Fragment>
      <Button style={{width:'100%'}}  color="secondary"  variant="contained" onClick={handleClickOpen}>
        {t("notice_delay")}
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} items={delays} />
    </Fragment>
  );
}
