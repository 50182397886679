import React, { useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import ChatLayout from './ChatLayout';
import { useHistory } from "react-router-dom";

import { MessageContext } from '../pages/assets/MessageProvider';
import axios from 'axios';
import config from 'react-global-configuration';
import { Button, Container, CssBaseline } from '@material-ui/core';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { ForumOutlined } from '@material-ui/icons';
import SessionContext from '../frame/SessionContext';
import SockJsClient from 'react-stomp';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  table: {
    //minWidth: 650,
  },
  chatSection: {
    width: '100%',
    
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(10),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function Chat(props) {
  const classes = useStyles();
  const [currentTicket, setCurrentTicket] = useState(getTicket());
  
  const [messages, setMessages] = useState(getTicket()?(getTicket().message?getTicket().message:"[]"):"[]");
  const [message, setMessage] = useState("");
  const [client] = useState("");
  const { addMessage } = useContext(MessageContext);
  const history = useHistory();
  
  function getTicket() {
    let ticket = props.location?props.location.ticket:props.ticket;
    if(!ticket) {  
      ticket= SessionContext.getActiveQueue().currentTicket;
    }
    return ticket;
  }

  function getMessages() {
    let gridMessages = [];
    if(messages) {
      let jsonMessages = JSON.parse(messages);
      jsonMessages.forEach(function (aMessage, index) {
        gridMessages.push(
            {
              message:  aMessage.message ,
              timestamp:  aMessage.timestamp ,
              sender:  aMessage.clientId ,
              who: aMessage.clientId==SessionContext.getClientId()?"you":"them",
              color: aMessage.clientId==SessionContext.getClientId()?"#b0e8bf":"#d8dbc3",
              direction:  aMessage.clientId==SessionContext.getClientId()?"flex-start":"flex-end",
            }
          );
      }.bind(this));
    }
    return gridMessages;
  }

  function addComMessage(aMessage) {
    console.log("addComMessage."+aMessage)
    let jsonMessages = JSON.parse(messages);
      
    let tmpMessage = {};
    tmpMessage.clientId = SessionContext.getClientId();
    tmpMessage.message = aMessage;
    tmpMessage.sender = SessionContext.getClientId();
    tmpMessage.timestamp = new Date().getTime();

    jsonMessages.push(tmpMessage);
    return jsonMessages;
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save Ticket.")
    let ticketEntity = currentTicket;
    ticketEntity.message = JSON.stringify(addComMessage(message));      
    const url = config.get('vqueueUrl') + '/api/tickets';
    axios.put(url, ticketEntity)
    .then(res => {
        addMessage(t("sended"));        
    })    
  }

  function processTicketChange(changedTicket) {
    console.log(changedTicket);
    setCurrentTicket(changedTicket);
    setMessages(changedTicket.message);
  }

  return (
        <Fragment>
          <SockJsClient url={config.get('broadcaster')+'/ws/ws-tickets'}
            topics={['/topic/tickets/'+currentTicket.uid]}
            onConnect={() => {
                console.log("connected for Ticket "+currentTicket.uid+" changes.");
            }}
            onDisconnect={() => {
                console.log("Disconnected Ticket "+currentTicket.uid);
            }}
            onMessage={(ticket) => {
                processTicketChange(ticket);              
            }}
            onConnectFailure={() => {
            console.log("Could not connect Ticket "+currentTicket.uid);
        }}
            ref={(client) => {
                client = client
        }}/>   
        <CssBaseline />
        <div className={classes.paper}>
            <Grid container>
                <Grid item xs={12} >
                    <Typography variant="h5" className="header-message"></Typography>
                </Grid>
            </Grid>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={12}>
                    <ChatLayout messages={getMessages()}/>       
                    <Divider />
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid container style={{padding: '10px'}}>
                            <Grid item xs={11}>
                                <TextField value={message} onChange={(e) => setMessage(e.target.value)} id="outlined-basic-email" label={t("type_something")} fullWidth />
                            </Grid>
                            <Grid xs={1} align="right">
                            <Button type="submit">
                                <Fab color="primary" aria-label="add">
                                <SendIcon /></Fab>
                            </Button> 
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
      </Fragment>
  );
}