import React, { useState,useContext, createContext, useEffect, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import TimeRangeSelector from '../pages/assets/TimeRangeSelector';
import SendDelay from '../ticket/assets/SendDelay';
import { useHistory } from "react-router-dom";
import config from 'react-global-configuration';

import axios from 'axios';
import SessionContext from '../frame/SessionContext';
import { FormControl, FormGroup, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, Switch } from '@material-ui/core';

import { Edit, Save, Schedule, Settings } from '@material-ui/icons';
import StatusTicket from '../ticket/assets/StatusTicket';
import Crumbs from './Crumbs';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  formControl: {
    width: '100%'
  }
}));

function QueueEditor(props) {
  const {t} = props;
  const classes = useStyles();
  const { vQueue } = props.location;

  const [queue, setQueue] = useState(vQueue);
  const [group, setGroup] = useState(queue?queue.group:{});
  const [groups, setGroups] = useState([]);
  const [place,setPlace] = useState(SessionContext.getPlace())

  const { addMessage } = useContext(MessageContext)
  const history = useHistory();

  

  useEffect(() => {    // Update the document title using the browser API 
    console.log("queue: " + JSON.stringify(queue));
    console.log("config: " + JSON.stringify(config));
    
    let uri = '/api/groups/place/'+place.id; //später noch auf den Ort einschränken
    const url = config.get('vqueueUrl')+uri;
    axios.get(url )
        .then(res => {
          setGroups(res.data)
        }).catch(function (error) {
          console.log(error);
          addMessage(error.detail);
        })     
  }, [] )

  function update (name, millis, e, cfg, aValue, float)  {
    
      console.log("update."+name+" - "+millis+" - "+e.target.value)
      let lQueue = queue;
      let value = e.target.value;
      
      
      if(aValue != undefined && aValue) {
        value = aValue;
      } else if(!value) {
        value = e.target.textContent; //mui slider value is here
      }
      if(millis && !isNaN(value)) {
        value = value*1000*60;
      }
      if(float) {
        value = value.replace(",", ".");
      }
      if(!cfg) {
        lQueue[name] = value;
      } else {
        let qConfig = JSON.parse(lQueue.config);
        if(!qConfig) {
          qConfig = {};      
        }
        qConfig[name] = value;
        lQueue.config = JSON.stringify(qConfig);
      }
      setQueue((prevState) => {
          return { ...prevState, lQueue };
      });
  }
  
  function addComMessage(ticketEntity,aMessage) {
    console.log("addComMessage."+aMessage)
    let jsonMessages = null;
    if(ticketEntity.message) {
      jsonMessages = JSON.parse(ticketEntity.message);    
    }
    if(!jsonMessages) {
      jsonMessages = [];
    }
    let tmpMessage = {};
    tmpMessage.clientId = SessionContext.getClientId();
    tmpMessage.message = aMessage;
    tmpMessage.sender = "sender";
    tmpMessage.timestamp = new Date().getTime();

    jsonMessages.push(tmpMessage);
    return jsonMessages;
  }

  function changeGroup(id) {
    if(id) {
      let lGroups  = groups.filter((group) => {
        return group.id==id
      });
      setGroup(lGroups[0]);
    } else {
      setGroup(null);
    }
  }
  function millisToMinuten(millis) {
    if(millis) {
      return millis / 1000 / 60;
    }
  }

  function validateForm() {
    console.log("queue: " + queue);
    return queue && queue.name.length > 0;
  }


  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/queues/'+queue.id;
    axios.delete(url,  queue )
        .then(res => {
          history.push("/my/groups/slotmanager");
          addMessage("Slotmanager gelöscht");
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
        })
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Save Queue.");
    
      if(place) {
        queue.placeId=place.id;
      }
      queue.group=group;

      queue.domainUid = SessionContext.getCreatorUuid();
      const url = config.get('vqueueUrl')+'/api/queues';
      if(queue.id) {
        axios.put(url,  queue )
        .then(res => {
         addMessage(t("changes_saved"));
         history.goBack();         
         setQueue(res.data);
         SessionContext.setActiveQueue(res.data);
        })
      } else {
        axios.post(url,  queue )
        .then(res => {
          addMessage(t("waiting_room_created"));
          history.goBack();          
          setQueue(res.data);
          SessionContext.setActiveQueue(res.data);
        })
      }
  }

    function getQuickActionItems() {
      let quickActionItems = [];
      quickActionItems.push(
        <Link onClick={(e) => handleSubmit(e)} style={!validateForm()?{pointerEvents: "none", color: "#ffb1b1"}:{cursor: "pointer"}}>
            <Save />            
        </Link>
      );
      return quickActionItems;
    }

    function formatFloatString(string) {
      if(string) {
        return string.replace(".", ",");
      }
      return string;

    }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
    <DeskHeader 
        title={<Fragment><Crumbs queue={vQueue}/> : <Fragment>{queue.id?"Slotmanager bearbeiten":"Slotmanager anlegen"} </Fragment></Fragment>}
        quickActions={getQuickActionItems()}  />
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <Settings />
      </Avatar>
      <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item  xs={12}>
              <Typography component="h1" variant="h5">
              {t("most_important")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField style={{width:"100%"}}
                    label={t("waiting_room_name")}
                    variant="outlined" 
                    value={queue.name}
                    onChange={(e) => update("name", false, e)}
                  />
            </Grid>
            <Grid item  xs={12}>
              <TextField style={{width:"100%"}}
                label={t("email_counter_desk")}
                variant="outlined" 
                value={queue.contact} 
                onChange={(e) => update("contact", false, e)}/>
            </Grid>
            <Grid item  xs={12}>
              <Grid container spacing={1}>
                <Grid item  xs={12}>
                  <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-group-label">{t("group")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-group-label"
                      id="demo-simple-select-group"
                      value={group && group.id} 
                      onChange={(e) => changeGroup(e.target.value)}
                      label={t("group")}
                    >
                      <MenuItem  key={-1} value={null}>
                        {t("no_group")}
                      </MenuItem>
                            
                    {groups.map((group,i) => {
                          return (
                            <MenuItem  key={i} value={group.id}>
                              {group.name}
                            </MenuItem>
                          )
                    })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={12}>
              <Typography component="h1" variant="h5">
              {t("slot_care")}
              </Typography>
            </Grid>
            
            <Grid item  xs={12}>
              <Grid container spacing={1}>
                <Grid item  xs={12}>
                  <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-standardTicketProcessingDuration-label">{t("basic_work_time")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standardTicketProcessingDuration-label"
                      id="demo-simple-select-standardTicketProcessingDuration"
                      value={millisToMinuten(queue.standardTicketProcessingDuration)} 
                      onChange={(e) => update("standardTicketProcessingDuration",true, e)}
                      label={t("basic_work_time")}
                    >
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={45}>45</MenuItem>
                      <MenuItem value={60}>60</MenuItem>
                      <MenuItem value={120}>120</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={12}>
                <Grid container spacing={1}>
                  <Grid item  xs={12}>
                    <StatusTicket primaryStatus="WAITING" secondaryStatus="LAST_CALL" />
                  </Grid>
                  <Grid item  xs={12}>
                    <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-outlined-label">{t("cash_dead_line")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={millisToMinuten(queue.standardTicketRedeemDuration)} 
                        onChange={(e) => update("standardTicketRedeemDuration",true, e)}
                        label={t("cash_dead_line")}
                      >
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                        <MenuItem value={120}>120</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item  xs={12}>
                <Grid container spacing={1}>
                  <Grid item  xs={12}>
                    <StatusTicket primaryStatus="WAITING" secondaryStatus="CALL" />
                  </Grid>
                  <Grid item  xs={12}>
                    <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-outlined-label">{t("call_advance")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={millisToMinuten(queue.standardCallingTimeDuration)} 
                        onChange={(e) => update("standardCallingTimeDuration",true, e)}
                        label={t("call_advance")}
                      >
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={45}>45</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                        <MenuItem value={120}>120</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item  xs={12}>
                <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                  <TextField style={{width:"100%"}}
                    label={t("max_cycles_per_slot")}
                    variant="outlined" 
                    value={queue.config?formatFloatString(JSON.parse(queue.config).resourceLimit):"10"}
                    onChange={(e) => update("resourceLimit", false, e, true, false, true)}/>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                  <TextField style={{width:"100%"}}
                    label={t("offset_beginning")}
                    variant="outlined" 
                    value={queue.config?JSON.parse(queue.config).offsetBegin:"0"}
                    onChange={(e) => update("offsetBegin", false, e, true, false, true)}/>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                  <TextField style={{width:"100%"}}
                    label={t("offset_end")}
                    variant="outlined" 
                    value={queue.config?JSON.parse(queue.config).offsetEnd:"0"}
                    onChange={(e) => update("offsetEnd", false, e, true, false, true)}/>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                  <InputLabel id="demo-simple-select-outlined-label">{t("behavoiur_if_your_turn")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={queue.autoReturn} 
                    onChange={(e) =>update("autoReturn", false, e)}
                    label={t("behavoiur_if_your_turn")}
                  >
                    <MenuItem value={"ON"}>{t("terminates_automatically")}</MenuItem>
                    <MenuItem value={"OFF"}>{t("automatically_in_progress")}</MenuItem>
                    { /*<MenuItem value={"MOVE_BACK"}>automatisch neu planen</MenuItem>   */}                           
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item  xs={12}>
                <FormControlLabel control={<Switch defaultChecked 
                  checked={queue.config?JSON.parse(queue.config).RECALCULATE_ON_RESOURCE_CHANGE:false}
                  onChange={(e) => update("RECALCULATE_ON_RESOURCE_CHANGE", false, e, true, e.target.checked)}/>} label={t("replan_slots_on_resource_change")} />
              </Grid>
              { /*
              <Grid item  xs={12}>
                <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                  <InputLabel id="demo-simple-select-outlined-label">Slot Offset</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={queue.config?JSON.parse(queue.config).SLOT_OFFSET:""}
                      onChange={(e) => update("SLOT_OFFSET", false, e, true)}
                    label={t("slot_offset")}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <Typography component="h1" variant="h5">
                {t("behaviour")}
                </Typography>
              </Grid>
              <Grid item  xs={12}>
               
                    <FormControl className={classes.formControl} variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">{t("room_mode")}</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={queue.status} 
                            onChange={(e) =>update("status", false, e)}
                            label={t("room_mode")}
                          >
                            <MenuItem value={"TIMESLOT"}>{t("timeslot_based")}</MenuItem>
                            <MenuItem value={"TIMESLOT_DYNAMIC"}>{t("time_slot_and_dynamic_hybrid")}</MenuItem>
                            <MenuItem value={"DYNAMIC"}>{t("full_dynamic")}</MenuItem>
                            <MenuItem value={"SERVICE"}>{t("service_mode")}</MenuItem>
                          </Select>
                        </FormControl>
                 
              </Grid>
              <Grid item  xs={12}>
                <FormControl className={classes.formControl} variant="outlined" style={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-queueingKind-label">{t("sort_of_queuing")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-queueingKind-label"
                        id="demo-simple-select-queueingKind"
                        value={queue.queueingKind} 
                        onChange={(e) => update("queueingKind", false, e)}
                        label={t("sort_of_queuing")}
                      >
                        <MenuItem value={"SIMPLE"}>{t("directly")}</MenuItem>
                        <MenuItem value={"SIMPLE"}>{t("directly")}</MenuItem>
                        <MenuItem value={"FORMULAR"}>{t("with_formular_directly")}</MenuItem>
                        <MenuItem value={"SIMPLE_CONFIRM"}>{t("with_confirmation")}</MenuItem>
                        <MenuItem value={"FORMULAR_CONFIRM"}>{t("with_formular_and_confirmation")}</MenuItem>                                
                      </Select>
                    </FormControl>
              </Grid><Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <InputLabel id="demo-simple-select-outlined-label">{t("how_many_positions_back")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={queue.moveBackPosCount} 
                    onChange={(e) =>update("moveBackPosCount", false, e)}
                    label={t("how_many_positions_back")}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={5}>5</MenuItem>                              
                  </Select>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <Typography component="h1" variant="h5">
                  {t("assistance")}
                </Typography>
              </Grid>
              <Grid item  xs={12}>
                <Typography component="h2" variant="h5">
                  {t("slotmanagement")}
                </Typography>
              </Grid>
              <Grid item  xs={12}>
                <FormControlLabel control={<Switch defaultChecked 
                  checked={queue.config?JSON.parse(queue.config).OPTIMIZER:false}
                  onChange={(e) => update("OPTIMIZER", false, e, true, e.target.checked)}/>} label={t("opimizer")} />
              </Grid>
              <Grid item  xs={12}>
                <FormControl className={classes.formControl}
                 variant="outlined" style={{width:"100%"}}>
                  <InputLabel id="demo-simple-select-outlined-label">{t("opimizer_tart")}</InputLabel>
                  <Select  disabled={queue.config?!JSON.parse(queue.config).OPTIMIZER:!false}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={queue.config?JSON.parse(queue.config).OPTIMIZER_TRIGGER_BY_GAPS:""}
                    onChange={(e) => update("OPTIMIZER_TRIGGER_BY_GAPS", false, e, true)}
                    label={t("opimizer_tart")}
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={120}>120</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <FormLabel id="demo-simple-select-outlined-label">{t("optimizer_order")}</FormLabel>
                    <RadioGroup 
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={queue.config?JSON.parse(queue.config).OPTIMIZER_ORERING:""}
                      onChange={(e) => update("OPTIMIZER_ORDERING", false, e, true)}
                      label={t("optimizer_order")}
                      row
                    >
                    <FormControlLabel disabled={queue.config?!JSON.parse(queue.config).OPTIMIZER:!false} value="OPTIMIZER_VAL_POSITION" control={<Radio />} label={t("position")} />
                    <FormControlLabel disabled={queue.config?!JSON.parse(queue.config).OPTIMIZER:!false} value="OPTIMIZER_VAL_PLANNED" control={<Radio />} label={t("Planed")} />
                    <FormControlLabel disabled={queue.config?!JSON.parse(queue.config).OPTIMIZER:!false} value="OPTIMIZER_VAL_PRIO" control={<Radio />} label={t("prio")} />
                  </RadioGroup>
                </FormControl>
              </Grid>


              <Grid item  xs={12}>
                <FormControlLabel control={<Switch defaultChecked 
                  checked={queue.config?JSON.parse(queue.config).DEFRAG:false}
                  onChange={(e) => update("DEFRAG", false, e, true, e.target.checked)}/>} label={t("defrag")} />
              </Grid>
              <Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-outlined-label">{t("defrag_at_gap")}</InputLabel>
                  <Select disabled={queue.config?!JSON.parse(queue.config).DEFRAG:!false}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={queue.config?JSON.parse(queue.config).DEFRAG_TRIGGER_BY_GAPS:""}
                    onChange={(e) => update("DEFRAG_TRIGGER_BY_GAPS", false, e, true)}
                    label={t("defrag_at_gap")}
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={120}>120</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <FormLabel id="demo-simple-select-outlined-label">{t("defrag_order")}</FormLabel>
                    <RadioGroup 
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={queue.config?JSON.parse(queue.config).DEFRAG_ORERING:""}
                      onChange={(e) => update("DEFRAG_ORDERING", false, e, true)}
                      label={t("defrag_order")}
                      row
                    >
                    <FormControlLabel  disabled={queue.config?!JSON.parse(queue.config).DEFRAG:!false} value="DEFRAG_VAL_POSITION" control={<Radio />} label={t("position")} />
                    <FormControlLabel disabled={queue.config?!JSON.parse(queue.config).DEFRAG:!false} value="DEFRAG_VAL_PLANNED" control={<Radio />} label={t("Planed")} />
                    <FormControlLabel disabled={queue.config?!JSON.parse(queue.config).DEFRAG:!false} value="DEFRAG_VAL_PRIO" control={<Radio />} label={t("prio")} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <Typography component="h2" variant="h5">
                  {t("result_and_reaction")}
                </Typography>
              </Grid>
              <Grid item  xs={12}>
                <Typography component="p" variant="p">
                  {t("results_automatically_on_slot")}
                </Typography>
              </Grid>
              <Grid item  xs={12}>
                <FormControlLabel control={<Switch defaultChecked 
                  checked={queue.config?JSON.parse(queue.config).DEFRAG_ON_TICKET_CHANGE:false}
                  onChange={(e) => update("DEFRAG_ON_TICKET_CHANGE", false, e, true, e.target.checked)}/>} label={t("completly_at_change")} />
              </Grid>
              <Grid item  xs={12}>
                <FormControlLabel control={<Switch defaultChecked 
                  checked={queue.config?JSON.parse(queue.config).OPTIMIZE_ON_TICKET_CHANGE:false}
                  onChange={(e) => update("OPTIMIZE_ON_TICKET_CHANGE", false, e, true, e.target.checked)}/>} label={t("timeslot_at_change")} />
              </Grid>
              <Grid item  xs={12}>
                <FormControlLabel control={<Switch defaultChecked 
                  checked={queue.config?JSON.parse(queue.config).REORDER_ON_SLOT_CHANGE:false}
                  onChange={(e) => update("REORDER_ON_SLOT_CHANGE", false, e, true, e.target.checked)}/>} label={t("openinh_hour_change")} />
              </Grid>
             
              {
              
              <Grid item xs={12}>
                <TextField style={{width:"100%"}}
                      label="TEST CONFIG"
                      variant="outlined" 
                      value={queue.config?JSON.parse(queue.config).name:""}
                      onChange={(e) => update("name", false, e, true)}
                    />
              </Grid>
              <Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <InputLabel id="demo-simple-select-outlined-label">Stufe AUTO</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={queue.config?JSON.parse(queue.config).optimization:""}
                    onChange={(e) => update("optimization", false, e, true)}
                    label="Stufe AUTO"
                  >
                    <MenuItem value={"1"}>aus</MenuItem>
                    <MenuItem value={"2"}>Zeitraum AUTO</MenuItem>
                    <MenuItem value={"3"}>Slotmanager AUTO</MenuItem>                              
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item  xs={12}>
                <FormControl variant="outlined" style={{width:"100%"}}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked checked={queue.config?JSON.parse(queue.config).checkbox1:false} />} label="Label" 
                      value={queue.config?JSON.parse(queue.config).checkbox1:""}
                      onChange={(e) => update("checkbox1", false, e, true, e.target.checked)}/>
                    <FormControlLabel control={<Checkbox checked={queue.config?JSON.parse(queue.config).checkbox2:false} />} label="Disabled" 
                      value={queue.config?JSON.parse(queue.config).checkbox2:""}
                      onChange={(e) => update("checkbox2", false, e, true, e.target.checked)}/>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item  xs={12}>
                <Slider
                    aria-label="Temperature"
                    defaultValue={30}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                    value={queue.config?JSON.parse(queue.config).temperatur:0}
                    onChangeCommitted={(e, newValue) => update("temperatur", false, e, true, newValue)}/>
              </Grid>
              */
              }
         
            <Grid item  xs={6} >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t("save")}
              </Button> 
            </Grid>
              <Grid item  xs={6} >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => deleteEntity()}
                  disabled={!queue || !queue.id}
                >
                  {t("delete")}
                </Button>  
              </Grid>            
        </Grid>
       
                  
      </form>
    </div>
    <AppFooter />
    
  </Container>
  );
}

export default withTranslation()(QueueEditor);