import React, { Fragment, useEffect , useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { t } from 'i18next';
import log from 'loglevel';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  let equal =  JSON.stringify(prevProps)===JSON.stringify(nextProps);
  equal =  equal && prevProps.from===nextProps.from;
  log.trace("areEqual ! "+equal);
  return equal;
  
}

export default  React.memo(ResourcesUsage, areEqual); 

function ResourcesUsage(props) {
  const classes = useStyles();
  const { resourcePeriodStatusDTOMap } = props;
  const { from } = props;
  const { to } = props;
  const { offsetFromCallback } = props;
  const { offsetToCallback } = props;
  const [offsetFrom, setOffsetFrom] = useState(0)
  const [offsetTo, setOffsetTo] = useState(0)
  
  const [ availableMax, setAvailableMax ]  = useState({});
  
  const [ zoomPeriodState, setZoomeriodState ] = useState({});
  const [open, setOpen] = React.useState(false);
  

  useEffect(() => {   
    updateOffsetFrom(0);
    updateOffsetTo(to-from);
    if(resourcePeriodStatusDTOMap && resourcePeriodStatusDTOMap.length>0) {
      let max = resourcePeriodStatusDTOMap.reduce(function(prev, current) {
          return (prev.availableCylces > current.availableCylces) ? prev : current
      });
      if(max) {
        log.debug("found maximum "+max.availableCylces);
        setAvailableMax(max.availableCylces);
      }
    }
  }, [resourcePeriodStatusDTOMap] ) 

  
  function updateOffsetFrom(pOffsetFrom) {
    if(pOffsetFrom!=offsetFrom) {
      log.debug("updateOffsetFrom "+offsetFrom+" -> "+pOffsetFrom);
      setOffsetFrom(pOffsetFrom);
      offsetFromCallback(pOffsetFrom);
    }
  }
  
  function updateOffsetTo(pOffsetTo) {
    if(pOffsetTo!=offsetTo) {
      log.debug("updateOffsetTo "+offsetTo+" -> "+pOffsetTo);
      setOffsetTo(pOffsetTo);
      offsetToCallback(pOffsetTo);
    }
  }
  
  
  function getStateStyleColorFor(periodState, style) {
    let red = 0;
    let green = 0;
    let blue = 0;

    const availableCycles = parseFloat(periodState.availableCylces);
    const usedCycles = parseFloat(periodState.usedCycles);

    // Überprüfen, ob availableCycles eine gültige Zahl ist
    if (isNaN(availableCycles) || availableCycles <= 0) {
        // Wenn nicht, den Hintergrund grau setzen und zurückgeben
        red = 160;
        green = 160;
        blue = 160;
        style.backgroundColor = `rgb(${red},${green},${blue})`;
        return style;
    }

    const remainingCycles = availableCycles - usedCycles;

    if (usedCycles === 0) {
        // Hintergrund grün, wenn usedCycles gleich 0 ist
        red = 0;
        green = 150; // Dunkleres Grün
        blue = 0;
    } else if (remainingCycles < 0) {
      // Hintergrund rot, wenn keine verbleibenden Zyklen übrig sind
        red = 200;
        green = 0;
        blue = 0;
    } else {
        // Farbverlauf von grün zu grau, basierend auf remainingCycles
        const gradientRatio = remainingCycles / availableCycles;

        // Startfarbe: Grün
        const startRed = 0;
        const startGreen = 100; // Dunkleres Grün
        const startBlue = 0;

        // Endfarbe: Grau
        const endRed = 160;
        const endGreen = 160;
        const endBlue = 160;

        // Interpolierte Farbe berechnen
        red = Math.round(startRed * gradientRatio + endRed * (1 - gradientRatio));
        green = Math.round(startGreen * gradientRatio + endGreen * (1 - gradientRatio));
        blue = Math.round(startBlue * gradientRatio + endBlue * (1 - gradientRatio));
    }

    style.backgroundColor = `rgb(${red},${green},${blue})`;
    return style;
}








  

  function isPeriodSubPeriod(periodState, interval, important) {
    return (periodState.periodStart%(interval*60*1000))==0;
  }

  function getBarStateStyle(periodState, index) {
    let prevPeriodState = resourcePeriodStatusDTOMap[index-1];
    let diff = 0;
    if(prevPeriodState) {
      diff = periodState.timePeriod - prevPeriodState.timePeriod
      
    }

    if(diff>0) {
      diff = (diff-60000)/(1000)/30; 
    }
    let distance = (1000*60*60)/(1000*60/6*3)/60; 

    let style = {width:"100%", maxHeight:distance+"px", minHeight:distance+"px", marginTop: diff+"px", padding: "0", display: "block"};
    style = getStateStyleColorFor(periodState, style);
    
    return style;
  }

  
  
   function getFillerBarStateStyle(periodState, revert) {
    let timePeriod = periodState.timePeriod;
    let diff = 0;
    if(revert) {
      diff = (timePeriod-from);
      log.debug("getFillerBarStateStyle timePeriod-from diff to: "+timePeriod+"-"+from+"="+diff);
      updateOffsetTo(diff);
    } else {
      diff = (timePeriod-from);
      log.debug("getFillerBarStateStyle timePeriod-from diff to: "+timePeriod+"-"+from+"="+diff);
      updateOffsetFrom(diff);
    }
    //diff = diff/1000/60; // diff in minutes
    let distance = 0; //(1000*60*60)/(1000*60/6*3)/60*diff; //broken down to minutes and then multiplied with diff

    let style = {width:"100%", maxHeight:distance+"px", minHeight:distance+"px", margin: "0", padding: "0", display: "block"};
    style.backgroundColor = "rgb(255,0,0)";
    
    return style;
  }
  
  //BEGIN -- Popup Dialog Info stuff
  function zoomIn(e, periodState) {
    e.preventDefault();
    if(periodState) {
      if(periodState.remainingCycles>0) {
        let availableCylcesF = parseFloat(periodState.availableCylces).toFixed(2);; 
        let usedCyclesF = parseFloat(periodState.usedCycles).toFixed(2); 
        let remainingCyclesF = parseFloat(availableCylcesF-usedCyclesF).toFixed(2); 
        let uncoveredF = parseFloat(availableCylcesF-usedCyclesF<0?availableCylcesF-usedCyclesF:0).toFixed(2);
        periodState.availableCylcesF=availableCylcesF;
        periodState.usedCyclesF=usedCyclesF;
        periodState.remainingCyclesF=remainingCyclesF;
        periodState.uncoveredF=uncoveredF;
      } else {
        periodState = null;
      }
    } 
    setZoomeriodState(periodState);
    handleClickOpen();
  }
  function format(time) {
    let date = new Date(parseInt(time,10));      
   return date.toLocaleTimeString("de-DE");
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //END -- Popup Dialog Info stuff

  return (
    <Fragment>   
    { 
      log.debug("render !")
    }
      { availableMax>0 && resourcePeriodStatusDTOMap && resourcePeriodStatusDTOMap.length>0 && 
        <span style={getFillerBarStateStyle(resourcePeriodStatusDTOMap[0])} onClick={(e) => zoomIn(e)}/> }
      { availableMax>0 && resourcePeriodStatusDTOMap && 
            resourcePeriodStatusDTOMap.map((value, index) => (
                
                <span key={index} style={getBarStateStyle(value, index)} onClick={(e) => zoomIn(e,value)}/>
            
      ))} 
      
      

      { availableMax>0 &&  resourcePeriodStatusDTOMap && resourcePeriodStatusDTOMap.length>0 && 
        <span style={getFillerBarStateStyle(resourcePeriodStatusDTOMap[resourcePeriodStatusDTOMap.length-1], true)} onClick={(e) => zoomIn(e)}/> }
      <Dialog
            open={open}
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{zoomPeriodState? t("details_to")+" "+format(zoomPeriodState.periodStart): t("no_data")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {!zoomPeriodState && 
                  <Grid container>  
                    <Grid item xs={12}  >
                      <Typography component="p" variant="h4" align="center">
                        {t("no_resources_and_information")}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                {zoomPeriodState && 
                  <Grid container>  
                    <Grid item xs={12}  >
                    
                      <Grid container>  
                        <Grid item xs={3}  >
                          <Typography component="p" variant="subtitle1" align="center">
                            {t("avaiable")}   
                          </Typography>
                          <Typography component="p" variant="h4" align="center">
                            {zoomPeriodState.availableCylcesF}          
                          </Typography>    
                          <Typography component="p" variant="caption" align="center">
                            {t("cycl")}{zoomPeriodState.availableCylces==1?t("sing_end"):t("plur_end")}    
                          </Typography>                  
                        </Grid>
                        <Grid item xs={3}  >
                            <Typography component="p" variant="subtitle1" align="center">
                              {t("used")} 
                            </Typography>
                            <Typography component="p" variant="h4" align="center">
                              {zoomPeriodState.usedCyclesF}                   
                            </Typography>
                            <Typography component="p" variant="caption" align="center">
                            {t("cycl")}{zoomPeriodState.usedCycles==1?t("sing_end"):t("plur_end")}    
                            </Typography>  
                        </Grid>
                        <Grid item xs={3}  >
                          <Typography component="p" variant="subtitle1" align="center">
                            {t("remaining")}    
                          </Typography>
                          <Typography component="p" variant="h4" align="center">
                            {zoomPeriodState.remainingCyclesF}                  
                          </Typography>
                          <Typography component="p" variant="caption" align="center">
                          {t("cycl")}{zoomPeriodState.remainingCycles==1?t("sing_end"):t("plur_end")}    
                          </Typography>        
                        </Grid>
                        <Grid item xs={3}  >
                          <Typography component="p" variant="subtitle1" align="center">
                            {t("missing")}    
                          </Typography>
                          <Typography component="p" variant="h4" align="center">
                            {zoomPeriodState.uncoveredF}          
                          </Typography>
                          <Typography component="p" variant="caption" align="center">
                          {t("cycl")}{zoomPeriodState.uncovered==1?t("sing_end"):t("plur_end")}    
                          </Typography>        
                        </Grid>
                      </Grid> 
                    </Grid>
                 </Grid>
                  }                
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                {t("ah_ok")}
              </Button>
            </DialogActions>
          </Dialog>
      </Fragment>
  );
}
