import React, { useState,useContext, createContext, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppFooter from '../pages/assets/AppFooter';
import DeskHeader from '../pages/assets/DeskHeader';
import { MessageContext } from '../pages/assets/MessageProvider';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import config from 'react-global-configuration';
import SessionContext from '../frame/SessionContext';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import StatusTicket from '../ticket/assets/StatusTicket';
import EditLocation from '../pages/assets/EditLocation';
import { LocationCity, Save } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
}));




function EditDomain(props) {
  const {t} = props;
  const classes = useStyles();
  const { vDomain } = props.location;

  const [domain, getDomain] = useState(vDomain?vDomain:SessionContext.getDomain());
  const [address, setAddress] = useState(domain?domain.address:{});

  

  const [domainName, setDomainName] = useState(domain?domain.domainName:"");
  const [domainUid] = useState(domain?domain.domainUid:"");
  const [decription, setDecription] = useState(domain?domain.decription:"");
 
  const { addMessage } = useContext(MessageContext)

  const history = useHistory();

  const Address = <EditLocation />;

  useEffect(() => {    // Update the document title using the browser API 
    console.log("props: " + JSON.stringify(props));
    console.log("domain: " + JSON.stringify(domain));
    SessionContext.setDomain(domain);    
  }, [] )

  function validateForm() {
    console.log("domain: " + domain);
    return domainName && domainName.length > 0;
  }


  function deleteEntity() {
    const url = config.get('vqueueUrl')+'/api/domains/'+domain.id;
    axios.delete(url,  domain )
        .then(res => {
          history.push("/my/domains");
        }).catch(function (error) {
          addMessage("Da ging was schief. Gelöscht werden kann nur, wenn keine abhängigen Elemente mehr gibt. Bitte prüfen.");
          
        })
  }

  function handleSubmit(event) {
    if(event) {
      event.preventDefault();
    }
    console.log("Save Domain.")
    let tDomain = domain;
    if(!tDomain) {
      tDomain = {};
    }

    tDomain.domainName = domainName;
    tDomain.decription = decription;
    tDomain.address = address;

    const url = config.get('vqueueUrl')+'/api/domains';
    if(!tDomain.id) {
        axios.post(url,  tDomain )
        .then(res => {
          history.push("/my/domains");
          //addMessage(t("domain_saved"));
        }).catch(function (error) {
          console.log(error);
        })
     } else {
        axios.put(url,  tDomain )
        .then(res => {
          history.push("/my/domains");
          //addMessage(t("domain_saved"));
        }).catch(function (error) {
          console.log(error);
        })
      }
  }
  
  function getQuickActionItems() {
    let quickActionItems = [];
    quickActionItems.push(
      <Link onClick={() => handleSubmit()} style={!validateForm()?{pointerEvents: "none", color: "#ffb1b1"}:{cursor: "pointer"}}>
              <Save />            
        </Link>
    );
    return quickActionItems;
  }

  return (
    <Container maxWidth="xs"  style={{position: 'relative', marginTop:"90px", marginBottom:"100px"}}>
    
      <DeskHeader title={vDomain?t("change_domain"):t("create_domain")} backTo="/my/domains" 
        quickActions={getQuickActionItems()} />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocationCity />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("domain")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="domainName"
                variant="outlined"
                value={domainName}
                required
                fullWidth
                id="domainName"
                label={t("domain_name")}
                autoFocus
                onChange={(e) => setDomainName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{width:"100%"}}
                label={t("description")}
                variant="outlined"
                fullWidth
                value={decription}
                id="decription"
                multiline
                rows={4}
                name="decription"
                autoComplete="fcolor"
                onChange={(e) => setDecription(e.target.value)}
              />
            </Grid>
            

            <EditLocation vAddress={address} setAddress={setAddress}/>
            
         
              <Grid item  xs={6} >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!validateForm()}
                >
                  {t("save")}
                </Button> 
              </Grid>
              <Grid item  xs={6} >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => deleteEntity()}
                  disabled={!domain || !domain.id}
                >
                  {t("delete")}
                </Button>  
              </Grid>
          </Grid>
         
                    
        </form>
      </div>
      <AppFooter />
      
    </Container>
  );
}

export default withTranslation()(EditDomain);
