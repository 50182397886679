
import AppBar from '@material-ui/core/AppBar';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';
import SessionContext from '../../frame/SessionContext';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Divider, Grid, InputBase, Paper, withStyles } from '@material-ui/core';
import { Fragment } from 'react';
import { AccountBalanceWallet, AccountCircle, Album, Directions, ExitToApp, Favorite, Flare, LocationCity, MyLocation, PlayArrow, PowerSettingsNew, Search, Settings } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DirectionsRun } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import DropdownLanguage from './DropdownLanguage';




const useStyles =(theme) => ({
  titleGrid: {
    marginLeft:"1rem",
    fontSize:"1rem",
    whiteSpace: "nowrap"
  },
  root: {
    flexFlow: "nowrap"
  },
  menuButton: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  root1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-start",
    width: "100%"
  },
  root2: {
    padding: '0px 4px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 11,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  dividerH: {
    height: 1,
  }
});

class Header extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      mainMenu: false,
      filterMenu: false,
      menuTab: 1,
    };
    this.getSideMenuHeader = this.getSideMenuHeader.bind(this); 
  }


  toggleDrawer(show) {
    this.setState({
      mainMenu : show
    })
  }

  
  
  goBack() {
    if(this.props.backTo) {
      this.props.history.push(this.props.backTo);
    } else {
      this.props.history.goBack();
    }
  }

  
  

  getSideMenuHeader() {
    
   
    return <Fragment> 
      { this.props.moreMenuItems && <IconButton edge="start" onClick={(e) => this.setState({ menuTab:1 })} style={this.state.menuTab==1?{borderBottom:"solid 1px", background:"#c3d7ba"}:{}} >
        <Flare />
      </IconButton>
      }
      
     <IconButton onClick={(e) => this.setState({ menuTab:2 })} style={this.state.menuTab==2?{borderBottom:"solid 1px", background:"#c3d7ba"}:{}} >
            <Directions />
      </IconButton>
      
      <IconButton onClick={(e) => this.setState({ menuTab:3 })} style={this.state.menuTab==3?{borderBottom:"solid 1px", background:"#c3d7ba"}:{}} >
            <DirectionsRun />
      </IconButton>
      
    </Fragment>
    
  }

  

  getGridContent(props) {
    const { t } = this.props;
    
    let mainContent = [
      {
        icon:<PlayArrow />,
        title: t("start_page"),               
        text: t("lets_go"),               
        link:"/",
        id: "startpage"
      },
      {
        icon:<Favorite />,
        title: t("favourite_title"),               
        text: t("for_you_hint"),               
        link:"/favourites"
      },
      {
        icon:<AccountBalanceWallet />,
        title: t("wallet_title"),               
        text: t("for_you_hint"),               
        link:"/wallet"
      },
      {
        icon:<Search />,
        title: t("suche_title"),  
        text: t("suche_hint"),        
        link:"/search"
      },
      
      {
        title: t("settings"),
        icon:<Settings />,          
        text:t("set_settings"),
        link:"/settings"
      }
      
    ];

    


    if(SessionContext.isLoggedIn()) {
      let manageContent = [{
        icon:<LocationCity />,
        title: t("domain_title"),
        text: t("verwaltung_hint"),
        link:"/my/domains"
      },
      {
        icon:<MyLocation />,
        title: t("my_places"),        
        text: t("verwaltung_hint"),        
        link:"/my/places"
      }];
      mainContent = mainContent.concat(manageContent)
    }   
    

    let functionsUser = [];

    

    if(SessionContext.isLoggedIn()) {
      
      functionsUser.push(
        {
          title:<span> {t("hello")} { SessionContext.getUser().firstName+" "+ SessionContext.getUser().lastName }</span>,
          icon: <AccountCircle />,
          text: t("my_account"),
          link: "/myAccount"
        }
      );
      functionsUser.push(
        {
          title:<span> {t("logout_de")} </span>,
          icon: <PowerSettingsNew />,
          text: t("logout_de"),
          link: "/logout"
        }
      );
      
      
    } else {
      functionsUser.push(
        {
          title:<span> {t("not_logged_in")} </span>,
          icon: <ExitToApp />,
          text: t("my_account"),
          link: "/login"
        }
      );
      functionsUser.push(
        {
          title:<span> {t("to_login")} </span>,
          icon: <AccountBoxIcon />,
          text: t("registration"),
          link: "/login"
        }
      );
    }
    functionsUser.push(
      {
        language: <DropdownLanguage/>
      }
    )

    if(this.state.menuTab==1 && this.props.moreMenuItems) {
        return this.props.moreMenuItems;
    } else if(this.state.menuTab==2) {
        return mainContent;
    } else if(this.state.menuTab==3) {
        return functionsUser;
    } 
    this.setState({ menuTab:2 })    
    return [];
  }

  getLink (value) {
    
    if(value.externalLink) {
      return <a href={value.externalLink} style={{textDecoration:"none", color:"inherit"}}>
        {<ListItemText primary={value.title} />}
      </a>
    } else if(value.action) {
      return  <ListItemText onClick={() => value.action()} primary={value.title} />
    } else if(value.to) {
      return <Link to={value.to}
              style={{color: "inherit", textDecoration: "none"}}>  
        <ListItemText primary={value.title} />
      </Link>
    } else {
      return <><Link to={{pathname: value.link}} 
              style={{color: "inherit", textDecoration: "none"}}>  
        <ListItemText primary={value.title} />
      </Link>
      <Fragment>{value.language && <DropdownLanguage/>}</Fragment></>
      
    }
  }

  render() {
    const { classes } = this.props;
    
    return (
      <header>
        <AppBar position="fixed">
        
        {
         /*  <CommonHeaderFragment backTo={this.props.backTo} title={this.props.title}/>       */
        }
              
        { (this.props.title) &&
          <Paper elevation={0} square className={classes.root1}>
            <Grid xs container justifyContent="space-between" alignItems="center"  >
              <Grid item xs={12}  className={classes.titleGrid}>
                  <span >{(this.props.title?this.props.title:SessionContext.getActiveQueue()?SessionContext.getActiveQueue().name:"")} </span>                    
              </Grid>
              <Grid item xs={12}  className={classes.titleGrid}>
                <Divider className={classes.dividerH} orientation="horizontal" />
              </Grid>
            </Grid>
          </Paper>
        }      
        <Grid xs container justifyContent="space-between" alignItems="center"  className={classes.root} >

           <Paper elevation={0} square className={classes.root1}>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"  >
                <ArrowBackIcon onClick={() => this.goBack()}/>
              </IconButton>
              { this.props.searchAction && <Fragment>
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton disabled={!this.state.searchString || this.state.searchString.length==0} className={classes.iconButton} aria-label="clear" onClick={event => this.setState({searchString : ""})}>
                    <ClearIcon />
                  </IconButton>
                  <InputBase 
                    className={classes.input}
                    placeholder={this.props.searchTitle?this.props.searchTitle:"etwas suchen"}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    value={this.state.searchString}
                    onChange={event=>{                                 //adding the onChange event
                      this.setState({searchString : event.target.value})
                    }}
                  />
                  <IconButton disabled={!this.state.searchString || this.state.searchString.length==0} type="submit" className={classes.iconButton} aria-label="search" onClick={() => this.props.searchAction(this.state.searchString)}>
                    <SearchIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />                  
                </Fragment>
              }
            </Paper>  
            <Paper elevation={0} square className={classes.root2}>
              { this.props.quickActions &&
                  this.props.quickActions.map((value, index) => (
                    value
                  ))
              }
              { 
                this.props.filterActions && this.props.filterActions                
              }
              <IconButton edge="end" className={classes.menuButton} aria-label="menu">
                <Drawer anchor="right" open={this.state.mainMenu} onClose={() => this.toggleDrawer(false)}>
                  <div
                      className={classes.list}
                      role="presentation"
                    >
                      <List>
                      <ListItem key={-1} button >
                        <Grid container>
                            { this.getSideMenuHeader() }                          
                        </Grid>
                      </ListItem> 
                      
                      {this.getGridContent(this.props).map((value, index) => (
                        <ListItem key={index} button 
                          onClick={() => this.toggleDrawer( false)}
                          onKeyDown={() => this.toggleDrawer( false)}>
                          <ListItemIcon>{value.icon}</ListItemIcon>
                          {this.getLink(value)}
                        </ListItem>                        
                      ))}                      
                      </List>
                      
                    </div>
                </Drawer>
                <MenuIcon onClick={() => this.toggleDrawer(true)}/>
              </IconButton>
              
            </Paper>
            </Grid> 
           
            
          
        </AppBar>
        
                
      </header>
    );
  }
}

export default withRouter(withStyles(useStyles)(withTranslation()(Header)))
